import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CardHeader } from '../utils';
import { loadSecondaryThresholds } from '../../actions/fdd/FDDSecondaryThresholdActions';
import FDDSecondaryThresholdRow from './FDDSecondaryThresholdRow';

class FDDThresholdsTable extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadSecondaryThresholds());
  }

  render() {
    const { fddSecondaryThresholds } = this.props;

    return (
      <Card>
        <CardHeader>Second Seuil FDD</CardHeader>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="div">Seuil</TableCell>
              <TableCell component="div" />
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {fddSecondaryThresholds.map((threshold) => (
              <FDDSecondaryThresholdRow
                key={threshold.id}
                threshold={threshold}
                editing={Boolean(threshold.editing)}
                disabled={!threshold.id}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
    );
  }
}

const stateToProps = ({ fddSecondaryThresholds }) => ({
  fddSecondaryThresholds,
});

export default connect(stateToProps)(FDDThresholdsTable);
