import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { LabelValue, TextField } from '../utils';
import { INTERVENTION_STATUSES } from '../../constants/AppConstants';

class InterventionGlobalInformation extends React.Component {
  static propTypes = {
    subcontractorReference: PropTypes.string,
    teamCode: PropTypes.string,
    comment: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    hideComment: PropTypes.bool,
    status: PropTypes.string,
  };

  onChange = (fieldName) => (event) => {
    const { onChange } = this.props;
    const { value } = event.target;

    if (onChange) {
      onChange(fieldName)(value);
    }
  };

  render() {
    const { readOnly, hideComment, status, comment, subcontractorReference, teamCode, children } = this.props;

    return (
      <Grid container spacing={2}>
        {status && (
          <Grid item xs={12}>
            <LabelValue label="Statut">{INTERVENTION_STATUSES.getNameById(status)}</LabelValue>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            label="Référence prestataire"
            fullWidth
            name="subcontractor"
            onChange={this.onChange('subcontractorReference')}
            value={subcontractorReference}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            label="Code Équipe"
            fullWidth
            name="teamCode"
            onChange={this.onChange('teamCode')}
            value={teamCode}
          />
        </Grid>
        {!hideComment && (
          <Grid item xs={12}>
            <TextField
              disabled={readOnly}
              label="Commentaires"
              fullWidth
              plceholder={"Exemple : pose d'une borne"}
              multiline
              name="comment"
              onChange={this.onChange('comment')}
              value={comment}
            />
          </Grid>
        )}
        {children}
      </Grid>
    );
  }
}

export default InterventionGlobalInformation;
