import debugFactory from 'debug';

import {
  INTERVENTION_ATTACHMENTS_DELETE,
  INTERVENTION_ATTACHMENTS_ITEM,
  INTERVENTION_ATTACHMENTS_LIST,
  apiBase,
  urls,
} from '../constants/AppConstants';
import httpService from '../services/HttpService';
import { addGlobalError } from './SnackbarActions';
import { getErrorMessage } from '../services/ErrorMessageService';
import { escapeFileName } from '../utils/attachment-utils';

const debug = debugFactory('prestago:InterventionAttachmentsActions');

function attachmentUrl(interventionId, attachmentId, fileName) {
  return `${apiBase}/${urls.interventions}/${interventionId}/attachments${
    attachmentId && fileName ? `/${attachmentId}/${escapeFileName(fileName)}` : ''
  }`;
}

/**
 * Returns the action to store in Redux all the intervention attachments.
 *
 * @param attachments The existing attachments.
 * @returns {{type, attachments: *}} The action to store the attachments in Redux.
 */
export function interventionAttachments(attachments) {
  return {
    type: INTERVENTION_ATTACHMENTS_LIST,
    attachments,
  };
}

export function addAttachments(interventionId, acceptedFiles, rejectedFiles) {
  return (dispatch) => {
    debug('addAttachments %o %o %s', acceptedFiles, rejectedFiles, interventionId);
    acceptedFiles.forEach((file) => {
      const fileName = file.attachmentName || file.name;

      /*
       * Adding the files to the list in Redux until it is stored in the back-end.
       */
      dispatch({
        type: INTERVENTION_ATTACHMENTS_ITEM,
        attachment: {
          name: fileName,
          type: file.type,
          thumbnail: file.type.startsWith('image/') ? file.preview : null,
          uploading: true,
        },
      });

      /*
       * Calling the back-server to add the files
       */
      const formData = new FormData();
      formData.append('file', file, fileName);
      httpService
        .post({
          url: attachmentUrl(interventionId),
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => Promise.reject(json));
          }
          return response.json();
        })
        /*
         * Replacing the temporary attachment in Redux by the
         * server version (without the "uploading" flag).
         */
        .then((json) =>
          dispatch({
            type: INTERVENTION_ATTACHMENTS_ITEM,
            attachment: json,
          }),
        )
        /*
         * If there was an error server-side, a message is displayed and
         * the temporary attachment is removed from Redux.
         */
        .catch((err) => {
          console.error('Error while saving the attachment:', err);
          dispatch(addGlobalError(getErrorMessage(err)));
          dispatch({
            type: INTERVENTION_ATTACHMENTS_DELETE,
            id: null,
            name: fileName,
          });
        });
    });
  };
}

export function deleteAttachment(interventionId, attachmentId, attachmentName) {
  return (dispatch) => {
    /*
     * Removing the attachment from the Redux store
     */
    dispatch({
      type: INTERVENTION_ATTACHMENTS_DELETE,
      id: attachmentId,
      name: attachmentName,
    });

    /*
     * Calling the back-end server to delete the attachment.
     */
    httpService
      .delete({
        url: attachmentUrl(interventionId, attachmentId, attachmentName),
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response;
      })
      .catch((err) => {
        console.error('Error while deleting the attachment:', err);
        dispatch(addGlobalError(getErrorMessage(err)));
      });
  };
}
