import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Switch,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';

import { EditableTableRow } from '../utils';
import { deleteAgreementType, saveAgreementType } from '../../actions/fdd/FDDAgreementTypeActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';

class FDDAgreementTypeRow extends Component {
  static propTypes = {
    agreementType: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
  };

  state = {
    agreementType: {},
    editing: this.props.editing,
    deleting: false,
  };

  onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { agreementType } = this.props;
    this.setState(({ editing }) => (editing ? null : { editing: true, agreementType }));
  };

  onChange = (fieldName) => (value) => {
    this.setState(({ agreementType }) => ({
      agreementType: {
        ...agreementType,
        [fieldName]: value,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Case enter is pressed
      this.onSave(e);
    } else if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, agreementType } = this.props;

    if (!agreementType.id) {
      dispatch(deleteAgreementType(agreementType));
      return;
    }

    this.setState({
      agreementType: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;

    const { agreementType } = this.state;
    dispatch(saveAgreementType(agreementType))
      .then(() => {
        this.setState({ editing: false });
        dispatch(addGlobalMessage('Modifications sauvegardées'));
      })
      .catch((error) => {
        console.error('Error saving agreement type: ', error);
      });
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, agreementType } = this.props;
    dispatch(deleteAgreementType(agreementType));
    dispatch(addGlobalMessage(`Nature d'accord supprimée : ${agreementType.name}`));
  }

  render() {
    const { agreementType, editing, deleting } = this.state;
    const { editable, agreementType: agreementTypeProp, dispatch, ...props } = this.props;

    return (
      <EditableTableRow
        {...props}
        editable
        editing={editing}
        onEdit={(e) => this.onEditClick(e)}
        actions={
          <>
            <Tooltip title="Enregistrer la nature d'accord">
              <IconButton onClick={this.onSave} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(agreementTypeProp.id) && (
              <Tooltip title="Supprimer la nature d'accord">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <TableCell>
          {editing ? (
            <TextField
              name="name"
              autoFocus
              value={agreementType.name}
              onChange={(e) => this.onChange('name')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <span>{agreementTypeProp.name}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Switch
              name="canBeSent"
              autoFocus
              checked={agreementType.canBeSent}
              onClick={(e) => this.onChange('canBeSent')(!agreementType.canBeSent)}
            />
          ) : (
            <span>{agreementTypeProp.canBeSent ? 'Oui' : 'Non'}</span>
          )}
        </TableCell>
        <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer la nature d'accord {agreementTypeProp.name} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </EditableTableRow>
    );
  }
}

export default connect()(FDDAgreementTypeRow);
