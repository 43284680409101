import {
  EQUIPMENT_DELETION_ERRORS,
  EQUIPMENT_ERROR,
  EQUIPMENT_LOADED,
  EQUIPMENT_LOADING,
  EQUIPMENT_RESET,
  EQUIPMENT_VALIDATION_ERRORS,
  EQUIPMENTS_LOADED,
  SORT_EQUIPMENTS,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError, addGlobalMessage } from '../SnackbarActions';
import history from '../../history';
import { AppDispatch } from '../../store';
import { Equipment } from '../../model/model';
import { SortDirection } from '../../model/pagination';

/**
 * The REST collection used to manage equipment resources.
 */
const equipmentsCollection = api.all(urls.settings.equipments);

export const resetEquipment = () => ({
  type: EQUIPMENT_RESET,
});

export const loadEquipment = (id: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: id ? EQUIPMENT_LOADING : EQUIPMENT_RESET,
  });
  if (!id) {
    return;
  }

  equipmentsCollection
    .get(id)
    .then((response) => {
      const equipment: Equipment = response.body(false);
      dispatch({
        type: EQUIPMENT_LOADED,
        equipment,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading equipment: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement du matériel'));
      dispatch({
        type: EQUIPMENT_ERROR,
        error: 'Une erreur a eu lieu pendant le chargement du matériel',
      });
    });
};

export const saveOrUpdateEquipment = (equipment: Equipment) => {
  const request = equipment.id
    ? equipmentsCollection.put(equipment.id, equipment)
    : equipmentsCollection.post(equipment);

  return (dispatch) => {
    request
      .then((response) => {
        if (!equipment.id) {
          const eq: Equipment = response.body(false);
          dispatch(addGlobalMessage('Le matériel a été créé.'));
          dispatch(loadEquipment(eq.id));
          history.push(`/settings/equipments/${eq.id}`);
        } else {
          dispatch(loadEquipment(equipment.id));
          dispatch(addGlobalMessage('Le matériel a été enregistré'));
        }
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving or updating equipment:', err);
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch({
            type: EQUIPMENT_VALIDATION_ERRORS,
            errors: err.response.data.errors,
          });
          dispatch(addGlobalError('Le formulaire contient des erreurs'));
        } else {
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      });
  };
};

export const deleteEquipment = (id: string) => (dispatch: AppDispatch) => {
  equipmentsCollection
    .delete(id)
    .then(() => {
      dispatch(addGlobalMessage('Le matériel a été supprimé.'));
      history.push('/settings/equipments');
    })
    .catch(ignore401)
    .catch((err) => {
      console.error('Error while deleting equipment:', err);
      if (err.response && err.response.data && err.response.data.errors && err.response.statusCode === 400) {
        dispatch({
          type: EQUIPMENT_DELETION_ERRORS,
          errors: err.response.data.errors[0].rejectedValue,
        });
      }
      dispatch(addGlobalError("Le matériel n'a pas pu être supprimé"));
    });
};

export const loadEquipments = () => (dispatch: AppDispatch) => {
  equipmentsCollection
    .getAll()
    .then((response) => {
      const equipments = response.body(false);
      dispatch({
        type: EQUIPMENTS_LOADED,
        equipments,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading equipments: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement des matériels'));
    });
};

export const loadActiveEquipmentsWithSerialNumber = () => (dispatch: AppDispatch) => {
  equipmentsCollection
    .custom('with-serial-number/active')
    .get()
    .then((response) => {
      const equipments = response.body(false);
      dispatch({
        type: EQUIPMENTS_LOADED,
        equipments,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading equipments: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement des matériels'));
    });
};

export const sortEquipments = (field: string, direction: SortDirection) => ({
  type: SORT_EQUIPMENTS,
  field,
  direction,
});
