import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debugFactory from 'debug';
import { Button, Card } from '@mui/material';

import { DEMAND_TRANSITIONS } from '../../constants/demandTransitions';
import DemandTransition from './DemandTransition';
import DemandCost from './DemandCost';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import InterventionCreation from '../interventions/InterventionCreation';
import { checkCanCreateIntervention, checkDemandCanBeModified } from '../../services/WorkFlowService';
import { FormActions } from '../utils';
import { COMPANIES } from '../../constants/AppConstants';

const debug = debugFactory('prestago:DemandButtons');

const DemandButtons = ({
  demandId,
  demandNumber,
  demandStatus,
  readOnly,
  onDemandFormEdit,
  onDemandFormCancel,
  onDemandFormSubmit,
  onDemandEstimateCost,
  onDemandUpdateCost,
  demandCanBeModified,
  canCreateIntervention,
  canUpdateCost,
  transitions,
  dispatch,
}) => {
  /*
   * The buttons to display.
   */
  const leftActions = [];
  const rightActions = [];

  rightActions.push(
    <DemandCost
      key="cost"
      onDemandEstimateCost={onDemandEstimateCost}
      onDemandUpdateCost={onDemandUpdateCost}
      showUpdateCost={readOnly && canUpdateCost}
    />,
  );

  if (readOnly) {
    if (demandCanBeModified) {
      rightActions.push(
        <Button variant="contained" key="edit" onClick={onDemandFormEdit}>
          Modifier
        </Button>,
      );
    }

    if (demandId && demandNumber && canCreateIntervention) {
      rightActions.push(
        <InterventionCreation
          key="intervention-creation"
          demandId={demandId}
          demandNumber={demandNumber}
          demandStatus={demandStatus}
        />,
      );
    }
    transitions.forEach((tr) =>
      (tr.left ? leftActions : rightActions).push(
        <DemandTransition
          key={tr.id}
          dispatch={dispatch}
          transition={tr}
          demandId={demandId}
          demandNumber={demandNumber}
        />,
      ),
    );
  } else {
    rightActions.push(
      <Button variant="contained" key="edit-cancel" onClick={onDemandFormCancel}>
        Annuler
      </Button>,
    );
    rightActions.push(
      <Button variant="contained" key="edit-save" color="primary" onClick={onDemandFormSubmit}>
        Enregistrer
      </Button>,
    );
  }

  if (!leftActions.length && !rightActions.length) {
    return null;
  }

  return (
    <Card>
      <FormActions leftActions={leftActions} rightActions={rightActions} />
    </Card>
  );
};

DemandButtons.propTypes = {
  demandCanBeModified: PropTypes.bool.isRequired,
  demandId: PropTypes.string,
  demandNumber: PropTypes.number,
  demandStatus: PropTypes.string,
  onDemandFormCancel: PropTypes.func.isRequired,
  onDemandFormEdit: PropTypes.func.isRequired,
  onDemandFormSubmit: PropTypes.func.isRequired,
  onDemandEstimateCost: PropTypes.func.isRequired,
  onDemandUpdateCost: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  transitions: PropTypes.array.isRequired,
};

function isTransitionCompatibleWithUserCompany(transition, userCompany, demandSubcontractorId) {
  return transition.subcontractor ? userCompany.id === demandSubcontractorId : !userCompany.isSubcontractor;
}

function isTransitionCompatibleWithInterventions(transition, demand) {
  return !transition.withoutInterventions || !demand?.interventions?.length;
}

function availableTransitions(currentUser, demandStatus, demand) {
  if (!currentUser.user) {
    return [];
  }
  const userCompany = COMPANIES.getById(currentUser.user.company);
  debug('availableTransitions', userCompany, demand.demand.subcontractor);
  return DEMAND_TRANSITIONS.filter(
    (transition) =>
      transition.from.includes(demandStatus) &&
      isTransitionCompatibleWithUserCompany(transition, userCompany, demand.demand.subcontractor) &&
      hasRole(currentUser, transition.role.code) &&
      isTransitionCompatibleWithInterventions(transition, demand.demand),
  );
}

export default connect((state, ownProps) => ({
  canCreateIntervention: checkCanCreateIntervention(state.currentUser, ownProps.demandStatus),
  transitions: availableTransitions(state.currentUser, ownProps.demandStatus, state.demand),
  demandCanBeModified: checkDemandCanBeModified(state.currentUser, ownProps.demandStatus),
  canUpdateCost: hasRole(state.currentUser, roles.demand.updateCost.code),
}))(DemandButtons);
