import { compose } from 'recompose';

import UserProfilesTable from '../../user-profiles/UserProfilesTable';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { withPageTitle } from '../../../utils/page-title';

export default compose(
  withPageTitle(() => 'Paramétrage des profils utilisateurs'),
  securedPage(roles.profile.view.code),
)(UserProfilesTable);
