/**
 * Build a debounce method. Each call to the builder generates a debounce method that operates independently.
 * This should be called only to create a singleton, never from inside a render method
 * (otherwise the reference to the timeoutID will be lost between calls).
 */
const buildDebounce = (delayMilli: number = 200) => {
  let timeoutID = 0;
  return (handler: TimerHandler, ...arg: any) => {
    if (timeoutID) {
      window.clearTimeout(timeoutID);
    }
    timeoutID = window.setTimeout(handler, delayMilli, ...arg);
  };
};

export default buildDebounce;
