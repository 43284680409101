import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import OperationBundlesTable from '../../operations/OperationBundlesTable';
import { withPageTitle } from '../../../utils/page-title';

const OperationBundlesPage = () => <OperationBundlesTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des forfaits'),
  securedPage(roles.equipment.view.code),
)(OperationBundlesPage);
