import React from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { avatarColors } from '../utils/colors';

function fullName({ firstName, lastName, login }) {
  if (firstName || lastName) {
    return `${firstName} ${lastName}`;
  }
  return login;
}

const MenuAvatar = ({ user, dispatch, ...props }) => (
  <div {...props}>
    {user ? <Avatar email={user.login} name={fullName(user)} size={47} round colors={avatarColors} /> : <div />}
  </div>
);

const stateToProps = ({ currentUser: { user } }) => ({
  user,
});
export default connect(stateToProps)(MenuAvatar);
