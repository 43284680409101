import { FC, useEffect, useState } from 'react';
import { StockBatchElement } from '../../model/model';
import { Button, Card, Grid, Table, TableBody, Typography } from '@mui/material';
import Formsy from 'formsy-react';
import { FormActions } from '../utils';
import { v4 as uuid } from 'uuid';
import { useAppDispatch } from '../../hooks';
import StockEquipmentRow from './StockEquipmentRow';
import { useNavigate } from 'react-router-dom';
import { loadActiveEquipmentsWithSerialNumber } from '../../actions/settings/EquipmentActions';
import { addGlobalError } from '../../actions/SnackbarActions';
import { sendInventory } from '../../actions/StockEquipmentActions';

type StockEquipmentInventoryFieldProps = {
  stockOutletCode: string;
};

const StockEquipmentInventory: FC<StockEquipmentInventoryFieldProps> = ({ stockOutletCode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadActiveEquipmentsWithSerialNumber());
  }, [dispatch]);

  const getEmptyItem = (): StockBatchElement => {
    return {
      id: uuid(),
      serialNumber: null,
      equipmentName: null,
      equipmentCode: null,
    };
  };

  const [items, setItems] = useState([getEmptyItem()]);

  const onSave = () => {
    const itemsToSave = items.filter((item) => item.serialNumber);
    if (itemsToSave?.length) {
      dispatch(sendInventory(stockOutletCode, itemsToSave));
    } else {
      dispatch(addGlobalError('Veuillez renseigner au moins un matériel.'));
    }
  };

  const onRemoveItem = (id: string) => {
    let newItems = items.filter((item) => item.id !== id);
    if (!newItems.length) {
      newItems = [getEmptyItem()];
    }
    setItems(newItems);
  };

  const onEquipmentChange = (stockEquipment: StockBatchElement) => {
    let newItems: StockBatchElement[] = items.map((item) => {
      return item.id === stockEquipment.id
        ? {
            ...item,
            serialNumber: stockEquipment?.serialNumber ?? null,
            equipmentCode: stockEquipment?.equipmentCode ?? null,
            equipmentName: stockEquipment?.equipmentName ?? null,
          }
        : item;
    });

    if (newItems.every((item) => item.serialNumber)) {
      setItems([...newItems, getEmptyItem()]);
    } else {
      setItems(newItems);
    }
  };

  return (
    <Formsy onValidSubmit={onSave} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Saisie d'inventaire</Typography>
        </Grid>

        <Grid item container xs={12} marginTop={2}>
          <Card>
            <Table>
              <TableBody>
                {items.map((item: StockBatchElement) => (
                  <StockEquipmentRow
                    key={item.id}
                    stockBatchElement={item}
                    onRemoveItem={onRemoveItem}
                    onEquipmentChange={onEquipmentChange}
                    handleState={false}
                  />
                ))}
              </TableBody>
            </Table>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <FormActions
              leftActions={[]}
              rightActions={[
                <Button key="cancel" variant="contained" onClick={() => navigate(-1)}>
                  Annuler
                </Button>,
                <Button key="save" variant="contained" color="primary" type="submit">
                  Valider l'inventaire
                </Button>,
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </Formsy>
  );
};

export default StockEquipmentInventory;
