import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ChevronDown } from 'mdi-material-ui';
import { makeStyles } from '@mui/styles';

type SubMenuProps = PropsWithChildren<{
  /**
   * Route prefix that determines whether the submenu is opened on the first render.
   */
  routePrefix: string;
  /**
   * Menu label.
   */
  label: string;
  /**
   * Menu icon.
   */
  icon?: ReactNode;
}>;

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.grey[100],
  },
  activeText: {
    color: '#000',
    fontWeight: 500,
  },
  expand: {
    transform: 'rotate(0deg)',
    margin: '-16px 0 -16px auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const SubMenu = ({ routePrefix, label, icon, children }: SubMenuProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const active = pathname?.startsWith(routePrefix);
  const [opened, setOpened] = useState(active);

  return (
    <>
      <ListItemButton onClick={() => setOpened(!opened)} className={classNames({ [classes.active]: active })}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText disableTypography inset={!icon}>
          <Typography variant="body1" className={classNames({ [classes.activeText]: active })}>
            {label}
          </Typography>
        </ListItemText>
        <ChevronDown
          className={classNames(classes.expand, {
            [classes.expandOpen]: opened,
          })}
        />
      </ListItemButton>
      <Collapse in={opened} timeout="auto" unmountOnExit>
        <List>{children}</List>
      </Collapse>
    </>
  );
};

export default SubMenu;
