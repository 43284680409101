/**
 * @deprecated Will be removed soon.
 */
import fetch from 'isomorphic-fetch';

const csrfToken = () => {
  const cookie = document.cookie;
  if (cookie.indexOf('XSRF-TOKEN') < 0) {
    return null;
  }

  /*
   * The regular expression was found here:
   * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
   */
  return cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
};

const executeRequest =
  (method) =>
  ({ url, headers = {}, body }) => {
    const token = csrfToken();
    const fullHeaders = {
      ...headers,
      'X-XSRF-TOKEN': token,
    };

    return fetch(url, {
      method,
      headers: fullHeaders,
      credentials: 'same-origin',
      body,
    });
  };

const httpService = {
  get: executeRequest('GET'),
  post: executeRequest('POST'),
  put: executeRequest('PUT'),
  delete: executeRequest('DELETE'),
};

export default httpService;
