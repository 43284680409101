import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormsy } from 'formsy-react';
import { DialogContentText, Grid } from '@mui/material';

import { ATTACHMENT_OPTIONS } from '../../constants/demandTransitions';
import { addTransitionAttachment, deleteTransitionAttachment } from '../../actions/DemandTransitionActions';
import AttachmentsZone from '../utils/AttachmentsZone';

class DemandTransitionAttachments extends React.Component {
  static propTypes = {
    demandId: PropTypes.string.isRequired,
    transition: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setValue(this.props.attachments);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.attachments !== nextProps.attachments) {
      this.props.setValue(nextProps.attachments);
    }
  }

  onAdd = (files) => {
    const { dispatch, demandId } = this.props;
    dispatch(addTransitionAttachment(files, demandId));
  };

  onRemove = (attachmentId, attachmentName) => {
    const { dispatch, demandId } = this.props;
    dispatch(deleteTransitionAttachment(demandId, attachmentId, attachmentName));
  };

  render() {
    const { attachments, transition, dispatch } = this.props;
    if (transition.attachments === ATTACHMENT_OPTIONS.NONE) {
      return null;
    }

    const errorMessage = this.props.isPristine ? '' : this.props.errorMessage;
    return (
      <>
        <Grid item xs={12}>
          <AttachmentsZone
            attachments={Object.values(attachments)}
            dispatch={dispatch}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            cols={4}
          />
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <DialogContentText color="error">{errorMessage}</DialogContentText>
          </Grid>
        )}
      </>
    );
  }
}

const stateToProps = (state) => ({
  attachments: state.demandTransitionAttachments,
});
export default compose(connect(stateToProps), withFormsy)(DemandTransitionAttachments);
