import React from 'react';
import * as PropTypes from 'prop-types';
import { CardContent, Fab, Tooltip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Plus as ContentAdd } from 'mdi-material-ui';
import classNames from 'classnames';

import { Secured } from './index';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    position: 'relative',
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    padding: 16,
  },
  addButton: {
    position: 'absolute',
    right: 25,
    bottom: -26,
    zIndex: 1,
  },
  actions: {
    paddingRight: 16,
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 18px)',
  },
  actionsWithAdd: {
    right: 80,
  },
});

const CardHeader = ({ addLabel, addRole, onAdd, addDisabled, actions, children, classes }) => (
  <CardContent className={classes.root}>
    <Typography variant="h2" className={classes.title}>
      {children}
    </Typography>
    {actions && (
      <div
        className={classNames(classes.actions, {
          [classes.actionsWithAdd]: Boolean(addLabel && onAdd && !addDisabled),
        })}
      >
        {actions}
      </div>
    )}
    {addLabel && onAdd && !addDisabled && (
      <Secured requiredRole={addRole}>
        <Tooltip title={addLabel}>
          <Fab color="primary" className={classes.addButton} onClick={onAdd}>
            <ContentAdd />
          </Fab>
        </Tooltip>
      </Secured>
    )}
  </CardContent>
);

CardHeader.propTypes = {
  addLabel: PropTypes.string,
  addDisabled: PropTypes.bool,
  addRole: PropTypes.string,
  onAdd: PropTypes.func,
  children: PropTypes.node,
};

export default withStyles(styles)(CardHeader);
