import { useParams } from 'react-router-dom';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import StockEquipmentInventory from '../../stock-equipments/StockEquipmentInventory';

const StockEquipmentInventoryPage = () => {
  usePageTitle("Saisie d'inventaire");
  const { stockOutletCode } = useParams();

  return <StockEquipmentInventory stockOutletCode={stockOutletCode} />;
};

export default securedPage(roles.stockEquipments.edit.code)(StockEquipmentInventoryPage);
