import { OUTLET_LOADED, OUTLET_RESET, OUTLET_ERROR } from '../constants/AppConstants';

const initialState = {
  outlet: null,
  error: null,
};

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    // OUTLET_SAVED : nothing to do at the moment. A message is displayed in the action
    case OUTLET_LOADED:
      return {
        ...state,
        outlet: action.outlet,
        error: null,
      };
    case OUTLET_RESET:
      return {
        ...state,
        outlet: null,
        error: null,
      };
    case OUTLET_ERROR:
      return {
        ...state,
        error: action.err,
      };
    default:
      return state;
  }
}
