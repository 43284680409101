import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { usePageTitle } from '../../utils/page-title';

const NotFoundPage = () => {
  usePageTitle('Page non trouvée');
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h1">Page non trouvée</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="body1">La page que vous avez demandée n'existe pas.</Typography>
            <Link component={RouterLink} variant="body1" to="/">
              Retour à l'accueil
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
