import { OPERATION_GROUPS_LOADED } from '../../constants/AppConstants';

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPERATION_GROUPS_LOADED:
      return {
        ...state,
        list: action.operationGroups,
      };
    default:
      return state;
  }
}
