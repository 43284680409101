import React from 'react';
import { connect } from 'react-redux';
import { CardContent, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Formsy from 'formsy-react';
import debugFactory from 'debug';

import DateViewer from '../commons/DateViewer';
import { ORACLE_COMMAND_STATUSES } from '../../constants/AppConstants';
import { updateCommand } from '../../actions/InterventionActions';
import { AutocompleteField, ResponsiveTable } from '../utils';

const debug = debugFactory('prestago:InterventionCommands');

const getOutletLabel = (isAgency) => (isAgency ? 'Zone de vente' : 'Point de vente');

class InterventionCommands extends React.Component {
  onDeliveryLocationChange = (commandIndex) => (value) => {
    const { interventionId, dispatch } = this.props;
    debug('onDeliveryLocationChange %s - %d -> %s', interventionId, commandIndex, value);
    dispatch(updateCommand(interventionId, commandIndex, value));
  };

  render() {
    const { oracleCommands, deliveryLocations, readOnly, interventionStatus, isAgency } = this.props;
    return !oracleCommands || !oracleCommands.length ? (
      <CardContent>
        <Typography variant="body1">Aucune commande Oracle dans cette intervention.</Typography>
      </CardContent>
    ) : (
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Lieu de livraison</TableCell>
            <TableCell>N° commande</TableCell>
            <TableCell>Date de traitement</TableCell>
            <TableCell>Statut</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {oracleCommands.map(({ index, deliveryToOutlet, deliveryLocationId, code, inclusionDate, status }) => {
            const readOnlyDelivery = readOnly || isAgency || (status && status !== 'NOT_SENT' && status !== 'ERROR');
            const deliveryLocation =
              !deliveryToOutlet &&
              deliveryLocationId &&
              deliveryLocations &&
              deliveryLocations.getById(deliveryLocationId);
            const oracleStatus = ORACLE_COMMAND_STATUSES.getById(status);
            return (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                {readOnlyDelivery ? (
                  <TableCell>
                    {deliveryToOutlet ? getOutletLabel(isAgency) : deliveryLocation && deliveryLocation.name}
                  </TableCell>
                ) : (
                  <TableCell>
                    <Formsy>
                      <AutocompleteField
                        options={deliveryLocations}
                        required
                        name="deliveryLocationId"
                        onChange={this.onDeliveryLocationChange(index)}
                        value={deliveryLocationId}
                      />
                    </Formsy>
                  </TableCell>
                )}
                <TableCell>{code}</TableCell>
                <TableCell>
                  <DateViewer date={inclusionDate} />
                </TableCell>
                <TableCell>
                  {oracleStatus && oracleStatus.icon}{' '}
                  {interventionStatus < '_020_SCHEDULED'
                    ? 'En attente de planification'
                    : oracleStatus && oracleStatus.name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </ResponsiveTable>
    );
  }
}

const stateToProps = ({
  intervention: {
    intervention: { id: interventionId, status: interventionStatus, oracleCommands, area },
  },
  deliveryLocations,
}) => ({
  interventionId,
  interventionStatus,
  oracleCommands,
  deliveryLocations,
  isAgency: !area || !area.id,
});

export default connect(stateToProps)(InterventionCommands);
