import {
  PROFILE_EDIT_START,
  PROFILE_SAVE_SUCCESS,
  PROFILE_EDIT_ERROR,
  PROFILE_EDIT_CANCEL,
} from '../constants/AppConstants';

const initialState = {
  open: false,
  editing: false,
  error: null,
};

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case PROFILE_EDIT_START:
      return {
        ...state,
        open: true,
        editing: false,
        error: null,
      };
    case PROFILE_EDIT_CANCEL:
      return {
        ...state,
        open: false,
        editing: false,
        error: null,
      };
    case PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        open: false,
        editing: false,
        error: null,
      };
    case PROFILE_EDIT_ERROR:
      return {
        ...state,
        open: true,
        editing: true,
        error: action.error,
      };
    default:
      return state;
  }
}
