import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';

import { TextField } from '../utils';
import PasswordInputs from './PasswordInputs';
import { changeUserPassword } from '../../actions/UserActions';

const errorMessages = {
  oldPassword: {
    isDefaultRequiredValue: 'Le mot de passe actuel est obligatoire',
    isExisty: 'Le mot de passe actuel est obligatoire',
  },
};

class ChangePassword extends Component {
  state = {
    oldPassword: null,
    newPassword: null,
  };

  form = React.createRef();

  onOldPasswordChange = (event) => {
    this.setState({ oldPassword: event.target.value });
  };

  onNewPasswordChange = (newPassword) => {
    this.setState({ newPassword });
  };

  onChangePassword = () => {
    const { dispatch } = this.props;
    const { oldPassword, newPassword } = this.state;
    dispatch(changeUserPassword(oldPassword, newPassword));
  };

  render() {
    const { oldPassword } = this.state;
    return (
      <Card>
        <CardContent>
          <Formsy onValidSubmit={this.onChangePassword} noValidate ref={this.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">Changement de mot de passe</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mot de passe actuel"
                  fullWidth
                  name="oldPassword"
                  type="password"
                  required
                  validations="isExisty"
                  validationErrors={errorMessages.oldPassword}
                  onChange={this.onOldPasswordChange}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInputs
                  onPasswordChange={this.onNewPasswordChange}
                  oldPassword={oldPassword}
                  showIcons={false}
                />
              </Grid>
            </Grid>
          </Formsy>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => this.form.current.submit()}>
            Modifier le mot de passe
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default connect()(ChangePassword);
