import { COMPANIES } from '../constants/AppConstants';
import roles from '../constants/roles';

// eslint-disable-next-line import/prefer-default-export
export class User {
  constructor(user) {
    Object.assign(this, user);
  }

  isRegional() {
    return !!this.regionId;
  }

  isAgency() {
    return !!this.agencyId;
  }

  isArea() {
    return !!(this.areasIds && this.areasIds.length);
  }

  isNational() {
    return !this.isRegional() && !this.isAgency() && !this.isArea();
  }

  isSubcontractorTech() {
    const company = COMPANIES.getById(this.company);
    return (
      company &&
      company.isSubcontractor &&
      !this.authorities.some((auth) => auth.authority === roles.subcontractor.interventionView.code)
    );
  }
}
