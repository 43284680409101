import { RELOAD_DEMAND_COSTS_STATUS_LOADED, urls } from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError, addGlobalMessage } from '../SnackbarActions';
import history from '../../history';

const reloadEndpoint = api.custom(urls.settings.reloadDemandCosts);
const addEndpoint = api.custom(urls.settings.addToDemands);

export const resetReloadDemandCostsStatus = () => (dispatch) => {
  dispatch({
    type: RELOAD_DEMAND_COSTS_STATUS_LOADED,
    statuses: [],
  });
};

export const loadReloadDemandCostsStatus = () => (dispatch) => {
  reloadEndpoint
    .get()
    .then((response) => {
      const statuses = response.body(false);
      dispatch({
        type: RELOAD_DEMAND_COSTS_STATUS_LOADED,
        statuses,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading reload demand costs status: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement du statut de la mise à jour des tarifs'));
    });
};

export const reloadDemandCosts = (date, equipment) => (dispatch) => {
  reloadEndpoint
    .post({
      equipmentId: equipment && equipment.id,
      date,
    })
    .then(() => {
      dispatch(addGlobalMessage(`Mise à jour des tarifs en cours${equipment ? ` pour ${equipment.name}` : ''}`));
      history.push('/settings/reload-demands');
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while reloading demand costs: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le déclenchement de la mise à jour des tarifs'));
    });
};

export const addOperationToDemands =
  ({ bundleId, operationType, operationIndex, optionId, afterSalesId }) =>
  (dispatch) => {
    addEndpoint
      .post({
        bundleId,
        operationType,
        operationIndex,
        optionId,
        afterSalesId,
      })
      .then(() => {
        dispatch(addGlobalMessage('Ajout aux demandes en cours'));
        history.push('/settings/reload-demands');
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while adding to demands: ', error);
        dispatch(addGlobalError("Une erreur a eu lieu pendant le déclenchement de l'ajout aux demandes"));
      });
  };
