import { isNullish } from './ts-utils';

const numberFormat = new global.Intl.NumberFormat('fr');

const currencyFormat = new global.Intl.NumberFormat('fr', {
  style: 'currency',
  currency: 'EUR',
});

const roundedCurrencyFormat = new global.Intl.NumberFormat('fr', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
});

export const formatCustomCurrency = (value, currencySymbol) => {
  if (isNullish(value)) {
    return value;
  }
  return numberFormat.format(value) + ' ' + currencySymbol;
};

export const formatCurrency = (value) => {
  if (isNullish(value)) {
    return value;
  }
  return currencyFormat.format(value);
};

export const formatRoundedCurrency = (value) => {
  if (isNullish(value)) {
    return value;
  }
  return roundedCurrencyFormat.format(value);
};
