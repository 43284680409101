import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { closeCost } from '../../actions/DemandCostActions';
import { formatCurrency } from '../../utils/currency-utils';
import Table from '../utils/SortableTable';

class DemandCost extends Component {
  static propTypes = {
    onDemandEstimateCost: PropTypes.func.isRequired,
    onDemandUpdateCost: PropTypes.func.isRequired,
    demandCost: PropTypes.object.isRequired,
    showUpdateCost: PropTypes.bool.isRequired,
  };

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(closeCost());
  };

  render() {
    const {
      onDemandEstimateCost,
      onDemandUpdateCost,
      demandCost: { opened, cost },
      showUpdateCost,
    } = this.props;
    return (
      <>
        <Button variant="contained" onClick={onDemandEstimateCost}>
          Budget
        </Button>
        <Dialog open={opened} onClose={this.onClose} maxWidth="sm" fullWidth>
          <DialogTitle>Estimation du budget</DialogTitle>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="right">Montant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Coût Forfait</TableCell>
                <TableCell align="right">{formatCurrency(cost.bundleCost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Coût Options</TableCell>
                <TableCell align="right">{formatCurrency(cost.optionsCost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Coût Matériel</TableCell>
                <TableCell align="right">{formatCurrency(cost.equipmentCost)}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell align="right">
                  {formatCurrency(cost.bundleCost + cost.optionsCost + cost.equipmentCost)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <DialogActions>
            {showUpdateCost && (
              <Button onClick={onDemandUpdateCost} style={{ marginRight: 'auto' }}>
                Mettre à jour les tarifs
              </Button>
            )}
            <Button onClick={this.onClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect((state) => ({
  demandCost: state.demandCost,
}))(DemandCost);
