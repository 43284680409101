import React from 'react';
import { Tab } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  icon: {
    [theme.breakpoints.down('md')]: {
      minHeight: 48,
      padding: 0,
    },
  },
  label: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

const ResponsiveTab = ({ classes, label, ...props }) => (
  <Tab classes={{ labelIcon: classes.icon }} label={<div className={classes.label}>{label}</div>} {...props} />
);

export default withStyles(styles)(ResponsiveTab);
