import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Alert } from 'mdi-material-ui';
import UAParser from 'ua-parser-js';

const styles = {
  button: {
    textTransform: 'none',
  },
};

/**
 * Checks that browser is supported by this application.
 * @see https://eogile.jira.com/wiki/x/A4DhBQ
 * @param ua current user agent
 * @returns {{isAllowed: boolean, browser: *}}
 */
const getBrowserAndCheckAllowed = (ua) => {
  const { browser } = ua;

  if (
    (browser.name === 'Chrome' && browser.major >= 43) ||
    (browser.name === 'Firefox' && browser.major >= 40) ||
    (browser.name === 'IE' && browser.major >= 11) ||
    (/Safari/.test(browser.name) && browser.major >= 9) ||
    browser.name === 'Edge'
  ) {
    return {
      ...browser,
      isAllowed: true,
    };
  }
  return {
    ...browser,
    isAllowed: false,
  };
};

class BrowserChecker extends Component {
  constructor() {
    super();
    const result = new UAParser().getResult();
    this.browser = getBrowserAndCheckAllowed(result);
  }

  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleTouchTap = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes } = this.props;
    return (
      !this.browser.isAllowed && (
        <div {...this.props}>
          <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleTouchTap}>
            <Alert />
            Navigateur non supporté !
          </Button>
          <Dialog open={this.state.open}>
            <DialogTitle>Votre navigateur n'est pas supporté !</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Vous utilisez un navigateur '{this.browser.name}' en version '{this.browser.version}'.
              </DialogContentText>
              <DialogContentText>
                Pour pouvoir profiter de l'ensemble des fonctionnalités de cette application, vous devez mettre à jour
                votre navigateur. Les versions de navigateur supportées sont les suivantes:
              </DialogContentText>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Navigateur</TableCell>
                    <TableCell>Version minimale</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Google Chrome</TableCell>
                    <TableCell>43</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mozilla Firefox</TableCell>
                    <TableCell>40</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Internet Explorer</TableCell>
                    <TableCell>11</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Safari iOS</TableCell>
                    <TableCell>9</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose}>
                J'ai compris
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    );
  }
}

export default withStyles(styles)(BrowserChecker);
