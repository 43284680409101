import {
  OPERATION_BUNDLE_LOADED,
  OPERATION_BUNDLE_LOADING,
  OPERATION_BUNDLE_ERROR,
  OPERATION_BUNDLE_RESET,
  OPERATION_BUNDLE_DELETION_ERRORS,
  OPERATION_BUNDLE_VALIDATION_ERRORS,
  OPERATION_BUNDLE_OPTIONS_LOADING,
  OPERATION_BUNDLE_OPTIONS_LOADED,
  OPERATION_BUNDLE_OPTIONS_RESET,
} from '../../constants/AppConstants';

const emptyBundle = {
  id: null,
  name: null,
  demandTypeId: null,
  compatibleConcepts: [],
  answers: {},
  price: 0,
  addEquipments: [],
  removeEquipments: [],
  moveEquipments: [],
};

const defaultOptions = {
  ADD: [],
  REMOVE: [],
  MOVE: [],
};
const initialState = {
  bundle: emptyBundle,
  loading: false,
  error: null,
  deletionErrors: [], // The list of references to the bundle preventing it to be deleted
  validationErrors: [],
  validationErrorsCounter: 0, // To know when form inputs must be updated
  compatibleOptions: defaultOptions,
  loadingOptions: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPERATION_BUNDLE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        deletionErrors: [],
        validationErrors: [],
      };
    case OPERATION_BUNDLE_LOADING:
      return {
        ...state,
        bundle: emptyBundle,
        loading: true,
        error: null,
        deletionErrors: [],
        validationErrors: [],
        compatibleOptions: defaultOptions,
      };
    case OPERATION_BUNDLE_LOADED:
      return {
        ...state,
        bundle: action.bundle,
        loading: false,
        error: null,
        deletionErrors: [],
        validationErrors: [],
      };
    case OPERATION_BUNDLE_RESET:
      return {
        ...state,
        bundle: emptyBundle,
        loading: false,
        error: null,
        deletionErrors: [],
        validationErrors: [],
        compatibleOptions: defaultOptions,
        loadingOptions: false,
      };
    case OPERATION_BUNDLE_DELETION_ERRORS:
      return {
        ...state,
        deletionErrors: action.errors,
      };
    case OPERATION_BUNDLE_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.errors,
        validationErrorsCounter: state.validationErrorsCounter + 1,
      };
    case OPERATION_BUNDLE_OPTIONS_LOADING:
      return {
        ...state,
        compatibleOptions: defaultOptions,
        loadingOptions: true,
      };
    case OPERATION_BUNDLE_OPTIONS_LOADED:
      return {
        ...state,
        compatibleOptions: action.options,
        loadingOptions: false,
      };
    case OPERATION_BUNDLE_OPTIONS_RESET:
      return {
        ...state,
        compatibleOptions: defaultOptions,
        loadingOptions: false,
      };
    default:
      return state;
  }
}
