import 'react-app-polyfill/ie11';
import 'core-js/modules/es.symbol';
import React from 'react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import parseZone from './utils/parseZone';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './components/App';
import initSentry from './utils/sentry';
import history from './history';
import store from './store';
import { createRoot } from 'react-dom/client';
import debugFactory from 'debug';

/**
 * Enable debug logging.
 */
if ('developement' === process.env.NODE_ENV) {
  debugFactory.enable('prestago:*');
}

initSentry();

// Configure the dayjs default locale
dayjs.locale('fr');
dayjs.defaultFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
dayjs.extend(parseZone);

// Mostly boilerplate, except for the Routes. These are the pages you can go to,
// which are all wrapped in the App component, which contains the navigation etc
const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);
