import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Formsy from 'formsy-react';
import queryString from 'query-string';
import { Fab, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { ArrowLeft, Send } from 'mdi-material-ui';

import PasswordInputs from '../account/PasswordInputs';
import { createUserPassword } from '../../actions/UserActions';
import { usePageTitle } from '../../utils/page-title';
import { useAppDispatch } from '../../hooks';
import { useStyles } from './LoginPage';

const ChangePasswordPage = () => {
  usePageTitle('Changement de mot de passe');

  const classes = useStyles();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);

  const { token, expired } = queryString.parse(search);

  const onValidSubmit = () => {
    dispatch(createUserPassword(token, password));
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Paper elevation={2} className={classes.paper}>
          <Formsy
            onValid={() => setValid(true)}
            onInvalid={() => setValid(false)}
            onValidSubmit={onValidSubmit}
            noValidate
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {expired ? (
                  <Typography variant="body2">
                    Votre mot de passe a expiré. Veuillez choisir un nouveau mot de passe avant d'accéder à
                    l'application.
                  </Typography>
                ) : (
                  <Typography variant="body2">Veuillez saisir votre nouveau mot de passe.</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <PasswordInputs onPasswordChange={setPassword} />
              </Grid>
            </Grid>

            <Tooltip title="Modifier le mot de passe">
              <div className={classes.buttonContainer}>
                <Fab
                  type="submit"
                  size="medium"
                  disabled={!valid}
                  classes={{
                    root: classes.button,
                    disabled: classes.buttonDisabled,
                  }}
                >
                  <Send />
                </Fab>
              </div>
            </Tooltip>
          </Formsy>
        </Paper>

        <Typography variant="body1" className={classes.footer}>
          <Link to="/login">
            <ArrowLeft className={classes.footerIcon} fontSize="inherit" />
            Retour à la connexion
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
