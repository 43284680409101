import React from 'react';
import { Grid, ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DateViewer from '../commons/DateViewer';
import { COMPANIES } from '../../constants/AppConstants';
import { compose } from 'recompose';
import classNames from 'classnames';
import withWidth from '@mui/material/Hidden/withWidth';

const styles = (theme) => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexEnd: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  listRow: {
    '&:nth-of-type(2n)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  muted: {
    fontStyle: 'italic',
    color: theme.palette.grey[500],
  },
});

const InterventionsListItem = ({ intervention, classes, onClick, width }) => {
  const renderAgency = (intervention) => (
    <span>
      {intervention.agency.name}{' '}
      <span className={classes.muted}>{intervention.area.name && '(' + intervention.area.name + ')'}</span>
    </span>
  );

  const renderOperationScheduleDate = (intervention) =>
    intervention.operationScheduleDate ? (
      <span>
        Planifiée : <DateViewer date={intervention.operationScheduleDate} />
      </span>
    ) : null;

  const renderDemandType = (intervention) => (
    <span>
      {intervention.demandType.name}
      {' - '}
      <span style={{ color: intervention.concept.color }}>{intervention.concept.name}</span>{' '}
      {COMPANIES.getById(intervention.subcontractor.id).icon}
    </span>
  );

  const getSecondary = () =>
    width === 'xs' ? (
      <>
        {renderAgency(intervention)}
        {renderDemandType(intervention) && (
          <>
            <br />
            {renderDemandType(intervention)}
          </>
        )}
        {renderOperationScheduleDate(intervention) && (
          <>
            <br />
            {renderOperationScheduleDate(intervention)}
          </>
        )}
      </>
    ) : (
      <Grid component="span" container>
        <Grid component="span" item xs={12} sm={6} className={classes.flexColumn}>
          {renderAgency(intervention)}
          {renderOperationScheduleDate(intervention)}
        </Grid>
        <Grid component="span" item xs={12} sm={6} className={classNames(classes.flexColumn, classes.flexEnd)}>
          {renderDemandType(intervention)}
        </Grid>
      </Grid>
    );

  return (
    <ListItem key={intervention.id} className={classes.listRow} onClick={onClick}>
      <ListItemText
        primary={
          <>
            <strong>{intervention.outlet.code + ' - ' + intervention.outlet.name + ' '}</strong>
            <span className={classes.muted}>n°{intervention.number} </span>
            <br />
            <span>{intervention.status.name}</span>
            <br />
          </>
        }
        secondary={getSecondary()}
      />
    </ListItem>
  );
};

export default compose(withStyles(styles), withWidth())(InterventionsListItem);
