import { PassDownProps } from 'formsy-react/src/withFormsy';

type UndocumentedFormsyProps = {
  innerRef: any;
  attachToForm: any;
  detachFromForm: any;
  runValidation: any;
  validate: any;
};

type PrestagoPropsToIgnore = {
  doNotIncludeFormTag: any;
  displayMultipleErrors: any;
  convertDateToString: any;
  regionIds: any;
  agencyIds: any;
  outletNames: any;
};

export const getNonFormsyProps = <T>(
  props: Partial<PassDownProps<T> & UndocumentedFormsyProps & PrestagoPropsToIgnore>,
) => {
  const {
    setValidations,
    setValue,
    resetValue,
    value,
    hasValue,
    errorMessage,
    errorMessages,
    innerRef,
    isFormDisabled,
    isValid,
    isPristine,
    isFormSubmitted,
    isRequired,
    showRequired,
    showError,
    isValidValue,
    validationError,
    validationErrors,
    validations,
    attachToForm,
    detachFromForm,
    runValidation,
    validate,
    doNotIncludeFormTag,
    displayMultipleErrors,
    convertDateToString,
    regionIds,
    agencyIds,
    outletNames,
    ...others
  } = props;
  return others;
};
