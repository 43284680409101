import { FC, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasAllRoles, hasAnyRole, hasRole, isLoggedIn } from '../../services/SecurityService';

type SecuredProps = {
  requiredRole?: string;
  anyRole?: string[];
  allRoles?: string[];
  children: ReactNode;
};

/**
 * Wrapper for component that are visible only for authenticated users, and optionally for a role.
 */
const Secured: FC<SecuredProps> = ({ requiredRole, anyRole, allRoles, children }) => {
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const forbidden = useMemo(
    () =>
      !isLoggedIn(currentUser) ||
      (requiredRole && !hasRole(currentUser, requiredRole)) ||
      (anyRole && !hasAnyRole(currentUser, anyRole)) ||
      (allRoles && !hasAllRoles(currentUser, allRoles)),
    [allRoles, anyRole, currentUser, requiredRole],
  );
  return forbidden ? null : <>{children}</>;
};

export default Secured;
