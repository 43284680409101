import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';

import { EditableTableRow } from '../utils';
import { deleteGoal, saveGoal } from '../../actions/fdd/FDDGoalActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';

class FDDGoalRow extends Component {
  static propTypes = {
    goal: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
  };

  state = {
    goal: this.props.goal,
    editing: this.props.editing,
    deleting: false,
  };

  onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { goal } = this.props;

    this.setState(({ editing }) => (editing ? null : { editing: true, goal }));
  };

  onChange = (fieldName) => (value) => {
    this.setState(({ goal }) => ({
      goal: {
        ...goal,
        [fieldName]: value,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Case enter is pressed
      this.onSave(e);
    } else if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, goal } = this.props;

    if (!goal.id) {
      dispatch(deleteGoal(goal));
      return;
    }

    this.setState({
      goal: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;

    const { goal } = this.state;
    dispatch(saveGoal(goal))
      .then(() => {
        this.setState({ editing: false });
        dispatch(addGlobalMessage('Modifications sauvegardées'));
      })
      .catch((error) => {
        console.error('Error saving goal: ', error);
      });
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, goal } = this.props;
    dispatch(deleteGoal(goal));
    dispatch(addGlobalMessage(`Objectif supprimé : ${goal.name}`));
  }

  render() {
    const { goal, editing, deleting } = this.state;
    const { editable, goal: goalProp, dispatch, ...props } = this.props;

    return (
      <EditableTableRow
        {...props}
        editable
        editing={editing}
        onEdit={(e) => this.onEditClick(e)}
        actions={
          <>
            <Tooltip title="Enregistrer l'objectif">
              <IconButton onClick={this.onSave} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(goalProp.id) && (
              <Tooltip title="Supprimer l'objectif">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <TableCell>
          {editing ? (
            <TextField
              name="name"
              autoFocus
              value={goal.name}
              onChange={(e) => this.onChange('name')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <span>{goalProp.name}</span>
          )}
        </TableCell>
        <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer l'objectif {goalProp.name} ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </EditableTableRow>
    );
  }
}

export default connect()(FDDGoalRow);
