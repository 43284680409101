import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { STOCK_OUTLETS, urls } from '../../constants/AppConstants';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ApiDate } from '../../model/model';
import { api, convert404ToNull, ignore401 } from '../../services/RestService';
import { formatDateTime } from '../../utils/date-utils';

const loadLastInventories = async (): Promise<Record<string, ApiDate>> => {
  const endpoint = api.custom(urls.lastInventories);

  try {
    const response = await endpoint.get();
    return response.body(false);
  } catch (error) {
    return convert404ToNull(error).catch((error) => ignore401(error));
  }
};

const StockEquipmentOutletsTable = () => {
  const navigate = useNavigate();
  const [lastInventories, setLastInventories] = useState<Record<string, ApiDate>>({});

  useEffect(() => {
    (async () => {
      setLastInventories(await loadLastInventories());
    })();
  }, []);

  const onRowClick = (outletCode: string) => {
    navigate(`/stock-equipments/${outletCode}`);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Stock</TableCell>
          <TableCell>Dernier inventaire</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {STOCK_OUTLETS.map((stockOutlet) => (
          <TableRow key={stockOutlet.stockOutletCode} onClick={() => onRowClick(stockOutlet.stockOutletCode)} hover>
            <TableCell>
              {stockOutlet.icon} {stockOutlet.name}
            </TableCell>
            <TableCell>{formatDateTime(lastInventories[stockOutlet.stockOutletCode]) || '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default StockEquipmentOutletsTable;
