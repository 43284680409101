import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import NewEquipmentMove from '../../equipment-moves/NewEquipmentMove';
import { withPageTitle } from '../../../utils/page-title';

export default compose(
  withPageTitle(() => 'Nouveau mouvement de matériel'),
  securedPage(roles.equipmentMove.create.code),
)(NewEquipmentMove);
