const roles = {
  demand: {
    label: 'Demandes',
    view: {
      code: 'ROLE_DEMAND_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_DEMAND_EDIT',
      label: 'Modifier',
    },
    create: {
      code: 'ROLE_DEMAND_CREATE',
      label: 'Créer',
    },
    delete: {
      code: 'ROLE_DEMAND_DELETE',
      label: 'Supprimer',
    },
    deleteAlways: {
      code: 'ROLE_DEMAND_DELETE_ALWAYS',
      label: 'Supprimer à tout moment',
    },
    validate: {
      code: 'ROLE_DEMAND_VALIDATE',
      label: 'Valider',
    },
    process: {
      code: 'ROLE_DEMAND_PROCESS',
      label: 'Traiter',
    },
    receive: {
      code: 'ROLE_DEMAND_RECEIVE',
      label: 'Réceptionner',
    },
    equipmentExport: {
      code: 'ROLE_DEMAND_EQUIPMENT_EXPORT',
      label: 'Exporter les matériels',
    },
    invoice: {
      code: 'ROLE_DEMAND_INVOICE',
      label: 'Marquer comme facturées',
    },
    updateCost: {
      code: 'ROLE_DEMAND_UPDATE_COST',
      label: 'Mettre à jour les tarifs',
    },
    cancelReceive: {
      code: 'ROLE_DEMAND_CANCEL_RECEIVE',
      label: 'Annuler les demandes de réception',
    },
    import: {
      code: 'ROLE_DEMAND_IMPORT',
      label: 'Importer',
    },
  },
  intervention: {
    label: 'Interventions',
    view: {
      code: 'ROLE_INTERVENTION_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_INTERVENTION_EDIT',
      label: 'Modifier',
    },
    create: {
      code: 'ROLE_INTERVENTION_CREATE',
      label: 'Créer',
    },
    process: {
      code: 'ROLE_INTERVENTION_PROCESS',
      label: 'Traiter',
    },
    equipmentExport: {
      code: 'ROLE_INTERVENTION_EQUIPMENT_EXPORT',
      label: 'Exporter les matériels',
    },
    deleteAlways: {
      code: 'ROLE_INTERVENTION_DELETE_ALWAYS',
      label: 'Supprimer à tout moment',
    },
    reopen: {
      code: 'ROLE_INTERVENTION_REOPEN',
      label: 'Rouvrir',
    },
  },
  subcontractor: {
    label: 'Interventions Prestataires',
    interventionView: {
      code: 'ROLE_SUBCONTRACTOR_INTERVENTION_VIEW',
      label: 'Voir toutes les interventions du prestataire',
    },
    interventionAssign: {
      code: 'ROLE_SUBCONTRACTOR_INTERVENTION_ASSIGN',
      label: 'Affecter aux utilisateurs',
    },
  },
  outlet: {
    label: 'Points de vente',
    view: {
      code: 'ROLE_OUTLET_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_OUTLET_EDIT',
      label: 'Modifier',
    },
  },
  equipment: {
    label: 'Matériels',
    view: {
      code: 'ROLE_EQUIPMENT_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_EQUIPMENT_EDIT',
      label: 'Modifier',
    },
    create: {
      code: 'ROLE_EQUIPMENT_CREATE',
      label: 'Créer',
    },
    delete: {
      code: 'ROLE_EQUIPMENT_DELETE',
      label: 'Supprimer',
    },
  },
  equipmentMove: {
    label: 'Mouvements de matériels',
    view: {
      code: 'ROLE_EQUIPMENT_MOVE_VIEW',
      label: 'Lister les mouvements',
    },
    create: {
      code: 'ROLE_EQUIPMENT_MOVE_CREATE',
      label: 'Déclarer un matériel dans un lieu',
    },
    fix: {
      code: 'ROLE_EQUIPMENT_MOVE_FIX',
      label: 'Marquer comme corrigé',
    },
  },
  configuration: {
    label: 'Configuration',
    view: {
      code: 'ROLE_CONFIGURATION_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_CONFIGURATION_EDIT',
      label: 'Créer, Modifier, Supprimer',
    },
  },
  deliveryLocation: {
    label: 'Lieux de livraison',
    view: {
      code: 'ROLE_DELIVERY_LOCATION_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_DELIVERY_LOCATION_EDIT',
      label: 'Créer, Modifier, Supprimer',
    },
  },
  user: {
    label: 'Utilisateurs',
    view: {
      code: 'ROLE_USER_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_USER_EDIT',
      label: 'Modifier',
    },
    create: {
      code: 'ROLE_USER_CREATE',
      label: 'Créer',
    },
    delete: {
      code: 'ROLE_USER_DELETE',
      label: 'Supprimer',
    },
  },
  profile: {
    label: 'Profils',
    view: {
      code: 'ROLE_PROFILE_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_PROFILE_EDIT',
      label: 'Modifier',
    },
    create: {
      code: 'ROLE_PROFILE_CREATE',
      label: 'Créer',
    },
    delete: {
      code: 'ROLE_PROFILE_DELETE',
      label: 'Supprimer',
    },
  },
  fdd: {
    label: 'FDD',
    view: {
      code: 'ROLE_FDD_VIEW',
      label: 'Lister et voir',
    },
    admin: {
      code: 'ROLE_FDD_ADMIN',
      label: 'Administrer les données de référence',
    },
    edit: {
      code: 'ROLE_FDD_EDIT',
      label: 'Créer et Modifier (Brouillon)',
    },
    edit_bd: {
      code: 'ROLE_FDD_EDIT_BD',
      label: 'Modifier avant validation finale',
    },
    validate_da: {
      code: 'ROLE_FDD_VALIDATE_DA',
      label: 'Valider MDZ',
    },
    alert_da: {
      code: 'ROLE_FDD_ALERT_DA',
      label: 'Recevoir les notifications MDZ',
    },
    validate_bd: {
      code: 'ROLE_FDD_VALIDATE_BD',
      label: 'Valider RDD',
    },
    alert_bd: {
      code: 'ROLE_FDD_ALERT_BD',
      label: 'Recevoir les notifications RDD',
    },
    validate_daf: {
      code: 'ROLE_FDD_VALIDATE_DAF',
      label: 'Valider DAF',
    },
    alert_daf: {
      code: 'ROLE_FDD_ALERT_DAF',
      label: 'Recevoir les notifications DAF',
    },
    validate_dr: {
      code: 'ROLE_FDD_VALIDATE_DR',
      label: 'Valider DR',
    },
    alert_dr: {
      code: 'ROLE_FDD_ALERT_DR',
      label: 'Recevoir les notifications DR',
    },
    validate_ci: {
      code: 'ROLE_FDD_VALIDATE_CI',
      label: 'Valider CI',
    },
    alert_ci: {
      code: 'ROLE_FDD_ALERT_CI',
      label: 'Recevoir les notifications CI',
    },
    reopen: {
      code: 'ROLE_FDD_REOPEN',
      label: 'Rouvrir après validation',
    },
    alert_final: {
      code: 'ROLE_FDD_ALERT_FINAL',
      label: 'Recevoir les notifications de validation finale',
    },
    payment_edit: {
      code: 'ROLE_FDD_PAYMENT_EDIT',
      label: 'Saisir versement',
    },
    payment_validate: {
      code: 'ROLE_FDD_PAYMENT_VALIDATE',
      label: 'Valider versement',
    },
    assessment_edit: {
      code: 'ROLE_FDD_ASSESSMENT_EDIT',
      label: 'Saisir bilan',
    },
    attachment_edit: {
      code: 'ROLE_FDD_ATTACHMENT_EDIT',
      label: 'Ajouter des pièces jointes après validation',
    },
    reopenClosed: {
      code: 'ROLE_FDD_REOPEN_CLOSED',
      label: 'Rouvrir après clôture',
    },
    delete: {
      code: 'ROLE_FDD_DELETE',
      label: 'Annuler',
    },
  },
  stockEquipments: {
    label: 'Stocks',
    view: {
      code: 'ROLE_STOCK_EQUIPMENT_VIEW',
      label: 'Lister et voir',
    },
    edit: {
      code: 'ROLE_STOCK_EQUIPMENT_EDIT',
      label: 'Créer, Modifier, Supprimer',
    },
    notify: {
      code: 'ROLE_STOCK_EQUIPMENT_INVENTORY_ALERT',
      label: "Notification d'inventaire en retard",
    },
  },
};

export default roles;
