import { FDD_SUB_GOAL_DELETED, FDD_SUB_GOAL_SAVED, FDD_SUB_GOALS_LOADED, urls } from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError } from '../SnackbarActions';
import { SubGoal } from '../../model/model';
import { AppDispatch } from '../../store';

const subGoalsCollection = api.all(urls.fdd.subGoals);
subGoalsCollection.isFetchNeeded = true;

export function loadSubGoals() {
  return (dispatch: AppDispatch): void => {
    subGoalsCollection
      .getAll()
      .then((response): void => {
        const list = response.body(false);
        if (list && list.length) {
          subGoalsCollection.isFetchNeeded = false;
        }
        dispatch({
          type: FDD_SUB_GOALS_LOADED,
          subGoals: list,
        });
      })
      .catch(ignore401);
  };
}

export function loadSubGoalsIfNeeded() {
  return (dispatch: AppDispatch): void => {
    if (subGoalsCollection.isFetchNeeded) {
      loadSubGoals()(dispatch);
    }
  };
}

export function saveSubGoal(subGoal: SubGoal) {
  return (dispatch) => {
    const creation: boolean = !subGoal.id;
    const saveRequest = creation ? subGoalsCollection.post(subGoal) : subGoalsCollection.put(subGoal.id, subGoal);

    return saveRequest
      .then((response): void => {
        let newSubGoal: SubGoal = subGoal;
        if (creation) {
          newSubGoal = response.body(false);
        }
        dispatch({
          type: FDD_SUB_GOAL_SAVED,
          subGoal: {
            ...newSubGoal,
            editing: false,
          },
          creation,
        });
      })
      .catch(ignore401);
  };
}

export function deleteSubGoal(subGoal) {
  return (dispatch: AppDispatch): void => {
    if (subGoal.id) {
      subGoalsCollection
        .delete(subGoal.id)
        .then((): void => {
          dispatch({
            type: FDD_SUB_GOAL_DELETED,
            id: subGoal.id,
          });
        })
        .catch(ignore401)
        .catch((err) => {
          console.error('Error while deleting:', err);
          if (err.response && err.response.data && err.response.statusCode === 400) {
            dispatch(addGlobalError(err.response.data.error));
          } else {
            dispatch(addGlobalError('Erreur lors de la suppression'));
          }
        });
    } else {
      dispatch({
        type: FDD_SUB_GOAL_DELETED,
        id: null,
      });
    }
  };
}
