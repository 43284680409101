import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { ResponsiveTable, SortableTableHeader } from '../utils';
import { SortDirections } from '../../utils/sorting';
import history from '../../history';

class UsersTable extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    sorts: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
  };

  onHeaderClick = (field) => () => {
    const { tableHeaderClick, sorts, filters } = this.props;
    tableHeaderClick(field, sorts, filters)();
  };

  onRowClick = (userId) => () => {
    history.push(`/users/users/${userId}`);
  };

  sortDirection = (field) => {
    const { sorts } = this.props;
    if (sorts[0].field === field) {
      return sorts[0].direction;
    }
    return SortDirections.none;
  };

  render() {
    const { users } = this.props;
    return (
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <SortableTableHeader
              direction={this.sortDirection('lastName')}
              onClickCallback={this.onHeaderClick('lastName')}
              title="Nom"
            />
            <SortableTableHeader
              direction={this.sortDirection('firstName')}
              onClickCallback={this.onHeaderClick('firstName')}
              title="Prénom"
            />
            <SortableTableHeader
              direction={this.sortDirection('email')}
              onClickCallback={this.onHeaderClick('email')}
              title="Email"
            />
            <TableCell>Profils</TableCell>
            <SortableTableHeader
              direction={this.sortDirection('company.name')}
              onClickCallback={this.onHeaderClick('company.name')}
              title="Entreprise"
            />
            <TableCell>Région</TableCell>
            <TableCell>Zone de vente</TableCell>
            <TableCell>Secteurs</TableCell>
            <SortableTableHeader
              direction={this.sortDirection('status.name')}
              onClickCallback={this.onHeaderClick('status.name')}
              title="Statut"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length > 0 ? (
            users.map((user) => (
              <TableRow key={user.id} onClick={this.onRowClick(user.id)} hover>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.profiles.map((profile) => profile.name).join(', ')}</TableCell>
                <TableCell style={{ verticalAlign: 'middle' }}>
                  {user.company.icon} {user.company.name}
                </TableCell>
                <TableCell>{user.region ? user.region.name : '-'}</TableCell>
                <TableCell>{user.agency ? user.agency.name : '-'}</TableCell>
                <TableCell>
                  {user.areas && user.areas.length ? user.areas.map((area) => area.name).join(', ') : '-'}
                </TableCell>
                <TableCell>
                  {user.status.icon} {user.status.name}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="9">Aucun utilisateur ne correspond aux critères renseignés</TableCell>
            </TableRow>
          )}
        </TableBody>
      </ResponsiveTable>
    );
  }
}

export default UsersTable;
