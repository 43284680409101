import { AREAS_LOADED, AREA_ADDED, AREA_SAVED, AREA_DELETED, AREAS_LIST_SORT } from '../../constants/AppConstants';
import { computeSorts, SortDirections } from '../../utils/sorting';

const initialState = {
  list: [],
  sorts: [
    { field: 'name', direction: SortDirections.asc },
    { field: 'agency.name', direction: SortDirections.asc },
    { field: 'agency.region.name', direction: SortDirections.asc },
  ],
  showNew: false,
};

/**
 * Replace an item in the list (by id).
 */
function updateList(list, newArea) {
  return list.map((area) => (area.id === newArea.id ? newArea : area));
}

/**
 * Delete an item in the list (by id).
 */
function deleteInList(list, areaId) {
  return list.filter((area) => area.id !== areaId);
}

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case AREAS_LOADED:
      return {
        ...state,
        list: action.areas,
      };
    case AREA_ADDED:
      return {
        ...state,
        showNew: true,
      };
    case AREA_SAVED:
      if (action.creation) {
        return {
          ...state,
          list: [...state.list, action.area],
          showNew: false,
        };
      }
      return {
        ...state,
        list: updateList(state.list, action.area),
      };
    case AREA_DELETED:
      if (!action.id) {
        return {
          ...state,
          showNew: false,
        };
      }
      return {
        ...state,
        list: deleteInList(state.list, action.id),
      };
    case AREAS_LIST_SORT:
      return {
        ...state,
        sorts: computeSorts(state.sorts, action.field, action.direction),
      };
    default:
      return state;
  }
}
