import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Grid } from '@mui/material';
import Formsy from 'formsy-react';
import { updateOutletsFilter } from '../../actions/OutletActions';
import { User } from '../../model/user';
import { AutocompleteField, FilterCard, ToggleSwitch } from '../utils';
import { OUTLET_TYPES } from '../../constants/AppConstants';
import {
  AgencyAutocompleteField,
  AreaAutocompleteField,
  DemandTypeAutocompleteField,
  OutletAutocompleteField,
  RegionAutocompleteField,
} from '../commons/autocompleteFields';

/**
 * Get initial filter state.
 *
 * @returns filters
 */
function initializeFilters(userProp, pagination) {
  const user = new User(userProp);
  const { filter } = pagination;

  return {
    isRegionDisabled: user.isRegional() || user.isAgency() || user.isArea(),
    isAgencyDisabled: user.isAgency() || user.isArea(),
    textOrCode: filter.textOrCode && filter.textOrCode.length ? filter.textOrCode[0] : '',
    ids: filter.ids || [], // outlet ids
    outletNames: filter.outletNames || [],
    regionIds: user.isRegional() ? [user.regionId] : filter.regionIds || [],
    agencyIds: user.isAgency() ? [user.agencyId] : filter.agencyIds || [],
    areaIds: filter.areaIds || [],
    demandTypeAnyId: filter.demandTypeAnyId || [],
    types: filter.types || [],
    includeInactive: filter.includeInactive || false,
  };
}

class OutletsFilters extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = initializeFilters(props.user, props.pagination);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, pagination } = nextProps;
    this.setState(initializeFilters(user, pagination));
  }

  onChange = (fieldName) => (value, valueObj) => {
    const newState = {
      [fieldName]: value,
    };
    if (fieldName === 'ids') {
      newState.outletNames = valueObj.map(({ code, name }) => (code ? `${code} - ${name}` : name));
    }
    this.setState(newState);
  };

  /**
   * Triggers the demand list fetch action with filters defined by user.
   */
  onFilter = () => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = this.getFilter();
    updateOutletsFilter(pagination);
  };

  /**
   * Resets state and fetch from server.
   *
   * @param event
   */
  onResetClick = (event) => {
    event.preventDefault();
    const { user, pagination } = this.props;
    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = {};

    // Reset filter (setState returns a promise)
    this.setState(initializeFilters(user, pagination), () => {
      updateOutletsFilter(pagination);
    });
  };

  getFilter() {
    // Clean state
    const { ...epuredState } = this.state;
    delete epuredState.isRegionDisabled;
    delete epuredState.isAgencyDisabled;
    return epuredState;
  }

  hasFilters = () => {
    const {
      pagination: {
        filter: {
          ids = [],
          regionIds = [],
          agencyIds = [],
          areaIds = [],
          demandTypeAnyId = [],
          types = [],
          includeInactive = false,
        },
      },
    } = this.props;
    return Boolean(
      regionIds.length ||
        agencyIds.length ||
        areaIds.length ||
        ids.length ||
        demandTypeAnyId.length ||
        types.length ||
        includeInactive,
    );
  };

  render() {
    const {
      textOrCode,
      ids,
      outletNames,
      regionIds,
      agencyIds,
      areaIds,
      includeInactive,
      demandTypeAnyId,
      types,
      isRegionDisabled,
      isAgencyDisabled,
    } = this.state;

    return (
      <Formsy noValidate onSubmit={this.onFilter}>
        <FilterCard
          hasFilters={this.hasFilters()}
          text={textOrCode}
          textPlaceholder="Filtrer par code, enseigne, ville, région, zone de vente, secteur..."
          onTextChange={this.onChange('textOrCode')}
          onResetClick={this.onResetClick}
          actions={
            <>
              <Button type="submit" variant="contained" color="primary">
                Rechercher
              </Button>
              <Button variant="contained" onClick={this.onResetClick}>
                Réinitialiser
              </Button>
            </>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegionAutocompleteField
                    multiple
                    name="regionIds"
                    value={regionIds}
                    label="Régions"
                    placeholder="Filtrer par région"
                    disabled={isRegionDisabled}
                    onChange={this.onChange('regionIds')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AgencyAutocompleteField
                    multiple
                    regionIds={regionIds}
                    name="agencyIds"
                    value={agencyIds}
                    label="Zones de vente"
                    placeholder="Filtrer par zone de vente"
                    disabled={isAgencyDisabled}
                    onChange={this.onChange('agencyIds')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AreaAutocompleteField
                    multiple
                    agencyIds={agencyIds}
                    regionIds={regionIds}
                    name="areaIds"
                    value={areaIds}
                    label="Secteurs"
                    placeholder="Filtrer par secteur"
                    onChange={this.onChange('areaIds')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ToggleSwitch
                    label="Inclure les points de vente en erreur"
                    value={includeInactive}
                    onChange={this.onChange('includeInactive')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OutletAutocompleteField
                    multiple
                    outletNames={outletNames}
                    name="ids"
                    value={ids}
                    label="Points de vente"
                    placeholder="Filtrer par point de vente"
                    onChange={this.onChange('ids')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DemandTypeAutocompleteField
                    multiple
                    name="demandTypeAnyId"
                    value={demandTypeAnyId}
                    label="Types de demande"
                    placeholder="Filtrer par type de demande"
                    onChange={this.onChange('demandTypeAnyId')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={OUTLET_TYPES}
                    label="Type"
                    placeholder="Filtrer par type"
                    fullWidth
                    name="types"
                    value={types}
                    onChange={this.onChange('types')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FilterCard>
      </Formsy>
    );
  }
}

export default connect((state) => {
  const {
    currentUser: { user },
    outlets: { pagination },
  } = state;
  return {
    user,
    pagination,
  };
})(OutletsFilters);
