import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { SortableTableHeader, CardHeader, ResponsiveTable } from '../utils';
import { loadAreas, sortAreas, addArea } from '../../actions/settings/AreaActions';
import { loadAgencies } from '../../actions/settings/AgencyActions';
import { sort, SortDirections } from '../../utils/sorting';
import AreaRow from './AreaRow';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

class AreasTable extends Component {
  componentDidMount() {
    const { dispatch, agencies } = this.props;
    dispatch(loadAreas());
    if (!agencies.length) {
      dispatch(loadAgencies());
    }
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addArea());
  };

  onHeaderClick = (field) => () => {
    const { dispatch, sorts } = this.props;
    const direction = sorts[0].field === field ? -sorts[0].direction : SortDirections.asc;
    dispatch(sortAreas(field, direction));
  };

  sortDirection = (field) => {
    const { sorts } = this.props;
    if (sorts[0].field === field) {
      return sorts[0].direction;
    }
    return SortDirections.none;
  };

  render() {
    // From store
    const { areas, showNew, currentUser } = this.props;
    const editable = hasRole(currentUser, roles.configuration.edit.code);

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter un secteur"
          addRole={roles.configuration.edit.code}
          onAdd={this.onAddClick}
          addDisabled={showNew}
        >
          Paramétrage des secteurs
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <SortableTableHeader
                direction={this.sortDirection('name')}
                onClickCallback={this.onHeaderClick('name')}
                title="Nom"
              />
              <SortableTableHeader
                direction={this.sortDirection('agency.name')}
                onClickCallback={this.onHeaderClick('agency.name')}
                title="Zone de vente"
              />
              <SortableTableHeader
                direction={this.sortDirection('region.name')}
                onClickCallback={this.onHeaderClick('region.name')}
                title="Région"
              />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.map((s) => (
              <AreaRow key={s.id} area={s} editing={!!s.editing} editable={editable} />
            ))}
          </TableBody>
        </ResponsiveTable>
      </Card>
    );
  }
}

const emptyArea = (agency) => ({
  id: null,
  name: null,
  agency,
  region: agency && agency.region,
  editing: true,
});

const sortedAreasWithNew = (list, sorts, showNew, agencies) => {
  const sortedAreas = sort(list, sorts);
  if (!showNew) {
    return sortedAreas;
  }
  return [emptyArea(agencies[0]), ...sortedAreas];
};

const stateToProps = ({ areas: { list, sorts, showNew }, agencies: { list: agencies }, currentUser }) => {
  const sortedAgencies = sort(agencies, [
    {
      field: 'name',
      direction: SortDirections.asc,
    },
  ]);
  return {
    areas: sortedAreasWithNew(list, sorts, showNew, sortedAgencies),
    agencies: sortedAgencies,
    showNew,
    sorts,
    currentUser,
  };
};

export default connect(stateToProps)(AreasTable);
