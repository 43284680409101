import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';
import Formsy from 'formsy-react';

import { deleteAgency, saveAgency } from '../../actions/settings/AgencyActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import { EditableTableRow } from '../utils';
import { RegionAutocompleteField } from '../commons/autocompleteFields';

class AgencyRow extends Component {
  state = {
    editing: this.props.editing || false,
    deleting: false,
    agency: this.props.agency,
  };

  onEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { agency } = this.props;
    this.setState(({ editing }) => (editing ? null : { editing: true, agency }));
  };

  onChange = (fieldName) => (value) => {
    this.setState(({ agency }) => ({
      agency: {
        ...agency,
        [fieldName]: value,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Case enter is pressed
      this.onSave(e);
    } else if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, agency } = this.props;

    if (!agency.id) {
      dispatch(deleteAgency(agency));
      return;
    }

    this.setState({
      agency: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      editing: false,
    });

    const { dispatch } = this.props;
    const { agency } = this.state;
    dispatch(saveAgency(agency));
    dispatch(addGlobalMessage('Modifications sauvegardées'));
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, agency } = this.props;
    dispatch(deleteAgency(agency));
    dispatch(addGlobalMessage(`Zone de vente supprimée : ${agency.name}`));
  }

  render() {
    // From AgenciesTable
    const { agency, editing, deleting } = this.state;
    const { agency: agencyProp, editable } = this.props;

    return (
      <EditableTableRow
        editable={editable}
        editing={editing}
        onEdit={(e) => this.onEditClick(e, agency)}
        actions={
          <>
            <Tooltip title="Enregistrer la zone de vente">
              <IconButton onClick={this.onSave} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(agency.id) && (
              <Tooltip title="Supprimer la zone de vente">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="Code"
                autoFocus
                value={agency.code}
                onChange={(e) => this.onChange('code')(e.target.value)}
                fullWidth
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.code}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="label"
                value={agency.name}
                onChange={(e) => this.onChange('name')(e.target.value)}
                fullWidth
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.name}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <RegionAutocompleteField
                name="region"
                value={agency.region && agency.region.id}
                onChange={(id, region) => this.onChange('region')(region)}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.region.name}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="oracleCode"
                value={agency.oracleCode}
                fullWidth
                onChange={(e) => this.onChange('oracleCode')(e.target.value)}
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.oracleCode}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="oracleLabel"
                value={agency.oracleLabel}
                fullWidth
                onChange={(e) => this.onChange('oracleLabel')(e.target.value)}
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.oracleLabel}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="clientNumber"
                value={agency.clientNumber}
                fullWidth
                onChange={(e) => this.onChange('clientNumber')(e.target.value)}
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.clientNumber}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="deliveryLocation"
                value={agency.deliveryLocation}
                fullWidth
                onChange={(e) => this.onChange('deliveryLocation')(e.target.value)}
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.deliveryLocation}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                name="billingLocation"
                value={agency.billingLocation}
                fullWidth
                onChange={(e) => this.onChange('billingLocation')(e.target.value)}
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{agencyProp.billingLocation}</span>
          )}
        </TableCell>
        <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer la zone de vente {agencyProp.name} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </EditableTableRow>
    );
  }
}

export default connect()(AgencyRow);
