import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import debugFactory from 'debug';

import {
  deleteOption,
  loadOption,
  loadOptions,
  resetOption,
  saveOrUpdateGroup,
  saveOrUpdateOption,
} from '../../../actions/settings/OperationOptionActions';
import { loadDemandTypesIfNeeded } from '../../../actions/DemandTypeActions';
import { loadEquipments } from '../../../actions/settings/EquipmentActions';
import { addOperationToDemands } from '../../../actions/settings/ReloadDemandCostsActions';

import { sort, SortDirections } from '../../../utils/sorting';
import { AutocompleteField, LoadingMessage, TextField, ToggleSwitch } from '../../utils';
import { ACTIVE_INSTALLERS, COST_STRUCTURE_BUNDLE, OPERATION_TYPES } from '../../../constants/AppConstants';
import EquipmentButtons from '../../equipments/EquipmentButtons';
import GroupSelect from '../../commons/GroupSelect';
import { addMaxValidationRule, addMinValidationRule } from '../../../utils/validation-rules';
import { compose } from 'recompose';
import { withPageTitle } from '../../../utils/page-title';

const debug = debugFactory('prestago:OperationOption');

const getDemandTypes = (demandTypes = [], ids = []) =>
  demandTypes.filter((demandType) => ids.indexOf(demandType.id) >= 0);

/**
 * Returns the list of compatible equipments.
 *
 * To be returned, an equipment must be compatible with at least one concept
 * for each demand type.
 *
 * @param equipments The full list of equipments.
 * @param demandTypes The currently selected demand types.
 */
const getCompatibleEquipments = (equipments, demandTypes) => {
  if (!equipments || !demandTypes || demandTypes.length === 0) {
    return [];
  }

  const compatibleEquipmentPredicate = (eq) =>
    demandTypes.every((demandType) =>
      demandType.compatibleConcepts.some((concept) => eq.compatibleConcepts.indexOf(concept) >= 0),
    );

  return equipments.filter(compatibleEquipmentPredicate);
};

class OperationOption extends React.Component {
  static errorMessages = {
    name: {
      isDefaultRequiredValue: 'Veuillez saisir un libellé',
      isExisty: 'Veuillez saisir un libellé',
    },
    operationType: {
      isDefaultRequiredValue: 'Veuillez choisir un type de prestation',
      isExisty: 'Veuillez choisir un type de prestation',
    },
    price: {
      isDefaultRequiredValue: 'Veuillez saisir un prix',
      isExisty: 'Veuillez saisir un prix',
      min: 'Le prix minimum est 0€',
    },
    maxQuantity: {
      isDefaultRequiredValue: 'Veuillez saisir une quantité maximale',
      isExisty: 'Veuillez saisir une quantité maximale',
      isInt: 'Veuillez saisir un entier',
      min: 'Minimum 1',
      max: 'Maximum 100',
    },
    demandTypes: {
      isDefaultRequiredValue: 'Veuillez sélectionner au moins un type de demande',
      isExisty: 'Veuillez sélectionner au moins un type de demande',
      minLength: 'Veuillez sélectionner au moins un type de demande',
    },
    equipment: {
      isDefaultRequiredValue: 'Veuillez sélectionner un matériel',
      isExisty: 'Veuillez sélectionner un matériel',
    },
  };

  static propTypes = {
    allOptions: PropTypes.array.isRequired,
    deletionErrors: PropTypes.array.isRequired,
    demandTypes: PropTypes.array,
    equipments: PropTypes.array,
    loading: PropTypes.bool,
    option: PropTypes.object,
    optionId: PropTypes.string,
    serverError: PropTypes.string,
    validationErrors: PropTypes.array.isRequired,
    validationErrorsCounter: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      demandTypeIds: [],
      emphasis: false,
      equipmentId: '',
      groupId: null,
      maxQuantity: 0,
      name: '',
      operationType: '',
      prices: {},

      /*
       * Options are associated with demand types which are themselves
       * associated with concepts.
       * In other hand, equipments are also associated with concepts.
       *
       * Consequently, an option can only be associated with an equipment
       * which is compatible with the concepts of the option's demand types.
       */
      compatibleEquipments: [],
      editing: !props.optionId,
      /*
       * When creating a group, some existing operations may join
       * the group.
       *
       * Consequently, these operations should also be updated to fill
       * their groups ids.
       */
      touchedOptions: [],
    };
    this.form = React.createRef();
    addMinValidationRule();
    addMaxValidationRule();
  }

  componentDidMount() {
    const { dispatch, optionId } = this.props;
    dispatch(loadOption(optionId));

    dispatch(loadDemandTypesIfNeeded());
    dispatch(loadEquipments());

    /*
     * Loading other options for groups
     */
    dispatch(loadOptions());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, allOptions, demandTypes, equipments, option, optionId, validationErrorsCounter } = this.props;
    const {
      allOptions: nextAllOptions,
      demandTypes: nextDemandTypes,
      equipments: nextEquipments,
      option: nextOption,
      optionId: nextOptionId,
      validationErrorsCounter: nextValidationErrorsCounter,
      validationErrors: nextValidationErrors,
    } = nextProps;

    /*
     * If route changed, the new option is loaded.
     *
     * Route can change without mounting a new component when an option
     * of the group is clicked.
     */
    if (nextOptionId !== optionId) {
      dispatch(loadOption(nextOptionId));
      return;
    }

    const allOptionsChanged = JSON.stringify(allOptions) !== JSON.stringify(nextAllOptions);
    const equipmentsChanged = JSON.stringify(equipments) !== JSON.stringify(nextEquipments);
    const demandTypesChanged = JSON.stringify(demandTypes) !== JSON.stringify(nextDemandTypes);
    const optionChanged = option.id !== nextOption.id;

    /*
     * If the option's id changes, the form state is reset.
     *
     * On top of that, if the next option has an id, then that means that
     * the form is read-only because this is the form of a new option.
     */
    if (optionChanged) {
      this.setState({ editing: false });
    }

    if (equipmentsChanged || demandTypesChanged || optionChanged || allOptionsChanged) {
      this.resetFormState(nextOption, nextEquipments, nextDemandTypes);
    }

    /*
     * If the server invalidates some fields, we need to update the form.
     */
    if (validationErrorsCounter !== nextValidationErrorsCounter) {
      this.form.current.updateInputsWithError(
        nextValidationErrors.reduce((errors, error) => {
          // eslint-disable-next-line no-param-reassign
          errors[error.field] = error.defaultMessage;
          return errors;
        }, {}),
      );
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetOption());
  }

  onActivateEditingMode = () => {
    this.setState({ editing: true });
  };

  onCancel = () => {
    const { demandTypes, equipments, option } = this.props;
    this.resetFormState(option, equipments, demandTypes);
    this.setState({ editing: false });
    this.form.current.reset();
  };

  onChangeBasic = (fieldName) => (event) => {
    const { value } = event.target;
    const newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  };

  onDelete = () => {
    const { dispatch, option } = this.props;
    dispatch(deleteOption(option.id));
  };

  onAddToDemands = () => {
    const { dispatch, option } = this.props;
    dispatch(addOperationToDemands({ optionId: option.id }));
  };

  onDemandTypesChange = (demandTypeIds) => {
    const { demandTypes, equipments } = this.props;
    const selectedDemandTypes = getDemandTypes(demandTypes, demandTypeIds);
    const compatibleEquipments = getCompatibleEquipments(equipments, selectedDemandTypes);

    this.setState(({ equipmentId: oldEquipmentId }) => {
      /*
       * Checking that the selected equipment (if any) is still present
       * in the list of compatible equipments.
       */
      let equipmentId = oldEquipmentId;
      if (
        compatibleEquipments.length > 0 &&
        oldEquipmentId &&
        compatibleEquipments.every((eq) => eq.id !== oldEquipmentId)
      ) {
        equipmentId = null;
      }

      return {
        compatibleEquipments,
        demandTypeIds,
        equipmentId,
      };
    });
  };

  onEmphasisChange = (checked) => {
    this.setState({ emphasis: checked });
  };

  onEquipmentChange = (value) => {
    const { compatibleEquipments, equipmentId } = this.state;

    /*
     * If the selected equipment really changed, this equipment is
     * searched to update the option name.
     */
    const equipment = equipmentId !== value ? compatibleEquipments.find((eq) => eq.id === value) : null;

    this.setState(({ name }) => ({
      equipmentId: value,
      name: equipment ? equipment.name : name,
    }));
  };

  onGroupSelect = (groupId) => {
    this.setState({
      groupId,
    });
  };

  onOperationTouched = (opId, groupId) => {
    const { touchedOptions } = this.state;

    const newTouchedOptions = [
      {
        id: opId,
        groupId,
      },
    ].concat(touchedOptions.filter((op) => op.id !== opId));
    this.setState({
      touchedOptions: newTouchedOptions,
    });
  };

  onOperationTypeChange = (operationType) => {
    this.setState({ operationType });
  };

  onPriceChange = (subcontractorId) => (event) => {
    debug('onPriceChange', subcontractorId, event.target.value);
    this.setState(({ prices }) => ({
      prices: {
        ...prices,
        [subcontractorId]: event.target.value,
      },
    }));
  };

  onSave = () => {
    this.form.current.submit();
  };

  onValidSubmit = () => {
    const { dispatch, option } = this.props;
    const { demandTypeIds, emphasis, equipmentId, groupId, maxQuantity, name, operationType, prices } = this.state;

    /*
     * The callback that dispatches an action to save or update
     * the current operation option.
     */
    const saveCallback = () => {
      dispatch(
        saveOrUpdateOption({
          id: option.id,
          demandTypeIds,
          emphasis,
          equipmentId,
          groupId,
          maxQuantity,
          name,
          operationType,
          position: option.position,
          prices,
        }),
      );
    };

    /*
     * The options that were touched when (un-)selecting groups.
     *
     * Really touched options are options that do not have a group id (disassociation)
     * or that have the group id than the current option.
     */
    const { touchedOptions } = this.state;
    const reallyTouchedOptions = touchedOptions.filter((opt) => !opt.groupId || opt.groupId === groupId);

    /*
     * Dispatches the action to save group information and then save or update
     * the option.
     */
    dispatch(saveOrUpdateGroup(reallyTouchedOptions, saveCallback));
  };

  getElementUrl = (id) => `/settings/operation-options/${id}`;

  resetFormState(option, equipments, demandTypes) {
    const selectedDemandTypes = getDemandTypes(demandTypes, option.demandTypeIds);
    const compatibleEquipments = getCompatibleEquipments(equipments, selectedDemandTypes);

    this.setState({
      compatibleEquipments,
      demandTypeIds: option.demandTypeIds,
      emphasis: option.emphasis,
      equipmentId: option.equipmentId,
      groupId: option.groupId,
      maxQuantity: option.maxQuantity,
      name: option.name,
      operationType: option.operationType,
      prices: option.prices,
      touchedOptions: [],
    });
  }

  render() {
    const { allOptions, deletionErrors, demandTypes, loading, option, serverError } = this.props;

    if (loading || serverError) {
      return (
        <LoadingMessage loading={loading} serverError={serverError}>
          Option
        </LoadingMessage>
      );
    }

    const availableDemandTypes = demandTypes.filter((type) => type.costStructure === COST_STRUCTURE_BUNDLE);

    const {
      compatibleEquipments,
      demandTypeIds,
      editing,
      emphasis,
      equipmentId,
      groupId,
      maxQuantity,
      name,
      operationType,
      prices,
      touchedOptions,
    } = this.state;

    /*
     * Hiding the equipment select element when there is no selected
     * demand types.
     *
     * The equipment select component must be hidden and not removed
     * for Formsy.
     */
    const equipmentSelectStyle = {};
    if (demandTypeIds.length === 0) {
      equipmentSelectStyle.display = 'none';
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Option {option.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Formsy noValidate ref={this.form} onValidSubmit={this.onValidSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Informations générales</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Libellé"
                          fullWidth
                          name="name"
                          onChange={this.onChangeBasic('name')}
                          required
                          validations="isExisty"
                          validationErrors={OperationOption.errorMessages.name}
                          value={name}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          disabled={!editing}
                          label="Type"
                          fullWidth
                          name="operationType"
                          onChange={this.onOperationTypeChange}
                          required
                          validations="isExisty"
                          validationErrors={OperationOption.errorMessages.operationType}
                          value={operationType}
                          options={OPERATION_TYPES}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Quantité max"
                          fullWidth
                          name="maxQuantity"
                          onChange={this.onChangeBasic('maxQuantity')}
                          required
                          type="number"
                          min="1"
                          max="100"
                          validations="isExisty,isInt,min:1,max:100"
                          validationErrors={OperationOption.errorMessages.maxQuantity}
                          value={maxQuantity || ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <ToggleSwitch
                          name="emphasis"
                          label="Affichée par défaut"
                          value={emphasis}
                          disabled={!editing}
                          onChange={this.onEmphasisChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <GroupSelect
                          elementId={option.id}
                          getElementUrl={this.getElementUrl}
                          groupId={groupId}
                          name={name}
                          readOnly={!editing}
                          onChange={this.onGroupSelect}
                          onOperationTouched={this.onOperationTouched}
                          operations={allOptions}
                          touchedOperations={touchedOptions}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Prix</Typography>
                      </Grid>
                      {ACTIVE_INSTALLERS.map((subcontractor) => (
                        <Grid item xs={12} key={subcontractor.id}>
                          <TextField
                            disabled={!editing}
                            label={`Prix ${subcontractor.name} (€)`}
                            fullWidth
                            name="price"
                            type="number"
                            min="0"
                            validations="min:0"
                            validationErrors={OperationOption.errorMessages.price}
                            onChange={this.onPriceChange(subcontractor.id)}
                            value={prices[subcontractor.id]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Matériel</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={availableDemandTypes}
                          disabled={!editing}
                          label="Types de demande compatibles"
                          fullWidth
                          name="demandTypes"
                          onChange={this.onDemandTypesChange}
                          required
                          validations="isExisty,minLength:1"
                          validationErrors={OperationOption.errorMessages.demandTypes}
                          value={demandTypeIds}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          disabled={!editing}
                          label="Matériel"
                          fullWidth
                          name="equipmentId"
                          onChange={this.onEquipmentChange}
                          required
                          style={equipmentSelectStyle}
                          validations="isExisty"
                          validationErrors={OperationOption.errorMessages.equipment}
                          value={equipmentId}
                          options={compatibleEquipments}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Formsy>
        </Grid>
        <Grid item xs={12}>
          <EquipmentButtons
            deletionErrors={deletionErrors}
            editing={editing}
            elementId={option.id}
            elementName={`l'option "${option.name}"`}
            onCancel={this.onCancel}
            onActivateEditingMode={this.onActivateEditingMode}
            onDelete={this.onDelete}
            onSave={this.onSave}
            onAddToDemands={this.onAddToDemands}
          />
        </Grid>
      </Grid>
    );
  }
}

const stateToProps = ({
  operationOptions: { options: allOptions },
  operationOption: { option, deletionErrors, loading, error, validationErrors, validationErrorsCounter },
  equipments: { equipments },
  demandTypes,
}) => ({
  allOptions,
  deletionErrors,
  demandTypes,
  equipments: sort(equipments, [
    {
      field: 'name',
      direction: SortDirections.asc,
    },
  ]),
  loading,
  option,
  serverError: error,
  validationErrors,
  validationErrorsCounter,
});

export default compose(
  connect(stateToProps),
  withPageTitle(({ optionId, option }) => (optionId ? `Option ${option?.name ?? ''}` : 'Nouvelle option')),
)(OperationOption);
