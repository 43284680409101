import debugFactory from 'debug';

import { COST_STRUCTURE_AFTER_SALES } from '../constants/AppConstants';

const debug = debugFactory('prestago:OperationRuleService');

/*
 * KIT TOTEM equipments
 */
const KIT_TOTEM = 'equipment_KIT_TOTEM';

/*
 * KIT TV equipments
 */
const KIT_TV = 'equipment_KIT_TV';

/*
 * Écran tactile EIPDV
 */
const ECRAN_TACTILE_EIPDV = 'equipment_EQT.2000.01';
/*
 * Multiprise
 */
const STRIP = 'equipment_MULTIPRISE';

function someWithEquipmentId(equipmentId) {
  return (op) => op.equipments.some((eq) => eq.id.indexOf(equipmentId) >= 0);
}

export default class OperationRuleService {
  static fn = {
    isKitTotem: someWithEquipmentId(KIT_TOTEM),
    anyKitTotemWithQuantity: (op) => OperationRuleService.fn.isKitTotem(op) && op.quantity > 0,

    isKitTV: someWithEquipmentId(KIT_TV),
    anyKitTVWithQuantity: (op) => OperationRuleService.fn.isKitTV(op) && op.quantity > 0,
    isEIPDV: someWithEquipmentId(ECRAN_TACTILE_EIPDV),
    anyEIPDVWithQuantity: (op) => OperationRuleService.fn.isEIPDV(op) && op.quantity > 0,
    anyKitTVOrEIPDVWithQuantity: (op) =>
      OperationRuleService.fn.anyKitTVWithQuantity(op) || OperationRuleService.fn.anyEIPDVWithQuantity(op),

    isStrip: someWithEquipmentId(STRIP),

    isTouching:
      (...equipmentIds) =>
      (triggeringOp) => {
        if (triggeringOp) {
          return equipmentIds.some((equipmentId) => someWithEquipmentId(equipmentId)(triggeringOp));
        }
        return false;
      },

    /**
     * Returns true if there user selected Kit Totem + Kit TV or EIPDV.
     * @param operations
     * @returns {boolean}
     */
    isKitTotemWithKitTVOrEIPDV(operations) {
      const kitTotem = operations.find((op) => OperationRuleService.fn.anyKitTotemWithQuantity(op));
      if (kitTotem) {
        const kitTVOrEIPDV = operations.find((op) => OperationRuleService.fn.anyKitTVOrEIPDVWithQuantity(op));
        if (kitTVOrEIPDV) {
          return true;
        }
      }
      return false;
    },
  };

  constructor(demandType) {
    this.nbRule = 0;
    this.demandType = demandType;
    this.moveOperations = [];
  }

  // noinspection JSUnusedGlobalSymbols
  helpers = {
    incrementRuleCounter: (op) => {
      this.nbRule += 1;
      return op;
    },

    terminalExists: () => this.answers.BORNE_EXISTANTE === 'YES',

    addTerminal: () => this.answers.AJOUT_BORNE === 'YES',

    noAddTerminalQuestion: () => !this.answers.AJOUT_BORNE,

    eipdvExists: () => this.answers.EIPDV_EXISTANT === 'YES',

    atLeastOneTerminal: () => {
      const { terminalExists, addTerminal } = this.helpers;
      return terminalExists() || addTerminal();
    },

    noTerminal: () => {
      const { atLeastOneTerminal } = this.helpers;
      return !atLeastOneTerminal();
    },

    convertToNumber: (input) => {
      const nb = parseInt(input, 10);
      return Number.isNaN(nb) ? 0 : nb;
    },
  };

  rules = {
    /**
     * Rule #1: Rule for strip auto setting to 1.
     *
     * Quantity of strip is automatically set to 1 when there is:
     * <ul>
     *   <li>One TOTEM equipment (TV+Boitier+Encadrement Ecran), or</li>
     *   <li>One EIPDV</li>
     * </ul>
     * @see https://eogile.jira.com/browse/DEPL-195
     * @param operations Array of current operations
     * @param triggeringOperation The operation that triggered the rules application. {@code null} for the initial
     */
    rule1_SetOneStripIfKitTotemWithKitTVOrEIPDV: (operations, triggeringOperation) => {
      if (OperationRuleService.fn.isTouching(KIT_TOTEM, KIT_TV, ECRAN_TACTILE_EIPDV)(triggeringOperation)) {
        // Are the rules conditions fulfilled ?
        const isKitTotemWithKitTVOrEIPDV = OperationRuleService.fn.isKitTotemWithKitTVOrEIPDV(operations);

        const strip = operations.find((op) => OperationRuleService.fn.isStrip(op));
        if (strip) {
          if (isKitTotemWithKitTVOrEIPDV) {
            if (strip.quantity <= 0) {
              strip.quantity = 1;
            }
          } else {
            strip.quantity = 0;
          }
        }
      }
      return operations;
    },
  };

  orderedRules = [this.rules.rule1_SetOneStripIfKitTotemWithKitTVOrEIPDV];

  //---------------------------------------------------------------------------

  /**
   * Apply all rules to current operations.
   *
   * @param addOperations
   * @param moveOperations
   * @param triggeringOperation The operation that causes the rules application. Can be null.
   * @returns {*}
   */
  apply(addOperations, moveOperations, triggeringOperation) {
    if (this.demandType && this.demandType.costStructure === COST_STRUCTURE_AFTER_SALES) {
      debug('Skipping rules for after-sales demand');
      return addOperations;
    }

    debug('Applying rules');

    if (!Array.isArray(addOperations)) {
      throw new Error('Add operations are expected to be an array');
    }

    this.moveOperations = moveOperations;

    // reset applied rules counter
    this.nbRule = 0;
    return this.orderedRules.reduce(
      (result, rule) => rule(((ops) => this.helpers.incrementRuleCounter(ops))(result), triggeringOperation),
      addOperations,
    );
  }
}
