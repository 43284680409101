import React from 'react';
import * as PropTypes from 'prop-types';
import { formatDateTime } from '../../utils/date-utils';

const DateTimeViewer = ({ date, format }) => <span>{date ? formatDateTime(date, format) : '\u00a0'}</span>;

DateTimeViewer.propTypes = {
  format: PropTypes.string,
  date: PropTypes.string,
};

DateTimeViewer.defaultProps = {
  format: undefined,
};

export default DateTimeViewer;
