import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { CardHeader, LoadingMessage, ResponsiveTable, SortableTableHeader } from '../utils';
import UserProfileDialog from './UserProfileDialog';
import { editProfileStart, loadProfiles, sortProfiles } from '../../actions/ProfileActions';
import { sort, SortDirections } from '../../utils/sorting';
import roles from '../../constants/roles';

class UserProfilesTable extends Component {
  state = {
    currentProfile: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadProfiles());
  }

  onRowClick = (currentProfile) => () => {
    const { dispatch } = this.props;
    this.setState({
      currentProfile,
    });
    dispatch(editProfileStart());
  };

  onNewProfile = () => {
    const profile = {
      name: '',
      description: '',
      subcontractorProfile: false,
      roles: [],
    };
    this.onRowClick(profile)();
  };

  onHeaderClick = (field) => () => {
    const { dispatch, sorts } = this.props;
    const direction = sorts[0].field === field ? -sorts[0].direction : SortDirections.asc;
    dispatch(sortProfiles(field, direction));
  };

  sortDirection = (field) => {
    const { sorts } = this.props;
    if (sorts[0].field === field) {
      return sorts[0].direction;
    }
    return SortDirections.none;
  };

  render() {
    const { profiles, error } = this.props;
    const { currentProfile } = this.state;
    if (error) {
      return <LoadingMessage serverError={error}>Liste des profils</LoadingMessage>;
    }

    return (
      <Card>
        <CardHeader addLabel="Ajouter un profil" addRole={roles.profile.create.code} onAdd={this.onNewProfile}>
          Liste des profils
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <SortableTableHeader
                direction={this.sortDirection('name')}
                onClickCallback={this.onHeaderClick('name')}
                title="Nom"
              />
              <SortableTableHeader
                direction={this.sortDirection('description')}
                onClickCallback={this.onHeaderClick('description')}
                title="Description"
              />
              <SortableTableHeader
                direction={this.sortDirection('subcontractorProfile')}
                onClickCallback={this.onHeaderClick('subcontractorProfile')}
                title="Prestataire"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile) => (
              <TableRow key={`profile-${profile.id}`} onClick={this.onRowClick(profile)} hover>
                <TableCell>{profile.name}</TableCell>
                <TableCell>{profile.description}</TableCell>
                <TableCell>{profile.subcontractorProfile ? 'Oui' : 'Non'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        {currentProfile && <UserProfileDialog profile={currentProfile} />}
      </Card>
    );
  }
}

const stateToProps = ({ profiles: { list, sorts, error } }) => ({
  profiles: sort(list, sorts),
  sorts,
  error,
});

export default connect(stateToProps)(UserProfilesTable);
