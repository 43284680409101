import debugFactory from 'debug';

import {
  PROFILES_LOAD_SUCCESS,
  PROFILES_LOAD_ERROR,
  PROFILES_LIST_UPDATE,
  PROFILES_LIST_DELETE,
  PROFILES_LIST_SORT,
  PROFILE_EDIT_START,
  PROFILE_EDIT_CANCEL,
  PROFILE_EDIT_ERROR,
  PROFILE_SAVE_SUCCESS,
  urls,
} from '../constants/AppConstants';
import { addGlobalMessage } from './SnackbarActions';
import { api, ignore401 } from '../services/RestService';
import { getErrorMessage } from '../services/ErrorMessageService';

const debug = debugFactory('prestago:ProfileActions');

const profilesCollection = api.all(urls.user.profiles);

function loadProfilesSuccess(profiles) {
  return {
    type: PROFILES_LOAD_SUCCESS,
    data: profiles,
  };
}

function loadProfilesError(err) {
  return {
    type: PROFILES_LOAD_ERROR,
    error: err,
  };
}

function updateProfileInList(profile) {
  return {
    type: PROFILES_LIST_UPDATE,
    data: profile,
  };
}

function deleteProfileInList(profile) {
  return {
    type: PROFILES_LIST_DELETE,
    data: profile,
  };
}

export function editProfileStart() {
  return {
    type: PROFILE_EDIT_START,
  };
}

export function editProfileCancel() {
  return {
    type: PROFILE_EDIT_CANCEL,
  };
}

function editProfileError(err) {
  return {
    type: PROFILE_EDIT_ERROR,
    error: err,
  };
}

function saveProfileSuccess() {
  return {
    type: PROFILE_SAVE_SUCCESS,
  };
}

export function loadProfiles() {
  return (dispatch) => {
    profilesCollection
      .getAll()
      .then((response) => {
        const profiles = response.body(false);
        debug('Profiles: %o', profiles);
        dispatch(loadProfilesSuccess(profiles));
      })
      .catch(ignore401)
      .catch((err) => {
        dispatch(loadProfilesError(getErrorMessage(err)));
      });
  };
}

export function sortProfiles(field, direction) {
  return {
    type: PROFILES_LIST_SORT,
    field,
    direction,
  };
}

export function saveProfile(profile) {
  return (dispatch) => {
    const saveRequest = profile.id ? profilesCollection.put(profile.id, profile) : profilesCollection.post(profile);
    saveRequest
      .then((response) => {
        if (!profile.id) {
          const newProfile = response.body(false);
          dispatch(updateProfileInList(newProfile));
          dispatch(addGlobalMessage(`Le profil ${newProfile.name} a été créé.`));
        } else {
          dispatch(updateProfileInList(profile));
          dispatch(addGlobalMessage(`Le profil ${profile.name} a été modifié.`));
        }
        dispatch(saveProfileSuccess());
      })
      .catch(ignore401)
      .catch(() => {
        dispatch(editProfileError(null)); // The error message is displayed in the snackbar
      });
  };
}

export function deleteProfile(profile) {
  return (dispatch) => {
    profilesCollection
      .delete(profile.id)
      .then(() => {
        dispatch(deleteProfileInList(profile));
        dispatch(editProfileCancel());
        dispatch(addGlobalMessage(`Le profil ${profile.name} a été supprimé.`));
      })
      .catch(ignore401)
      .catch(() => {
        dispatch(editProfileError(null)); // The error message is displayed in the snackbar
      });
  };
}
