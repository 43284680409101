import { DEMAND_OPERATIONS_LOADED, DEMAND_OPERATIONS_RESET, DEMAND_OPERATIONS_ERROR } from '../constants/AppConstants';

const initialState = {
  /**
   * The actual operations
   */
  operations: null,
  /**
   * Used to be aware that the operations have been updated, independently of whether the content itself has changed.
   */
  counter: 0,
  /**
   * Operations loading error details.
   */
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEMAND_OPERATIONS_LOADED:
      return {
        ...state,
        operations: action.operations,
        counter: state.counter + 1,
        error: null,
      };
    case DEMAND_OPERATIONS_RESET:
      return {
        ...state,
        operations: null,
        counter: 0,
        error: null,
      };
    case DEMAND_OPERATIONS_ERROR:
      return {
        ...state,
        operations: null,
        error: action.err,
      };
    default:
      return state;
  }
}
