import {
  FDD_ATTACHMENT_DELETE,
  FDD_ATTACHMENT_SET,
  FDD_ERROR,
  FDD_LOADED,
  FDD_LOADING,
  FDD_RESET,
  FDD_TOTAL_LOADED,
  FDD_VALIDATION_ERRORS,
} from '../../constants/AppConstants';

const emptyFdd = {
  id: null,
  outletId: null,
  outletRevenue: null,
  goal: null,
  subGoal: null,
  status: '_00_DRAFT',
  amount: null,
  payments: [
    {
      date: null,
      amount: null,
      condition: '',
      invoiceReceivedDate: null,
      paymentDate: null,
      paymentAmount: null,
      paymentValidated: false,
    },
  ],
  ideas: [''],
  revenueGoalYear1: null,
  revenueGoalYear2: null,
  comment: '',
  validationDate: null,
  signingDate: null,
  startDate: null,
  observation: '',
  attachments: {},
  argumentsContent: '',
  goalRevenue: null,
  goalProduct: null,
  goalStudyStartDateTime: null,
  goalStudyEndDateTime: null,
};

const initialState = {
  fdd: emptyFdd,
  total: null,
  loading: false,
  error: null,
  validationErrors: [],
  validationErrorsCounter: 0, // To know when form inputs must be updated
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FDD_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        validationErrors: [],
      };
    case FDD_LOADING:
      return {
        ...state,
        fdd: emptyFdd,
        total: null,
        loading: true,
        error: null,
        validationErrors: [],
      };
    case FDD_LOADED:
      return {
        ...state,
        fdd: action.fdd,
        total: null,
        loading: false,
        error: null,
        validationErrors: [],
      };
    case FDD_RESET:
      return {
        ...state,
        fdd: emptyFdd,
        total: null,
        loading: false,
        error: null,
        validationErrors: [],
      };
    case FDD_TOTAL_LOADED:
      return {
        ...state,
        total: action.total,
      };
    case FDD_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.errors,
        validationErrorsCounter: state.validationErrorsCounter + 1,
      };
    case FDD_ATTACHMENT_SET: {
      return {
        ...state,
        fdd: {
          ...state.fdd,
          attachments: {
            ...state.fdd.attachments,
            [action.attachment.name]: action.attachment,
          },
        },
      };
    }
    case FDD_ATTACHMENT_DELETE: {
      const attachments = { ...state.fdd.attachments };
      delete attachments[action.name];
      return {
        ...state,
        fdd: {
          ...state.fdd,
          attachments,
        },
      };
    }
    default:
      return state;
  }
}
