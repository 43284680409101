import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CardHeader, ResponsiveTable } from '../utils';
import { addGoal, loadGoals } from '../../actions/fdd/FDDGoalActions';
import FDDGoalRow from './FDDGoalRow';
import roles from '../../constants/roles';

class FDDGoalsTable extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadGoals());
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addGoal());
  };

  render() {
    const { fddGoals, hasNewGoal } = this.props;

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter un objectif"
          addRole={roles.fdd.admin.code}
          onAdd={this.onAddClick}
          addDisabled={hasNewGoal}
        >
          Objectifs FDD
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fddGoals.map((goal) => (
              <FDDGoalRow
                key={`${goal.id}-${goal.position}`}
                index={goal.position}
                goal={goal}
                editing={Boolean(goal.editing)}
                disabled={!goal.id}
              />
            ))}
          </TableBody>
        </ResponsiveTable>
      </Card>
    );
  }
}

const emptyGoal = (nbGoals) => ({
  id: null,
  name: '',
  editing: true,
  position: nbGoals,
});

const goalsWithNew = (goals) => {
  if (!goals.showNew) {
    return goals;
  }
  return [...goals, emptyGoal(goals.length)];
};

const stateToProps = ({ fddGoals }) => ({
  fddGoals: goalsWithNew(fddGoals),
  hasNewGoal: !!fddGoals.showNew,
});

export default connect(stateToProps)(FDDGoalsTable);
