import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import AfterSalesOperationsTable from '../../after-sales/AfterSalesOperationsTable';
import { withPageTitle } from '../../../utils/page-title';

const AfterSalesOperationsPage = () => <AfterSalesOperationsTable />;

export default compose(
  withPageTitle(() => "Paramétrage des prestations d'après-vente"),
  securedPage(roles.equipment.view.code),
)(AfterSalesOperationsPage);
