export const arrayEquals = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  const a2 = [...a],
    b2 = [...b];
  a2.sort();
  b2.sort();
  for (let i = 0; i < a2.length; ++i) {
    // eslint-disable-next-line eqeqeq
    if (a2[i] != b2[i]) return false;
  }
  return true;
};
