import { arrayMove } from 'react-sortable-hoc';

import { OPERATION_OPTIONS_LOADED, OPERATION_OPTIONS_UPDATING_POSITION } from '../../constants/AppConstants';

const initialState = {
  options: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPERATION_OPTIONS_LOADED:
      return {
        options: action.options,
      };
    case OPERATION_OPTIONS_UPDATING_POSITION:
      // Proactively update the displayed list while the position is saved to avoid flickering
      return {
        options: arrayMove(state.options, action.oldPosition, action.newPosition),
      };
    default:
      return state;
  }
}
