import React from 'react';

import securedPage from '../../securedPage';
import Outlet from '../../outlets/Outlet';
import roles from '../../../constants/roles';
import { useParams } from 'react-router-dom';

const OutletPage = () => {
  const { outletId } = useParams();
  return <Outlet outletId={outletId} />;
};

export default securedPage(roles.outlet.view.code)(OutletPage);
