import {
  AFTER_SALES_OPERATION_LOADING,
  AFTER_SALES_OPERATION_LOADED,
  AFTER_SALES_OPERATION_RESET,
  AFTER_SALES_OPERATION_ERROR,
  AFTER_SALES_OPERATIONS_LOADED,
  AFTER_SALES_OPERATION_VALIDATION_ERRORS,
  AFTER_SALES_OPERATION_DELETION_ERRORS,
  AFTER_SALES_OPERATION_UPDATING_POSITION,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError, addGlobalMessage } from '../SnackbarActions';
import history from '../../history';

/**
 * The REST collection used to manage after sales operation resources.
 */
const afterSalesOperationsCollection = api.all(urls.settings.afterSalesOperations);

/**
 * Dispatches two actions :
 * 1 - Displays the error message in the snackbar,
 * 2 - Adds the error to the "after sales operation" reducer.
 *
 * @param dispatch The dispatch function.
 * @param message The error message to dispatch.
 */
const dispatchAfterSalesOperationError = (dispatch, message) => {
  dispatch(addGlobalError(message));
  dispatch({
    type: AFTER_SALES_OPERATION_ERROR,
    error: message,
  });
};

export const resetAfterSalesOperation = () => ({
  type: AFTER_SALES_OPERATION_RESET,
});

export const loadAfterSalesOperation = (id) => (dispatch) => {
  dispatch({
    type: id ? AFTER_SALES_OPERATION_LOADING : AFTER_SALES_OPERATION_RESET,
  });
  if (!id) {
    return;
  }

  afterSalesOperationsCollection
    .get(id)
    .then((response) => {
      const afterSalesOperation = response.body(false);
      dispatch({
        type: AFTER_SALES_OPERATION_LOADED,
        afterSalesOperation,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading after sales operation: ', error);
      dispatchAfterSalesOperationError(
        dispatch,
        "Une erreur a eu lieu pendant le chargement de la prestation d'après vente",
      );
    });
};

export const saveOrUpdateAfterSalesOperation = (afterSalesOperation) => {
  const request = afterSalesOperation.id
    ? afterSalesOperationsCollection.put(afterSalesOperation.id, afterSalesOperation)
    : afterSalesOperationsCollection.post(afterSalesOperation);

  return (dispatch) =>
    request
      .then((response) => {
        if (!afterSalesOperation.id) {
          const eq = response.body(false);
          dispatch(addGlobalMessage("La prestation d'après vente a été créé."));
          dispatch(loadAfterSalesOperation(eq.id));
          history.push(`/settings/after-sales-operations/${eq.id}`);
        } else {
          dispatch(loadAfterSalesOperation(afterSalesOperation.id));
          dispatch(addGlobalMessage("La prestation d'après vente a été mise à jour"));
        }
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving or updating after sales operation:', err);
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch({
            type: AFTER_SALES_OPERATION_VALIDATION_ERRORS,
            errors: err.response.data.errors,
          });
          dispatch(addGlobalError('Le formulaire contient des erreurs'));
        } else {
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      });
};

export const deleteAfterSalesOperation = (id) => (dispatch) => {
  afterSalesOperationsCollection
    .delete(id)
    .then(() => {
      dispatch(addGlobalMessage("La prestation d'après vente a été supprimée."));
      history.push('/settings/after-sales-operations/');
    })
    .catch(ignore401)
    .catch((err) => {
      console.error('Error while deleting after sales operation:', err);
      if (err.response && err.response.data && err.response.data.errors && err.response.statusCode === 400) {
        dispatch({
          type: AFTER_SALES_OPERATION_DELETION_ERRORS,
          errors: err.response.data.errors[0].rejectedValue,
        });
      }
      dispatch(addGlobalError("La prestation d'après vente n'a pas pu être supprimée"));
    });
};

export const loadAfterSalesOperations = () => (dispatch) => {
  afterSalesOperationsCollection
    .getAll()
    .then((response) => {
      const afterSalesOperations = response.body(false);
      dispatch({
        type: AFTER_SALES_OPERATIONS_LOADED,
        afterSalesOperations,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading after sales operations: ', error);
      dispatch(addGlobalError("Une erreur a eu lieu pendant le chargement des prestations d'après vente"));
    });
};

export const updatePosition = (id, oldPosition, newPosition) => (dispatch) => {
  dispatch({
    type: AFTER_SALES_OPERATION_UPDATING_POSITION,
    oldPosition,
    newPosition,
  });
  afterSalesOperationsCollection
    .patch(id, {
      position: newPosition,
    })
    .then(() => {
      dispatch(loadAfterSalesOperations());
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while updating operations position:', error);
      dispatch(addGlobalError("Une erreur a eu lieu pendant l'enregistrement des données"));
    });
};

export const updateGroup = (operations, callback) => (dispatch) =>
  operations && operations.length
    ? api
        .custom('operation-groups/after-sales-operations')
        .post(operations.map((op) => ({ id: op.id, groupId: op.groupId })))
        .then(callback)
        .catch(ignore401)
        .catch((error) => {
          console.error('Error while updating operations group:', error);
          dispatch(addGlobalError("Une erreur a eu lieu pendant l'enregistrement des données"));
        })
    : callback();
