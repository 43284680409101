import { useEffect } from 'react';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { loadStockEquipment, refreshStockEquipment } from '../../../actions/StockEquipmentActions';
import StockEquipmentTable from '../../stock-equipments/StockEquipmentTable';
import { STOCK_OUTLETS } from '../../../constants/AppConstants';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { hasRole, isSubcontractor } from '../../../services/SecurityService';
import { Button, Card } from '@mui/material';
import { CardHeader, FormActions } from '../../utils';
import Hidden from '@mui/material/Hidden';
import StockEquipmentExport from '../../stock-equipments/StockEquipmentExport';
import { Sync } from 'mdi-material-ui';

const StockEquipmentPage = () => {
  const dispatch = useAppDispatch();
  const isUserSubcontractor = useAppSelector(({ currentUser }) => isSubcontractor(currentUser));
  const canEditStocks = useAppSelector(({ currentUser }) => hasRole(currentUser, roles.stockEquipments.edit.code));

  const { stockOutletCode } = useParams();

  useEffect(() => {
    dispatch(loadStockEquipment(stockOutletCode || ''));
  });
  const stockOutlet = STOCK_OUTLETS.find((value) => value.stockOutletCode === stockOutletCode);

  const refreshStock = () => {
    dispatch(refreshStockEquipment(stockOutletCode));
  };

  return (
    <Card>
      {canEditStocks && isUserSubcontractor && (
        <Card>
          <FormActions
            leftActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/stock-equipments/${stockOutletCode}/inventory`}
                >
                  Saisie d'inventaire
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/stock-equipments/${stockOutletCode}/reception`}
                >
                  Réception de matériel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/stock-equipments/${stockOutletCode}/removal`}
                >
                  Sortie de matériel
                </Button>
              </>
            }
            rightActions={
              <Button variant="text" color="info" onClick={() => refreshStock()}>
                <Sync /> Mettre à jour le stock
              </Button>
            }
          />
        </Card>
      )}
      <CardHeader
        actions={
          <Hidden mdDown>
            <StockEquipmentExport stockOutletCode={stockOutletCode} />
          </Hidden>
        }
      >
        Stocks de matériels {stockOutlet ? ' - ' + stockOutlet.name : ''}
      </CardHeader>
      <StockEquipmentTable />
    </Card>
  );
};

export default securedPage(roles.stockEquipments.view.code)(StockEquipmentPage);
