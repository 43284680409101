import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { withFormsy } from 'formsy-react';
import { getNonFormsyProps } from '../../utils/formsy-utils';
import { computeErrorText, ErrorTextProps } from './errorText';
import { InjectedProps, WrapperProps } from 'formsy-react/src/withFormsy';

type InnerTextFieldProps = MuiTextFieldProps & ErrorTextProps;

export type TextFieldProps = InnerTextFieldProps & WrapperProps<string>;

/**
 * DEPL-407: Workaround for firefox, for an input type number, checks if the key pressed is forbidden.
 * Only numbers are allowed and all other common keys like 'backspace', enter, F5, etc...
 * So we use the firefox event.key (not supported by Safari, then has to check that event.key exists)
 * to test if key pressed is a letter, or a special character in the unicode table.
 * The space between [\u002F - \u003A] is where numbers are found.
 * Has to allow "." and "," for decimal values, so we :
 * - stop unicode sequence @\u002B (+)
 * - then allow \u002C (,)
 * - forbid \u002D (-) using the character rather than unicode as we don't need a sequence
 * - then allow \u002E (.)
 * - forbid \u002F (/) using the character rather than unicode as we don't need a sequence
 *
 */
const isKeyForbidden = (event: KeyboardEvent<HTMLInputElement>): boolean =>
  event.key && !event.ctrlKey && /^[\u0020-\u002B\-/\u003A-\uFFFF]$/.test(event.key);

const TextField = (props: InnerTextFieldProps & InjectedProps<string>) => {
  const { value, setValue, onChange, type, onKeyDown, InputLabelProps, required, helperText } = props;
  const errorText = computeErrorText(props);

  const childProps = getNonFormsyProps(props);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    event.persist();
    const { onBlur } = props;
    if (onBlur) onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValue(event.target.value);
    if (onChange) onChange(event);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.persist();
    if (event.key === 'Enter') {
      setValue((event.target as HTMLInputElement).value);
    }
    if (type === 'number' && isKeyForbidden(event)) {
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <MuiTextField
      {...childProps}
      value={value !== null ? value : ''}
      error={Boolean(errorText)}
      helperText={errorText ?? helperText}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      required={required}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
    />
  );
};

export default withFormsy(TextField);
