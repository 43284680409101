import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { requestCloseDialog, selectContent, selectIsOpened } from '../../reducers/dialogBoxReducer';
import { connect } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';

class DialogBox extends React.Component {
  /**
   * Handler called to close the dialog.
   */
  onCloseHandler = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  render() {
    const {
      data: { actions, message, title },
      opened,
    } = this.props;
    return (
      <Dialog open={opened} onClose={this.onCloseHandler}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map((action) => (
            <Button color={action.color} key={action.label} onClick={action.callback}>
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }
}

DialogBox.propTypes = {
  /**
   * The function used to close the dialog.
   */
  closeDialog: PropTypes.func.isRequired,
  /**
   * The dialog content.
   */
  data: PropTypes.shape({
    /**
     * The array of actions that will be displayed using buttons.
     */
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * The button label.
         */
        label: PropTypes.string.isRequired,
        /**
         * The callback executed when then the button is clicked.
         */
        callback: PropTypes.func.isRequired,
        /**
         * The color of the button.
         */
        color: PropTypes.string.isRequired,
      }),
    ).isRequired,
    message: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  /**
   * Boolean indicating whether or not the dialog is opened.
   */
  opened: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  data: selectContent(state),
  opened: selectIsOpened(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeDialog: () => dispatch(requestCloseDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogBox);
