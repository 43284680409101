import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { COST_STRUCTURE_BUNDLE } from '../../constants/AppConstants';
import { CardHeader, SortableTable } from '../utils';
import { loadDemandTypes, addDemandType, updateDemandTypePosition } from '../../actions/DemandTypeActions';
import DemandTypeRow from './DemandTypeRow';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

class DemandTypesTable extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadDemandTypes());
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addDemandType());
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const { dispatch, demandTypes } = this.props;
      const demandType = demandTypes[oldIndex];
      dispatch(updateDemandTypePosition(demandType.id, newIndex));
    }
  };

  render() {
    // From store
    const { demandTypes, hasNewDemandType, currentUser } = this.props;
    const editable = hasRole(currentUser, roles.equipment.edit.code);

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter un type de demande"
          addRole={roles.equipment.create.code}
          onAdd={this.onAddClick}
          addDisabled={hasNewDemandType}
        >
          Paramétrage des types de demande
        </CardHeader>
        <SortableTable onSortEnd={this.onSortEnd}>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Code projet Oracle</TableCell>
              <TableCell>Libellé en-têtes Oracle</TableCell>
              <TableCell>Libellé lignes Oracle</TableCell>
              <TableCell>Concepts compatibles</TableCell>
              <TableCell>Structure de coûts</TableCell>
              <TableCell>Questions</TableCell>
              <TableCell>Multi-PDV</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {demandTypes.map((dt) => (
              <DemandTypeRow
                key={`${dt.id}-${dt.position}`}
                index={dt.position}
                demandType={dt}
                editable={editable}
                editing={Boolean(dt.editing)}
                disabled={!dt.id}
              />
            ))}
          </TableBody>
        </SortableTable>
      </Card>
    );
  }
}

const emptyDemandType = (nbDemandTypes) => ({
  id: null,
  name: '',
  compatibleConcepts: [],
  costStructure: COST_STRUCTURE_BUNDLE,
  questions: [],
  editing: true,
  position: nbDemandTypes,
});

const demandTypesWithNew = (demandTypes) => {
  if (!demandTypes.showNew) {
    return demandTypes;
  }
  return [...demandTypes, emptyDemandType(demandTypes.length)];
};

export default connect((state) => ({
  demandTypes: demandTypesWithNew(state.demandTypes),
  hasNewDemandType: !!state.demandTypes.showNew,
  currentUser: state.currentUser,
}))(DemandTypesTable);
