import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { CardContent, Collapse, IconButton, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ChevronDown } from 'mdi-material-ui';
import classNames from 'classnames';
import debugFactory from 'debug';

const debug = debugFactory('prestago:CollapsiblePanel');

const styles = (theme) => ({
  root: {},
  header: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:last-of-type': {
      paddingBottom: 16,
    },
  },
  headerText: {},
  expand: {
    transform: 'rotate(0deg)',
    margin: '-16px 0 -16px auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const CollapsiblePanel = ({ title, defaultOpened, onToggle, unmountOnExit, children, classes, theme }) => {
  const [opened, setOpened] = useState(defaultOpened);
  useEffect(() => {
    if (onToggle) {
      debug('onToggle', opened);
      window.setTimeout(() => onToggle(opened), theme.transitions.duration.standard + 50);
    }
  }, [onToggle, opened, theme.transitions.duration.standard]);
  return (
    <div className={classes.root}>
      <CardContent className={classes.header} onClick={() => setOpened(!opened)}>
        <Typography variant="h2" className={classes.headerText}>
          {title}
        </Typography>
        <IconButton
          size="small"
          className={classNames(classes.expand, {
            [classes.expandOpen]: opened,
          })}
        >
          <ChevronDown />
        </IconButton>
      </CardContent>
      <Collapse in={opened} unmountOnExit={unmountOnExit}>
        {children}
      </Collapse>
    </div>
  );
};

CollapsiblePanel.propTypes = {
  title: PropTypes.node.isRequired,
  defaultOpened: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  onToggle: PropTypes.func,
};

CollapsiblePanel.defaultProps = {
  defaultOpened: true,
  unmountOnExit: false,
};

export default withStyles(styles, { withTheme: true })(CollapsiblePanel);
