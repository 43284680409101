import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { addValidationRule } from 'formsy-react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Lock } from 'mdi-material-ui';

import { TextField } from '../utils';

const styles = (theme) => ({
  inputLabel: {
    color: theme.palette.text.secondary,
  },
});

const errorMessages = {
  password: {
    isDefaultRequiredValue: 'Le nouveau mot de passe est obligatoire',
    isExisty: 'Le nouveau mot de passe est obligatoire',
    containsOneDigit: 'Le mot de passe doit contenir au moins un chiffre',
    containsLowerCaseLetter: 'Le mot de passe doit contenir au moins une lettre minuscule',
    containsUpperCaseLetter: 'Le mot de passe doit contenir au moins une lettre majuscule',
    containsSpecialCharacter: 'Le mot de passe doit contenir au moins un caractère spécial, par exemple !-',
    minLength: 'Le mot de passe doit contenir au moins huit caractères',
    blacklist: "Le nouveau mot de passe doit être différent de l'actuel",
  },
  confirmation: {
    isDefaultRequiredValue: 'La confirmation est obligatoire',
    isExisty: 'La confirmation est obligatoire',
    equalsField: 'Les deux saisies ne sont pas égales',
  },
};

class PasswordInputs extends Component {
  static propTypes = {
    onPasswordChange: PropTypes.func.isRequired,
    oldPassword: PropTypes.string,
    showIcons: PropTypes.bool,
  };

  static defaultProps = {
    showIcons: true,
    oldPassword: '',
  };

  state = {
    password: '',
    confirmation: '',
  };

  UNSAFE_componentWillMount() {
    addValidationRule('blacklist', (values, value, forbiddenValue) => value !== forbiddenValue);
    addValidationRule('containsOneDigit', (values, value) => /^.*[0-9].*$/.test(value));
    addValidationRule('containsLowerCaseLetter', (values, value) => /^.*[a-z].*$/.test(value));
    addValidationRule('containsUpperCaseLetter', (values, value) => /^.*[A-Z].*$/.test(value));
    addValidationRule('containsSpecialCharacter', (values, value) =>
      /^.*[!"#$%&'()*+\-/:;<=>?@{|}~[\]\\].*$/.test(value),
    );
  }

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
    this.props.onPasswordChange(event.target.value);
  };

  onPasswordConfirmationChange = (event) => {
    this.setState({
      confirmation: event.target.value,
    });
  };

  render() {
    const { oldPassword, showIcons, classes } = this.props;
    const passwordValidations = `${
      'isExisty,minLength:8,containsOneDigit,containsLowerCaseLetter,' +
      'containsUpperCaseLetter,containsSpecialCharacter'
    }${oldPassword ? `,blacklist:${oldPassword}` : ''}`;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="flex-end">
            {showIcons && (
              <Grid item>
                <Lock fontSize="inherit" />
              </Grid>
            )}
            <Grid item xs>
              <TextField
                displayMultipleErrors
                label="Nouveau mot de passe"
                fullWidth
                name="password"
                type="password"
                required
                validations={passwordValidations}
                validationErrors={errorMessages.password}
                value={this.state.password}
                onChange={this.onPasswordChange}
                InputLabelProps={{ classes: { root: showIcons && classes.inputLabel } }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="flex-end">
            {showIcons && (
              <Grid item>
                <Lock fontSize="inherit" />
              </Grid>
            )}
            <Grid item xs>
              <TextField
                label="Confirmation du mot de passe"
                fullWidth
                name="password-confirm"
                type="password"
                required
                validations="isExisty,equalsField:password"
                validationErrors={errorMessages.confirmation}
                value={this.state.confirmation}
                onChange={this.onPasswordConfirmationChange}
                InputLabelProps={{ classes: { root: showIcons && classes.inputLabel } }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PasswordInputs);
