import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withFormsy } from 'formsy-react';
import { compose } from 'recompose';

import { getNonFormsyProps } from '../../utils/formsy-utils';

const styles = (theme) => ({
  errorLabel: {
    color: theme.palette.error.main,
  },
});

class CheckboxField extends React.Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    disabled: false,
  };

  onChange = (event, checked) => {
    const { onChange, setValue } = this.props;
    setValue(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  render() {
    const { value, errorMessage, isPristine } = this.props;
    const { label, classes, ...childProps } = getNonFormsyProps(this.props);
    const message = isPristine ? '' : errorMessage;
    const hasError = Boolean(errorMessage);

    return (
      <FormControl error={hasError}>
        <FormControlLabel
          control={
            <Checkbox
              {...childProps}
              checked={value}
              onChange={this.onChange}
              color={hasError ? 'secondary' : 'primary'}
            />
          }
          label={label}
          classes={hasError ? { label: classes.errorLabel } : {}}
        />
        {hasError && <FormHelperText>{message}</FormHelperText>}
      </FormControl>
    );
  }
}

export default compose(withFormsy, withStyles(styles))(CheckboxField);
