import React, { useEffect } from 'react';
import { Card, Grid } from '@mui/material';
import queryString from 'query-string';

import { filterAndSortUsers, loadUsers } from '../../actions/UserActions';
import UsersTable from './UsersTable';
import UsersFilters from './UsersFilters';
import { CardHeader, LoadingMessage } from '../utils';
import roles from '../../constants/roles';
import { useAppDispatch } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';

type UsersListProps = {
  error?: string;
  filterAvailableData: any;
  sorts: any[];
  filters: any;
  users: any[];
  availableCompanies: any[];
};

/**
 * Returns the object that will be provided to the "push"
 * method of the router. Data about filtering and  sorting are
 * added to the query string.
 */
function pushObject(sortItem, filters) {
  const query: Record<string, any> = {
    sortField: sortItem.field,
    sortDirection: sortItem.direction,
  };

  if (filters.text) {
    query.text = filters.text;
  }
  if (filters.name) {
    query.name = filters.name;
  }
  if (filters.profiles.length > 0) {
    query.profiles = filters.profiles;
  }
  if (filters.companies.length > 0) {
    query.companies = filters.companies;
  }
  if (filters.statuses.length > 0) {
    query.statuses = filters.statuses;
  }
  if (filters.regions.length > 0) {
    query.regions = filters.regions;
  }
  if (filters.agencies.length > 0) {
    query.agencies = filters.agencies;
  }
  if (filters.areas.length > 0) {
    query.areas = filters.areas;
  }

  return { search: queryString.stringify(query) };
}

const UsersList = ({ users, error, filters, sorts, filterAvailableData, availableCompanies }: UsersListProps) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadUsers());
  }, [dispatch]);
  useEffect(() => {
    dispatch(filterAndSortUsers(queryString.parse(search)));
  }, [dispatch, search]);

  const onNewUserClick = () => {
    navigate('/users/users/new');
  };

  const onHeaderClick = (field) => () => {
    let direction = 1;
    if (sorts[0].field === field) {
      direction = -sorts[0].direction;
    }
    navigate(pushObject({ field, direction }, filters));
  };

  const onFilterClick = (filters) => {
    navigate(pushObject(sorts[0], filters));
  };

  if (error) {
    return (
      <LoadingMessage serverError="Une erreur est survenue lors du chargement des utilisateurs.">
        Utilisateurs
      </LoadingMessage>
    );
  }

  const totalElements = users ? users.length : 0;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <UsersFilters
          filterAvailableData={filterAvailableData}
          availableCompanies={availableCompanies}
          filterClick={onFilterClick}
          sorts={sorts}
          filters={filters}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader addLabel="Ajouter un utilisateur" addRole={roles.user.create.code} onAdd={onNewUserClick}>
            Utilisateurs{totalElements > 0 ? ` (${totalElements})` : ''}
          </CardHeader>
          <UsersTable users={users} sorts={sorts} filters={filters} tableHeaderClick={onHeaderClick} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default UsersList;
