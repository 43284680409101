import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, InputAdornment, TableCell, Tooltip } from '@mui/material';
import { Check, Close } from 'mdi-material-ui';
import Formsy from 'formsy-react';

import { TextField } from '../utils';
import { saveSecondaryThreshold } from '../../actions/fdd/FDDSecondaryThresholdActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import EditableTableRow from '../utils/EditableTableRow';
import { formatRoundedCurrency } from '../../utils/currency-utils';
import { addMinValidationRule } from '../../utils/validation-rules';

class SecondaryThresholdRow extends Component {
  static errorMessages = {
    amount: {
      isExisty: 'Veuillez saisir un montant',
      isInt: 'Veuillez saisir un montant entier',
      min: 'Veuillez saisir un montant supérieur à 0',
    },
  };

  static propTypes = {
    threshold: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      threshold: {},
      editing: this.props.editing || false,
      deleting: false,
    };

    this.form = React.createRef();
    addMinValidationRule();
  }

  onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { threshold } = this.props;
    this.setState(({ editing }) => (editing ? null : { editing: true, threshold }));
  };

  onChange = (fieldName) => (value) => {
    this.setState(({ threshold }) => ({
      threshold: {
        ...threshold,
        [fieldName]: value,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;

    this.setState({
      threshold: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSubmitClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.form.current.submit();
  };

  onSave = () => {
    const { dispatch } = this.props;
    const { threshold } = this.state;

    this.setState({ editing: false });

    dispatch(saveSecondaryThreshold(threshold));
    dispatch(addGlobalMessage('Modifications sauvegardées'));
  };

  renderForm = (props) => <Formsy ref={this.form} noValidate onValidSubmit={this.onSave} {...props} />;

  render() {
    const { threshold, editing } = this.state;
    const { threshold: thresholdProp } = this.props;

    return (
      <EditableTableRow
        component={this.renderForm}
        editable={true}
        editing={editing}
        onEdit={(e) => this.onEditClick(e)}
        actions={
          <>
            <Tooltip title="Enregistrer le seuil">
              <IconButton type="submit" onClick={this.onSubmitClick} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
          </>
        }
        TableCellProps={{ component: 'div' }}
      >
        <TableCell component="div">
          {editing ? (
            <TextField
              type="number"
              name="amount"
              value={threshold.amount || null}
              onChange={(e) => this.onChange('amount')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
              min="1"
              validations="isExisty,isInt,min:1"
              validationErrors={SecondaryThresholdRow.errorMessages.amount}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          ) : (
            <span>{formatRoundedCurrency(thresholdProp.amount)}</span>
          )}
        </TableCell>
      </EditableTableRow>
    );
  }
}

export default connect()(SecondaryThresholdRow);
