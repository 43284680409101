import React from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: { width: 16, height: 16, margin: '0 0px' },
};

const Logo = ({ image, classes }) => <img src={image} alt="" className={classes.root} />;

Logo.propTypes = {
  image: PropTypes.string.isRequired,
};

export default withStyles(styles)(Logo);
