import React from 'react';
import { compose } from 'recompose';
import { Card, Grid } from '@mui/material';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import InterventionsFilters from '../../interventions/InterventionsFilters';
import InterventionsCalendar from '../../interventions/InterventionsCalendar';
import { loadInterventionsListForCalendar } from '../../../actions/InterventionActions';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import { withPageTitle } from '../../../utils/page-title';

const InterventionCalendarPage = () => {
  return (
    <Grid container spacing={3}>
      <HierarchicalSearch loadActionCreator={loadInterventionsListForCalendar} />
      <Grid item xs={12}>
        <InterventionsFilters calendar={true} map={false} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <InterventionsCalendar />
        </Card>
      </Grid>
    </Grid>
  );
};

export default compose(
  withPageTitle(() => 'Interventions - Planning'),
  securedPage(roles.intervention.view.code),
)(InterventionCalendarPage);
