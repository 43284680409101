import debugFactory from 'debug';

import {
  INTERVENTION_OPERATIONS_THAT_CAN_BE_ADDED_LIST,
  INTERVENTION_OPERATIONS,
  INTERVENTION_OPERATION_ADDED,
  INTERVENTION_OPERATION_UPDATE_COMPLETED_QUANTITY,
  INTERVENTION_OPERATION_UPDATE_QUANTITY,
  INTERVENTION_COMMAND_ADDED,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { operationsWithSerialNumbers, hasOperationMissingSerialNumbers } from '../utils/operation-utils';

import { addGlobalError, addGlobalMessage } from './SnackbarActions';

const debug = debugFactory('prestago:InterventionOperationsActions');

/**
 * Returns a boolean indicating whether or not the given operation has at least
 * one missing serial numbers.
 *
 * @param operation The operation to check.
 * @returns {*|boolean} {@code true} if there is at least one missing serial number.
 */
const operationHasMissingSerialNumbers = (operation) => {
  /*
   * The operation does not have equipments with serial numbers.
   */
  if (operationsWithSerialNumbers([operation]).length === 0) {
    return false;
  }

  return hasOperationMissingSerialNumbers(operation);
};

export function getOperationsThatCanBeAdded(interventionId) {
  const endpoint = api.one(urls.interventions, interventionId);

  return (dispatch) => {
    endpoint
      .all('possible-operations')
      .getAll()
      .then((response) => {
        const operations = response.body(false);
        dispatch({
          type: INTERVENTION_OPERATIONS_THAT_CAN_BE_ADDED_LIST,
          operations,
        });
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while loading operations that can be added:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant le chargement des prestations"));
      });
  };
}

export function addOperation(interventionId, operation, deliveryLocationId) {
  const endpoint = api.one(urls.interventions, interventionId);
  const body = {
    operation: {
      ...operation,
      origin: 'INTERVENTION',
    },
    deliveryLocationId,
  };

  debug('addOperation %s %o', deliveryLocationId, body);

  return (dispatch) => {
    endpoint
      .all('operations')
      .post(body)
      .then((response) => {
        const command = response.body(false);
        if (command) {
          dispatch({
            type: INTERVENTION_COMMAND_ADDED,
            command,
          });
        }
        dispatch({
          type: INTERVENTION_OPERATION_ADDED,
          operation: body.operation,
        });
        dispatch(addGlobalMessage('La prestation a bien été ajoutée.'));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while adding operation:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement"));
      });
  };
}

export function updateOperationCompletedQuantity(interventionId, operation, completedQuantity) {
  const endpoint = api.one(urls.interventions, interventionId);
  return (dispatch) => {
    endpoint
      .all('operation')
      .post({
        type: operation.type,
        equipmentId: operation.selectedEquipmentId,
        completedQuantity,
      })
      .then(() => {
        dispatch({
          type: INTERVENTION_OPERATION_UPDATE_COMPLETED_QUANTITY,
          operationType: operation.type,
          completedQuantity,
          equipmentId: operation.selectedEquipmentId,
        });

        if (completedQuantity > 0 && operationHasMissingSerialNumbers({ ...operation, completedQuantity })) {
          dispatch(
            addGlobalMessage("Cette prestation contient du matériel nécessitant la saisie d'un numéro de série"),
          );
        } else {
          dispatch(addGlobalMessage('La modification est enregistrée'));
        }
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while updating completed quantity:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement de la modification"));
      });
  };
}

export function updateOperationQuantity(interventionId, operation, quantity) {
  const endpoint = api.one(urls.interventions, interventionId);
  return (dispatch) => {
    endpoint
      .all('operation')
      .post({
        type: operation.type,
        equipmentId: operation.selectedEquipmentId,
        quantity,
      })
      .then(() => {
        dispatch({
          type: INTERVENTION_OPERATION_UPDATE_QUANTITY,
          operationType: operation.type,
          quantity,
          equipmentId: operation.selectedEquipmentId,
        });

        dispatch(addGlobalMessage('La modification est enregistrée'));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while updating quantity:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement de la modification"));
      });
  };
}

export function interventionOperations(addOperations, moveOperations, removeOperations) {
  return {
    type: INTERVENTION_OPERATIONS,
    operations: {
      addOperations,
      moveOperations,
      removeOperations,
    },
  };
}
