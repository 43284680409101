import { DISPLAY_LOADER, HIDE_LOADER } from '../constants/AppConstants';

const initialState = [];

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case DISPLAY_LOADER:
      return state.concat(action.name);
    case HIDE_LOADER:
      return state.filter((name) => name !== action.name);
    default:
      return state;
  }
}
