import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Delete } from 'mdi-material-ui';
import classNames from 'classnames';

import { AutocompleteField, MultiValue, TextField } from '../utils';
import AddToDemands from '../reload-demands/AddToDemands';
import SortableRow from '../utils/SortableRow';

const styles = {
  equipmentCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  equipmentEditingCell: {
    paddingBottom: 0,
  },
  quantityCell: {
    paddingTop: 6,
    paddingBottom: 0,
    width: 100,
  },
  actionCell: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 20,
    textAlign: 'right',
    width: 83,
  },
  buttonSpacer: {
    display: 'inline-block',
    width: 48,
  },
};

class OperationBundleOperation extends Component {
  static errorMessages = {
    maxQuantity: {
      isInt: 'Veuillez saisir un entier',
      isDefaultRequiredValue: 'Veuillez saisir un max',
      isExisty: 'Veuillez saisir un max',
      min: 'Minimum 1',
    },
    minQuantity: {
      isInt: 'Veuillez saisir un entier',
      min: 'Minimum 1',
      max: 'Supérieur au max',
    },
    equipments: {
      isDefaultRequiredValue: 'Veuillez sélectionner au moins un matériel',
      isExisty: 'Veuillez sélectionner au moins un matériel',
      minLength: 'Veuillez sélectionner au moins un matériel',
    },
  };

  static propTypes = {
    operation: PropTypes.object.isRequired,
    equipments: PropTypes.array.isRequired,
    usedEquipmentIds: PropTypes.array.isRequired,
    editing: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onAddToDemands: PropTypes.func.isRequired,
    canAddToDemands: PropTypes.bool.isRequired,
  };

  state = {
    editingEquipments: !this.props.operation.equipmentIds.length,
  };

  onEdit = () => {
    const { editing } = this.props;
    if (editing) {
      this.setState({ editingEquipments: true });
    }
  };

  onEquipmentsChange = (equipmentIds) => {
    const { onChange, index } = this.props;
    onChange(index, 'equipmentIds', equipmentIds);
    // Force a render to help Formsy
    this.setState({});
  };

  unusedEquipments = (equipments, usedEquipmentIds, whiteListedIds) =>
    equipments.filter((eq) => whiteListedIds.includes(eq.id) || !usedEquipmentIds.includes(eq.id));

  render() {
    const {
      operation,
      equipments,
      usedEquipmentIds,
      editing,
      index,
      onChange,
      onRemove,
      onAddToDemands,
      canAddToDemands,
      classes,
    } = this.props;
    const { editingEquipments } = this.state;

    const equipmentNames = operation.equipmentIds.map((eqId) =>
      equipments.getNameById(eqId, () => '(matériel incompatible)'),
    );
    return (
      <SortableRow index={index} disabled={!editing}>
        <TableCell
          className={classNames(classes.equipmentCell, {
            [classes.equipmentEditingCell]: editing,
          })}
          onClick={this.onEdit}
        >
          {editing && editingEquipments ? (
            <AutocompleteField
              multiple
              options={this.unusedEquipments(equipments, usedEquipmentIds, operation.equipmentIds)}
              fullWidth
              name="equipments"
              required
              validations="isExisty,minLength:1"
              validationErrors={OperationBundleOperation.errorMessages.equipments}
              value={operation.equipmentIds}
              onChange={this.onEquipmentsChange}
            />
          ) : (
            <MultiValue values={equipmentNames} />
          )}
        </TableCell>
        {editing ? (
          <TableCell className={classes.quantityCell}>
            <TextField
              type="number"
              fullWidth
              name="maxQuantity"
              value={operation.maxQuantity}
              min="1"
              validations={{
                isInt: true,
                min: operation.minQuantity || 1,
              }}
              validationErrors={{
                ...OperationBundleOperation.errorMessages.maxQuantity,
                min: operation.minQuantity ? 'Inférieur au min' : 'Minimum 1',
              }}
              onChange={(e) => onChange(index, 'maxQuantity', e.target.value)}
            />
          </TableCell>
        ) : (
          <TableCell>{operation.maxQuantity || ''}</TableCell>
        )}
        {editing ? (
          <TableCell className={classes.quantityCell}>
            <TextField
              type="number"
              step="1"
              fullWidth
              name="minQuantity"
              value={operation.minQuantity}
              min="1"
              validations={{
                isInt: true,
                min: 1,
                max: operation.maxQuantity,
              }}
              validationErrors={OperationBundleOperation.errorMessages.minQuantity}
              onChange={(e) => onChange(index, 'minQuantity', e.target.value)}
            />
          </TableCell>
        ) : (
          <TableCell>{operation.minQuantity || ''}</TableCell>
        )}
        <TableCell className={classes.actionCell}>
          {editing && (
            <Tooltip title="Supprimer la prestation">
              <IconButton onClick={() => onRemove(index)} size="large">
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {canAddToDemands && !editing && (
            <AddToDemands
              icon
              elementName={`la prestation "${equipmentNames.join('", "')}"`}
              onAddToDemands={onAddToDemands}
            />
          )}
        </TableCell>
      </SortableRow>
    );
  }
}

export default withStyles(styles)(OperationBundleOperation);
