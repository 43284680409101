import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControl, InputLabel, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {},
  label: {},
  content: {
    marginTop: 16,
    lineHeight: '32px',
  },
};

/**
 * Static component that looks like a disabled TextField.
 */
const LabelValue = ({ label, children, text, classes, ...props }) => (
  <FormControl className={classes.root} fullWidth {...props}>
    <InputLabel shrink className={classes.label}>
      {label}
    </InputLabel>
    {text ? (
      <Typography variant="body1" className={classes.content}>
        {children}
      </Typography>
    ) : (
      <div className={classes.content}>{children}</div>
    )}
  </FormControl>
);

LabelValue.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  text: PropTypes.bool,
};

LabelValue.defaultProps = {
  text: true,
};

export default withStyles(styles)(LabelValue);
