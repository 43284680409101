import React from 'react';
import { compose } from 'recompose';
import { Grid } from '@mui/material';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import FDDGoalsTable from '../../settings/FDDGoalsTable';
import FDDAgreementTypesTable from '../../settings/FDDAgreementTypesTable';
import FDDThresholdsTable from '../../settings/FDDThresholdsTable';
import FDDSecondaryThresholdsTable from '../../settings/FDDSecondaryThresholdsTable';
import { withPageTitle } from '../../../utils/page-title';
import FDDSubGoalsTable from '../../settings/FDDSubGoalsTable';

const FDDSettingsPage = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <FDDGoalsTable />
    </Grid>
    <Grid item xs={12}>
      <FDDSubGoalsTable />
    </Grid>
    <Grid item xs={12}>
      <FDDAgreementTypesTable />
    </Grid>
    <Grid item xs={12}>
      <FDDThresholdsTable />
    </Grid>
    <Grid item xs={12}>
      <FDDSecondaryThresholdsTable />
    </Grid>
  </Grid>
);

export default compose(
  withPageTitle(() => 'Paramétrage des FDD'),
  securedPage(roles.fdd.admin.code),
)(FDDSettingsPage);
