import { combineReducers } from 'redux';
import afterSalesOperation from './settings/afterSalesOperationReducer';
import afterSalesOperations from './settings/afterSalesOperationsReducer';
import agencies from './settings/agenciesReducer';
import areas from './settings/areasReducer';
import currentUser from './currentUserReducer';
import dayActivity from './dayActivityReducer';
import deliveryLocations from './deliveryLocationsReducer';
import demand from './demandReducer';
import demandAttachments from './demandAttachmentsReducer';
import demandCost from './demandCostReducer';
import demandImport from './demandImportReducer';
import demandOperations from './demandOperationsReducer';
import demands from './demandsReducer';
import demandTransitionAttachments from './demandTransitionAttachmentsReducer';
import demandTypes from './demandTypesReducer';
import dialog from './dialogBoxReducer';
import equipment from './settings/equipmentReducer';
import equipments from './settings/equipmentsReducer';
import equipmentMoves from './equipmentMovesReducer';
import fdd from './fdd/fddReducer';
import fdds from './fdd/fddsReducer';
import fddAgreementTypes from './fdd/fddAgreementTypesReducer';
import fddGoals from './fdd/fddGoalsReducer';
import fddSubGoals from './fdd/fddSubGoalsReducer';
import fddThresholds from './fdd/fddThresholdsReducer';
import fddSecondaryThresholds from './fdd/fddSecondaryThresholdsReducer';
import globalMessage from './globalMessageReducer';
import intervention from './interventionReducer';
import interventionAttachments from './interventionAttachmentsReducers';
import interventionOperations from './interventionOperationsReducer';
import interventionSignatures from './interventionSignaturesReducers';
import interventions from './interventionsReducer';
import login from './loginReducer';
import loader from './loaderReducer';
import mainMenu from './mainMenuReducer';
import operationBundle from './settings/operationBundleReducer';
import operationBundles from './settings/operationBundlesReducer';
import operationOption from './settings/operationOptionReducer';
import operationOptions from './settings/operationOptionsReducer';
import operationGroups from './settings/operationGroupsReducer';
import outletsImports from './outletsImportsReducers';
import outlets from './outletsReducer';
import outlet from './outletReducer';
import outletAutoComplete from './outletAutoCompleteReducer';
import profiles from './profilesReducer';
import profileEdit from './profileEditReducer';
import regions from './settings/regionsReducer';
import reloadDemandCostsStatus from './settings/reloadDemandCostsStatusReducer';
import user from './userReducer';
import users from './usersReducer';
import stockEquipments from './stockEquipmentsReducer';

export default combineReducers({
  afterSalesOperation,
  afterSalesOperations,
  agencies,
  areas,
  currentUser,
  dayActivity,
  deliveryLocations,
  demand,
  demandAttachments,
  demandCost,
  demandImport,
  demandOperations,
  demands,
  demandTransitionAttachments,
  demandTypes,
  dialog,
  equipment,
  equipments,
  equipmentMoves,
  fdd,
  fdds,
  fddAgreementTypes,
  fddGoals,
  fddSubGoals,
  fddThresholds,
  fddSecondaryThresholds,
  globalMessage,
  intervention,
  interventionAttachments,
  interventionOperations,
  interventionSignatures,
  interventions,
  login,
  loader,
  mainMenu,
  operationBundle,
  operationBundles,
  operationOption,
  operationOptions,
  operationGroups,
  outletsImports,
  outlets,
  outlet,
  outletAutoComplete,
  profiles,
  profileEdit,
  regions,
  reloadDemandCostsStatus,
  user,
  users,
  stockEquipments,
});
