import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import debugFactory from 'debug';

import Pagination from '../commons/Pagination';
import DateViewer from '../commons/DateViewer';
import { ResponsiveTable, SortableTableHeader } from '../utils';
import { SortDirections } from '../../utils/sorting';
import { updateInterventionsFilter } from '../../actions/InterventionActions';
import history from '../../history';
import Hidden from '@mui/material/Hidden';
import withWidth from '@mui/material/Hidden/withWidth';
import { compose } from 'recompose';
import InterventionsListItem from './InterventionsListItem';

const debug = debugFactory('prestago:InterventionsTable');

const styles = () => ({
  list: {
    padding: 0,
  },
});

class InterventionsTable extends Component {
  static propTypes = {
    // Redux props
    interventions: PropTypes.array,
    pagination: PropTypes.object,
  };

  /**
   * Invoked when a component is receiving new props. This method is not called for the initial render.
   * @see https://facebook.github.io/react/docs/component-specs.html#updating-componentwillreceiveprops
   *
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pagination } = nextProps;
    debug('RECEIVED pagination: %o', pagination);
  }

  onPageClick = (pageNumber) => {
    const { pagination } = this.props;
    pagination.currentPage = pageNumber;
    updateInterventionsFilter(pagination);
  };

  onPageSizeChange = (pageSize) => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.pageSize = pageSize;
    updateInterventionsFilter(pagination);
  };

  onHeaderClick = (field) => () => {
    const { pagination } = this.props;
    pagination.sort = { field, direction: this.nextDirection(field) };
    updateInterventionsFilter(pagination);
  };

  onRowClick = (interventionId) => () => {
    debug('onRowClick %s', interventionId);
    history.push({ pathname: `/interventions/${interventionId}` });
  };

  sortDirection = (field) => {
    const { sort } = this.props.pagination;
    return sort && sort.field === field ? sort.direction : SortDirections.none;
  };

  nextDirection(field) {
    const { sort } = this.props.pagination;
    return sort && sort.field === field && sort.direction === SortDirections.asc
      ? SortDirections.desc
      : SortDirections.asc;
  }

  getFlexAlign = () => {
    const { width } = this.props;
    return 'xs' === width ? '' : 'flex-end';
  };

  render() {
    const { interventions, pagination, classes } = this.props;

    return (
      <div>
        <Hidden mdUp>
          <List className={classes.list}>
            {Boolean(interventions.length) &&
              interventions.map((intervention) => (
                <InterventionsListItem
                  key={intervention.number}
                  intervention={intervention}
                  onClick={this.onRowClick(intervention.id)}
                />
              ))}
          </List>
        </Hidden>
        <Hidden mdDown>
          <ResponsiveTable>
            <TableHead>
              <TableRow>
                <SortableTableHeader
                  direction={this.sortDirection('number')}
                  onClickCallback={this.onHeaderClick('number')}
                  title="Numéro"
                />
                <TableCell>Zone de vente</TableCell>
                <TableCell>Secteur</TableCell>
                <SortableTableHeader
                  direction={this.sortDirection('outlet_code')}
                  onClickCallback={this.onHeaderClick('outlet_code')}
                  title="Code PDV"
                />
                <SortableTableHeader
                  direction={this.sortDirection('outlet_name')}
                  onClickCallback={this.onHeaderClick('outlet_name')}
                  title="Enseigne"
                />
                <TableCell>PDV Origine</TableCell>
                <SortableTableHeader
                  direction={this.sortDirection('demand_type_name')}
                  onClickCallback={this.onHeaderClick('demand_type_name')}
                  title="Type"
                />
                <SortableTableHeader
                  direction={this.sortDirection('concept')}
                  onClickCallback={this.onHeaderClick('concept')}
                  title="Concept"
                />
                <SortableTableHeader
                  direction={this.sortDirection('subcontractor')}
                  onClickCallback={this.onHeaderClick('subcontractor')}
                  title="Prestataire"
                />
                <SortableTableHeader
                  direction={this.sortDirection('operation_schedule_date')}
                  onClickCallback={this.onHeaderClick('operation_schedule_date')}
                  title="Date de planification"
                />
                <SortableTableHeader
                  direction={this.sortDirection('status')}
                  onClickCallback={this.onHeaderClick('status')}
                  title="Statut"
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(interventions.length) ? (
                interventions.map((intervention) => (
                  <TableRow key={intervention.number} onClick={this.onRowClick(intervention.id)} hover>
                    <TableCell>{intervention.number}</TableCell>
                    <TableCell>{intervention.agency.name}</TableCell>
                    <TableCell>{intervention.area.name}</TableCell>
                    <TableCell>{intervention.outlet.code}</TableCell>
                    <TableCell>{intervention.outlet.name}</TableCell>
                    <TableCell>{intervention.originOutlet.code}</TableCell>
                    <TableCell>{intervention.demandType.name}</TableCell>
                    <TableCell style={{ color: intervention.concept.color }}>{intervention.concept.name}</TableCell>
                    <TableCell>{intervention.subcontractor.name}</TableCell>
                    <TableCell>
                      {intervention.operationScheduleDate ? (
                        <DateViewer date={intervention.operationScheduleDate} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>{intervention.status.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="11">Aucune intervention ne correspond aux critères renseignés</TableCell>
                </TableRow>
              )}
            </TableBody>
          </ResponsiveTable>
        </Hidden>
        <Pagination
          currentPage={pagination.currentPage}
          numberOfPages={pagination.numberOfPages}
          pageSize={pagination.pageSize}
          onPageChange={this.onPageClick}
          onPageSizeChange={this.onPageSizeChange}
        />
      </div>
    );
  }
}

const stateToProps = ({ interventions: { list: interventions, pagination } }) => ({
  // Sorted by server
  interventions,
  pagination,
});

export default compose(withStyles(styles), withWidth(), connect(stateToProps))(InterventionsTable);
