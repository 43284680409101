import { DEMANDS_FILTER_UPDATED, DEMANDS_LOADED } from '../constants/AppConstants';
import { SortDirections } from '../utils/sorting';

const initialState = {
  list: [],
  pagination: {
    currentPage: 0,
    numberOfPages: 0,
    pageSize: 10,
    totalElements: 0,
    sort: { field: 'number', direction: SortDirections.desc },
    filter: {},
  },
};

/**
 * Manage only one level of sort.
 *
 * @param sort
 */
const getSort = (sort) =>
  sort
    ? {
        field: sort[0].property,
        direction: sort[0].direction === 'ASC' ? SortDirections.asc : SortDirections.desc,
      }
    : {};

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case DEMANDS_FILTER_UPDATED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize: action.pageSize || 10,
          filter: action.filter,
        },
      };
    case DEMANDS_LOADED:
      return {
        ...state,
        list: action.page.content,
        pagination: {
          ...state.pagination,
          currentPage: action.page.number,
          numberOfPages: action.page.totalPages,
          sort: getSort(action.page.sort),
          totalElements: action.page.totalElements,
        },
      };
    default:
      return state;
  }
}
