import { INTERVENTION_STATUSES } from '../constants/AppConstants';
import roles from '../constants/roles';
import { hasRole, hasAnyRole } from '../services/SecurityService';

export const canUserEditIntervention = (state) => {
  const intervention = state.intervention;
  if (!intervention || !intervention.intervention || !intervention.intervention.status) {
    return false;
  }
  if (!hasRole(state.currentUser, roles.intervention.process.code)) {
    return false;
  }

  return !INTERVENTION_STATUSES.getById(intervention.intervention.status).final;
};

/**
 * Returns a boolean indicating whether or not the current user can
 * modify the serial numbers of the currently intervention.
 *
 * To do that, the user needs to have either the right to treat the
 * intervention or to edit the related demand. The intervention status
 * and the demand one are not relevant because serial numbers often
 * need to be fixed several weeks after the end of the demand.
 *
 * Please notice that there is no access checking here. We consider
 * that the verification of the intervention access for the current
 * user has already been performed. If we are checking if the user
 * can edit the intervention's serial numbers, that means that we
 * are already on the intervention page.
 *
 * @param state The current application state.
 * @return {boolean} {@code true} if the current user can modify
 * the serial numbers of the currently loaded intervention. Otherwise
 * {@code false} is returned.
 */
export const canUserEditSerialNumbers = (state) =>
  hasAnyRole(state.currentUser, [roles.intervention.process.code, roles.demand.edit.code]);
