import { USER_LOAD, USER_CARD_LEAVE, USER_TOGGLE_EDIT, USER_ERROR } from '../constants/AppConstants';

const initialState = {
  user: {
    firstName: null,
    lastName: null,
    email: null,
    company: null,
    region: null,
    agency: null,
    areas: [],
    profiles: [],
  },
  editing: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOAD:
      return {
        ...state,
        user: action.user || initialState.user,
        editing: false,
      };
    case USER_CARD_LEAVE:
      return {
        ...state,
        ...initialState,
      };
    case USER_TOGGLE_EDIT:
      return {
        ...state,
        editing: !state.editing,
      };
    case USER_ERROR:
      return {
        ...state,
        editing: action.err !== null && typeof action.err !== 'undefined',
      };
    default:
      return state;
  }
}
