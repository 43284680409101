import UserCard from '../../user-profiles/User';
import securedPage from '../../securedPage';
import { sort, SortDirections } from '../../../utils/sorting';
import { hasRole, visibleCompanies } from '../../../services/SecurityService';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';

/**
 * Returns a predicate indicating whether a region is compatible with the given user profile.
 */
const regionPredicate = (state) => (region) => {
  const currentUser = state.currentUser.user;
  // noinspection JSUnresolvedVariable
  const regionId = currentUser.regionID;
  return !regionId || regionId === region.id;
};

/**
 * Returns a predicate indicating whether an agency is compatible with the given user profile.
 */
const agencyPredicate = (state) => (agency) => {
  const currentUser = state.currentUser.user;
  // noinspection JSUnresolvedVariable
  const regionId = currentUser.regionID;
  // noinspection JSUnresolvedVariable
  const agencyId = currentUser.agencyID;

  return (!regionId || regionId === agency.region.id) && (!agencyId || agencyId === agency.id);
};

/**
 * Returns a predicate indicating whether an area is related to one of the given agencies.
 */
const areasPredicate = (agencies) => {
  const agenciesIds = agencies.map((agency) => agency.id);
  return (area) => agenciesIds.indexOf(area.agency.id) >= 0;
};

/**
 * Returns all the agencies available for the "agencies" Select
 * component.
 *
 * If the user card is not in edition mode, then the returned array
 * should contain only the user's agency. Otherwise, it will contain
 * all the existing agencies (possibly restricted to the currently
 * authenticated user).
 *
 * Please notice that areas restrictions in the form (eg: after
 * selecting a region, only agencies related to this region should
 * appear in the agencies Select component) are not managed here.
 * Such restrictions are managed in the underlying component.
 */
const selectAvailableAgencies = (state) => {
  const agencies = state.agencies ? state.agencies.list : [];
  const filteredAgencies = agencies.filter(agencyPredicate(state));
  return sort(filteredAgencies, [{ field: 'name', direction: SortDirections.asc }]);
};

/**
 * Returns all the areas available for the "areas" multi Select
 * component.
 *
 * If the user card is not in edition mode, the returned array
 * will only contain the user's areas. Otherwise, it will contain
 * all the existing areas (possibly restricted according to the
 * currently authenticated user).
 *
 * Please notice that areas restrictions in the form (eg: after
 * selecting an agency, only areas related to this agency should
 * appear in the areas Select component) are not managed here. Such
 * restrictions are managed in the underlying component.
 */
const selectAvailableAreas = (agencies) => (state) => {
  const areas = state.areas ? state.areas.list : [];
  const filteredAreas = areas.filter(areasPredicate(agencies));
  return sort(filteredAreas, [{ field: 'name', direction: SortDirections.asc }]);
};

/**
 * Returns the array of profiles available for the "profiles" multi
 * Select component.
 *
 * If the user card is not in edition mode, the profiles array will
 * only contain the user's profiles. Otherwise, it will contain all
 * the existing profiles.
 */
const selectAvailableProfiles = (state) =>
  sort(state.profiles.list, [{ field: 'name', direction: SortDirections.asc }]);

/**
 * Returns the regions available for the "regions" Select component.
 *
 * If the user card is not in edition mode, the regions array will
 * only contain the user's region. In edition mode, it will contain
 * all the regions (possibly restricted by the currently authenticated
 * user).
 */
const selectAvailableRegions = (state) => {
  const regions = state.regions ? state.regions : [];
  const filteredRegions = regions.filter(regionPredicate(state));
  return sort(filteredRegions, [{ field: 'name', direction: SortDirections.asc }]);
};

/**
 * Transforms the user present in the Redux store into the object
 * to use in the React component that displays the user card.
 *
 * The main differences concern the region, the agency, the area and
 * the profiles. In the store, this data contains for each field the
 * Id and the names. For the user card, the user model contains only
 * Ids.
 *
 * @param storeUser The user stored in the Redux store.
 * @returns {*} The user to use to display the user card.
 */
const transformUser = (storeUser) => ({
  id: storeUser.id,
  active: storeUser.active,
  blocked: storeUser.blocked,
  firstName: storeUser.firstName,
  lastName: storeUser.lastName,
  email: storeUser.email,
  phoneNumber: storeUser.phoneNumber,
  company: storeUser.company,
  regionId: storeUser.region ? storeUser.region.id : null,
  agencyId: storeUser.agency ? storeUser.agency.id : null,
  areasIds: storeUser.areas.map((area) => area.id),
  profilesIds: storeUser.profiles.map((profile) => profile.id),
});

const UserPage = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const { user, editing } = useAppSelector(({ user }) => user);
  const availableAgencies = useAppSelector(selectAvailableAgencies);
  const availableAreas = useAppSelector(selectAvailableAreas(availableAgencies));
  const availableProfiles = useAppSelector(selectAvailableProfiles);
  const availableRegions = useAppSelector(selectAvailableRegions);

  usePageTitle(userId ? `Utilisateur ${user?.firstName ?? ''} ${user?.lastName ?? ''}` : 'Nouvel utilisateur');

  return (
    <UserCard
      availableAgencies={availableAgencies}
      availableAreas={availableAreas}
      availableCompanies={visibleCompanies(currentUser)}
      availableProfiles={availableProfiles}
      availableRegions={availableRegions}
      editing={editing}
      user={transformUser(user)}
      hasUserEditRole={hasRole(currentUser, roles.user.edit.code)}
      hasUserDeleteRole={hasRole(currentUser, roles.user.delete.code)}
      userId={userId}
      dispatch={dispatch}
    />
  );
};

export default securedPage(roles.user.view.code)(UserPage);
