/* global google */
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import securedPage from '../securedPage';
import roles from '../../constants/roles';
import { Typography } from '@mui/material';
import { isNullish } from '../../utils/ts-utils';
import InterventionMarker from './InterventionMarker';

const defaultOptions: google.maps.MapOptions = {
  mapTypeControl: false,
  gestureHandling: 'greedy',
  fullscreenControl: false,
  streetViewControl: false,
};

const InterventionsMap = ({ outletWithInterventions, numberOfInterventions, numberOfInterventionsWithCoordinates }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCQVlW_RRGqsx--iJhtGb6aQat2Ekafg4U',
  });
  const [map, setMap] = React.useState<google.maps.Map>();

  useEffect(() => {
    if (!map || !outletWithInterventions.length) return;
    const bounds = new google.maps.LatLngBounds();
    outletWithInterventions.forEach(({ outlet }) => {
      bounds.extend({
        lat: outlet.latitude,
        lng: outlet.longitude,
      });
    });
    map.fitBounds(bounds);
  }, [map, outletWithInterventions]);

  const onLoad = (map: google.maps.Map) => setMap(map);
  const onUnmount = () => setMap(undefined);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ height: '100%' }}
          options={defaultOptions}
          zoom={6}
          center={{ lat: 46.0989258, lng: 0.348666 }}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {outletWithInterventions.map((item) => (
            <InterventionMarker key={item.outlet.code} outlet={item.outlet} interventions={item.interventions} />
          ))}
        </GoogleMap>
      ) : (
        <></>
      )}
      <Typography key={'map-legend'}>
        {numberOfInterventions} intervention(s) trouvée(s). {numberOfInterventionsWithCoordinates} intervention(s)
        visible(s) sur la carte.
      </Typography>
    </>
  );
};

const toOutletWithIntervention = ({
  outlet,
  id,
  number,
  status,
  operationScheduleDate,
  operationStartedDate,
  operationFinishedDate,
}) => ({
  outletName: outlet.name,
  outlet: {
    code: outlet.code,
    longitude: outlet.address.coordinates.longitude,
    latitude: outlet.address.coordinates.latitude,
    name: outlet.name,
  },
  intervention: {
    id,
    number,
    status,
    operationScheduleDate,
    operationStartedDate,
    operationFinishedDate,
  },
});

const toOutletWithInterventionsByOutletName = (byOutletName, outletWithIntervention) => {
  const outletName = outletWithIntervention.outletName;
  byOutletName[outletName] = byOutletName[outletName] || { outlet: {}, interventions: [] };
  if (!byOutletName[outletName].outlet.name) {
    byOutletName[outletName].outlet = outletWithIntervention.outlet;
  }
  byOutletName[outletName].interventions.push(outletWithIntervention.intervention);
  return byOutletName;
};

const stateToProps = ({ interventions: { list, pagination } }) => {
  const interventionsWithCoordinates = list.filter(
    ({ outlet: { address } }) =>
      !isNullish(address?.coordinates?.latitude) && !isNullish(address?.coordinates?.longitude),
  );
  const outletWithInterventions = Object.values(
    interventionsWithCoordinates.map(toOutletWithIntervention).reduce(toOutletWithInterventionsByOutletName, {}),
  );
  return {
    outletWithInterventions: outletWithInterventions,
    numberOfInterventionsWithCoordinates: interventionsWithCoordinates.length,
    numberOfInterventions: list.length,
    pagination,
  };
};

export default compose(connect(stateToProps), securedPage(roles.intervention.view.code))(InterventionsMap);
