import React from 'react';
import * as PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { CardActions, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Truck from 'mdi-material-ui/Truck';

import InterventionOperation from './InterventionOperation';
import InterventionOperationAdd from './InterventionOperationAdd';
import InterventionCommandAdd from './InterventionCommandAdd';
import { CollapsiblePanel } from '../utils';

const styles = (theme) => ({
  narrow: {
    width: 0,
    paddingRight: 0,
  },
  actions: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > button svg': {
      marginRight: theme.spacing(1),
    },
  },
  action: {
    flex: 1,
    minWidth: 300,
  },
});

class InterventionOperationsList extends React.Component {
  static propTypes = {
    canEdit: PropTypes.bool.isRequired,
    completionStatusCanBeToggled: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    interventionId: PropTypes.string.isRequired,
    operations: PropTypes.array,
    operationsThatCanBeAdded: PropTypes.array.isRequired,
    operationType: PropTypes.string.isRequired,
    onOpenCommandDetails: PropTypes.func,
    updateTabsHeight: PropTypes.func,
  };

  static messages = {
    title: {
      add: 'Prestations de pose',
      move: 'Prestations de déplacement',
      remove: 'Prestations de dépose',
      multiOutletSuffix: ' (PDV destination)',
    },
    addButton: {
      add: 'Ajouter une prestation de pose',
      move: 'Ajouter une prestation de déplacement',
      remove: 'Ajouter une prestation de dépose',
    },
  };

  render() {
    const {
      canEdit,
      completionStatusCanBeToggled,
      dispatch,
      interventionId,
      operationType,
      operations,
      operationsThatCanBeAdded,
      suffix,
      showDeliveryStatus,
      showAddDelivery,
      getOracleStatus,
      onOpenCommandDetails,
      updateTabsHeight,
      classes,
    } = this.props;
    const displayedOperations = operations.filter((op) => op.displayed && op.quantity > 0);

    const addOpLabel = InterventionOperationsList.messages.addButton[operationType];
    return (
      (displayedOperations.length > 0 || (operationsThatCanBeAdded.length > 0 && canEdit)) && (
        <CollapsiblePanel
          title={`${InterventionOperationsList.messages.title[operationType]} ${suffix}`}
          onToggle={updateTabsHeight}
        >
          {displayedOperations.length > 0 && (
            <Formsy noValidate>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Matériel</TableCell>
                    {showDeliveryStatus && (
                      <TableCell className={classes.narrow}>
                        <Tooltip title="Statut des livraisons">
                          <Truck />
                        </Tooltip>
                      </TableCell>
                    )}
                    <TableCell className={classes.narrow}>Fait</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedOperations.map((op) => (
                    <InterventionOperation
                      key={`${op.type}-${op.equipments[0].id}`}
                      completionStatusCanBeToggled={completionStatusCanBeToggled}
                      canEdit={canEdit}
                      dispatch={dispatch}
                      interventionId={interventionId}
                      operation={op}
                      showOracleStatus={showDeliveryStatus}
                      oracleStatus={getOracleStatus && getOracleStatus(op.selectedEquipmentId)}
                      onOpenCommandDetails={onOpenCommandDetails}
                      updateTabsHeight={updateTabsHeight}
                    />
                  ))}
                </TableBody>
              </Table>
            </Formsy>
          )}

          {Boolean(canEdit && (operationsThatCanBeAdded.length || showAddDelivery)) && (
            <CardActions className={classes.actions}>
              <InterventionOperationAdd
                className={classes.action}
                label={addOpLabel}
                operations={operationsThatCanBeAdded}
                needsDelivery={showAddDelivery && operationType === 'add'}
              />
              {showAddDelivery && operationType === 'add' && (
                <InterventionCommandAdd operations={operations} className={classes.action} />
              )}
            </CardActions>
          )}
        </CollapsiblePanel>
      )
    );
  }
}

export default withStyles(styles)(InterventionOperationsList);
