import React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, Button, DialogContent, DialogActions, DialogContentText, Grid } from '@mui/material';
import Formsy from 'formsy-react';
import { DatePickerField } from '../utils';

class ReloadDemandCosts extends React.Component {
  static propTypes = {
    elementName: PropTypes.string,
    onReloadDemandCosts: PropTypes.func,
  };

  static errorMessages = {
    date: {
      isDefaultRequiredValue: 'Veuillez sélectionner une date',
      isExisty: 'Veuillez sélectionner une date',
    },
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      dialogOpened: false,
    };
  }

  onOpenDialog = () => {
    this.setState({ dialogOpened: true });
  };

  onCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };

  onConfirm = () => {
    this.form.current.submit();
    this.onCloseDialog();
  };

  render() {
    const { elementName, onReloadDemandCosts, ...props } = this.props;
    const { dialogOpened } = this.state;
    return (
      <div {...props}>
        <Button variant="contained" onClick={this.onOpenDialog}>
          Mettre à jour les tarifs des demandes
        </Button>
        <Dialog open={dialogOpened} onClose={this.onCloseDialog}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {elementName ? (
                  <DialogContentText>Mettre à jour les tarifs des demandes contenant {elementName} ?</DialogContentText>
                ) : (
                  <DialogContentText>
                    Mettre à jour les tarifs de <strong>toutes</strong> les demandes ?
                  </DialogContentText>
                )}
              </Grid>

              <Grid item xs={12}>
                <Formsy ref={this.form} noValidate onValidSubmit={onReloadDemandCosts}>
                  <DatePickerField
                    label="Créées depuis le :"
                    fullWidth
                    required
                    validationErrors={ReloadDemandCosts.errorMessages.date}
                    validations="isExisty"
                    name="date"
                    maxDate={new Date()}
                  />
                </Formsy>
              </Grid>

              <Grid item xs={12}>
                <DialogContentText>
                  Attention, la modification d'un grand nombre de demandes peut ralentir l'ensemble de l'application
                  pendant plusieurs minutes. Effectuez cette opération de préférence en dehors des heures les plus
                  chargées.
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button key="reload-costs-cancel" onClick={this.onCloseDialog}>
              Annuler
            </Button>
            <Button key="reload-costs-confirm" onClick={this.onConfirm} color="primary">
              Mettre à jour les tarifs
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ReloadDemandCosts;
