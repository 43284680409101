import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell } from '@mui/material';

import SortableRow from '../utils/SortableRow';
import { useNavigate } from 'react-router-dom';

const AfterSalesOperationsTableRow = ({ operation, staticContext, ...props }) => {
  const navigate = useNavigate();

  const onClick = (operation) => () => {
    navigate(`/settings/after-sales-operations/${operation.id}`);
  };

  return (
    <SortableRow onClick={onClick(operation)} {...props}>
      <TableCell>{operation.name}</TableCell>
      <TableCell>{operation.equipmentName}</TableCell>
      <TableCell>{operation.emphasis ? 'Oui' : 'Non'}</TableCell>
    </SortableRow>
  );
};

AfterSalesOperationsTableRow.propTypes = {
  operation: PropTypes.object.isRequired,
};

export default AfterSalesOperationsTableRow;
