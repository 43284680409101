import {
  EQUIPMENT_DELETION_ERRORS,
  EQUIPMENT_ERROR,
  EQUIPMENT_LOADED,
  EQUIPMENT_LOADING,
  EQUIPMENT_RESET,
  EQUIPMENT_VALIDATION_ERRORS,
} from '../../constants/AppConstants';
import { DeletionError, Equipment, ValidationError } from '../../model/model';

export type EquipmentState = {
  equipment: Equipment;
  loading: boolean;
  error: string;
  deletionErrors: DeletionError[]; // The list of references to the equipment preventing it to be deleted
  validationErrors: ValidationError[];
};

const emptyEquipment: Equipment = {
  id: null,
  name: null,
  code: null,
  type: null,
  compatibleConcepts: [],
  equipmentIds: [],
  unitPrice: 0,
  fillSerialNumber: false,
  dummy: false,
  maxCharacters: 0,
  minCharacters: 0,
  serialNumberType: null,
  unauthorizedCharacters: '',
};

const initialState: EquipmentState = {
  equipment: emptyEquipment,
  loading: false,
  error: null,
  deletionErrors: [],
  validationErrors: [],
};

export default function (state: EquipmentState = initialState, action): EquipmentState {
  switch (action.type) {
    case EQUIPMENT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        deletionErrors: [],
        validationErrors: [],
      };
    case EQUIPMENT_LOADING:
      return {
        ...state,
        equipment: emptyEquipment,
        loading: true,
        error: null,
        deletionErrors: [],
        validationErrors: [],
      };
    case EQUIPMENT_LOADED:
      return {
        ...state,
        equipment: action.equipment,
        loading: false,
        error: null,
        deletionErrors: [],
        validationErrors: [],
      };
    case EQUIPMENT_RESET:
      return {
        ...state,
        equipment: emptyEquipment,
        loading: false,
        error: null,
        deletionErrors: [],
        validationErrors: [],
      };
    case EQUIPMENT_DELETION_ERRORS:
      return {
        ...state,
        deletionErrors: action.errors,
      };
    case EQUIPMENT_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.errors,
      };
    default:
      return state;
  }
}
