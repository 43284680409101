import {
  FDD_AGREEMENT_TYPES_LOADED,
  FDD_AGREEMENT_TYPE_ADDED,
  FDD_AGREEMENT_TYPE_SAVED,
  FDD_AGREEMENT_TYPE_DELETED,
  getByIdDecorator,
} from '../../constants/AppConstants';

const initialState = getByIdDecorator([]);
initialState.showNew = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case FDD_AGREEMENT_TYPES_LOADED: {
      const newState = [...action.list];
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    case FDD_AGREEMENT_TYPE_ADDED: {
      const newState = [...state];
      newState.showNew = true;
      return getByIdDecorator(newState);
    }
    case FDD_AGREEMENT_TYPE_SAVED: {
      if (action.creation) {
        return getByIdDecorator([...state, action.item]);
      }
      const newState = state.map((agreementType) =>
        agreementType.id === action.item.id ? action.item : agreementType,
      );
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    case FDD_AGREEMENT_TYPE_DELETED: {
      if (!action.id) {
        return getByIdDecorator([...state]);
      }
      const newState = state.filter((agreementType) => agreementType.id !== action.id);
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    default:
      return state;
  }
}
