import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link } from '@mui/material';

const ForbiddenPage = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h1">Accès interdit</Typography>
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="body1">Vous n'avez pas les droits suffisants pour accéder à cette page.</Typography>
          <Link component={RouterLink} variant="body1" to="/">
            Retour à l'accueil
          </Link>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default ForbiddenPage;
