import { compose } from 'recompose';
import { connect } from 'react-redux';

import securedPage from '../../securedPage';
import UsersList from '../../user-profiles/UsersList';
import { sort } from '../../../utils/sorting';
import { hasRole, visibleCompanies } from '../../../services/SecurityService';
import roles from '../../../constants/roles';
import { withPageTitle } from '../../../utils/page-title';

/**
 * Returns a boolean indicating whether or not the given user
 * is compatible with the name.
 *
 * The comparison is case-insensitive and "space-insensitive".
 * Moreover, the combination "first name + last name" and "last
 * name + "first name" are tested.
 *
 * @param user The user.
 * @param name The name to use as filter.
 * @returns { boolean } { @code true } if the user is compatible.
 */
function userMatchesName(user, name) {
  if (!name) {
    return true;
  }
  const filterName = name.trim().toLowerCase().replace(/\s+/g, ' ');

  // noinspection JSUnresolvedVariable
  const firstName = user.firstName;
  // noinspection JSUnresolvedVariable
  const lastName = user.lastName;
  const firstLast = `${firstName} ${lastName}`;
  const lastFirst = `${lastName} ${firstName}`;
  return (
    firstLast.toLowerCase().indexOf(filterName) >= 0 ||
    lastFirst.toLowerCase().indexOf(filterName) >= 0 ||
    user.email.indexOf(filterName) >= 0
  );
}

/**
 * Returns a boolean indicating whether or not the given user
 * has at least one profile from the given list of profiles.
 *
 * @param user The user.
 * @param profiles The list of profiles to compare to.
 * @returns { boolean } { @code true } if the given user has at least
 * one matching profile.
 */
function userHasAtLeastOneProfileOf(user, profiles) {
  if (!profiles || profiles.length === 0) {
    return true;
  }

  return profiles.some((profileID) => user.profiles.filter((userProfile) => userProfile.id === profileID).length > 0);
}

/**
 * Filters the users list.
 *
 * @param users The users list.
 * @param filters The filters values.
 * @returns {*} Only the users that match the filters.
 */
function filter(users, filters) {
  return users.filter((user) => {
    if (!userMatchesName(user, filters.name)) {
      return false;
    }

    if (filters.companies.length && !filters.companies.includes(user.company.id)) {
      return false;
    }

    if (filters.statuses.length && !filters.statuses.includes(user.status.id)) {
      return false;
    }

    if (filters.regions.length && (!user.region || !filters.regions.includes(user.region.id))) {
      return false;
    }

    if (filters.agencies.length && (!user.agency || !filters.agencies.includes(user.agency.id))) {
      return false;
    }

    if (
      filters.areas.length > 0 &&
      (!user.areas || !user.areas.length || user.areas.every((area) => !filters.areas.includes(area.id)))
    ) {
      return false;
    }

    if (!userHasAtLeastOneProfileOf(user, filters.profiles)) {
      return false;
    }

    if (filters.text) {
      const text = filters.text.toLowerCase();
      const textMatch =
        userMatchesName(user, text) ||
        (user.company && user.company.name.toLowerCase().startsWith(text)) ||
        user.status.name.toLowerCase().startsWith(text) ||
        (user.region && user.region.name.toLowerCase().startsWith(text)) ||
        (user.agency && user.agency.name.toLowerCase().includes(text));
      if (!textMatch) {
        return false;
      }
    }

    return true;
  });
}

const stateToProps = ({ users: { users, filters, sorts, error, filterAvailableData }, currentUser }) => ({
  users: sort(filter(users, filters), sorts),
  sorts,
  filters,
  error,
  filterAvailableData,
  hasUserCreateRole: hasRole(currentUser, roles.user.create.code),
  availableCompanies: visibleCompanies(currentUser),
});

export default compose(
  withPageTitle(() => 'Paramétrage des utilisateurs'),
  securedPage(roles.user.view.code),
  connect(stateToProps),
)(UsersList);
