import { DEMAND_IMPORTS_LOADED } from '../constants/AppConstants';

const initialState = {
  result: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEMAND_IMPORTS_LOADED:
      return {
        ...state,
        result: action.result,
      };
    default:
      return state;
  }
}
