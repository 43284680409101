import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CardHeader } from '../utils';
import { addThreshold, loadThresholds } from '../../actions/fdd/FDDThresholdActions';
import FDDThresholdRow from './FDDThresholdRow';
import roles from '../../constants/roles';

class FDDThresholdsTable extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadThresholds());
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addThreshold());
  };

  render() {
    const { fddThresholds, hasNewThreshold } = this.props;

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter un seuil"
          addRole={roles.fdd.admin.code}
          onAdd={this.onAddClick}
          addDisabled={hasNewThreshold}
        >
          Premier Seuil FDD
        </CardHeader>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="div">Région</TableCell>
              <TableCell component="div">Seuil</TableCell>
              <TableCell component="div" />
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {fddThresholds.map((threshold) => (
              <FDDThresholdRow
                key={threshold.id}
                threshold={threshold}
                editing={Boolean(threshold.editing)}
                disabled={!threshold.id}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
    );
  }
}

const emptyThreshold = {
  id: null,
  region: null,
  amount: null,
  editing: true,
};

const thresholdsWithNew = (thresholds) => {
  if (!thresholds.showNew) {
    return thresholds;
  }
  return [...thresholds, emptyThreshold];
};

const stateToProps = ({ fddThresholds }) => ({
  fddThresholds: thresholdsWithNew(fddThresholds),
  hasNewThreshold: !!fddThresholds.showNew,
});

export default connect(stateToProps)(FDDThresholdsTable);
