export const pmuGreen = '#00692C';
export const pmuRed = '#D41224';

export const horse = '#009F1D';
export const sport = '#DB4504';
export const poker = '#1D4F90';

export const pmuColors = [
  '#1D1D1B',
  '#E6007E',
  '#912167',
  '#521C6C',
  '#261C64',
  '#F9B000',
  '#EA580C',
  '#E30613',
  '#A11A16',
  '#720E00',
  '#FFDD00',
  '#DEDC00',
  '#80BA27',
  '#00A989',
  '#007C62',
  '#FFFFFF',
  '#5BC5F2',
  '#009FE3',
  '#004D9D',
  '#00491C',
];

export const pieColors = [
  '#00A989',
  '#A11A16',
  '#FFDD00',
  '#009FE3',
  '#521C6C',
  '#80BA27',
  '#F9B000',
  '#E30613',
  '#DEDC00',
  '#EA580C',
  '#E6007E',
  '#007C62',
  '#720E00',
  '#5BC5F2',
  '#912167',
  '#004D9D',
  '#00491C',
];

export const avatarColors = pmuColors.filter((color) => !['#FFFFFF', '#FFDD00', '#DEDC00'].includes(color));

export const primaryColor = pmuGreen;
export const secondaryColor = pmuRed;

export const labelColor = '#EA580C';

export const interventionOperationColor = pmuColors[2];
export const subcontractorOperationColor = pmuColors[1];

/*
 * The alternative color for fields label.
 *
 * The typical use case is forms that are the only thing on the page (login, forgotten
 * password...) where orange labels are weird.
 */
export const altFieldLabelColor = 'rgba(0, 0, 0, 0.5)';

export const lightGrey = '#F7F7F7';
