import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Download } from 'mdi-material-ui';

import { paginationToParams } from '../../utils/paging';
import { downloadUrl } from '../../actions/DownloadActions';
import { fddExportUrl, fddPaymentsExportUrl } from '../../actions/fdd/FDDActions';

const styles = (theme) => ({
  button: {
    color: theme.palette.grey[400],
  },
  buttonLabel: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

class FDDExport extends Component {
  render() {
    const { pagination, dispatch, classes } = this.props;
    const params = paginationToParams(pagination);
    delete params.page;
    delete params.size;

    let urlParam = '';
    if (params.filter) {
      params.filter.forEach((filter, idx) => {
        urlParam += `${idx === 0 ? '?' : '&'}filter%5B%5D=${encodeURIComponent(filter)}`;
      });
    }

    return (
      !!pagination.totalElements && (
        <>
          <Button className={classes.button} onClick={() => dispatch(downloadUrl(fddExportUrl + urlParam, 'FDD.xlsx'))}>
            <Download />
            <span className={classes.buttonLabel}>Exporter les FDD</span>
          </Button>
          <Button
            className={classes.button}
            onClick={() => dispatch(downloadUrl(fddPaymentsExportUrl + urlParam, 'FDD-Versements.xlsx'))}
          >
            <Download />
            <span className={classes.buttonLabel}>Exporter les versements</span>
          </Button>
        </>
      )
    );
  }
}

const stateToProps = ({ fdds: { pagination } }) => ({
  pagination,
});

export default compose(withStyles(styles), connect(stateToProps))(FDDExport);
