import { CommandOperation, DemandOperation } from '../../model/model';
import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { operationName, operationsWithNonDummyEquipments } from '../../utils/operation-utils';
import { InputAdornment, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TextField } from '../utils';

type InterventionOperationsToCommandProps = {
  operations: DemandOperation[];
  onChange: (operations: CommandOperation[]) => void;
  filledByDefault?: boolean;
};

const filledOperations = (ops: CommandOperation[]) =>
  ops.map((operation) => ({ ...operation, commandQuantity: operation.quantity }));

const InterventionOperationsToCommand = forwardRef(
  ({ operations: operationsProp, onChange, filledByDefault }: InterventionOperationsToCommandProps, ref) => {
    const [operations, setOperations] = useState<CommandOperation[]>([]);

    useEffect(() => {
      const newOperations = operationsWithNonDummyEquipments(operationsProp);
      setOperations(filledByDefault ? filledOperations(newOperations) : newOperations);
    }, [filledByDefault, operationsProp]);

    useEffect(() => {
      onChange(operations);
    }, [onChange, operations]);

    useImperativeHandle(ref, () => ({
      fillAllQuantities: () => setOperations(filledOperations(operations)),
    }));

    const onChangeQuantity =
      (index: number) =>
      ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const newOperations = [...operations];
        newOperations[index] = { ...newOperations[index], commandQuantity: value ? parseInt(value) : undefined };
        setOperations(newOperations);
      };

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Matériel</TableCell>
            <TableCell align="right">Quantité</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations.map((operation, idx) => (
            <TableRow key={operation.selectedEquipmentId}>
              <TableCell>{operationName(operation)}</TableCell>
              <TableCell align="right">
                <TextField
                  name={`command-eq-${idx}`}
                  onChange={onChangeQuantity(idx)}
                  value={operation.commandQuantity ?? ''}
                  validations={{
                    isInt: true,
                    min: 0,
                    max: operation.quantity,
                  }}
                  validationErrors={{
                    isInt: 'Veuillez saisir un entier',
                    min: 'Min 0',
                    max: `Max ${operation.quantity}`,
                  }}
                  sx={{ minWidth: 60, width: 90 }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">/&nbsp;{operation.quantity}</InputAdornment>,
                  }}
                  inputProps={{
                    min: 0,
                    max: operation.quantity,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  },
);

export default InterventionOperationsToCommand;
