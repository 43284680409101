import * as Sentry from '@sentry/browser';
import { CURRENT_USER_LOADED, LOGOUT, CURRENT_USER } from '../constants/AppConstants';

function loadInitialStateFromLocalStorage() {
  const lsUser = localStorage && localStorage.getItem(CURRENT_USER);
  const user = lsUser ? JSON.parse(lsUser) : null;

  return {
    loggedIn: !!user,
    user,
  };
}

const initialState = loadInitialStateFromLocalStorage();

export default function currentUserReducer(state = initialState, action = null) {
  Object.freeze(state);
  switch (action.type) {
    case CURRENT_USER_LOADED:
      Sentry.setUser({ email: action.user.username });
      localStorage.setItem(CURRENT_USER, JSON.stringify(action.user));
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      };
    case LOGOUT:
      Sentry.setUser(null);
      localStorage.removeItem(CURRENT_USER);
      return {
        ...state,
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
