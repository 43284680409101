import {
  FDD_THRESHOLDS_LOADED,
  FDD_THRESHOLD_ADDED,
  FDD_THRESHOLD_SAVED,
  FDD_THRESHOLD_DELETED,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError } from '../SnackbarActions';

const thresholdsCollection = api.all(urls.fdd.thresholds);
thresholdsCollection.isFetchNeeded = true;

export function loadThresholds() {
  return (dispatch) => {
    thresholdsCollection
      .getAll()
      .then((response) => {
        const list = response.body(false);
        if (list && list.length) {
          thresholdsCollection.isFetchNeeded = false;
        }
        dispatch({
          type: FDD_THRESHOLDS_LOADED,
          list,
        });
      })
      .catch(ignore401);
  };
}

export function loadThresholdsIfNeeded() {
  return (dispatch) => {
    if (thresholdsCollection.isFetchNeeded) {
      loadThresholds()(dispatch);
    }
  };
}

export function addThreshold() {
  return {
    type: FDD_THRESHOLD_ADDED,
  };
}

export function saveThreshold(threshold) {
  return (dispatch) => {
    const creation = !threshold.id;
    const saveRequest = creation
      ? thresholdsCollection.post(threshold)
      : thresholdsCollection.put(threshold.id, threshold);

    saveRequest
      .then((response) => {
        let newThreshold = threshold;
        if (creation) {
          newThreshold = response.body(false);
        }
        dispatch({
          type: FDD_THRESHOLD_SAVED,
          item: {
            ...newThreshold,
            editing: false,
          },
          creation,
        });
      })
      .catch(ignore401);
  };
}

export function deleteThreshold(threshold) {
  return (dispatch) => {
    if (threshold.id) {
      thresholdsCollection
        .delete(threshold.id)
        .then(() => {
          dispatch({
            type: FDD_THRESHOLD_DELETED,
            id: threshold.id,
          });
        })
        .catch(ignore401)
        .catch((err) => {
          console.error('Error while deleting:', err);
          if (err.response && err.response.data && err.response.statusCode === 400) {
            dispatch(addGlobalError(err.response.data.error));
          } else {
            dispatch(addGlobalError('Erreur lors de la suppression'));
          }
        });
    } else {
      dispatch({
        type: FDD_THRESHOLD_DELETED,
        id: null,
      });
    }
  };
}
