import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Formsy from 'formsy-react';
import RangeDatePicker from '../commons/RangeDatePicker';
import { updateDemandsFilter } from '../../actions/DemandActions';
import { User } from '../../model/user';
import { DateRange } from '../../model/range';
import { COMPANIES, CONCEPTS, DEMAND_STATUSES, INSTALLERS } from '../../constants/AppConstants';
import { AutocompleteField, FilterCard, TextField } from '../utils';
import { arrayEquals } from '../../utils/array-utils';
import { startingFromLastMonth } from '../../utils/date-utils';
import {
  AgencyAutocompleteField,
  AreaAutocompleteField,
  DemandTypeAutocompleteField,
  OutletAutocompleteField,
  RegionAutocompleteField,
} from '../commons/autocompleteFields';

/**
 * Get initial filter state.
 *
 * @returns filters
 */
function initializeFilters(userProp, pagination) {
  const user = new User(userProp);
  const company = COMPANIES.getById(user.company);
  const { filter = {} } = pagination;

  return {
    isRegionDisabled: user.isRegional() || user.isAgency() || user.isArea(),
    isAgencyDisabled: user.isAgency() || user.isArea(),
    isSubcontractorDisabled: company && company.isSubcontractor,
    text: filter.text && filter.text.length ? filter.text[0] : '',
    number: filter.number && filter.number.length ? filter.number[0] : '',
    regionIds: user.isRegional() ? [user.regionId] : filter.regionIds || [],
    agencyIds: user.isAgency() ? [user.agencyId] : filter.agencyIds || [],
    areaIds: filter.areaIds || [],
    denormOutletIds: filter.denormOutletIds || [],
    outletNames: filter.outletNames || [],
    demandTypeIds: filter.demandTypeIds || [],
    concepts: filter.concepts || [],
    subcontractors: company && company.isSubcontractor ? [user.company] : filter.subcontractors || [],
    demandStatuses: filter.demandStatuses || [],
    validationDateRange: filter.validationDateRange || new DateRange(),
    scheduledDateRange: filter.scheduledDateRange || new DateRange(),
  };
}

class DemandsFilters extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = initializeFilters(props.user, props.pagination);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, pagination } = nextProps;
    this.setState(initializeFilters(user, pagination));
  }

  /**
   * Change callback that occurs on children filter component selection.
   */
  onChange = (fieldName) => (value, valueObj) => {
    const newState = {
      [fieldName]: value,
    };
    if (fieldName === 'denormOutletIds') {
      newState.outletNames = valueObj.map(({ code, name }) => (code ? `${code} - ${name}` : name));
    }
    this.setState(newState);
  };

  /**
   * Change callback that occurs when a date selection changes.
   *
   * @param fieldName field name targeted by filter change
   * @param state current state of field
   */
  onDateChange = (fieldName, state) => {
    const newState = {};
    newState[fieldName] = state;
    this.setState(newState);
  };

  /**
   * Triggers the demand list fetch action with filters defined by user.
   */
  onFilter = () => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = this.getFilter();
    updateDemandsFilter(pagination);
  };

  /**
   * Resets state and fetch from server.
   *
   * @param event
   */
  onResetClick = (event) => {
    event.preventDefault();
    const { map, user, pagination } = this.props;
    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = {
      scheduledDateRange: map ? startingFromLastMonth() : new DateRange(),
    };

    // Reset filter (setState returns a promise)
    this.setState(initializeFilters(user, pagination), () => {
      updateDemandsFilter(pagination);
    });
  };

  getFilter() {
    // Clean state
    const { ...epuredState } = this.state;
    delete epuredState.isRegionDisabled;
    delete epuredState.isAgencyDisabled;
    delete epuredState.isSubcontractorDisabled;
    if (epuredState.validationDateRange.isEmpty()) {
      delete epuredState.validationDateRange;
    }
    if (epuredState.scheduledDateRange.isEmpty()) {
      delete epuredState.scheduledDateRange;
    }
    return epuredState;
  }

  hasFilters = () => {
    const { isRegionDisabled, isAgencyDisabled, isSubcontractorDisabled } = this.state;
    const {
      pagination: {
        filter: {
          number = [],
          regionIds = [],
          agencyIds = [],
          areaIds = [],
          denormOutletIds = [],
          demandTypeIds = [],
          concepts = [],
          subcontractors = [],
          demandStatuses = [],
          validationDateRange = new DateRange(),
          scheduledDateRange = new DateRange(),
        },
      },
      user: { areasIds: userAreaIds = [] },
    } = this.props;
    return Boolean(
      number.length ||
        (regionIds.length && !isRegionDisabled) ||
        (agencyIds.length && !isAgencyDisabled) ||
        (areaIds.length && !arrayEquals(areaIds, userAreaIds)) ||
        denormOutletIds.length ||
        demandTypeIds.length ||
        concepts.length ||
        (subcontractors.length && !isSubcontractorDisabled) ||
        demandStatuses.length ||
        !validationDateRange.isEmpty() ||
        !scheduledDateRange.isEmpty(),
    );
  };

  render() {
    const {
      text,
      number,
      regionIds,
      agencyIds,
      areaIds,
      isRegionDisabled,
      isAgencyDisabled,
      isSubcontractorDisabled,
      denormOutletIds,
      outletNames,
      demandTypeIds,
      concepts,
      subcontractors,
      demandStatuses,
      validationDateRange,
      scheduledDateRange,
    } = this.state;

    return (
      <Formsy noValidate onSubmit={this.onFilter}>
        <FilterCard
          hasFilters={this.hasFilters()}
          text={text}
          textPlaceholder="Filtrer par numéro, zone de vente, point de vente, type, prestataire, concept, statut..."
          onTextChange={this.onChange('text')}
          onResetClick={this.onResetClick}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="number"
                    value={number}
                    label="Numéro"
                    placeholder="Filtrer par numéro"
                    fullWidth
                    onChange={(event) => this.onChange('number')(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegionAutocompleteField
                    multiple
                    name="regionIds"
                    value={regionIds}
                    label="Régions"
                    placeholder="Filtrer par région"
                    disabled={isRegionDisabled}
                    onChange={this.onChange('regionIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AgencyAutocompleteField
                    multiple
                    regionIds={regionIds}
                    name="agencyIds"
                    value={agencyIds}
                    label="Zones de vente"
                    placeholder="Filtrer par zone de vente"
                    disabled={isAgencyDisabled}
                    onChange={this.onChange('agencyIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AreaAutocompleteField
                    multiple
                    agencyIds={agencyIds}
                    regionIds={regionIds}
                    name="areaIds"
                    value={areaIds}
                    label="Secteurs"
                    placeholder="Filtrer par secteur"
                    onChange={this.onChange('areaIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutletAutocompleteField
                    multiple
                    outletNames={outletNames}
                    name="denormOutletIds"
                    value={denormOutletIds}
                    label="Points de vente"
                    placeholder="Filtrer par point de vente"
                    onChange={this.onChange('denormOutletIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DemandTypeAutocompleteField
                    multiple
                    name="demandTypeIds"
                    value={demandTypeIds}
                    label="Types de demande"
                    placeholder="Filtrer par type de demande"
                    onChange={this.onChange('demandTypeIds')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={INSTALLERS}
                    label="Prestataire"
                    placeholder="Filtrer par prestataire"
                    fullWidth
                    name="subcontractors"
                    value={subcontractors}
                    disabled={isSubcontractorDisabled}
                    onChange={this.onChange('subcontractors')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={CONCEPTS}
                    label="Concepts de demande"
                    placeholder="Filtrer par concept"
                    fullWidth
                    name="concepts"
                    value={concepts}
                    onChange={this.onChange('concepts')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={DEMAND_STATUSES}
                    label="Statuts de demande"
                    placeholder="Filtrer par statut"
                    fullWidth
                    name="demandStatuses"
                    value={demandStatuses}
                    onChange={this.onChange('demandStatuses')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RangeDatePicker
                    minClearable={true}
                    maxClearable={true}
                    label="Validée entre :"
                    name="validationDateRange"
                    range={validationDateRange}
                    onChange={this.onDateChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RangeDatePicker
                    minClearable={true}
                    maxClearable={true}
                    label="Planifiée entre :"
                    name="scheduledDateRange"
                    range={scheduledDateRange}
                    onChange={this.onDateChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FilterCard>
      </Formsy>
    );
  }
}

const stateToProps = ({ currentUser: { user }, demands: { pagination } }) => ({
  user,
  pagination,
});

export default connect(stateToProps)(DemandsFilters);
