import {
  INTERVENTION_ATTACHMENTS_DELETE,
  INTERVENTION_ATTACHMENTS_ITEM,
  INTERVENTION_ATTACHMENTS_LIST,
} from '../constants/AppConstants';

export default function (state = {}, action) {
  switch (action.type) {
    case INTERVENTION_ATTACHMENTS_LIST:
      return action.attachments || {};

    case INTERVENTION_ATTACHMENTS_ITEM: {
      const newState = {
        ...state,
      };
      newState[action.attachment.name] = action.attachment;
      return newState;
    }

    case INTERVENTION_ATTACHMENTS_DELETE: {
      const newState = {
        ...state,
      };
      delete newState[action.name];
      return newState;
    }
    default:
      return state;
  }
}
