import { SortDirections } from './sorting';
import { DateRange } from '../model/range';
import dayjs, { Dayjs } from 'dayjs';
import { Pagination, PaginationParams, Sort } from '../model/pagination';
import { isArray } from 'lodash';

function sortDirectionToAscOrDesc(sort: Sort) {
  return sort.direction === SortDirections.asc ? 'asc' : 'desc';
}

const serializeFilter = (filterValue: string | Dayjs): string => {
  if (dayjs.isDayjs(filterValue)) {
    return filterValue.format();
  }
  return filterValue;
};

export const paginationToParams = (pagination: Pagination, blacklist: string[] = []): PaginationParams => {
  const { currentPage, pageSize, sort, filter } = pagination || {};

  const params: PaginationParams = {
    page: `${currentPage ?? 0}`,
    size: `${pageSize ?? 10}`,
  };
  if (sort) {
    params.sort = [sort.field, sortDirectionToAscOrDesc(sort)].join(',');
  }
  if (filter) {
    params.filter = Object.keys(filter)
      .filter((k) => {
        if (blacklist.includes(k)) {
          return false;
        }
        const val = filter[k];
        // Don't use empty arrays as filter
        if (Array.isArray(val) && !val.length) {
          return false;
        }
        // Don't filter numbers (could be 0)
        if (typeof val === 'number') {
          return true;
        }
        // Don't use falsy types
        return !!filter[k];
      })
      .map((k) => `${k},${serializeFilter(filter[k])}`);
  }
  return params;
};

const addFilter = (pagination: Pagination, f: string) => {
  const filterParts = f.split(',');
  const filterName = filterParts.shift();
  if (filterName.endsWith('DateRange')) {
    pagination.filter[filterName] = new DateRange({
      minDate: filterParts[0],
      maxDate: filterParts[1],
    }); // eslint-disable-line no-param-reassign
  } else if (filterName.toLowerCase().endsWith('date')) {
    pagination.filter[filterName] = filterParts[0]; // eslint-disable-line no-param-reassign
  } else {
    pagination.filter[filterName] = filterParts; // eslint-disable-line no-param-reassign
  }
};

export const paramsToPagination = (params: PaginationParams, defaultSort?: Sort): Pagination => {
  const { page, size, sort, filter } = params;
  const pagination: Pagination = {
    currentPage: parseInt(page, 10) || 0,
    pageSize: parseInt(size, 10) || 10,
    sort: defaultSort,
    filter: {},
  };
  if (sort) {
    const sortParts = sort.split(',');
    pagination.sort = {
      field: sortParts[0],
      direction: SortDirections[sortParts[1]],
    };
  }
  if (filter) {
    if (isArray(filter)) {
      filter.forEach((f) => {
        addFilter(pagination, f);
      });
    } else {
      addFilter(pagination, filter);
    }
  }
  return pagination;
};
