import {
  OPERATION_BUNDLES_LOADED,
  OPERATION_BUNDLES_SORT,
  OPERATION_BUNDLE_LOADED,
  OPERATION_BUNDLE_LOADING,
  OPERATION_BUNDLE_ERROR,
  OPERATION_BUNDLE_RESET,
  OPERATION_BUNDLE_DELETION_ERRORS,
  OPERATION_BUNDLE_VALIDATION_ERRORS,
  urls,
  OPERATION_BUNDLE_OPTIONS_LOADING,
  OPERATION_BUNDLE_OPTIONS_LOADED,
  OPERATION_BUNDLE_OPTIONS_RESET,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError, addGlobalMessage } from '../SnackbarActions';
import history from '../../history';

/**
 * The REST collection used to manage operation bundle resources.
 */
const bundlesCollection = api.all(urls.settings.bundles);
const optionsCollection = api.all(`${urls.settings.options}/for-bundle`);

export const resetBundle = () => ({
  type: OPERATION_BUNDLE_RESET,
});

export const loadBundle = (id) => (dispatch) => {
  dispatch({
    type: id ? OPERATION_BUNDLE_LOADING : OPERATION_BUNDLE_RESET,
  });
  if (!id) {
    return;
  }

  bundlesCollection
    .get(id)
    .then((response) => {
      const bundle = response.body(false);
      dispatch({
        type: OPERATION_BUNDLE_LOADED,
        bundle,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading bundle: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement du forfait'));
      dispatch({
        type: OPERATION_BUNDLE_ERROR,
        error: 'Une erreur a eu lieu pendant le chargement du forfait',
      });
    });
};

export const loadCompatibleOptions = (demandTypeId, subcontractor) => (dispatch) => {
  if (!demandTypeId || !subcontractor) {
    dispatch({
      type: OPERATION_BUNDLE_OPTIONS_RESET,
    });
    return;
  }
  dispatch({
    type: OPERATION_BUNDLE_OPTIONS_LOADING,
  });
  optionsCollection
    .getAll({ demandTypeId, subcontractor })
    .then((response) => {
      const options = response.body(false);
      dispatch({
        type: OPERATION_BUNDLE_OPTIONS_LOADED,
        options,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      dispatch({
        type: OPERATION_BUNDLE_OPTIONS_RESET,
      });
      console.error('Error while loading compatible options: ', error);
      dispatch(addGlobalError('Erreur à la récupération des options compatibles'));
    });
};

export const saveOrUpdateBundle = (bundle) => {
  const request = bundle.id ? bundlesCollection.put(bundle.id, bundle) : bundlesCollection.post(bundle);

  return (dispatch) => {
    request
      .then((response) => {
        if (!bundle.id) {
          const eq = response.body(false);
          dispatch(addGlobalMessage('Le forfait a été créé.'));
          dispatch(loadBundle(eq.id));
          history.push(`/settings/operation-bundles/${eq.id}`);
        } else {
          dispatch(loadBundle(bundle.id));
          dispatch(addGlobalMessage('Le forfait a été enregistré'));
        }
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving or updating bundle:', err);
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch({
            type: OPERATION_BUNDLE_VALIDATION_ERRORS,
            errors: err.response.data.errors,
          });
          dispatch(addGlobalError('Le formulaire contient des erreurs'));
        } else {
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      });
  };
};

export const deleteBundle = (id) => (dispatch) => {
  bundlesCollection
    .delete(id)
    .then(() => {
      dispatch(addGlobalMessage('Le forfait a été supprimé.'));
      history.push('/settings/operation-bundles');
    })
    .catch(ignore401)
    .catch((err) => {
      console.error('Error while deleting bundle:', err);
      if (err.response && err.response.data && err.response.data.errors && err.response.statusCode === 400) {
        dispatch({
          type: OPERATION_BUNDLE_DELETION_ERRORS,
          errors: err.response.data.errors[0].rejectedValue,
        });
      }
      dispatch(addGlobalError("Le forfait n'a pas pu être supprimé"));
    });
};

export const loadBundles = () => (dispatch) => {
  bundlesCollection
    .getAll()
    .then((response) => {
      const bundles = response.body(false);
      dispatch({
        type: OPERATION_BUNDLES_LOADED,
        bundles,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading bundles: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement des forfaits'));
    });
};

export const sortBundles = (field, direction) => ({
  type: OPERATION_BUNDLES_SORT,
  field,
  direction,
});
