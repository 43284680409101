import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { AutocompleteField, CollapsiblePanel } from '../utils';
import { COMPANIES } from '../../constants/AppConstants';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import DemandOperation from './DemandOperation';

const showOperation = (operation, readOnly) => operation.displayed && (!readOnly || operation.quantity > 0);

class DemandOperations extends Component {
  static propTypes = {
    canUserToggleCompletionStatuses: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    demandId: PropTypes.string.isRequired,
    demandStatus: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    operations: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired,
    onChangeOperation: PropTypes.func.isRequired,
    onAddOperation: PropTypes.func.isRequired,
    onChangeOperationGroup: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { title: oldTitle, operations: oldOperations, readOnly: oldReadOnly } = this.props;
    const { title, operations, readOnly } = nextProps;
    return (
      readOnly !== oldReadOnly || title !== oldTitle || JSON.stringify(operations) !== JSON.stringify(oldOperations)
    );
  }

  render() {
    const {
      dispatch,
      demandId,
      canUserToggleCompletionStatuses,
      title,
      operations,
      onChangeOperation,
      onAddOperation,
      onChangeOperationGroup,
      readOnly,
      updateTabsHeight,
    } = this.props;

    const displayedOperations = operations.filter(
      (operation) => showOperation(operation, readOnly) && !operation.hiddenByRules,
    );
    const hiddenOperations = readOnly
      ? []
      : operations.filter((operation) => !showOperation(operation, readOnly) && !operation.hiddenByRules);

    return displayedOperations.length || hiddenOperations.length ? (
      <CollapsiblePanel title={title} onToggle={updateTabsHeight}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Matériel</TableCell>
              <TableCell>Qté</TableCell>
              {readOnly && <TableCell>Fait</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedOperations.map((operation) => (
              <DemandOperation
                key={operation.selectedEquipmentId}
                dispatch={dispatch}
                demandId={demandId}
                operation={operation}
                readOnly={readOnly}
                onChangeOperation={onChangeOperation}
                onChangeOperationGroup={onChangeOperationGroup}
                canUserToggleCompletionStatuses={canUserToggleCompletionStatuses}
              />
            ))}
          </TableBody>
          {hiddenOperations.length > 0 && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <AutocompleteField
                    options={hiddenOperations.map((operation) => ({
                      id: operation.equipments[0].id,
                      name: operation.equipments[0].name,
                    }))}
                    label="Ajouter une prestation"
                    fullWidth
                    name="hiddenOps"
                    onChange={onAddOperation}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </CollapsiblePanel>
    ) : null;
  }
}

const doCanUserToggleCompletionStatuses = (currentUser, demandStatus) => {
  const userCompany = currentUser.user && COMPANIES.getById(currentUser.user.company);
  if (!userCompany || userCompany.isSubcontractor) {
    return false;
  }

  if (demandStatus !== '_070_AWAITING_PMU_ACCEPTANCE') {
    return false;
  }

  return hasRole(currentUser, roles.demand.receive.code);
};

const stateToProps = ({ currentUser }, { demandStatus }) => ({
  canUserToggleCompletionStatuses: doCanUserToggleCompletionStatuses(currentUser, demandStatus),
});

export default connect(stateToProps)(DemandOperations);
