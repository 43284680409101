import React from 'react';
import * as PropTypes from 'prop-types';
import { Card } from '@mui/material';
import CollapsiblePanel from './CollapsiblePanel';

const CollapsibleCard = (props) => (
  <Card>
    <CollapsiblePanel {...props} />
  </Card>
);

CollapsibleCard.propTypes = {
  title: PropTypes.node.isRequired,
  defaultOpened: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
};

CollapsibleCard.defaultProps = {
  defaultOpened: true,
  unmountOnExit: true,
};

export default CollapsibleCard;
