import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Formsy from 'formsy-react';

import { updateEquipmentMoveFilter } from '../../actions/EquipmentMoveActions';
import { User } from '../../model/user';
import { AutocompleteField, FilterCard, TextField } from '../utils';
import RangeDatePicker from '../commons/RangeDatePicker';
import { COMPANIES, EQUIPMENT_MOVE_SEND_STATUSES, EQUIPMENT_STATES } from '../../constants/AppConstants';
import { DateRange } from '../../model/range';
import { arrayEquals } from '../../utils/array-utils';
import {
  AgencyAutocompleteField,
  AreaAutocompleteField,
  OutletAutocompleteField,
  RegionAutocompleteField,
} from '../commons/autocompleteFields';

/**
 * Get initial filter state.
 *
 * @returns filters
 */
function initializeFilters(userProp, pagination) {
  const user = new User(userProp);
  const company = COMPANIES.getById(user.company);
  const { filter } = pagination;

  return {
    isRegionDisabled: user.isRegional() || user.isAgency() || user.isArea(),
    isAgencyDisabled: user.isAgency() || user.isArea(),
    isSubcontractorDisabled: company && company.isSubcontractor,
    text: filter.text && filter.text.length ? filter.text[0] : '',
    regionIds: user.isRegional() ? [user.regionId] : filter.regionIds || [],
    agencyIds: user.isAgency() ? [user.agencyId] : filter.agencyIds || [],
    areaIds: filter.areaIds || [],
    denormOutletIds: filter.denormOutletIds || [],
    outletNames: filter.outletNames || [],
    subcontractors: company && company.isSubcontractor ? [user.company] : filter.subcontractors || [],
    serialNumber: filter.serialNumber && filter.serialNumber.length ? filter.serialNumber[0] : '',
    state: filter.state || [],
    sendStatus: filter.sendStatus || [],
    moveDateRange: filter.moveDateRange || new DateRange(),
    sendDateRange: filter.sendDateRange || new DateRange(),
  };
}

class EquipmentMoveFilters extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = initializeFilters(props.user, props.pagination);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, pagination } = nextProps;
    this.setState(initializeFilters(user, pagination));
  }

  /**
   * Change callback that occurs on children filter component selection.
   */
  onChange = (fieldName) => (value, valueObj) => {
    const newState = {
      [fieldName]: value,
    };
    if (fieldName === 'denormOutletIds') {
      newState.outletNames = valueObj.map(({ code, name }) => (code ? `${code} - ${name}` : name));
    }
    this.setState(newState);
  };

  /**
   * Change callback that occurs when a date selection changes.
   *
   * @param fieldName field name targeted by filter change
   * @param state current state of field
   */
  onDateChange = (fieldName, state) => {
    const newState = {};
    newState[fieldName] = state;
    this.setState(newState);
  };

  /**
   * Triggers the equipment move list fetch action with filters defined by user.
   */
  onFilter = () => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = this.getFilter();
    updateEquipmentMoveFilter(pagination);
  };

  /**
   * Resets state and fetch from server.
   *
   * @param event
   */
  onResetClick = (event) => {
    event.preventDefault();
    const { user, pagination } = this.props;
    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = {};

    // Reset filter (setState returns a promise)
    this.setState(initializeFilters(user, pagination), () => {
      updateEquipmentMoveFilter(pagination);
    });
  };

  getFilter() {
    // Clean state
    const { ...epuredState } = this.state;
    delete epuredState.isRegionDisabled;
    delete epuredState.isAgencyDisabled;
    delete epuredState.isSubcontractorDisabled;
    if (epuredState.moveDateRange.isEmpty()) {
      delete epuredState.moveDateRange;
    }
    if (epuredState.sendDateRange.isEmpty()) {
      delete epuredState.sendDateRange;
    }
    return epuredState;
  }

  hasFilters = () => {
    const { isRegionDisabled, isAgencyDisabled, isSubcontractorDisabled } = this.state;
    const {
      pagination: {
        filter: {
          regionIds = [],
          agencyIds = [],
          areaIds = [],
          denormOutletIds = [],
          subcontractors = [],
          serialNumber = [],
          state = [],
          sendStatus = [],
          moveDateRange = new DateRange(),
          sendDateRange = new DateRange(),
        },
      },
      user: { areasIds: userAreaIds = [] },
    } = this.props;
    return Boolean(
      (regionIds.length && !isRegionDisabled) ||
        (agencyIds.length && !isAgencyDisabled) ||
        (areaIds.length && !arrayEquals(areaIds, userAreaIds)) ||
        denormOutletIds.length ||
        (subcontractors.length && !isSubcontractorDisabled) ||
        serialNumber.length ||
        state.length ||
        sendStatus.length ||
        !moveDateRange.isEmpty() ||
        !sendDateRange.isEmpty(),
    );
  };

  render() {
    const {
      text,
      regionIds,
      agencyIds,
      areaIds,
      isRegionDisabled,
      isAgencyDisabled,
      isSubcontractorDisabled,
      denormOutletIds,
      outletNames,
      subcontractors,
      serialNumber,
      state,
      sendStatus,
      moveDateRange,
      sendDateRange,
    } = this.state;

    return (
      <Formsy noValidate onSubmit={this.onFilter}>
        <FilterCard
          hasFilters={this.hasFilters()}
          text={text}
          textPlaceholder="Filtrer par numéro de série, lieu, état, statut d'envoi, prestataire..."
          onTextChange={this.onChange('text')}
          onResetClick={this.onResetClick}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="serialNumber"
                    value={serialNumber}
                    label="Numéro de série"
                    placeholder="Filtrer par numéro de série"
                    fullWidth
                    onChange={(event) => this.onChange('serialNumber')(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegionAutocompleteField
                    multiple
                    name="regionIds"
                    value={regionIds}
                    label="Régions"
                    placeholder="Filtrer par région"
                    disabled={isRegionDisabled}
                    onChange={this.onChange('regionIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AgencyAutocompleteField
                    multiple
                    regionIds={regionIds}
                    name="agencyIds"
                    value={agencyIds}
                    label="Zones de vente"
                    placeholder="Filtrer par zone de vente"
                    disabled={isAgencyDisabled}
                    onChange={this.onChange('agencyIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AreaAutocompleteField
                    multiple
                    agencyIds={agencyIds}
                    regionIds={regionIds}
                    name="areaIds"
                    value={areaIds}
                    label="Secteurs"
                    placeholder="Filtrer par secteur"
                    onChange={this.onChange('areaIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutletAutocompleteField
                    multiple
                    outletNames={outletNames}
                    name="denormOutletIds"
                    value={denormOutletIds}
                    label="Points de vente"
                    placeholder="Filtrer par point de vente"
                    onChange={this.onChange('denormOutletIds')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={COMPANIES}
                    label="Prestataire"
                    placeholder="Filtrer par prestataire"
                    fullWidth
                    name="subcontractors"
                    value={subcontractors}
                    disabled={isSubcontractorDisabled}
                    onChange={this.onChange('subcontractors')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={EQUIPMENT_STATES}
                    label="État du matériel"
                    placeholder="Filtrer par état du matériel"
                    fullWidth
                    name="state"
                    value={state}
                    onChange={this.onChange('state')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={EQUIPMENT_MOVE_SEND_STATUSES}
                    label="Statut d'envoi"
                    placeholder="Filtrer par statut d'envoi"
                    fullWidth
                    name="sendStatus"
                    value={sendStatus}
                    onChange={this.onChange('sendStatus')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RangeDatePicker
                    minClearable={true}
                    maxClearable={true}
                    label="Date du mouvement :"
                    name="moveDateRange"
                    range={moveDateRange}
                    onChange={this.onDateChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RangeDatePicker
                    minClearable={true}
                    maxClearable={true}
                    label="Date d'envoi :"
                    name="sendDateRange"
                    range={sendDateRange}
                    onChange={this.onDateChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FilterCard>
      </Formsy>
    );
  }
}

export default connect((state) => {
  const {
    currentUser: { user },
    equipmentMoves: { pagination },
  } = state;
  return {
    user,
    pagination,
  };
})(EquipmentMoveFilters);
