import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@mui/material';
import { SortDirections } from '../../utils/sorting';

class SortableTableHeader extends Component {
  static propTypes = {
    direction: PropTypes.number.isRequired,
    onClickCallback: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  };

  getSortDirection = () => {
    const { direction } = this.props;
    if (direction === SortDirections.asc) return 'asc';
    if (direction === SortDirections.desc) return 'desc';
    return undefined;
  };

  render() {
    const { direction, title, onClickCallback } = this.props;
    return (
      <TableCell>
        <TableSortLabel active={Boolean(direction)} direction={this.getSortDirection()} onClick={onClickCallback}>
          {title}
        </TableSortLabel>
      </TableCell>
    );
  }
}

export default SortableTableHeader;
