import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Card, Grid } from '@mui/material';

import securedPage from '../../securedPage';
import OutletsTable from '../../outlets/OutletsTable';
import OutletsFilters from '../../outlets/OutletsFilters';
import roles from '../../../constants/roles';
import { CardHeader } from '../../utils';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import { loadOutlets } from '../../../actions/OutletActions';
import { withPageTitle } from '../../../utils/page-title';

const OutletListPage = ({ totalElements }) => (
  <Grid container spacing={3}>
    <HierarchicalSearch loadActionCreator={loadOutlets} />
    <Grid item xs={12}>
      <OutletsFilters />
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader>Points de vente{totalElements > 0 ? ` (${totalElements})` : ''}</CardHeader>
        <OutletsTable />
      </Card>
    </Grid>
  </Grid>
);

const stateToProps = ({
  outlets: {
    pagination: { totalElements },
  },
}) => ({
  totalElements,
});

export default compose(
  withPageTitle(() => 'Points de vente'),
  securedPage(roles.outlet.view.code),
  connect(stateToProps),
)(OutletListPage);
