import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { AutocompleteField, DatePickerField, DateTimePickerField, LabelValue } from '../utils';
import DateViewer from '../commons/DateViewer';
import DayTimeViewer from '../commons/DayTimeViewer';
import { DAYTIME_TYPES } from '../../constants/AppConstants';
import DateTimeViewer from '../commons/DateTimeViewer';

const errorMessages = {
  operationStartedDate: {
    max: 'Le début est après la fin',
  },
  operationFinishedDate: {
    min: 'La fin est avant le début',
  },
};

const InterventionDates = ({
  status,
  operationScheduleDate,
  operationScheduleDayTime,
  operationStartedDate,
  operationFinishedDate,
  readOnly,
  onChange,
}) => {
  const formattedStartedDate = operationStartedDate && dayjs(operationStartedDate);
  const formattedFinishedDate = operationFinishedDate && dayjs(operationFinishedDate);
  return (
    <Grid container spacing={2}>
      {readOnly ? (
        <>
          <Grid item xs={12}>
            <LabelValue label="Date de planification">
              {operationScheduleDate ? <DateViewer date={operationScheduleDate} /> : <span>Non planifiée</span>}
            </LabelValue>
          </Grid>
          <Grid item xs={12}>
            <LabelValue label="Heure de planification">
              {operationScheduleDayTime ? (
                <DayTimeViewer dayTime={operationScheduleDayTime} />
              ) : (
                <span>Non planifiée</span>
              )}
            </LabelValue>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            {operationScheduleDate ? (
              <DatePickerField
                label="Date de planification"
                fullWidth
                name="operationScheduleDate"
                value={operationScheduleDate}
                onChange={onChange('operationScheduleDate')}
              />
            ) : (
              <LabelValue label="Date de planification">Non planifiée</LabelValue>
            )}
          </Grid>
          <Grid item xs={12}>
            {operationScheduleDayTime ? (
              <AutocompleteField
                options={DAYTIME_TYPES}
                label="Heure de planification"
                fullWidth
                name="operationScheduleDayTime"
                value={operationScheduleDayTime}
                onChange={onChange('operationScheduleDayTime')}
              />
            ) : (
              <LabelValue label="Heure de planification">Non planifiée</LabelValue>
            )}
          </Grid>
        </>
      )}
      {status >= '_020_SCHEDULED' &&
        (readOnly ? (
          <>
            <Grid item xs={12}>
              <LabelValue label="Début d'intervention">
                <DateTimeViewer date={operationStartedDate} />
              </LabelValue>
            </Grid>
            <Grid item xs={12}>
              <LabelValue label="Fin d'intervention">
                <DateTimeViewer date={operationFinishedDate} />
              </LabelValue>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <DateTimePickerField
                label="Début d'intervention"
                fullWidth
                clearable
                name="operationStartedDate"
                value={formattedStartedDate}
                onChange={onChange('operationStartedDate')}
                minutesStep={5}
                openTo="hours"
                maxDate={formattedFinishedDate || undefined}
                validations={formattedFinishedDate ? { max: formattedFinishedDate } : undefined}
                validationErrors={errorMessages.operationStartedDate}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePickerField
                label="Fin d'intervention"
                fullWidth
                clearable
                name="operationFinishedDate"
                value={formattedFinishedDate}
                onChange={onChange('operationFinishedDate')}
                minutesStep={5}
                openTo="hours"
                minDate={formattedStartedDate ?? undefined}
                validations={formattedStartedDate ? { min: formattedStartedDate } : undefined}
                validationErrors={errorMessages.operationFinishedDate}
              />
            </Grid>
          </>
        ))}
    </Grid>
  );
};

InterventionDates.propTypes = {
  status: PropTypes.string,
  operationScheduleDate: PropTypes.any,
  operationScheduleDayTime: PropTypes.any,
  operationStartedDate: PropTypes.any,
  operationFinishedDate: PropTypes.any,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default InterventionDates;
