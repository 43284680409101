import {
  AGENCIES_LOADED,
  AGENCIES_LIST_SORT,
  AGENCY_ADDED,
  AGENCY_SAVED,
  AGENCY_DELETED,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';

const agenciesCollection = api.all(urls.settings.agencies);

function agenciesLoaded(agencies) {
  return {
    type: AGENCIES_LOADED,
    agencies,
  };
}

function agencyAdded() {
  return {
    type: AGENCY_ADDED,
  };
}

function agencySaved(agency, creation) {
  return {
    type: AGENCY_SAVED,
    agency,
    creation,
  };
}

function agencyDeleted(id) {
  return {
    type: AGENCY_DELETED,
    id,
  };
}

export function sortAgencies(field, direction) {
  return {
    type: AGENCIES_LIST_SORT,
    field,
    direction,
  };
}

export function addAgency() {
  return agencyAdded();
}

export function loadAgencies() {
  return (dispatch) => {
    agenciesCollection
      .getAll()
      .then((response) => {
        const agencies = response.body(false);
        dispatch(agenciesLoaded(agencies));
      })
      .catch(ignore401);
  };
}

export function saveAgency(agency) {
  return (dispatch) => {
    const creation = !agency.id;
    const saveRequest = creation ? agenciesCollection.post(agency) : agenciesCollection.put(agency.id, agency);

    saveRequest
      .then((response) => {
        let newAgency = agency;
        if (creation) {
          newAgency = response.body(false);
        }
        dispatch(
          agencySaved(
            {
              ...newAgency,
              editing: false,
            },
            creation,
          ),
        );
      })
      .catch(ignore401);
  };
}

export function deleteAgency(agency) {
  return (dispatch) => {
    if (agency.id) {
      agenciesCollection
        .delete(agency.id, agency)
        .then(() => {
          dispatch(agencyDeleted(agency.id));
        })
        .catch(ignore401);
    } else {
      dispatch(agencyDeleted(null));
    }
  };
}
