import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Magnify as SearchIcon, ChevronDown as ExpandIcon, Close as ClearIcon } from 'mdi-material-ui';

import { TextField } from '.';
import classNames from 'classnames';
import debugFactory from 'debug';

const debug = debugFactory('prestago:FilterCard');

const styles = (theme) => ({
  root: {},
  text: {
    flex: 1,
  },
  adornmentButton: {
    padding: 4,
  },
  content: {
    paddingTop: 16,
  },
  actions: {
    justifyContent: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const FilterCard = ({
  classes,
  children,
  hasFilters,
  fullTextEnabled,
  text,
  textPlaceholder,
  searchButtonText,
  title,
  onTextChange,
  onResetClick,
}) => {
  const [hadFilters, setHadFilters] = useState(hasFilters);
  if (!hadFilters && hasFilters) setHadFilters(true);
  const [opened, setOpened] = useState(hadFilters);
  if (hasFilters && !hadFilters) setOpened(true);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Grid container>
          <Grid item xs={12} onClick={fullTextEnabled ? undefined : () => setOpened(!opened)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item className={classes.text}>
                {fullTextEnabled ? (
                  <TextField
                    type="text"
                    name="text"
                    value={text}
                    placeholder={textPlaceholder}
                    fullWidth
                    onChange={(event) => onTextChange(event.target.value)}
                    InputProps={
                      Boolean(text) && !opened
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Réinitialiser">
                                  <IconButton onClick={onResetClick} className={classes.adornmentButton} size="large">
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                  />
                ) : (
                  <Typography variant="h2">{title}</Typography>
                )}
              </Grid>
              {fullTextEnabled && !opened && (
                <Grid item>
                  <Tooltip title="Rechercher">
                    <IconButton type="submit" size="large">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Tooltip title={opened ? 'Moins de filtres' : 'Plus de filtres'}>
                  <IconButton
                    className={classNames(classes.expand, {
                      [classes.expandOpen]: opened,
                    })}
                    onClick={() => {
                      debug('Click opened -> ', !opened);
                      setOpened(!opened);
                    }}
                    size="large"
                  >
                    <ExpandIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={opened} unmountOnExit={false}>
              <div className={classes.content}>{children}</div>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
      {opened && (
        <CardActions className={classes.actions}>
          <Button type="submit" variant="contained" color="primary">
            {searchButtonText}
          </Button>
          <Button variant="contained" onClick={onResetClick}>
            Réinitialiser
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

FilterCard.propTypes = {
  children: PropTypes.node.isRequired,
  hasFilters: PropTypes.bool,
  fullTextEnabled: PropTypes.bool,
  text: PropTypes.string,
  textPlaceholder: PropTypes.string,
  onTextChange: PropTypes.func,
  onResetClick: PropTypes.func,
  searchButtonText: PropTypes.string,
  title: PropTypes.string,
};

FilterCard.defaultProps = {
  textPlaceholder: 'Filtrer...',
  fullTextEnabled: true,
  searchButtonText: 'Rechercher',
  title: 'Filtres',
};

export default withStyles(styles)(FilterCard);
