import React from 'react';
import { connect } from 'react-redux';
import { Button, Card } from '@mui/material';

import { FormActions } from '../utils';
import fddTransitions from '../../constants/fddTransitions';
import { hasRole } from '../../services/SecurityService';
import FDDTransition from './FDDTransition';
import { getSaveButtonLabel } from './fddUtils';

const FDDButtons = ({
  editing,
  onActivateEditingMode,
  onCancel,
  onSave,
  correctPayments,
  fddStatus,
  elementId,
  elementNumber,
  currentUser,
  canEdit,
  dispatch,
  paymentsInForm,
}) => {
  const transitions = fddTransitions.filter(
    (transition) =>
      currentUser.user && transition.from.indexOf(fddStatus) >= 0 && hasRole(currentUser, transition.role.code),
  );

  const leftActions = [];
  const rightActions = [];

  if (editing) {
    if (elementId) {
      rightActions.push(
        <Button variant="contained" key="cancel" onClick={() => onCancel()}>
          Annuler
        </Button>,
      );
    }
    rightActions.push(
      <Button variant="contained" key="save" color="primary" onClick={onSave}>
        {getSaveButtonLabel(elementId, fddStatus, paymentsInForm)}
      </Button>,
    );
  } else {
    if (canEdit) {
      rightActions.push(
        <Button variant="contained" key="edit" onClick={() => onActivateEditingMode()}>
          Modifier
        </Button>,
      );
    }

    transitions.forEach((transition) =>
      (transition.left ? leftActions : rightActions).push(
        <FDDTransition
          key={transition.id}
          transition={transition}
          fddId={elementId}
          number={elementNumber}
          correctPayments={correctPayments}
          dispatch={dispatch}
        />,
      ),
    );
  }

  if (!leftActions.length && !rightActions.length) {
    return null;
  }

  return (
    <Card>
      <FormActions leftActions={leftActions} rightActions={rightActions} />
    </Card>
  );
};

const stateToProps = ({ fdd: { fdd }, currentUser }) => {
  const fddStatus = fdd && fdd.status;
  return {
    fddStatus,
    elementId: fdd && fdd.id,
    elementNumber: fdd && fdd.number,
    currentUser,
  };
};

export default connect(stateToProps)(FDDButtons);
