import React from 'react';
import { compose } from 'recompose';
import withStyles from '@mui/styles/withStyles';
import { SortableContainer } from 'react-sortable-hoc';
import { sortableRowProps } from '../../constants/AppConstants';
import ResponsiveTable from './ResponsiveTable';

const styles = (theme) => ({
  draggingRow: {
    fontFamily: theme.typography.fontFamily,
    display: 'table',
    boxShadow: theme.shadows[8],
  },
});

const SortableTable = (props) => <ResponsiveTable {...props} />;

const injectSortableProps =
  (ComposedComponent) =>
  ({ classes, ...props }) =>
    <ComposedComponent {...props} {...sortableRowProps} helperClass={classes.draggingRow} />;

export default compose(withStyles(styles), injectSortableProps, SortableContainer)(SortableTable);
