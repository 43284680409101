import React from 'react';
import { compose } from 'recompose';

import DemandImport from '../../demands/import/DemandImport';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { urls } from '../../../constants/AppConstants';
import { withPageTitle } from '../../../utils/page-title';

const DemandInvoicesImportPage = () => (
  <DemandImport
    title="Importer des demandes facturées"
    resultIntro={(nb) => `${nb} demande${nb > 1 ? 's' : ''} mise${nb > 1 ? 's' : ''} à jour.`}
    url={urls.demandInvoicesImport}
  />
);

export default compose(
  withPageTitle(() => 'Import de demandes facturées'),
  securedPage(roles.demand.invoice.code),
)(DemandInvoicesImportPage);
