import React from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import DateTimeViewer from '../commons/DateTimeViewer';
import { INTERVENTION_STATUSES } from '../../constants/AppConstants';
import { ResponsiveTable } from '../utils';
import { UNFINISHED_CAUSES } from '../../constants/interventionTransitions';

const styles = {
  comment: {
    whiteSpace: 'pre-wrap',
  },
  unfinishedReason: {
    fontStyle: 'italic',
  },
};

const InterventionHistory = ({ events, classes }) => (
  <ResponsiveTable>
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Événement</TableCell>
        <TableCell>Auteur</TableCell>
        <TableCell>Message</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {events &&
        events.map((event) => (
          <TableRow key={`event-${event.createdDate}`}>
            <TableCell>
              <DateTimeViewer date={event.createdDate} />
            </TableCell>
            <TableCell>{INTERVENTION_STATUSES.getNameById(event.status)}</TableCell>
            <TableCell>{event.user && `${event.user.firstName} ${event.user.lastName}`}</TableCell>
            <TableCell className={classes.comment}>
              {event.unfinishedReason && (
                <div className={classes.unfinishedReason}>
                  Motif : {UNFINISHED_CAUSES.getNameById(event.unfinishedReason)}
                </div>
              )}
              {event.comment}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  </ResponsiveTable>
);

export default withStyles(styles)(InterventionHistory);
