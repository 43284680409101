import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';
import Formsy from 'formsy-react';

import { deleteRegion, saveRegion } from '../../actions/settings/RegionActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import EditableTableRow from '../utils/EditableTableRow';

class RegionRow extends Component {
  state = {
    editing: this.props.editing || false,
    deleting: false,
    region: this.props.region,
  };

  onEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { region } = this.props;

    this.setState(({ editing }) => (editing ? null : { editing: true, region }));
  };

  onChangeName = (name) => {
    this.setState(({ region }) => ({
      region: {
        ...region,
        name,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Case enter is pressed
      this.onSave(e);
    } else if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, region } = this.props;

    if (!region.id) {
      dispatch(deleteRegion(region));
      return;
    }

    this.setState({
      region: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      editing: false,
    });

    const { dispatch } = this.props;
    const { region } = this.state;
    dispatch(saveRegion(region));
    dispatch(addGlobalMessage('Modifications sauvegardées'));
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, region } = this.props;
    dispatch(deleteRegion(region));
    dispatch(addGlobalMessage(`Région supprimée : ${region.name}`));
  }

  render() {
    // From RegionsTable
    const { region, editing, deleting } = this.state;
    const { region: regionProp, editable } = this.props;

    return (
      <EditableTableRow
        key={region.id}
        editable={editable}
        editing={editing}
        onEdit={(e) => this.onEditClick(e, region)}
        actions={
          <>
            <Tooltip title="Enregistrer la région">
              <IconButton onClick={this.onSave} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(region.id) && (
              <Tooltip title="Supprimer la région">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                autoFocus
                name="label"
                value={region.name}
                fullWidth
                onChange={(e) => this.onChangeName(e.target.value)}
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{regionProp.name}</span>
          )}
        </TableCell>
        <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer la région {regionProp.name} ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </EditableTableRow>
    );
  }
}

export default connect()(RegionRow);
