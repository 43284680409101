import { ADD_GLOBAL_MESSAGE, DISMISS_GLOBAL_MESSAGE } from '../constants/AppConstants';

export function addGlobalMessage(message) {
  return {
    type: ADD_GLOBAL_MESSAGE,
    level: 'info',
    message,
  };
}

export function addGlobalError(message) {
  return {
    type: ADD_GLOBAL_MESSAGE,
    level: 'error',
    message,
  };
}

export function dismissGlobalMessage() {
  return {
    type: DISMISS_GLOBAL_MESSAGE,
  };
}
