import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { CardContent, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import dayjs from 'dayjs';
import debugFactory from 'debug';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';

import { updateInterventionsFilter } from '../../actions/InterventionActions';
import history from '../../history';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';

const debug = debugFactory('prestago:InterventionsCalendar');

const localizer = dayjsLocalizer(dayjs);

const showMore = (nb) => `${nb} de plus...`;

const styles = (theme) => ({
  root: {
    height: 700,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: '16px',
  },
});

class InterventionsCalendar extends Component {
  onSelectEvent = (event) => {
    history.push({ pathname: `/interventions/${event.id}` });
  };

  onNavigate = (date) => {
    const { pagination } = this.props;
    debug('InterventionsTable.onNavigate %o %o', date, pagination);

    const cmd = { ...pagination };
    cmd.filter.date = dayjs(date);
    updateInterventionsFilter(cmd);
  };

  eventColor = (event) => ({
    style: {
      backgroundColor: event.color,
    },
  });

  render() {
    const {
      events,
      needsFilter,
      pagination: {
        filter: { date },
      },
      classes,
    } = this.props;
    return needsFilter ? (
      <CardContent>
        <Typography variant="body1">
          Veuillez sélectionner au moins une zone de vente, secteur ou point de vente afin de voir le planning des
          interventions.
        </Typography>
      </CardContent>
    ) : (
      <div className={classes.root}>
        <Calendar
          localizer={localizer}
          date={dayjs(date).toDate()}
          events={events}
          views={['month']}
          popup
          culture="fr"
          messages={{
            previous: <ChevronLeft />,
            next: <ChevronRight />,
            today: "Aujourd'hui",
            showMore,
          }}
          eventPropGetter={this.eventColor}
          onSelectEvent={this.onSelectEvent}
          onNavigate={this.onNavigate}
          formats={{ dayHeaderFormat: 'D MMMM YYYY' }}
        />
      </div>
    );
  }
}

const toEvent = (intervention) => {
  const date = dayjs(intervention.operationScheduleDate).hour(12).toDate();
  debug('toEvent', intervention.operationScheduleDate, '->', date);
  return {
    id: intervention.id,
    title: `${intervention.outlet.code} ${intervention.demandType.name}`,
    allDay: true,
    start: date,
    end: date,
    color: intervention.concept.color,
  };
};

const stateToProps = ({ interventions: { list, pagination, needsFilter } }) => ({
  events: list.map(toEvent),
  pagination,
  needsFilter,
});

export default compose(withStyles(styles), connect(stateToProps))(InterventionsCalendar);
