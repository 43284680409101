import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import Secured from '../utils/Secured';

export const styles = {
  active: {
    '& span': {
      color: '#000',
      fontWeight: 500,
    },
  },
  nested: {
    paddingLeft: 32,
  },
};

const NavLinkWithRef = React.forwardRef(({ className, activeClassName, ...props }, ref) => (
  <NavLink ref={ref} {...props} className={({ isActive }) => classNames(className, isActive ? activeClassName : '')} />
));

class MenuLink extends Component {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    index: PropTypes.bool,
    requiredRole: PropTypes.string,
    anyRole: PropTypes.array,
    onClick: PropTypes.func,
    nested: PropTypes.bool,
  };

  render() {
    const { label, icon, requiredRole, anyRole, nested, classes, index, to, onClick } = this.props;
    const li = (
      <ListItemButton
        component={NavLinkWithRef}
        className={classNames({ [classes.nested]: nested })}
        exact={index}
        to={to}
        onClick={onClick}
        activeClassName={classes.active}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText inset={!icon}>{label}</ListItemText>
      </ListItemButton>
    );
    if (requiredRole) {
      return <Secured requiredRole={requiredRole}>{li}</Secured>;
    }
    if (anyRole) {
      return <Secured anyRole={anyRole}>{li}</Secured>;
    }
    return li;
  }
}

export default withStyles(styles)(MenuLink);
