import {
  FDD_THRESHOLDS_LOADED,
  FDD_THRESHOLD_ADDED,
  FDD_THRESHOLD_SAVED,
  FDD_THRESHOLD_DELETED,
  getByIdDecorator,
} from '../../constants/AppConstants';

const initialState = getByIdDecorator([]);
initialState.showNew = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case FDD_THRESHOLDS_LOADED: {
      const newState = [...action.list];
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    case FDD_THRESHOLD_ADDED: {
      const newState = [...state];
      newState.showNew = true;
      return getByIdDecorator(newState);
    }
    case FDD_THRESHOLD_SAVED: {
      if (action.creation) {
        return getByIdDecorator([...state, action.item]);
      }
      const newState = state.map((threshold) => (threshold.id === action.item.id ? action.item : threshold));
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    case FDD_THRESHOLD_DELETED: {
      if (!action.id) {
        return getByIdDecorator([...state]);
      }
      const newState = state.filter((threshold) => threshold.id !== action.id);
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    default:
      return state;
  }
}
