import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { File, Folder, FolderPlus, FolderRemove } from 'mdi-material-ui';

import { AutocompleteField, LabelValue } from '../utils';

const nameGroup = (group) => {
  const MAX_CHARS = 60;
  const name = group.ops.map((op) => op.name).join(', ');

  if (name.length > MAX_CHARS) {
    const shortName = `${name.substring(0, MAX_CHARS)}...`;
    return { ...group, name, shortName };
  }
  return { ...group, name, shortName: name };
};

const mergeGroups = (result, op) => {
  const existingGroup = result.find((group) => group.id === op.groupId);
  if (existingGroup) {
    existingGroup.ops = [...existingGroup.ops, op];
  } else {
    result.push({ id: op.groupId, ops: [op] });
  }
  return result;
};

const getGroups = (allOperations) => {
  if (!allOperations || !allOperations.length) {
    return [];
  }

  return allOperations
    .filter((op) => !!op.groupId)
    .reduce((result, op) => mergeGroups(result, op), [])
    .map((group) => nameGroup(group));
};

const NO_GROUP = '__NO_GROUP__';

class GroupSelect extends Component {
  static propTypes = {
    elementId: PropTypes.string,
    getElementUrl: PropTypes.func.isRequired,
    groupId: PropTypes.string,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    operations: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onOperationTouched: PropTypes.func.isRequired,
    touchedOperations: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this.getStateForProps(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { readOnly } = this.props;
    const { elementId, name, groupId } = nextProps;

    /*
     * The state is resetted if the form changes to read-only mode.
     */
    if (!readOnly && nextProps.readOnly) {
      this.setState(this.getStateForProps(nextProps));
      return;
    }

    const element = {
      id: elementId,
      name,
      groupId,
    };

    const operationsChanged = JSON.stringify(this.props.operations) !== JSON.stringify(nextProps.operations);
    const touchedOpsChanged =
      JSON.stringify(this.props.touchedOperations) !== JSON.stringify(nextProps.touchedOperations);
    const groupIdChanged = groupId !== this.props.groupId;
    const nameChanged = this.props.name !== name;

    const { touchedOperations } = nextProps;

    // Set internal state
    this.setState(({ operations: oldOperations }) => {
      let operations = oldOperations;
      if (operationsChanged || touchedOpsChanged || groupIdChanged || nameChanged) {
        operations = nextProps.operations.map((op) => {
          if (op.id === elementId) {
            return element;
          }
          const touched = touchedOperations.find((touchedOp) => touchedOp.id === op.id);
          if (touched) {
            return {
              ...op,
              groupId: touched.groupId,
            };
          }
          return op;
        });
      }

      // Building the list of groups
      const groups = getGroups([...operations, !elementId && element]);

      return {
        groups,
        operations,
      };
    });
  }

  /**
   * Triggered when user adds an item to selection.
   *
   * @param value
   */
  onGroupSelect = (value) => {
    // value can either be a groupId or an operationId
    const { groups } = this.state;

    if (value === NO_GROUP) {
      this.onGroupUnSelect();
    } else {
      // if value is a groupId
      const group = groups.find((g) => g.id === value);
      if (group) {
        this.onGroupJoin(value);
      } else {
        this.onGroupCreate(value);
      }
    }
  };

  onGroupUnSelect = () => {
    const { groupId, onChange } = this.props;
    onChange(null);
    this.cleanUp(groupId);
  };

  onGroupJoin = (groupId) => {
    const { onChange } = this.props;
    onChange(groupId);
    this.cleanUp(this.props.groupId);
  };

  onGroupCreate = (operationId) => {
    const { groupId, onChange, onOperationTouched } = this.props;
    const nextGroupId = groupId || uuid();
    onOperationTouched(operationId, nextGroupId);
    onChange(nextGroupId);
  };

  getStateForProps = (props) => {
    const { operations } = props;
    return {
      groups: getGroups(operations),
      operations,
    };
  };

  cleanUp(groupId) {
    if (!groupId) {
      return;
    }
    const { elementId, onOperationTouched } = this.props;
    const { groups } = this.state;
    const group = groups.find((gr) => gr.id === groupId);
    if (group.ops.length !== 2) {
      return;
    }
    const operation = group.ops.find((op) => op.id && op.id !== elementId);
    onOperationTouched(operation.id, null);
  }

  render() {
    const { elementId, getElementUrl, groupId, readOnly } = this.props;
    const { groups, operations } = this.state;

    const groupValue = groupId || NO_GROUP;

    if (readOnly) {
      const group = groups.find((g) => groupId === g.id);
      return (
        <LabelValue label="Groupe">
          {!group
            ? 'Aucun groupe'
            : group.ops.map((op, idx) =>
                op.id ? (
                  <span key={op.id}>
                    <Link component={RouterLink} to={getElementUrl(op.id)}>
                      {op.name}
                    </Link>
                    {idx < group.ops.length - 1 && ', '}
                  </span>
                ) : (
                  <span key={op.id}>
                    {op.name}
                    {idx < group.ops.length - 1 && ', '}
                  </span>
                ),
              )}
        </LabelValue>
      );
    }

    const options = [
      groupId
        ? { id: NO_GROUP, name: 'Quitter le groupe', icon: <FolderRemove /> }
        : { id: NO_GROUP, name: 'Aucun groupe', icon: <File /> },
      ...groups.map((group) => ({
        id: group.id,
        name: group.shortName,
        icon: <Folder />,
      })),
      ...operations
        .filter((op) => !op.groupId && op.id !== elementId)
        .map((op) => ({ id: op.id, name: op.name, icon: <FolderPlus /> })),
    ];

    return (
      <AutocompleteField
        label="Groupe"
        fullWidth
        name="groupId"
        onChange={this.onGroupSelect}
        value={groupValue}
        options={options}
        disableClearable
      />
    );
  }
}

export default GroupSelect;
