import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CardHeader, ResponsiveTable } from '../utils';
import { addAgreementType, loadAgreementTypes } from '../../actions/fdd/FDDAgreementTypeActions';
import FDDAgreementTypeRow from './FDDAgreementTypeRow';
import roles from '../../constants/roles';

class FDDAgreementTypesTable extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadAgreementTypes());
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addAgreementType());
  };

  render() {
    const { fddAgreementTypes, hasNewAgreementType } = this.props;

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter une nature d'accord"
          addRole={roles.fdd.admin.code}
          onAdd={this.onAddClick}
          addDisabled={hasNewAgreementType}
        >
          Natures d'accord FDD
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Remonter dans PMU&Nous ?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fddAgreementTypes.map((agreementType) => (
              <FDDAgreementTypeRow
                key={`${agreementType.id}-${agreementType.position}`}
                index={agreementType.position}
                agreementType={agreementType}
                editing={Boolean(agreementType.editing)}
                disabled={!agreementType.id}
              />
            ))}
          </TableBody>
        </ResponsiveTable>
      </Card>
    );
  }
}

const emptyAgreementType = (nbAgreementTypes) => ({
  id: null,
  name: '',
  canBeSent: false,
  editing: true,
  position: nbAgreementTypes,
});

const agreementTypesWithNew = (agreementTypes) => {
  if (!agreementTypes.showNew) {
    return agreementTypes;
  }
  return [...agreementTypes, emptyAgreementType(agreementTypes.length)];
};

const stateToProps = ({ fddAgreementTypes }) => ({
  fddAgreementTypes: agreementTypesWithNew(fddAgreementTypes),
  hasNewAgreementType: !!fddAgreementTypes.showNew,
});

export default connect(stateToProps)(FDDAgreementTypesTable);
