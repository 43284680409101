import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Fab, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { Account, AccountQuestion, ArrowRight, Lock } from 'mdi-material-ui';
import Formsy from 'formsy-react';
import queryString from 'query-string';
import { TextField } from '../utils';
import { asyncLogin } from '../../actions/LoginActions';
import BrowserChecker from '../commons/BrowserChecker';
import debugFactory from 'debug';
import { usePageTitle } from '../../utils/page-title';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { makeStyles } from '@mui/styles';

const debug = debugFactory('prestago:LoginPage');

const emailErrorMessages = {
  isEmail: 'Veuillez saisir un e-mail valide',
  passwordRequiredMessage: 'Veuillez saisir un e-mail',
};

const passwordErrorMessages = {
  passwordRequiredMessage: 'Veuillez saisir un mot de passe',
};

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    width: '80%',
    maxWidth: 500,
  },
  paper: {
    padding: '40px 55px 45px 40px',
    position: 'relative',
  },
  buttonContainer: {
    position: 'absolute',
    right: -24,
    top: 'calc(50% - 24px)',
  },
  button: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '& > svg': {
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.complex,
      }),
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,

      '& > svg': {
        transform: 'rotate(360deg)',
      },
    },
    '&$buttonDisabled': {
      opacity: 1,
      color: theme.palette.secondary.contrastText,
      backgroundColor: '#eac8cb',
    },
  },
  buttonDisabled: {},
  box: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#e8e8e8',
    margin: '-40px -55px 20px -40px',
  },
  errorMessages: {
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
    color: 'red',
  },
  footer: {
    marginTop: 15,
    textAlign: 'center',
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      margin: '0 1px',
      height: 30,
      lineHeight: '30px',
      padding: '0 15px',
      borderRadius: 20,
      whiteSpace: 'nowrap',
      textAlign: 'left',
      background: 'rgba(255,255,255,.2)',
      color: '#fff',
      textDecoration: 'none',
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
  },
  footerIcon: {
    marginRight: 8,
  },
  inputLabel: {
    color: theme.palette.text.secondary,
  },
}));

const errorMessages = {
  NO_INPUT: 'Veuillez saisir un e-mail et un mot de passe',
  INVALID_INPUT:
    'Il y a une erreur dans la saisie de votre e-mail ou de votre mot de passe. ' +
    "Veuillez recommencer. Si le problème persiste, veuillez contacter l'assistance téléphonique.",
  TECHNICAL:
    'Une erreur technique est survenue. Veuillez essayer à nouveau dans quelques instants. ' +
    "Si le problème persiste, veuillez contacter l'assistance téléphonique.",
  INACTIVE_ACCOUNT: "Votre compte est inactif. Veuillez contacter l'assistance téléphonique pour activer votre compte.",
  BLOCKED_ACCOUNT:
    'Votre compte a été bloqué. ' +
    'Cliquez sur "mot de passe oublié" pour générer un nouveau mot de passe et débloquer votre compte. ' +
    "Si le problème persiste, veuillez contacter l'assistance téléphonique.",
  UNAUTHENTICATED: 'Veuillez vous authentifier pour accéder à cette ressource',
  UNINITIALIZED_ACCOUNT:
    "Vous n'avez pas encore activé votre compte. Vous avez dû recevoir par email un lien pour l'activer.",
  PASSWORD_EXPIRED: 'Mot de passe expiré',
};

const LoginPage = () => {
  usePageTitle('Connexion');

  const classes = useStyles();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const error = useAppSelector(({ login: { error } }) => error);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);

  const handleLogin = () => {
    const { callback } = queryString.parse(search);
    debug('login', callback, search);
    dispatch(asyncLogin(username, password, callback));
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Paper elevation={2} className={classes.paper}>
          {Boolean(error) && (
            <div className={classes.box}>
              <Typography variant="body2" className={classes.errorMessages}>
                {error && error.response && error.response.data && errorMessages[error.response.data.message]
                  ? errorMessages[error.response.data.message]
                  : 'Erreur technique'}
              </Typography>
            </div>
          )}
          <Formsy
            onValid={() => setValid(true)}
            onInvalid={() => setValid(false)}
            onValidSubmit={handleLogin}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Account fontSize="inherit" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Adresse e-mail"
                      name="username"
                      type="email"
                      value={username}
                      fullWidth
                      required
                      validations="isEmail"
                      validationErrors={emailErrorMessages}
                      onChange={(e) => setUsername(e.currentTarget.value)}
                      InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Lock fontSize="inherit" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Mot de passe"
                      name="password"
                      type="password"
                      value={password}
                      fullWidth
                      required
                      validationErrors={passwordErrorMessages}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Tooltip title="Connexion">
              <div className={classes.buttonContainer}>
                <Fab
                  id="login_button"
                  type="submit"
                  size="medium"
                  disabled={!valid}
                  classes={{
                    root: classes.button,
                    disabled: classes.buttonDisabled,
                  }}
                >
                  <ArrowRight />
                </Fab>
              </div>
            </Tooltip>
          </Formsy>
        </Paper>

        <Typography variant="body1" className={classes.footer}>
          <Link to="/forgot-password">
            <AccountQuestion className={classes.footerIcon} fontSize="inherit" />
            Mot de passe oublié
          </Link>
        </Typography>

        <Typography variant="body1" className={classes.footer}>
          <BrowserChecker />
        </Typography>
      </div>
    </div>
  );
};

export default LoginPage;
