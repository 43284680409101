import React from 'react';
import Quagga from 'quagga/dist/quagga';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = {
  viewport: {
    position: 'relative',
    overflow: 'hidden',

    '& video': {
      display: 'block',
      margin: '0 auto',
      width: '100%',
      height: '100%',
    },

    '& canvas': {
      position: 'absolute',
      width: 0,
      height: 0,
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
  },
};

class BarcodeScanner extends React.Component {
  componentDidMount() {
    this.initQuagga();

    window.addEventListener(
      'orientationchange',
      () => {
        Quagga.stop();
        window.setTimeout(this.initQuagga, 100);
      },
      false,
    );
  }

  componentWillUnmount() {
    Quagga.stop();
  }

  initQuagga = () => {
    const portrait = {
      top: '40%',
      right: '5%',
      left: '5%',
      bottom: '40%',
    };
    const landscape = {
      top: '35%',
      right: '25%',
      left: '25%',
      bottom: '35%',
    };
    const orientation = window.innerHeight > window.innerWidth ? portrait : landscape;

    try {
      Quagga.init(
        {
          frequency: 20,
          locate: false,
          target: '#interactive.viewport',
          inputStream: {
            type: 'LiveStream',
            constraints: {
              width: 1920,
              height: 1080,
              facingMode: 'environment',
            },
            area: orientation,
          },
          numOfWorkers: window.navigator.hardwareConcurrency || 4,
          decoder: {
            readers: ['code_128_reader', 'code_39_reader'],
          },
        },
        (err) => {
          if (err) {
            console.error('Quagga.init error', err);
            return null;
          }

          this.drawingCtx = Quagga.canvas.ctx.overlay;
          this.drawingCanvas = Quagga.canvas.dom.overlay;
          const videoElement = document.querySelector('#interactive.viewport > video');
          if (!videoElement) {
            console.error('Cannot find video element', err);
            return null;
          }
          this.video = videoElement.getBoundingClientRect();

          this.initCanvas();

          return Quagga.start();
        },
      );
    } catch (e) {
      console.error('Error initializing barcode scanner', e);
    }

    Quagga.onDetected((result) => {
      this.props.onDetected(result.codeResult.code);
    });

    Quagga.onProcessed((result) => {
      if (result) {
        // for debug, if locate = true
        // if (result.boxes) {
        //   this.initCanvas();
        //   result.boxes.filter((box) => (
        //     box !== result.box
        //   )).forEach((box) => {
        //     Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, this.drawingCtx, { color: '#00FF00', lineWidth: 2 });
        //   });
        // }

        if (result.codeResult && result.codeResult.code) {
          this.initCanvas();

          this.drawingCtx.fillStyle = '#00FF00';
          this.drawingCtx.font = '30px Arial';
          this.drawingCtx.textAlign = 'center';
          this.drawingCtx.fillText(
            result.codeResult.code,
            (result.line[0].x + result.line[1].x) / 2,
            result.line[0].y - 16,
          );

          this.drawingCtx.beginPath();
          this.drawingCtx.strokeStyle = '#00FF00';
          this.drawingCtx.lineWidth = '2';
          this.drawingCtx.moveTo(result.line[0].x, result.line[0].y);
          this.drawingCtx.lineTo(result.line[1].x, result.line[1].y);
          this.drawingCtx.stroke();
        }
      }
    });
  };

  moveCanvas = () => {
    this.drawingCanvas.style.width = `${this.video.width}px`;
    this.drawingCanvas.style.height = `${this.video.height}px`;
  };

  clearCanvas = () => {
    this.drawingCtx.clearRect(
      0,
      0,
      parseInt(this.drawingCanvas.getAttribute('width'), 10),
      parseInt(this.drawingCanvas.getAttribute('height'), 10),
    );
  };

  drawScannerBounds = () => {
    const portrait = {
      x: 0.9,
      y: 0.2,
    };
    const landscape = {
      x: 0.5,
      y: 0.3,
    };
    const orientation = window.innerHeight > window.innerWidth ? portrait : landscape;

    const topOffset = (this.drawingCanvas.height - this.drawingCanvas.height * orientation.y) / 2;
    const leftOffset = (this.drawingCanvas.width - this.drawingCanvas.width * orientation.x) / 2;

    this.drawingCtx.beginPath();
    this.drawingCtx.lineWidth = '4';
    this.drawingCtx.strokeStyle = '#FFFFFF';
    this.drawingCtx.rect(
      leftOffset,
      topOffset,
      this.drawingCanvas.width * orientation.x,
      this.drawingCanvas.height * orientation.y,
    );
    this.drawingCtx.stroke();
  };

  initCanvas = () => {
    this.moveCanvas();
    this.clearCanvas();
    this.drawScannerBounds();
  };

  render() {
    const { classes } = this.props;
    return <div id="interactive" className={classNames('viewport', classes.viewport)} />;
  }
}

export default withStyles(styles)(BarcodeScanner);
