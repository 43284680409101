import React, { Component } from 'react';
import { Grid } from '@mui/material';
import Formsy from 'formsy-react';
import { AutocompleteField, FilterCard, TextField } from '../utils';
import { AgencyAutocompleteField, AreaAutocompleteField, RegionAutocompleteField } from '../commons/autocompleteFields';

const defaultFilters = {
  text: '',
  name: '',
  statuses: [],
  profiles: [],
  companies: [],
  regions: [],
  agencies: [],
  areas: [],
};

class UsersFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultFilters,
      ...props.filters,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      ...defaultFilters,
      ...props.filters,
    });
  }

  onChange = (fieldName) => (value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  onFilter = () => {
    const { filterClick } = this.props;
    filterClick(this.state);
  };

  onResetClick = () => {
    this.setState(defaultFilters, this.onFilter);
  };

  hasFilters = () => {
    const {
      filters: { name = '', statuses = [], profiles = [], companies = [], regions = [], agencies = [], areas = [] },
    } = this.props;
    return Boolean(
      name ||
        statuses.length ||
        profiles.length ||
        companies.length ||
        regions.length ||
        agencies.length ||
        areas.length,
    );
  };

  render() {
    const {
      filterAvailableData: { availableStatuses, availableProfiles },
      availableCompanies,
    } = this.props;
    const { text, name, statuses, profiles, companies, regions, agencies, areas } = this.state;
    return (
      <Formsy noValidate onSubmit={this.onFilter}>
        <FilterCard
          title="Filtres"
          hasFilters={this.hasFilters()}
          text={text}
          textPlaceholder="Filtrer par nom, prénom, statut, entreprise, région, zone de vente..."
          onTextChange={this.onChange('text')}
          onResetClick={this.onResetClick}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="code"
                    label="Nom & Prénom"
                    placeholder="Filtrer par nom & prénom"
                    fullWidth
                    onChange={(event) => this.onChange('name')(event.target.value)}
                    value={name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    label="Statuts"
                    fullWidth
                    options={availableStatuses}
                    name="statuses"
                    onChange={this.onChange('statuses')}
                    placeholder="Filtrer par statut"
                    value={statuses}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    label="Profils"
                    fullWidth
                    options={availableProfiles}
                    name="profiles"
                    onChange={this.onChange('profiles')}
                    placeholder="Filtrer par profil"
                    value={profiles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    label="Entreprises"
                    fullWidth
                    options={availableCompanies}
                    name="companies"
                    onChange={this.onChange('companies')}
                    placeholder="Filtrer par entreprise"
                    value={companies}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegionAutocompleteField
                    multiple
                    label="Régions"
                    fullWidth
                    name="regions"
                    onChange={this.onChange('regions')}
                    placeholder="Filtrer par régions"
                    value={regions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AgencyAutocompleteField
                    multiple
                    regionIds={regions}
                    label="Zones de vente"
                    fullWidth
                    name="agencies"
                    onChange={this.onChange('agencies')}
                    placeholder="Filtrer par zones de vente"
                    value={agencies}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AreaAutocompleteField
                    multiple
                    agencyIds={agencies}
                    regionIds={regions}
                    label="Secteurs"
                    fullWidth
                    name="areas"
                    onChange={this.onChange('areas')}
                    placeholder="Filtrer par secteurs"
                    value={areas}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FilterCard>
      </Formsy>
    );
  }
}

export default UsersFilters;
