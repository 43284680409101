import React from 'react';
import { Button } from '@mui/material';
import { Download } from 'mdi-material-ui';

import { downloadUrl } from '../../actions/DownloadActions';
import { useAppDispatch } from '../../hooks';
import { makeStyles } from '@mui/styles';
import { stockEquipmentExportUrl } from '../../actions/StockEquipmentActions';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[400],
  },
  buttonLabel: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const StockEquipmentExport = (props: { stockOutletCode: string }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  return (
    <Button
      className={classes.button}
      onClick={() => dispatch(downloadUrl(stockEquipmentExportUrl(props.stockOutletCode), 'Stocks.csv'))}
    >
      <Download />
      <span className={classes.buttonLabel}>Exporter le stock</span>
    </Button>
  );
};

export default StockEquipmentExport;
