import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';

export default class ToggleSwitch extends React.Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    disabled: false,
    label: '',
  };

  onChange = (event, checked) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(checked);
    }
  };

  render() {
    // noinspection JSUnusedLocalSymbols
    const {
      value,
      onChange, // eslint-disable-line no-unused-vars
      label,
      ...otherProps
    } = this.props;

    return (
      <FormControlLabel
        control={<Checkbox checked={value} onChange={this.onChange} color="primary" {...otherProps} />}
        label={label}
      />
    );
  }
}
