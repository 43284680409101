import queryString from 'query-string';
import dayjs from 'dayjs';
import debugFactory from 'debug';

import {
  apiBase,
  FDD_ATTACHMENT_DELETE,
  FDD_ATTACHMENT_SET,
  FDD_ERROR,
  FDD_LOADED,
  FDD_LOADING,
  FDD_RESET,
  FDD_STATUSES,
  FDD_TOTAL_LOADED,
  FDD_VALIDATION_ERRORS,
  FDDS_FILTER_UPDATED,
  FDDS_LOADED,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { paginationToParams, paramsToPagination } from '../../utils/paging';
import { addGlobalError, addGlobalMessage } from '../SnackbarActions';
import history from '../../history';
import httpService from '../../services/HttpService';
import { getErrorMessage } from '../../services/ErrorMessageService';
import { escapeFileName } from '../../utils/attachment-utils';

const debug = debugFactory('prestago:FDDActions');

const fddCollection = api.all(urls.fdd.list);
export const fddExportUrl = `${fddCollection.url()}/export.xlsx`;
export const fddPaymentsExportUrl = `${fddCollection.url()}/export-payments.xlsx`;
const fddTotalCollection = api.all(urls.fdd.total);

function attachmentUrl(fddId, attachmentId, fileName) {
  return `${apiBase}/${urls.fdd.list}/${fddId}/attachments${
    attachmentId && fileName ? `/${attachmentId}/${escapeFileName(fileName)}` : ''
  }`;
}

const fddFilterUpdated = (pagination) => {
  const { pageSize, filter } = pagination;
  return {
    type: FDDS_FILTER_UPDATED,
    pageSize,
    filter,
  };
};

export const updateFDDFilter = (pagination) => {
  history.push({
    search: queryString.stringify(paginationToParams(pagination)),
  });
};

export const loadFDDs = (query) => (dispatch) => {
  const pagination = paramsToPagination(query);
  dispatch(fddFilterUpdated(pagination));
  fddCollection
    .getAll(paginationToParams(pagination))
    .then((response) => {
      const page = response.body(false);
      // keep filters
      dispatch({
        type: FDDS_LOADED,
        page,
      });
    })
    .catch(ignore401);
};

export const resetFDD = () => ({
  type: FDD_RESET,
});

export const loadFDD = (id) => (dispatch) => {
  if (!id) {
    dispatch(resetFDD());
    return;
  }

  dispatch({
    type: FDD_LOADING,
  });

  fddCollection
    .get(id)
    .then((response) => {
      const fdd = response.body(false);
      dispatch({
        type: FDD_LOADED,
        fdd,
      });
      if (fdd.outletId) {
        dispatch(loadFDDTotal(fdd.outletId, dayjs(fdd.openedDate ?? undefined).year()));
      }
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading fdd: ', error);
      const message =
        error && error.response && error.response.statusCode === 403
          ? "Vous n'avez pas accès à ce dossier"
          : 'Une erreur a eu lieu pendant le chargement du dossier';
      dispatch(addGlobalError(message));
      dispatch({
        type: FDD_ERROR,
        error: message,
      });
    });
};

export const loadFDDTotal = (outletId, year) => (dispatch) => {
  if (!outletId) {
    dispatch({
      type: FDD_TOTAL_LOADED,
      total: 0,
    });
    return;
  }
  fddTotalCollection
    .custom(outletId)
    .custom(year)
    .get()
    .then((response) => {
      const { total } = response.body(false);
      dispatch({
        type: FDD_TOTAL_LOADED,
        total,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading fdd total: ', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement du total des dossiers'));
    });
};

export const saveOrUpdateFDD = (fdd) => {
  const request = fdd.id ? fddCollection.put(fdd.id, fdd) : fddCollection.post(fdd);

  return (dispatch) => {
    request
      .then((response) => {
        if (!fdd.id) {
          const eq = response.body(false);
          dispatch(addGlobalMessage('Le dossier a été créé.'));
          dispatch(loadFDD(eq.id));
          history.push(`/fdd/${eq.id}`);
        } else {
          dispatch(loadFDD(fdd.id));
          dispatch(addGlobalMessage('Le dossier a été enregistré'));
        }
      })
      .catch(ignore401)
      .catch((err) => {
        if (err.response?.data?.errors && err.response.statusCode === 400) {
          dispatch({
            type: FDD_VALIDATION_ERRORS,
            errors: err.response.data.errors,
          });
          dispatch(addGlobalError('Le formulaire contient des erreurs'));
        } else {
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      });
  };
};

export const performFDDTransition = (fddId, transition, message) => {
  return (dispatch) => {
    api
      .custom(urls.fdd.transition)
      .post({
        fddId,
        transition: transition.id,
        message,
      })
      .then((response) => {
        const updatedFdd = response.body(false);
        dispatch(
          addGlobalMessage(
            `${transition.confirmationMessage}. Nouveau statut : ${FDD_STATUSES.getNameById(updatedFdd.status)}`,
          ),
        );
        dispatch({
          type: FDD_LOADED,
          fdd: updatedFdd,
        });
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while performing transition:', err);
        dispatch(addGlobalError(`Erreur : ${getErrorMessage(err)}`));
      });
  };
};

export function addAttachments(acceptedFiles, rejectedFiles) {
  return (dispatch, getState) => {
    const {
      fdd: { fdd },
    } = getState();
    debug('addAttachments %o, %o, %o, %o', acceptedFiles, rejectedFiles, fdd);
    acceptedFiles.forEach((file) => {
      const fileName = file.attachmentName || file.name;

      // Add the placeholder to the list
      dispatch({
        type: FDD_ATTACHMENT_SET,
        attachment: {
          name: fileName,
          type: file.type,
          thumbnail: file.type.startsWith('image/') ? file.preview : null,
          uploading: true,
        },
      });

      // Call the POST WS
      const formData = new FormData();
      formData.append('file', file, fileName);
      httpService
        .post({
          url: attachmentUrl(fdd.id),
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => Promise.reject(json));
          }
          return response.json();
        })
        .then((json) =>
          dispatch({
            type: FDD_ATTACHMENT_SET,
            attachment: json,
          }),
        )
        .catch((err) => {
          console.error('Error while saving the attachment:', err);
          dispatch(addGlobalError(getErrorMessage(err)));
          dispatch({
            type: FDD_ATTACHMENT_DELETE,
            name: fileName,
          });
        });
    });
  };
}

export function deleteAttachment(attachmentId, attachmentName) {
  debug('deleteAttachment %s %s', attachmentId, attachmentName);
  return (dispatch, getState) => {
    const {
      fdd: { fdd },
    } = getState();

    // Update the list
    dispatch({
      type: FDD_ATTACHMENT_DELETE,
      id: attachmentId,
      name: attachmentName,
    });

    // Actually call the DELETE WS
    httpService
      .delete({
        url: attachmentUrl(fdd.id, attachmentId, attachmentName),
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response;
      })
      .catch((err) => {
        console.error('Error while deleting the attachment:', err);
        dispatch(addGlobalError(getErrorMessage(err)));
      });
  };
}
