import React from 'react';
import { compose } from 'recompose';
import { TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

import DragIndicator from '../../img/drag_indicator.svg';

const styles = {
  sortable: {
    userSelect: 'none',
    backgroundImage: `url(${DragIndicator})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-4px center',
  },
};

const injectSortableProps =
  (ComposedComponent) =>
  ({ disabled, ...props }) =>
    <ComposedComponent {...props} disabled={disabled} draggingDisabled={disabled} />;

const SortableRow = ({ classes, component: Component, draggingDisabled, ...props }) => (
  <Component hover={!draggingDisabled} className={classNames({ [classes.sortable]: !draggingDisabled })} {...props} />
);

SortableRow.defaultProps = {
  component: TableRow,
};

export default compose(injectSortableProps, SortableElement, withStyles(styles))(SortableRow);
