import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Button, Card, CardActions, Grid } from '@mui/material';
import securedPage from '../../securedPage';
import { CardHeader } from '../../utils';
import roles from '../../../constants/roles';
import DemandsTable from '../../demands/DemandsTable';
import DemandsFilters from '../../demands/DemandsFilters';
import DemandsExport from '../../demands/DemandsExport';
import Secured from '../../utils/Secured';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import { loadDemands } from '../../../actions/DemandActions';
import history from '../../../history';
import Hidden from '@mui/material/Hidden';
import { withPageTitle } from '../../../utils/page-title';

class DemandListPage extends Component {
  onNewDemandClick = () => {
    history.push('/demands/new');
  };

  onImportDemandsClick = () => {
    history.push('/demands/import');
  };

  onImportInvoicesClick = () => {
    history.push('/demands/import-invoices');
  };

  render() {
    const { totalElements } = this.props;
    return (
      <Grid container spacing={3}>
        <HierarchicalSearch loadActionCreator={loadDemands} />
        <Grid item xs={12}>
          <DemandsFilters />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              addLabel="Créer une nouvelle demande"
              addRole={roles.demand.create.code}
              onAdd={this.onNewDemandClick}
              actions={
                <Hidden mdDown>
                  <DemandsExport />
                </Hidden>
              }
            >
              Demandes{totalElements > 0 ? ` (${totalElements})` : ''}
            </CardHeader>
            <DemandsTable />
          </Card>
        </Grid>

        <Secured anyRole={[roles.demand.invoice.code, roles.demand.import.code]}>
          <Grid item xs={12}>
            <Card>
              <CardActions>
                <Secured requiredRole={roles.demand.import.code}>
                  <Button variant="contained" onClick={this.onImportDemandsClick} color="primary">
                    Importer des nouvelles demandes
                  </Button>
                </Secured>
                <Secured requiredRole={roles.demand.invoice.code}>
                  <Button variant="contained" onClick={this.onImportInvoicesClick} color="primary">
                    Importer les demandes facturées
                  </Button>
                </Secured>
              </CardActions>
            </Card>
          </Grid>
        </Secured>
      </Grid>
    );
  }
}

const stateToProps = ({
  demands: {
    pagination: { totalElements },
  },
}) => ({
  totalElements,
});

export default compose(
  withPageTitle(() => 'Demandes'),
  securedPage(roles.demand.view.code),
  connect(stateToProps),
)(DemandListPage);
