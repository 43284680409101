import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import withWidth from '@mui/material/Hidden/withWidth';

import debugFactory from 'debug';

import Pagination from '../commons/Pagination';
import DateViewer from '../commons/DateViewer';
import { SortableTableHeader } from '../utils';
import { SortDirections } from '../../utils/sorting';
import { updateDemandsFilter } from '../../actions/DemandActions';
import ResponsiveTable from '../utils/ResponsiveTable';
import history from '../../history';
import Hidden from '@mui/material/Hidden';
import { compose } from 'recompose';
import DemandsListItem from './DemandsListItem';

const debug = debugFactory('prestago:DemandsTable');

const styles = () => ({
  list: {
    padding: 0,
  },
});

class DemandsTable extends Component {
  static propTypes = {
    demands: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  onPageClick = (pageNumber) => {
    const { pagination } = this.props;
    pagination.currentPage = pageNumber;
    updateDemandsFilter(pagination);
  };

  onPageSizeChange = (pageSize) => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.pageSize = pageSize;
    updateDemandsFilter(pagination);
  };

  onHeaderClick = (field) => () => {
    const { pagination } = this.props;
    pagination.sort = { field, direction: this.nextDirection(field) };
    updateDemandsFilter(pagination);
  };

  onRowClick = (demandId) => () => {
    debug('DemandsTable.onRowClick %s', demandId);
    history.push({ pathname: `/demands/${demandId}` });
  };

  sortDirection = (field) => {
    const { sort } = this.props.pagination;
    return sort.field === field ? sort.direction : SortDirections.none;
  };

  nextDirection(field) {
    const { sort } = this.props.pagination;
    return sort.field === field && sort.direction === SortDirections.asc ? SortDirections.desc : SortDirections.asc;
  }

  render() {
    const { demands, pagination, classes } = this.props;

    return (
      <div>
        <Hidden mdUp>
          <List className={classes.list}>
            {Boolean(demands.length) &&
              demands.map((demand) => (
                <DemandsListItem key={demand.id} onClick={this.onRowClick(demand.id)} demand={demand} />
              ))}
          </List>
        </Hidden>
        <Hidden mdDown>
          <ResponsiveTable>
            <TableHead>
              <TableRow>
                <SortableTableHeader
                  direction={this.sortDirection('number')}
                  onClickCallback={this.onHeaderClick('number')}
                  title="Numéro"
                />
                <TableCell>Zone de vente</TableCell>
                <TableCell>Secteur</TableCell>
                <SortableTableHeader
                  direction={this.sortDirection('outlet_code')}
                  onClickCallback={this.onHeaderClick('outlet_code')}
                  title="Code PDV"
                />
                <SortableTableHeader
                  direction={this.sortDirection('outlet_name')}
                  onClickCallback={this.onHeaderClick('outlet_name')}
                  title="Enseigne"
                />
                <SortableTableHeader
                  direction={this.sortDirection('origin_outlet_code')}
                  onClickCallback={this.onHeaderClick('origin_outlet_code')}
                  title="PDV Origine"
                />
                <SortableTableHeader
                  direction={this.sortDirection('demand_type_name')}
                  onClickCallback={this.onHeaderClick('demand_type_name')}
                  title="Type"
                />
                <SortableTableHeader
                  direction={this.sortDirection('concept')}
                  onClickCallback={this.onHeaderClick('concept')}
                  title="Concept"
                />
                <SortableTableHeader
                  direction={this.sortDirection('subcontractor')}
                  onClickCallback={this.onHeaderClick('subcontractor')}
                  title="Prestataire"
                />
                <SortableTableHeader
                  direction={this.sortDirection('operation_schedule_date')}
                  onClickCallback={this.onHeaderClick('operation_schedule_date')}
                  title="Planif."
                />
                <SortableTableHeader
                  direction={this.sortDirection('validation_date')}
                  onClickCallback={this.onHeaderClick('validation_date')}
                  title="Validée"
                />
                <SortableTableHeader
                  direction={this.sortDirection('status')}
                  onClickCallback={this.onHeaderClick('status')}
                  title="Statut"
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(demands.length) ? (
                demands.map((demand) => (
                  <TableRow key={demand.id} onClick={this.onRowClick(demand.id)} hover>
                    <TableCell>{demand.number}</TableCell>
                    <TableCell>{demand.agency.name}</TableCell>
                    <TableCell>{demand.area.name}</TableCell>
                    <TableCell>{demand.outlet.code}</TableCell>
                    <TableCell>{demand.outlet.name}</TableCell>
                    <TableCell>{demand.originOutlet && demand.originOutlet.code}</TableCell>
                    <TableCell>{demand.demandType.name}</TableCell>
                    <TableCell style={{ color: demand.concept.color }}>{demand.concept.name}</TableCell>
                    <TableCell>{demand.subcontractor.name}</TableCell>
                    <TableCell>
                      {demand.operationScheduleDate ? <DateViewer date={demand.operationScheduleDate} /> : ''}
                    </TableCell>
                    <TableCell>{demand.validationDate ? <DateViewer date={demand.validationDate} /> : ''}</TableCell>
                    <TableCell>{demand.status.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="12">Aucune demande ne correspond aux critères renseignés</TableCell>
                </TableRow>
              )}
            </TableBody>
          </ResponsiveTable>
        </Hidden>
        <Pagination
          currentPage={pagination.currentPage}
          numberOfPages={pagination.numberOfPages}
          pageSize={pagination.pageSize}
          onPageChange={this.onPageClick}
          onPageSizeChange={this.onPageSizeChange}
        />
      </div>
    );
  }
}

const stateToProps = ({ demands: { list: demands, pagination } }) => ({
  demands,
  pagination,
});

export default compose(withStyles(styles), withWidth(), connect(stateToProps))(DemandsTable);
