import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { CardHeader } from '../utils';
import { loadDeliveryLocations, addDeliveryLocation } from '../../actions/DeliveryLocationsActions';
import DeliveryLocationRow from './DeliveryLocationRow';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

class DeliveryLocationsTable extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadDeliveryLocations());
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addDeliveryLocation());
  };

  render() {
    // From store
    const { deliveryLocations, hasNewDeliveryLocation, currentUser } = this.props;
    const editable = hasRole(currentUser, roles.deliveryLocation.edit.code);

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter un lieu de livraison"
          addRole={roles.configuration.edit.code}
          onAdd={this.onAddClick}
          addDisabled={hasNewDeliveryLocation}
        >
          Paramétrage des lieux de livraison
        </CardHeader>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="div" style={{ minWidth: 200 }}>
                Société
              </TableCell>
              <TableCell component="div">Enseigne</TableCell>
              <TableCell component="div">Signataire</TableCell>
              <TableCell component="div">Adresse 1</TableCell>
              <TableCell component="div">Adresse 2</TableCell>
              <TableCell component="div">Adresse 3</TableCell>
              <TableCell component="div">Code postal</TableCell>
              <TableCell component="div">Ville</TableCell>
              <TableCell component="div" />
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {deliveryLocations.map((location) => (
              <DeliveryLocationRow
                key={location.id}
                deliveryLocation={location}
                editable={editable}
                editing={!!location.editing}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
    );
  }
}

const emptyDeliveryLocation = {
  id: null,
  editing: true,
};

const deliveryLocationsWithNew = (deliveryLocations) => {
  if (!deliveryLocations.showNew) {
    return deliveryLocations;
  }
  const newDeliveryLocations = [emptyDeliveryLocation, ...deliveryLocations];
  newDeliveryLocations.actualLength = deliveryLocations.length;
  return newDeliveryLocations;
};

export default connect((state) => ({
  deliveryLocations: deliveryLocationsWithNew(state.deliveryLocations),
  hasNewDeliveryLocation: !!state.deliveryLocations.showNew,
  currentUser: state.currentUser,
}))(DeliveryLocationsTable);
