import dayjs from 'dayjs';
import { DateValue } from '../utils/date-utils';

export class DateRange {
  minDate: DateValue;
  maxDate: DateValue;

  constructor(range?: { minDate: DateValue; maxDate: DateValue }) {
    Object.assign(this, range);
  }

  isEmpty(): boolean {
    return !this.minDate && !this.maxDate;
  }

  /**
   * Used especially in filters
   */
  toString(): string {
    let filterName = '';
    if (this.minDate) {
      filterName += dayjs(this.minDate).format();
    }
    if (this.maxDate) {
      // If there is a max without min, set the min as epoch 0 time
      filterName += `${this.minDate ? '' : dayjs(0).format()},${dayjs(this.maxDate).format()}`;
    }
    return filterName;
  }
}
