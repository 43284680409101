import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Pagination from '../commons/Pagination';
import { updateFDDFilter } from '../../actions/fdd/FDDActions';
import DateViewer from '../commons/DateViewer';
import ResponsiveTable from '../utils/ResponsiveTable';
import history from '../../history';
import { FDD_STATUSES } from '../../constants/AppConstants';

class FDDTable extends Component {
  onPageClick = (pageNumber) => {
    const { pagination } = this.props;
    pagination.currentPage = pageNumber;
    updateFDDFilter(pagination);
  };

  onPageSizeChange = (pageSize) => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.pageSize = pageSize;
    updateFDDFilter(pagination);
  };

  onRowClick = (fddId) => () => {
    history.push({ pathname: `/fdd/${fddId}` });
  };

  render() {
    const { fdds, pagination } = this.props;
    return (
      <div>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>N° FDD</TableCell>
              <TableCell>Date de saisie</TableCell>
              <TableCell>Point de vente</TableCell>
              <TableCell>Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fdds.length ? (
              fdds.map((fdd) => (
                <TableRow key={fdd.id} onClick={this.onRowClick(fdd.id)} hover>
                  <TableCell>{fdd.number}</TableCell>
                  <TableCell>{fdd.creationDate ? <DateViewer date={fdd.creationDate} /> : ''}</TableCell>
                  <TableCell>
                    {fdd.outletCode ? `${fdd.outletCode} - ${fdd.outletName}` : <em>Nouveau ({fdd.agencyName})</em>}
                  </TableCell>
                  <TableCell>{FDD_STATUSES.getNameById(fdd.status)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>Aucun dossier ne correspond aux critères renseignés</TableCell>
              </TableRow>
            )}
          </TableBody>
        </ResponsiveTable>
        <Pagination
          currentPage={pagination.currentPage}
          numberOfPages={pagination.numberOfPages}
          pageSize={pagination.pageSize}
          onPageChange={this.onPageClick}
          onPageSizeChange={this.onPageSizeChange}
        />
      </div>
    );
  }
}

const stateToProps = ({ fdds: { list: fdds, pagination } }) => ({
  fdds,
  pagination,
});

export default connect(stateToProps)(FDDTable);
