import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { LabelValue } from '../utils';
import { Link as RouterLink } from 'react-router-dom';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import { compose } from 'recompose';
import { OUTLET_TYPES } from '../../constants/AppConstants';
import { OutletAutocompleteField } from '../commons/autocompleteFields';

const errorMessages = {
  outlet: {
    isDefaultRequiredValue: 'Le point de vente est obligatoire',
    matchRegexp: 'Le code point de vente est constitué de chiffres',
    maxLength: 'Le code point de vente fait 6 caractères maximum',
    outletExists: 'Le point de vente doit exister',
  },
};

const styles = () => ({
  inactiveLabel: { fontStyle: 'italic', color: 'gray', fontSize: '0.7em' },
});

class OutletCardContent extends Component {
  static propTypes = {
    outlet: PropTypes.object,
    editing: PropTypes.bool.isRequired,
    multiOutlet: PropTypes.bool,
    outletCode: PropTypes.string,
    onOutletChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    hideTitle: PropTypes.bool,
  };

  onChange = (outletCode, outlet) => {
    const { onOutletChange } = this.props;
    onOutletChange(outletCode, outlet);
  };

  render() {
    const { outlet, outletCode, editing, multiOutlet, hideTitle, currentUser, classes } = this.props;
    return (
      <>
        {!hideTitle && (
          <Grid item xs={12}>
            <Typography variant="h2">
              {OUTLET_TYPES.getNameById(outlet?.type, () => 'Point de vente')} {multiOutlet && 'Destination'}
              {outlet && !outlet.active && <span className={classes.inactiveLabel}> (En erreur)</span>}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {editing ? (
            <OutletAutocompleteField
              label="Code PDV"
              fullWidth
              required
              name="outletCode"
              value={outletCode}
              onChange={this.onChange}
              getOptionValue={({ code }) => code}
              validations={{
                outletExists: outlet,
              }}
              validationErrors={errorMessages.outlet}
            />
          ) : (
            <LabelValue label={multiOutlet ? 'Code PDV Destination' : 'Code PDV'}>{outletCode}</LabelValue>
          )}
        </Grid>
        {outlet && (
          <>
            <Grid item xs={12}>
              <LabelValue label="Enseigne">
                {hasRole(currentUser, roles.outlet.view.code) ? (
                  <Link component={RouterLink} to={`/outlets/${outlet.id}`}>
                    {outlet.name}
                  </Link>
                ) : (
                  outlet.name
                )}
              </LabelValue>
            </Grid>
            {outlet.agency?.name && (
              <>
                <Grid item xs={12}>
                  <LabelValue label="Région">{outlet.region && outlet.region.name}</LabelValue>
                </Grid>
                <Grid item xs={12}>
                  <LabelValue label="Zone de vente">{outlet.agency && outlet.agency.name}</LabelValue>
                </Grid>
              </>
            )}
            {outlet.area?.name && (
              <Grid item xs={12}>
                <LabelValue label="Secteur">{outlet.area.name}</LabelValue>
              </Grid>
            )}
            <Grid item xs={12}>
              <LabelValue label="Casaque"> {outlet.casaque}</LabelValue>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const stateToProps = ({ currentUser }) => ({
  currentUser,
});

export default compose(connect(stateToProps), withStyles(styles))(OutletCardContent);
