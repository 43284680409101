import {
  EQUIPMENT_MOVE_FILTER_UPDATED,
  EQUIPMENT_MOVE_LOADED,
  EQUIPMENT_MOVE_UPDATED,
} from '../constants/AppConstants';
import { EquipmentMove } from '../model/model';
import { Pagination } from '../model/pagination';
import { AnyAction } from 'redux';

type EquipmentMovesState = {
  list: EquipmentMove[];
  pagination: Pagination;
};

const initialState: EquipmentMovesState = {
  list: [],
  pagination: {
    currentPage: 0,
    numberOfPages: 0,
    pageSize: 10,
    totalElements: 0,
    filter: {},
  },
};

export default function (state: EquipmentMovesState = initialState, action: AnyAction): EquipmentMovesState {
  Object.freeze(state);
  switch (action.type) {
    case EQUIPMENT_MOVE_FILTER_UPDATED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize: action.pageSize || 10,
          filter: action.filter,
        },
      };
    case EQUIPMENT_MOVE_LOADED:
      return {
        ...state,
        list: action.page.content,
        pagination: {
          ...state.pagination,
          currentPage: action.page.number,
          numberOfPages: action.page.totalPages,
          totalElements: action.page.totalElements,
        },
      };
    case EQUIPMENT_MOVE_UPDATED:
      return {
        ...state,
        list: state.list.map((move) => (move.id === action.move.id ? action.move : move)),
      };
    default:
      return state;
  }
}
