import React from 'react';
import { useParams } from 'react-router-dom';

import securedPage from '../../securedPage';
import Intervention from '../../interventions/Intervention';
import roles from '../../../constants/roles';

const InterventionPage = () => {
  const { interventionId } = useParams();
  return <Intervention interventionId={interventionId} />;
};

export default securedPage(roles.intervention.view.code)(InterventionPage);
