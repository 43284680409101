import {
  AFTER_SALES_OPERATION_ERROR,
  AFTER_SALES_OPERATION_LOADED,
  AFTER_SALES_OPERATION_LOADING,
  AFTER_SALES_OPERATION_RESET,
  AFTER_SALES_OPERATION_DELETION_ERRORS,
  AFTER_SALES_OPERATION_VALIDATION_ERRORS,
} from '../../constants/AppConstants';

const emptyAfterSalesOperation = {
  id: null,
  name: '',
  equipmentId: null,
  emphasis: true,
  prices: [
    {
      position: 0,
      companies: [],
      demandTypeIds: [],
      addPrice: null,
      interventionPrice: null,
      movePrice: null,
      removePrice: null,
    },
  ],
  addMaxQuantity: null,
  removeMaxQuantity: null,
  moveMaxQuantity: null,
  compatibleConcepts: [],
  demandTypeIds: [],
  position: 0,
  groupId: null,
};

const initialState = {
  afterSalesOperation: emptyAfterSalesOperation,
  loading: false,
  error: null,
  deletionErrors: [], // The list of references to the afterSalesOperation preventing it to be deleted
  validationErrors: [],
  validationErrorsCounter: 0, // To know when form inputs must be updated
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AFTER_SALES_OPERATION_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        afterSalesOperation: action.afterSalesOperation,
        deletionErrors: [],
        validationErrors: [],
      };
    case AFTER_SALES_OPERATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        afterSalesOperation: emptyAfterSalesOperation,
        deletionErrors: [],
        validationErrors: [],
      };
    case AFTER_SALES_OPERATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        deletionErrors: [],
        validationErrors: [],
      };
    case AFTER_SALES_OPERATION_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        afterSalesOperation: emptyAfterSalesOperation,
        deletionErrors: [],
        validationErrors: [],
      };
    case AFTER_SALES_OPERATION_VALIDATION_ERRORS:
      return {
        ...state,
        deletionErrors: [],
        validationErrors: action.errors || [],
        validationErrorsCounter: state.validationErrorsCounter + 1,
      };
    case AFTER_SALES_OPERATION_DELETION_ERRORS:
      return {
        ...state,
        deletionErrors: action.errors,
      };
    default:
      return state;
  }
}
