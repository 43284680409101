import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { closeMainMenu } from '../../actions/AppActions';
import PackageIcon from 'mdi-material-ui/PackageVariantClosed';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import {
  Account,
  AccountMultiple,
  Calendar,
  CashMultiple,
  ChevronDown,
  ClipboardText,
  Cog,
  Logout,
  Store,
  ViewDashboard,
} from 'mdi-material-ui';

import MenuAvatar from './MenuAvatar';
import Secured from '../utils/Secured';
import { asyncLogout } from '../../actions/CurrentUserActions';
import roles from '../../constants/roles';
import SubMenu from './SubMenu';
import MenuLink from './MenuLink';

import ProfileMenu from '../../img/profile-menu.jpg';
import queryString from 'query-string';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useAppDispatch } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  userSection: {
    padding: 0,
  },
  user: {
    background: `url(${ProfileMenu}) no-repeat top left`,
    backgroundSize: 'auto 100%',
    height: 129,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
  },
  userName: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
    width: '100%',
    padding: '4px 12px',
  },
  userIcon: {
    alignSelf: 'flex-start',
    marginLeft: 12,
    marginTop: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function fullName(currentUser) {
  if (currentUser.user.firstName || currentUser.user.lastName) {
    return `${currentUser.user.firstName} ${currentUser.user.lastName}`;
  }
  return currentUser.user.login;
}

const Menu = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const currentUser = useSelector(({ currentUser }) => currentUser);

  const location = useLocation();

  const [profileOpened, setProfileOpened] = useState(false);

  const onLinkClick = () => {
    dispatch(closeMainMenu());
  };

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(asyncLogout());
  };

  const toggleProfile = (e) => {
    e.preventDefault();

    setProfileOpened(!profileOpened);
  };

  return (
    <>
      {currentUser.loggedIn ? (
        <List className={classes.userSection}>
          <ListItemButton onClick={toggleProfile} className={classes.user}>
            <MenuAvatar className={classes.userIcon} />
            <div className={classes.userName}>
              <Typography variant="body1" color="inherit">
                {fullName(currentUser)}
              </Typography>
              <ChevronDown
                color="inherit"
                className={classNames(classes.expand, {
                  [classes.expandOpen]: profileOpened,
                })}
              />
            </div>
          </ListItemButton>
          <Collapse in={profileOpened} timeout="auto" unmountOnExit>
            <List>
              <MenuLink label="Mon compte" icon={<Account />} to="/account" onClick={onLinkClick} />
              <ListItemButton onClick={onLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Déconnexion</ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      ) : (
        <List>
          <MenuLink
            label="Connexion"
            icon={<Account />}
            to={{
              pathname: '/login',
              search: queryString.stringify({ callback: location.pathname + location.search }),
            }}
          />
        </List>
      )}

      <List>
        <MenuLink
          label="Points de vente"
          icon={<Store color="inherit" />}
          to="/outlets"
          requiredRole={roles.outlet.view.code}
          onClick={onLinkClick}
        />

        <MenuLink
          label="Demandes"
          icon={<ClipboardText />}
          to="/demands"
          requiredRole={roles.demand.view.code}
          onClick={onLinkClick}
        />

        <Secured requiredRole={roles.intervention.view.code}>
          <SubMenu routePrefix="/interventions" label="Interventions" icon={<Calendar />}>
            <MenuLink nested label="Liste" to="/interventions/list" onClick={onLinkClick} />
            <MenuLink nested label="Planning" to="/interventions/planning" onClick={onLinkClick} />
            <MenuLink nested label="Carte" to="/interventions/map" onClick={onLinkClick} />
          </SubMenu>
        </Secured>

        <Secured anyRole={[roles.stockEquipments.view.code, roles.equipmentMove.view.code]}>
          <SubMenu routePrefix="/stock-equipments" label="Stock" icon={<PackageIcon />}>
            <MenuLink
              nested
              label="Matériels"
              to="/stock-equipments"
              requiredRole={roles.stockEquipments.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Mouvements"
              to="/equipment-moves"
              requiredRole={roles.equipmentMove.view.code}
              onClick={onLinkClick}
            />
          </SubMenu>
        </Secured>

        <MenuLink
          label="FDD"
          icon={<CashMultiple />}
          to="/fdd"
          requiredRole={roles.fdd.view.code}
          onClick={onLinkClick}
        />

        <Secured anyRole={[roles.intervention.equipmentExport.code]}>
          <SubMenu routePrefix="/dashboards" label="Tableaux de bord" icon={<ViewDashboard />}>
            <MenuLink
              label="Activité de la journée"
              to="/dashboards/day"
              requiredRole={roles.intervention.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              label="Export des matériels"
              to="/dashboards/equipments"
              requiredRole={roles.intervention.equipmentExport.code}
              onClick={onLinkClick}
            />
          </SubMenu>
        </Secured>

        <Secured
          anyRole={[
            roles.configuration.view.code,
            roles.deliveryLocation.view.code,
            roles.equipment.view.code,
            roles.fdd.admin.code,
          ]}
        >
          <SubMenu routePrefix="/settings" label="Paramétrage" icon={<Cog />}>
            <MenuLink
              nested
              label="Régions"
              to="/settings/regions"
              requiredRole={roles.configuration.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Zones de vente"
              to="/settings/agencies"
              requiredRole={roles.configuration.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Secteurs"
              to="/settings/areas"
              requiredRole={roles.configuration.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Lieux de livraison"
              to="/settings/delivery-locations"
              requiredRole={roles.deliveryLocation.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Matériels"
              to="/settings/equipments"
              requiredRole={roles.equipment.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Forfaits"
              to="/settings/operation-bundles"
              requiredRole={roles.equipment.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Options"
              to="/settings/operation-options"
              requiredRole={roles.equipment.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Après-vente"
              to="/settings/after-sales-operations"
              requiredRole={roles.equipment.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Types de demandes"
              to="/settings/demand-types"
              requiredRole={roles.equipment.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="MAJ des demandes"
              to="/settings/reload-demands"
              requiredRole={roles.demand.updateCost.code}
              onClick={onLinkClick}
            />
            <MenuLink nested label="FDD" to="/settings/fdd" requiredRole={roles.fdd.admin.code} onClick={onLinkClick} />
          </SubMenu>
        </Secured>

        <Secured anyRole={[roles.user.view.code, roles.profile.view.code]}>
          <SubMenu routePrefix="/users" label="Utilisateurs & autorisations" icon={<AccountMultiple />}>
            <MenuLink
              nested
              label="Utilisateurs"
              to="/users/users"
              requiredRole={roles.user.view.code}
              onClick={onLinkClick}
            />
            <MenuLink
              nested
              label="Profils"
              to="/users/profiles"
              requiredRole={roles.profile.view.code}
              onClick={onLinkClick}
            />
          </SubMenu>
        </Secured>
      </List>
    </>
  );
};

export default Menu;
