import queryString from 'query-string';

import {
  DEMANDS_FILTER_UPDATED,
  DEMANDS_LOADED,
  DEMAND_LOADED,
  DEMAND_SAVED,
  DEMAND_RESET,
  DEMAND_ERROR,
  DEMAND_VALIDATION_ERROR,
  CONCEPTS,
  COMPANIES,
  DEMAND_STATUSES,
  DEMAND_UPDATE_INVOICE,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalMessage, addGlobalError } from './SnackbarActions';
import { replaceAttachments } from './DemandAttachmentsActions';
import { paginationToParams, paramsToPagination } from '../utils/paging';
import { SortDirections } from '../utils/sorting';
import history from '../history';

const demandsCollection = api.all(urls.demands);

export const demandExportUrl = `${demandsCollection.url()}/export.csv`;
export const demandEquipmentExportUrl = `${demandsCollection.url()}/equipments/export.csv`;

const transform = (paged) => ({
  ...paged,
  content: paged.content.map((demand) => ({
    ...demand,
    concept: CONCEPTS.getById(demand.concept),
    status: DEMAND_STATUSES.getById(demand.status),
    subcontractor: COMPANIES.getById(demand.subcontractor),
  })),
});

function demandsFilterUpdated(pagination) {
  const { pageSize, filter } = pagination;
  return {
    type: DEMANDS_FILTER_UPDATED,
    pageSize,
    filter,
  };
}

export function updateDemandsFilter(pagination) {
  history.push({
    search: queryString.stringify(paginationToParams(pagination)),
  });
}

export function loadDemands(query) {
  return (dispatch) => {
    const pagination = paramsToPagination(query, {
      field: 'number',
      direction: SortDirections.desc,
    });
    dispatch(demandsFilterUpdated(pagination));
    demandsCollection
      .getAll(paginationToParams(pagination, ['outletNames']))
      .then((response) => {
        const page = transform(response.body(false));
        // keep filters
        dispatch({
          type: DEMANDS_LOADED,
          page,
        });
      })
      .catch(ignore401);
  };
}

export function saveDemand(demand) {
  return (dispatch) => {
    const savedRequest = demand.id ? demandsCollection.put(demand.id, demand) : demandsCollection.post(demand);

    savedRequest
      .then((response) => {
        if (!demand.id) {
          history.push({ pathname: `/demands/${response.body(false).id}` });
        } else {
          const updatedDemand = response.body(false);
          dispatch({
            type: DEMAND_SAVED,
            demand: { ...demand, ...updatedDemand },
          });
        }

        dispatch(addGlobalMessage('La demande a bien été sauvegardée'));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving the demand:', err);
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch({
            type: DEMAND_VALIDATION_ERROR,
            errors: err.response.data.errors,
          });
          dispatch(addGlobalError('Le formulaire contient des erreurs'));
        } else {
          dispatch(addGlobalError("Erreur pendant l'enregistrement"));
        }
      });
  };
}

export function resetDemand() {
  return (dispatch) => {
    dispatch({
      type: DEMAND_RESET,
    });
    dispatch(replaceAttachments({}));
  };
}

export function loadDemand(id) {
  return (dispatch, state) => {
    if (state.demand && state.demand.demand && state.demand.demand.id && state.demand.demand.id !== id) {
      // Do not reset form if this is the same demand.
      dispatch(resetDemand());
    }
    if (!id) {
      return;
    }
    demandsCollection
      .get(id)
      .then((response) => {
        const demand = response.body(false);
        dispatch({
          type: DEMAND_LOADED,
          demand,
        });
        dispatch(replaceAttachments(demand.attachments));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while loading the demand:', err);
        dispatch({
          type: DEMAND_ERROR,
          err,
        });
      });
  };
}

export function deleteDemand(id) {
  return (dispatch) => {
    demandsCollection
      .delete(id)
      .then(() => {
        dispatch(addGlobalMessage('La demande a bien été supprimée'));
        history.push({ pathname: '/demands' });
      })
      .catch(ignore401)
      .catch(() => {
        dispatch(addGlobalError('Une erreur a eu lieu lors de la suppression de la demande'));
      });
  };
}

export function toggleDemandInvoice(demandId, invoice) {
  const endpoint = api.one(urls.demands, demandId);
  return (dispatch) => {
    endpoint
      .all('invoice')
      .post({ invoice })
      .then(() => {
        dispatch({
          type: DEMAND_UPDATE_INVOICE,
          invoice,
        });
        dispatch(addGlobalMessage('La modification est enregistrée'));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while toggling invoice:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement de la modification"));
      });
  };
}
