import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Formsy from 'formsy-react';

import { updateFDDFilter } from '../../actions/fdd/FDDActions';
import { User } from '../../model/user';
import { AutocompleteField, FilterCard, TextField } from '../utils';
import RangeDatePicker from '../commons/RangeDatePicker';
import { DateRange } from '../../model/range';
import { arrayEquals } from '../../utils/array-utils';
import { FDD_STATUSES } from '../../constants/AppConstants';
import {
  AgencyAutocompleteField,
  AreaAutocompleteField,
  OutletAutocompleteField,
  RegionAutocompleteField,
} from '../commons/autocompleteFields';

/**
 * Get initial filter state.
 *
 * @returns filters
 */
function initializeFilters(userProp, pagination) {
  const user = new User(userProp);
  const { filter } = pagination;

  return {
    isRegionDisabled: user.isRegional() || user.isAgency() || user.isArea(),
    isAgencyDisabled: user.isAgency() || user.isArea(),
    text: filter.text && filter.text.length ? filter.text[0] : '',
    number: filter.number && filter.number.length ? filter.number[0] : '',
    regionIds: user.isRegional() ? [user.regionId] : filter.regionIds || [],
    agencyIds: user.isAgency() ? [user.agencyId] : filter.agencyIds || [],
    areaIds: filter.areaIds || [],
    denormOutletIds: filter.denormOutletIds || [],
    outletNames: filter.outletNames || [],
    statuses: filter.statuses || [],
    creationDateRange: filter.creationDateRange || new DateRange(),
  };
}

class FDDFilters extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = initializeFilters(props.user, props.pagination);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, pagination } = nextProps;
    this.setState(initializeFilters(user, pagination));
  }

  /**
   * Change callback that occurs on children filter component selection.
   */
  onChange = (fieldName) => (value, valueObj) => {
    const newState = {
      [fieldName]: value,
    };
    if (fieldName === 'denormOutletIds') {
      newState.outletNames = valueObj.map(({ code, name }) => (code ? `${code} - ${name}` : name));
    }
    this.setState(newState);
  };

  /**
   * Change callback that occurs when a date selection changes.
   *
   * @param fieldName field name targeted by filter change
   * @param state current state of field
   */
  onDateChange = (fieldName, state) => {
    const newState = {};
    newState[fieldName] = state;
    this.setState(newState);
  };

  /**
   * Triggers the equipment move list fetch action with filters defined by user.
   */
  onFilter = () => {
    const { pagination } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = this.getFilter();
    updateFDDFilter(pagination);
  };

  /**
   * Resets state and fetch from server.
   *
   * @param event
   */
  onResetClick = (event) => {
    event.preventDefault();
    const { user, pagination } = this.props;
    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.filter = {};

    // Reset filter (setState returns a promise)
    this.setState(initializeFilters(user, pagination), () => {
      updateFDDFilter(pagination);
    });
  };

  getFilter() {
    // Clean state
    const { ...epuredState } = this.state;
    delete epuredState.isRegionDisabled;
    delete epuredState.isAgencyDisabled;
    if (epuredState.creationDateRange.isEmpty()) {
      delete epuredState.creationDateRange;
    }
    return epuredState;
  }

  hasFilters = () => {
    const { isRegionDisabled, isAgencyDisabled } = this.state;
    const {
      pagination: {
        filter: {
          number = [],
          regionIds = [],
          agencyIds = [],
          areaIds = [],
          denormOutletIds = [],
          statuses = [],
          creationDateRange = new DateRange(),
        },
      },
      user: { areasIds: userAreaIds = [] },
    } = this.props;
    return Boolean(
      number.length ||
        (regionIds.length && !isRegionDisabled) ||
        (agencyIds.length && !isAgencyDisabled) ||
        (areaIds.length && !arrayEquals(areaIds, userAreaIds)) ||
        denormOutletIds.length ||
        statuses.length ||
        !creationDateRange.isEmpty(),
    );
  };

  render() {
    const {
      text,
      number,
      regionIds,
      agencyIds,
      areaIds,
      isRegionDisabled,
      isAgencyDisabled,
      denormOutletIds,
      outletNames,
      statuses,
      creationDateRange,
    } = this.state;

    return (
      <Formsy noValidate onSubmit={this.onFilter}>
        <FilterCard
          hasFilters={this.hasFilters()}
          text={text}
          textPlaceholder="Filtrer par n° FDD, zone de vente, point de vente..."
          onTextChange={this.onChange('text')}
          onResetClick={this.onResetClick}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegionAutocompleteField
                    multiple
                    name="regionIds"
                    value={regionIds}
                    label="Régions"
                    placeholder="Filtrer par région"
                    disabled={isRegionDisabled}
                    onChange={this.onChange('regionIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AgencyAutocompleteField
                    multiple
                    regionIds={regionIds}
                    name="agencyIds"
                    value={agencyIds}
                    label="Zones de vente"
                    placeholder="Filtrer par zone de vente"
                    disabled={isAgencyDisabled}
                    onChange={this.onChange('agencyIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AreaAutocompleteField
                    multiple
                    agencyIds={agencyIds}
                    regionIds={regionIds}
                    name="areaIds"
                    value={areaIds}
                    label="Secteurs"
                    placeholder="Filtrer par secteur"
                    onChange={this.onChange('areaIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutletAutocompleteField
                    multiple
                    outletNames={outletNames}
                    name="denormOutletIds"
                    value={denormOutletIds}
                    label="Points de vente"
                    placeholder="Filtrer par point de vente"
                    onChange={this.onChange('denormOutletIds')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="number"
                    value={number}
                    label="N° FDD"
                    placeholder="Filtrer par n° FDD"
                    fullWidth
                    onChange={(event) => this.onChange('number')(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={FDD_STATUSES}
                    name="statuses"
                    value={statuses}
                    label="Statuts"
                    placeholder="Filtrer par statut"
                    onChange={this.onChange('statuses')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RangeDatePicker
                    label="Date de saisie :"
                    name="creationDateRange"
                    range={creationDateRange}
                    onChange={this.onDateChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FilterCard>
      </Formsy>
    );
  }
}

export default connect((state) => {
  const {
    currentUser: { user },
    fdds: { pagination },
  } = state;
  return {
    user,
    pagination,
  };
})(FDDFilters);
