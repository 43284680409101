import queryString from 'query-string';

import history from '../history';
import {
  OUTLETS_FILTER_UPDATED,
  OUTLETS_LOADED,
  OUTLET_LOADED,
  OUTLET_SAVED,
  OUTLET_RESET,
  OUTLET_ERROR,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalMessage } from './SnackbarActions';
import { paginationToParams, paramsToPagination } from '../utils/paging';
import { SortDirections } from '../utils/sorting';

/**
 * The REST collection used to manage outlet resources.
 */
const outletsCollection = api.all(urls.outlets);

function outletsFilterUpdated(pagination) {
  const { pageSize, filter } = pagination;
  return {
    type: OUTLETS_FILTER_UPDATED,
    pageSize,
    filter,
  };
}

export function updateOutletsFilter(pagination) {
  history.push({
    search: queryString.stringify(paginationToParams(pagination)),
  });
}

export function loadOutlets(query) {
  return (dispatch) => {
    const pagination = paramsToPagination(query, {
      field: 'code',
      direction: SortDirections.asc,
    });
    dispatch(outletsFilterUpdated(pagination));
    outletsCollection
      .getAll(paginationToParams(pagination))
      .then((response) => {
        const page = response.body(false);
        // keep filters
        dispatch({
          type: OUTLETS_LOADED,
          page,
        });
      })
      .catch(ignore401);
  };
}

export function saveOutlet(outlet) {
  return (dispatch) => {
    const command = {
      openingHoursComment: outlet.openingHoursComment,
      outletContact: outlet.outletContact,
      pmuContact: outlet.pmuContact,
      pmuContactFunction: outlet.pmuContactFunction,
    };
    outletsCollection
      .patch(outlet.id, command)
      .then(() => {
        dispatch({
          type: OUTLET_SAVED,
          outlet,
        });

        dispatch(addGlobalMessage('Le point de vente a bien été sauvegardé'));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving the outlet:', err);
        dispatch({
          type: OUTLET_ERROR,
          err,
        });
      });
  };
}

export function resetOutlet() {
  return {
    type: OUTLET_RESET,
  };
}

export function loadOutlet(id) {
  return (dispatch) => {
    dispatch(resetOutlet());
    if (!id) {
      return;
    }
    outletsCollection
      .get(id)
      .then((response) => {
        const outlet = response.body(false);
        dispatch({
          type: OUTLET_LOADED,
          outlet,
        });
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while loading the outlet:', err);
        dispatch({
          type: OUTLET_ERROR,
          err,
        });
      });
  };
}
