import rootReducer from './reducers/rootReducer';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(rootReducer, {}, composedEnhancers);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers/rootReducer', () => store.replaceReducer(rootReducer));
}

export type RootState = ReturnType<typeof store.getState>;
// Ideally we want `typeof store.dispatch`, but the thunk action is not inferred properly.
export type AppDispatch = ThunkDispatch<any, any, any>;

export default store;
