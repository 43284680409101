import React from 'react';
import * as PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { ButtonBase, CardContent, useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import classNames from 'classnames';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from '@mui/styles';

type PaginationProps = {
  numberOfPages: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  pageSizerDisabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  pagesContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      paddingBottom: 4,
    },
  },
  pages: {
    display: 'flex',
    margin: 0,
    padding: 0,
    '& > li': {
      display: 'inline-block',
      marginRight: 4,
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      '& > a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: 40,
        height: 40,
        lineHeight: '38px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[700],
      },
      '&$pageActive > a': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&.$pageDisabled > a': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
  pageDisabled: {},
  pageActive: {},
  pageSizes: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto',
    },
    display: 'flex',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  pageSize: {
    padding: '0 12px',
    lineHeight: '38px',
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey[700],
  },
  pageSizeButton: {
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  pageSizeActive: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const Pagination = ({
  numberOfPages,
  currentPage,
  pageSize,
  pageSizerDisabled,
  onPageChange,
  onPageSizeChange,
}: PaginationProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardContent className={classes.root}>
      <div className={classes.pagesContainer}>
        {Boolean(numberOfPages) && (
          <ReactPaginate
            pageCount={numberOfPages}
            pageRangeDisplayed={mobile ? 1 : 4}
            marginPagesDisplayed={1}
            forcePage={currentPage}
            onPageChange={(page) => onPageChange(page.selected)}
            containerClassName={classes.pages}
            activeClassName={classes.pageActive}
            disabledClassName={classes.pageDisabled}
            nextLabel={<ChevronRight />}
            previousLabel={<ChevronLeft />}
            breakLabel="..."
          />
        )}
      </div>

      {!pageSizerDisabled && (
        <div className={classes.pageSizes}>
          <Hidden smDown>
            <div className={classes.pageSize}>Taille des pages :</div>
          </Hidden>
          <ButtonBase
            type="button"
            className={classNames(classes.pageSize, classes.pageSizeButton, {
              [classes.pageSizeActive]: pageSize === 10,
            })}
            onClick={() => onPageSizeChange(10)}
          >
            10
          </ButtonBase>
          <ButtonBase
            type="button"
            className={classNames(classes.pageSize, classes.pageSizeButton, {
              [classes.pageSizeActive]: pageSize === 25,
            })}
            onClick={() => onPageSizeChange(25)}
          >
            25
          </ButtonBase>
          <ButtonBase
            type="button"
            className={classNames(classes.pageSize, classes.pageSizeButton, {
              [classes.pageSizeActive]: pageSize === 50,
            })}
            onClick={() => onPageSizeChange(50)}
          >
            50
          </ButtonBase>
          <ButtonBase
            type="button"
            className={classNames(classes.pageSize, classes.pageSizeButton, {
              [classes.pageSizeActive]: pageSize === 100,
            })}
            onClick={() => onPageSizeChange(100)}
          >
            100
          </ButtonBase>
        </div>
      )}
    </CardContent>
  );
};

Pagination.propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func,
  pageSizerDisabled: PropTypes.bool,
};

export default Pagination;
