import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import ForbiddenPage from './ForbiddenPage';
import securedPage from '../securedPage';

const IndexPage = () => {
  const currentUser = useSelector(({ currentUser }) => currentUser);
  if (hasRole(currentUser, roles.demand.view.code)) {
    return <Navigate to="/demands" />;
  } else if (hasRole(currentUser, roles.fdd.view.code)) {
    return <Navigate to="/fdd" />;
  } else if (hasRole(currentUser, roles.stockEquipments.view.code)) {
    return <Navigate to="/stock-equipments" />;
  } else {
    return <ForbiddenPage />;
  }
};

export default securedPage()(IndexPage);
