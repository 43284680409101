import roles from './roles';
import { getByIdDecorator } from './AppConstants';

export const POST_ACTIONS = {
  RELOAD: 'RELOAD',
  CREATE: 'CREATE',
};

export const INTERVENTION_TRANSITIONS = [
  {
    id: 'SCHEDULE',
    from: ['_010_CREATED'],
    to: '_020_SCHEDULED',
    name: 'Planifier',
    color: 'primary',
    confirmationMessage: "L'intervention a été planifiée",
    mandatoryCause: false,
    mandatorySerialNumbers: false,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: true,
    deliveryDetails: true,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.RELOAD,
    role: roles.intervention.process,
  },
  {
    id: 'DECLARE_CANCELLED',
    from: ['_020_SCHEDULED'],
    to: '_030_CANCELLED',
    name: 'Déclarer annulée',
    color: 'neutral',
    confirmationMessage: "L'intervention a été déclarée annulée",
    mandatoryCause: true,
    mandatorySerialNumbers: false,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: false,
    deliveryDetails: false,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.RELOAD,
    role: roles.intervention.process,
  },
  {
    id: 'REPROGRAM_CANCELLED',
    from: ['_030_CANCELLED'],
    to: '_040_CANCELLED_SCHEDULED',
    name: 'Reprogrammer',
    color: 'primary',
    confirmationMessage: "L'intervention a été déclarée reprogrammée",
    mandatoryCause: false,
    mandatorySerialNumbers: false,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: false,
    deliveryDetails: false,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.CREATE,
    role: roles.intervention.process,
  },
  {
    id: 'TERMINATE',
    from: ['_020_SCHEDULED'],
    to: '_070_FINISHED',
    name: 'Déclarer terminée',
    color: 'primary',
    confirmationMessage: "L'intervention est terminée",
    mandatoryCause: false,
    mandatorySerialNumbers: true,
    mandatorySignature: true,
    mandatoryAttachments: true,
    mandatoryDate: false,
    mandatoryStartedFinishedDates: true,
    deliveryDetails: false,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.RELOAD,
    role: roles.intervention.process,
  },
  {
    id: 'DECLARE_UNFINISHED',
    from: ['_020_SCHEDULED'],
    to: '_050_UNFINISHED',
    name: 'Déclarer inachevée',
    color: 'neutral',
    confirmationMessage: "L'intervention a été déclarée inachevée",
    mandatoryCause: true,
    mandatorySerialNumbers: true,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: false,
    deliveryDetails: false,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.RELOAD,
    role: roles.intervention.process,
  },
  {
    id: 'REPROGRAM_UNFINISHED',
    from: ['_050_UNFINISHED'],
    to: '_060_UNFINISHED_SCHEDULED',
    name: 'Reprogrammer',
    color: 'primary',
    confirmationMessage: "L'intervention a été déclarée reprogrammée",
    mandatoryCause: false,
    mandatorySerialNumbers: false,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: false,
    deliveryDetails: false,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.CREATE,
    role: roles.intervention.process,
  },
  {
    id: 'DELETE',
    from: ['_010_CREATED', '_020_SCHEDULED', '_030_CANCELLED', '_050_UNFINISHED'],
    to: '_100_DELETED',
    name: 'Supprimer',
    color: 'secondary',
    left: true,
    confirmationMessage: "L'intervention a été supprimée",
    mandatoryCause: false,
    mandatorySerialNumbers: false,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: false,
    forbiddenCompletedOps: true,
    withoutComment: true,
    role: roles.intervention.deleteAlways,
  },
  {
    id: 'REOPEN',
    from: [
      '_030_CANCELLED',
      '_040_CANCELLED_SCHEDULED',
      '_050_UNFINISHED',
      '_060_UNFINISHED_SCHEDULED',
      '_070_FINISHED',
    ],
    to: '_020_SCHEDULED',
    name: 'Rouvrir',
    color: 'neutral',
    left: true,
    confirmationMessage: "L'intervention a été rouverte",
    mandatoryCause: false,
    mandatorySerialNumbers: false,
    mandatorySignature: false,
    mandatoryAttachments: false,
    mandatoryDate: false,
    deliveryDetails: false,
    forbiddenCompletedOps: false,
    postAction: POST_ACTIONS.RELOAD,
    role: roles.intervention.reopen,
  },
];

export const UNFINISHED_CAUSES = getByIdDecorator([
  {
    id: 'SUBCONTRACTOR_MISSING_MATERIAL',
    name: 'Prestataire : Erreur au chargement',
  },
  {
    id: 'PMU_AUTHORIZATION',
    name: 'PMU : Défaut autorisation Enseigne',
  },
  {
    id: 'SUBCONTRACTOR_BROKEN_MATERIAL',
    name: 'Prestataire : Matériel abîmé ou HS',
  },
  {
    id: 'PMU_CANCELLATION',
    name: 'PMU : Annulation',
  },
  {
    id: 'PMU_FLAWED_MATERIAL',
    name: 'PMU : Produit défectueux',
  },
  {
    id: 'SUBCONTRACTOR_CANCELLATION',
    name: 'Prestataire : Annulation / report',
  },
  {
    id: 'SUBCONTRACTOR_BASKET',
    name: 'Prestataire : Nacelle non prévue',
  },
  {
    id: 'SPECIFIC_BRACKET',
    name: 'Potence spécifique',
  },
  {
    id: 'PMU_COMMAND_ERROR',
    name: 'PMU : Erreur saisie commande',
  },
  {
    id: 'SUBCONTRACTOR_UNFINISHED_INSTALLATION',
    name: 'Prestataire : Installation incomplète',
  },
  {
    id: 'PMU_WINDOWS_STICKER_TEMPERATURE',
    name: 'PMU : Vitrophanie température',
  },
  {
    id: 'SUBCONTRACTOR_NOT_COMPLETED',
    name: 'Prestataire : Intervention non finalisée',
  },
  {
    id: 'PMU_OUT_OF_STOCK',
    name: 'PMU : Rupture de stock',
  },
  {
    id: 'PMU_DELIVERY_ERROR',
    name: 'PMU : Pb logistique erreur livraison',
  },
  {
    id: 'PMU_DELIVERY_DELAY',
    name: 'PMU : Pb logistique retard livraison',
  },
  {
    id: 'PMU_BASKET_KO',
    name: 'PMU : Défaut éléments pour nacelle',
  },
  {
    id: 'PMU_DSO',
    name: 'PMU : DSO',
  },
  {
    id: 'PMU_HOLDER_REFUSAL',
    name: 'PMU : Refus titulaire',
  },
]);
