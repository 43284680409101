import React, { useEffect } from 'react';

const suffix = 'Prestago - PMU';

const setPageTitle = (title) => {
  document.title = title ? `${title} - ${suffix}` : suffix;
};

export const usePageTitle = (title) => {
  useEffect(() => {
    setPageTitle(title);
  }, [title]);
};

export const withPageTitle = (getTitle) => (ComposedComponent) => (props) => {
  usePageTitle(getTitle(props));
  return <ComposedComponent {...props} />;
};
