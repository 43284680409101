import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dialog, Button, DialogActions, DialogTitle, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import DateViewer from '../commons/DateViewer';
import ResponsiveTable from '../utils/ResponsiveTable';

const styles = {
  root: {
    minWidth: '50%',
  },
  statusColumn: {
    whiteSpace: 'nowrap',
  },
};

const InterventionOperationCommands = ({
  open,
  equipmentName,
  onClose,
  commands,
  deliveryLocations,
  interventionStatus,
  classes,
  outletLabel,
}) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
    <DialogTitle>Livraisons pour la prestation "{equipmentName}"</DialogTitle>
    <ResponsiveTable>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Quantité</TableCell>
          <TableCell>Lieu de livraison</TableCell>
          <TableCell>N° commande</TableCell>
          <TableCell>Date de traitement</TableCell>
          <TableCell>Statut</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {commands.map(({ index, quantity, deliveryToOutlet, deliveryLocationId, code, inclusionDate, status }) => {
          const deliveryLocation =
            !deliveryToOutlet &&
            deliveryLocationId &&
            deliveryLocations &&
            deliveryLocations.getById(deliveryLocationId);
          return (
            <TableRow key={index}>
              <TableCell>{index}</TableCell>
              <TableCell>{quantity}</TableCell>
              <TableCell>{deliveryToOutlet ? outletLabel : deliveryLocation && deliveryLocation.name}</TableCell>
              <TableCell>{code}</TableCell>
              <TableCell>
                <DateViewer date={inclusionDate} />
              </TableCell>
              <TableCell className={classes.statusColumn}>
                {status && status.icon}{' '}
                {interventionStatus < '_020_SCHEDULED' ? 'En attente de planification' : status && status.name}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </ResponsiveTable>
    <DialogActions>
      <Button onClick={onClose}>Fermer</Button>
    </DialogActions>
  </Dialog>
);

const stateToProps = ({
  intervention: {
    intervention: { status: interventionStatus, oracleCommands, area },
  },
  deliveryLocations,
}) => ({
  interventionStatus,
  oracleCommands,
  deliveryLocations,
  outletLabel: !area || !area.id ? 'Zone de vente' : 'Point de vente',
});

export default compose(withStyles(styles), connect(stateToProps))(InterventionOperationCommands);
