import React from 'react';
import { withFormsy } from 'formsy-react';
import { Typography } from '@mui/material';

import AttachmentsZone from './AttachmentsZone';
import { getNonFormsyProps } from '../../utils/formsy-utils';

const AttachementZoneField = (props) => {
  const { value, errorMessage, isPristine } = props;
  const childProps = getNonFormsyProps(props);
  const message = isPristine ? '' : errorMessage;
  const hasError = Boolean(errorMessage);

  return (
    <>
      <AttachmentsZone validationError={hasError} attachments={value} {...childProps} />
      {hasError && (
        <Typography variant="body2" color="error">
          {message}
        </Typography>
      )}
    </>
  );
};

export default withFormsy(AttachementZoneField);
