import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Secured, TextField, ToggleSwitch } from '../utils';
import { deleteProfile, editProfileCancel, saveProfile } from '../../actions/ProfileActions';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

const styles = (theme) => ({
  deleteButton: {
    marginRight: 'auto',
  },
  error: {
    color: theme.palette.error.main,
  },
  roleCheckBox: {
    padding: 4,
    marginLeft: 12,
  },
  panelHeader: {
    borderBottom: `solid ${theme.palette.primary.main} 2px`,
    padding: theme.spacing(1, 0),
  },
});

function buildResources(profile) {
  if (!profile || !profile.roles) {
    return [];
  }
  return Object.values(roles).map((resource) => ({
    label: resource.label,
    roles: Object.values(resource)
      .filter((role) => typeof role === 'object')
      .map((role) => ({
        code: role.code,
        label: role.label,
        enabled: profile.roles.indexOf(role.code) >= 0,
      })),
  }));
}

function buildRoles(resources) {
  return resources.flatMap((resource) => resource.roles.filter((role) => role.enabled).map((role) => role.code));
}

function buildState({ profile }, defaultState = {}) {
  return {
    ...defaultState,
    profile,
    resources: buildResources(profile),
    hasCheckedRoles: profile.roles.length > 0,
  };
}

class UserProfileDialog extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    profile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = buildState(this.props, { deleting: false });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!props.profileEdit.editing) {
      this.setState(buildState(props));
    }
  }

  onChangeName(name) {
    this.setState(({ profile }) => ({
      profile: {
        ...profile,
        name,
      },
    }));
  }

  onChangeDescription(description) {
    this.setState(({ profile }) => ({
      profile: {
        ...profile,
        description,
      },
    }));
  }

  onChangeSubcontractor = (checked) => {
    this.setState(({ profile }) => ({
      profile: {
        ...profile,
        subcontractorProfile: checked,
      },
    }));
  };

  onChangeRole = (role) => (checked) => {
    this.setState(({ profile, resources: oldResources }) => {
      const resources = oldResources.map((res) => ({
        ...res,
        roles: res.roles.map((r) => ({
          ...r,
          enabled: r.code === role.code ? checked : r.enabled,
        })),
      }));
      const newRoles = buildRoles(resources);
      const hasCheckedRoles = newRoles.length > 0;
      return {
        resources,
        hasCheckedRoles,
        profile: {
          ...profile,
          roles: newRoles,
        },
      };
    });
  };

  onSave() {
    const { dispatch } = this.props;
    const { profile, resources, hasCheckedRoles } = this.state;
    if (!hasCheckedRoles) {
      return;
    }
    const newProfile = {
      ...profile,
      roles: buildRoles(resources),
    };
    dispatch(saveProfile(newProfile));
  }

  onCloseDialog() {
    const { dispatch } = this.props;
    dispatch(editProfileCancel());
  }

  onRequestDelete() {
    this.setState({
      deleting: true,
    });
  }

  onDelete() {
    const { dispatch } = this.props;
    const { profile } = this.state;
    dispatch(deleteProfile(profile));
    this.onCloseDelete();
  }

  onCloseDelete() {
    this.setState({
      deleting: false,
    });
  }

  disableSave() {
    this.setState({
      canSave: false,
    });
  }

  enableSave() {
    this.setState({
      canSave: true,
    });
  }

  render() {
    const {
      profile: { id, name, description, subcontractorProfile },
      resources,
      deleting,
      canSave,
      hasCheckedRoles,
    } = this.state;
    const {
      profileEdit: { open, error },
      currentUser,
      classes,
    } = this.props;
    const readonly = !hasRole(currentUser, roles.profile.edit.code);
    return (
      <>
        <Dialog open={open} onClose={() => this.onCloseDialog()} maxWidth="md">
          <DialogTitle>{id ? 'Fiche du profil' : 'Nouveau profil'}</DialogTitle>
          <DialogContent>
            <Formsy
              onValid={() => this.enableSave()}
              onInvalid={() => this.disableSave()}
              onValidSubmit={() => this.onSave()}
              noValidate
            >
              <Grid container spacing={2}>
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.error}>
                      {error}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    doNotIncludeFormTag
                    label="Nom"
                    fullWidth
                    disabled={readonly}
                    name="name"
                    value={name}
                    required
                    onChange={(e) => this.onChangeName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    doNotIncludeFormTag
                    label="Description"
                    fullWidth
                    disabled={readonly}
                    name="description"
                    value={description}
                    onChange={(e) => this.onChangeDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ToggleSwitch
                    name="subcontractorProfile"
                    label="Utilisable par les administrateurs prestataires"
                    value={subcontractorProfile}
                    disabled={readonly}
                    onChange={this.onChangeSubcontractor}
                    color="primary"
                  />
                </Grid>

                {resources.map((resource) => (
                  <Grid item xs={12} key={resource.label}>
                    <Typography variant="subtitle2" className={classes.panelHeader}>
                      {resource.label}
                    </Typography>
                    <Grid container>
                      {resource.roles.map((role) => (
                        <Grid item xs={6} key={`role_${role.code}`}>
                          <ToggleSwitch
                            label={role.label}
                            value={role.enabled}
                            disabled={readonly}
                            onChange={this.onChangeRole(role)}
                            className={classes.roleCheckBox}
                            color="primary"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Formsy>
          </DialogContent>
          <DialogActions>
            {id && (
              <Secured requiredRole="ROLE_PROFILE_DELETE">
                <Button color="secondary" className={classes.deleteButton} onClick={() => this.onRequestDelete()}>
                  Supprimer
                </Button>
              </Secured>
            )}
            <Button onClick={() => this.onCloseDialog()}>{readonly ? 'Fermer' : 'Annuler'}</Button>
            <Secured requiredRole="ROLE_PROFILE_EDIT">
              <Button color="primary" onClick={() => this.onSave()} disabled={!canSave || !hasCheckedRoles}>
                Enregistrer
              </Button>
            </Secured>
          </DialogActions>
        </Dialog>
        <Dialog open={deleting} onClose={() => this.onCloseDelete()}>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer le profil {name} ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCloseDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const stateToProps = (state) => ({
  profileEdit: state.profileEdit,
  currentUser: state.currentUser,
});

export default compose(withStyles(styles), connect(stateToProps))(UserProfileDialog);
