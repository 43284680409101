import fetch from 'isomorphic-fetch';
import { addGlobalError } from './SnackbarActions';
import { startProgress, stopProgress } from './AppActions';

function saveBlob(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

const responseFileName = (response, defaultFileName = '') => {
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return defaultFileName;
};

// Mutex for big downloads
let downloading = false;

// eslint-disable-next-line import/prefer-default-export
export const downloadUrl = (url, defaultFileName) => (dispatch) => {
  if (downloading) {
    console.warn('Ignoring download as one is already in progress');
    return;
  }
  downloading = true;
  dispatch(startProgress(`GET-${url}`));
  fetch(url, { method: 'GET', credentials: 'same-origin' })
    .then((response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          saveBlob(blob, responseFileName(response, defaultFileName));
        });
      } else if (response.status === 423) {
        dispatch(addGlobalError('Un export est déjà en cours. Veuillez réessayer plus tard.'));
      } else {
        dispatch(addGlobalError('Erreur lors du téléchargement'));
      }
      dispatch(stopProgress(`GET-${url}`));
      downloading = false;
    })
    .catch(() => {
      dispatch(addGlobalError('Erreur lors du téléchargement'));
      dispatch(stopProgress(`GET-${url}`));
      downloading = false;
    });
};
