import { ElementType, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { ChipTypeMap } from '@mui/material/Chip';
import { withFormsy } from 'formsy-react';
import { InjectedProps, WrapperProps } from 'formsy-react/src/withFormsy';
import { computeErrorText, ErrorTextProps } from './errorText';
import buildDebounce from '../../utils/debounce';
import { getNonFormsyProps } from '../../utils/formsy-utils';

import CheckIcon from '../../img/check.svg';

export type AutocompleteOption = {
  id: string;
  name: string;
  icon?: ReactElement;
  isActive?: boolean;
};

type FieldValue<Multiple extends boolean | undefined> = Multiple extends true ? string[] : string | null;

type InnerAutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = {
  label?: string;
  value: FieldValue<Multiple>;
  required?: boolean;
  placeholder?: string;
  getOptionLabel?: (option: T) => string;
  getOptionLabelInInput?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  onChange?: (
    idValue: FieldValue<Multiple>,
    fullValue: AutocompleteValue<T, Multiple, DisableClearable, false>,
  ) => void;
  knownOptions?: T[];
} & ErrorTextProps &
  Omit<
    AutocompleteProps<T, Multiple, DisableClearable, false, ChipComponent>,
    'value' | 'onChange' | 'renderInput' | 'renderOption' | 'renderTags'
  >;

type InnerAsyncAutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<InnerAutocompleteFieldProps<T, Multiple, DisableClearable, ChipComponent>, 'options' | 'onInputChange'> & {
  loadOptions: (inputValue: string) => Promise<T[]>;
};

export type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = InnerAutocompleteFieldProps<T, Multiple, DisableClearable, ChipComponent> & WrapperProps<FieldValue<Multiple>>;

export type AsyncAutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = InnerAsyncAutocompleteFieldProps<T, Multiple, DisableClearable, ChipComponent> & WrapperProps<FieldValue<Multiple>>;

const computeAutocompleteValue = <T extends AutocompleteOption, Multiple extends boolean | undefined = false>(
  value: FieldValue<Multiple>,
  multiple: Multiple,
  options: readonly T[],
  knownOptions: T[],
  getOptionValue: (option: T) => string,
): AutocompleteValue<T, Multiple, any, false> => {
  const allOptions = [...knownOptions, ...options];
  if (multiple) {
    return (value as string[]).map(
      (valueItem) =>
        allOptions.find((option) => getOptionValue(option) === valueItem) ?? ({ id: valueItem, name: valueItem } as T),
    ) as AutocompleteValue<T, Multiple, any, false>;
  } else {
    const stringValue = value as string | null;
    return (
      stringValue
        ? allOptions.find((option) => getOptionValue(option) === stringValue) ??
          ({ id: stringValue, name: stringValue } as T)
        : null
    ) as AutocompleteValue<T, Multiple, any, false>;
  }
};

const AutocompleteField = <
  T extends AutocompleteOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  // AutocompleteField
  label,
  value,
  required,
  placeholder = 'Sélectionner...',
  getOptionLabel = (option) => option.name,
  getOptionLabelInInput,
  getOptionValue = (option) => option.id,
  onChange,
  knownOptions = [],
  displayMultipleErrors,
  // InjectedProps
  errorMessage,
  errorMessages,
  isPristine,
  setValue,
  // Autocomplete
  options,
  multiple,
  disabled,
  ...props
}: InnerAutocompleteFieldProps<T, Multiple, DisableClearable, ChipComponent> & InjectedProps<FieldValue<Multiple>>) => {
  const childProps = getNonFormsyProps(props);
  const autocompleteValue = computeAutocompleteValue(value, multiple, options, knownOptions, getOptionValue);

  const errorText = computeErrorText({ displayMultipleErrors, errorMessages, errorMessage, isPristine });

  const handleChange = (_event: SyntheticEvent, value: AutocompleteValue<T, Multiple, DisableClearable, false>) => {
    let idValue: FieldValue<Multiple>;
    if (multiple) {
      const arrayValue = value as Array<T>;
      idValue = arrayValue.map((v) => getOptionValue(v)) as FieldValue<Multiple>;
    } else {
      const singleValue = value as T | null;
      idValue = (singleValue ? getOptionValue(singleValue) : null) as FieldValue<Multiple>;
    }
    setValue(idValue);
    onChange?.(idValue, value);
  };

  return (
    <Autocomplete
      {...childProps}
      value={autocompleteValue}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value?.length ? undefined : placeholder}
          label={label}
          InputLabelProps={{ shrink: true }}
          error={Boolean(errorText)}
          helperText={errorText}
          required={required}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          sx={{
            ...(option.isActive === false ? { opacity: 0.5 } : {}),
            ...(props['aria-selected']
              ? {
                  backgroundImage: `url(${CheckIcon})`,
                  backgroundPosition: 'center right 12px',
                  backgroundRepeat: 'no-repeat',
                }
              : {}),
          }}
        >
          {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
          <ListItemText>{getOptionLabel(option)}</ListItemText>
        </MenuItem>
      )}
      renderTags={(value: T[], getTagProps) =>
        value.map((option: T, index: number) => {
          const { disabled, ...tagProps } = getTagProps({ index });
          return <Chip icon={option.icon} label={getOptionLabel(option)} {...tagProps} />;
        })
      }
      options={options}
      multiple={multiple}
      disabled={disabled}
      readOnly={disabled}
      getOptionLabel={getOptionLabelInInput ?? getOptionLabel}
      onChange={handleChange}
    />
  );
};

export default withFormsy(AutocompleteField);

const debounce = buildDebounce();

export const AsyncAutocompleteField = withFormsy(
  <
    T extends AutocompleteOption,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
  >({
    loadOptions,
    ...props
  }: InnerAsyncAutocompleteFieldProps<T, Multiple, DisableClearable, ChipComponent> &
    InjectedProps<FieldValue<Multiple>>) => {
    const [options, setOptions] = useState<T[]>([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
      if (!inputValue?.length) {
        setOptions([]);
      } else {
        debounce(async () => {
          setOptions(await loadOptions(inputValue));
        });
      }
    }, [inputValue, loadOptions]);

    return (
      <AutocompleteField
        {...props}
        options={options}
        onInputChange={(_event, value, reason) => {
          if (reason === 'input') {
            setInputValue(value);
          }
        }}
        clearOnBlur={false}
        onBlur={() => {
          setInputValue('');
        }}
      />
    );
  },
);
