/* eslint-disable no-nested-ternary */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, IconButton, Link, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import debugFactory from 'debug';

import { updateOperationCompletedQuantity, updateOperationQuantity } from '../../actions/InterventionOperationsActions';
import { operationName, transformOperationToEquipmentsWithSerialNumbers } from '../../utils/operation-utils';
import SerialNumbersList from './serial-numbers/SerialNumbersList';

const debug = debugFactory('prestago:InterventionOperation');

const styles = (theme) => ({
  narrow: {
    width: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  quantityLink: {
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
  quantityInput: {
    minWidth: 35,
    width: 40,
  },
  interventionOrigin: {
    color: theme.palette.prestago.operation.intervention,
  },
  serialFiller: {
    display: 'none',
  },
  serialCell: {
    paddingBottom: theme.spacing(2),
    '&:first-of-type': {
      paddingLeft: 64,
      paddingRight: 72,
    },
  },
});

class InterventionOperation extends React.Component {
  static propTypes = {
    completionStatusCanBeToggled: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    interventionId: PropTypes.string.isRequired,
    operation: PropTypes.object.isRequired,
    showOracleStatus: PropTypes.bool,
    oracleStatus: PropTypes.object,
    onOpenCommandDetails: PropTypes.func,
    updateTabsHeight: PropTypes.func,
  };

  state = {
    editingCompletedQuantity: false,
    completedQuantity: '',
    completedQuantityError: '',
    editingQuantity: false,
    quantity: '',
    quantityError: '',
  };

  onCompletedChange = (event, checked) => {
    debug('onCompletedChange');
    const { dispatch, interventionId, operation } = this.props;

    if (parseInt(operation.quantity, 10) === 1) {
      // Check or uncheck
      dispatch(updateOperationCompletedQuantity(interventionId, operation, checked ? 1 : 0));
    } else {
      // Display the completed quantity field
      this.setState({
        editingCompletedQuantity: true,
        completedQuantity: this.props.operation.completedQuantity || '',
        completedQuantityError: '',
      });
    }
  };

  onCompletedQuantityCancel = () => {
    this.setState({ editingCompletedQuantity: false });
  };

  onCompletedQuantityChange = (e) => {
    const completedQuantity = e.target.value;
    const completedQuantityError = this.validateCompletedQuantity(completedQuantity);
    this.setState({
      completedQuantity,
      completedQuantityError,
    });
  };

  onCompletedQuantityKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Enter
      this.onCompletedQuantitySubmit();
    } else if (e.keyCode === 27) {
      // Escape
      this.onCompletedQuantityCancel();
    }
  };

  onCompletedQuantitySubmit = () => {
    debug('onCompletedQuantitySubmit');
    const { dispatch, interventionId, operation } = this.props;
    const { completedQuantity } = this.state;
    const completedQuantityError = this.validateCompletedQuantity(completedQuantity);
    if (completedQuantityError) {
      this.setState({ completedQuantityError });
      return;
    }

    // eslint-disable-next-line eqeqeq
    if (completedQuantity != operation.completedQuantity) {
      dispatch(updateOperationCompletedQuantity(interventionId, operation, parseInt(completedQuantity, 10)));
    }
    this.onCompletedQuantityCancel();
  };

  onCompletedQuantityFocus = () => {
    debug('onCompletedQuantityFocus');
    this.completedQuantityIsFocused = true;
  };

  onCompletedQuantityBlur = () => {
    debug('onCompletedQuantityBlur');
    if (this.completedQuantityIsFocused) {
      this.onCompletedQuantitySubmit();
    }
    this.completedQuantityIsFocused = false;
  };

  onQuantityEdit = () => {
    debug('onQuantityEdit');
    this.setState({
      editingQuantity: true,
      quantity: this.props.operation.quantity,
      quantityError: '',
    });
  };

  onQuantityCancel = () => {
    this.setState({ editingQuantity: false });
  };

  onQuantityChange = (e) => {
    const quantity = e.target.value;
    const quantityError = this.validateQuantity(quantity);
    this.setState({
      quantity,
      quantityError,
    });
  };

  onQuantityKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Enter
      this.onQuantitySubmit();
    } else if (e.keyCode === 27) {
      // Escape
      this.onQuantityCancel();
    }
  };

  onQuantitySubmit = () => {
    debug('onQuantitySubmit');
    const { dispatch, interventionId, operation } = this.props;
    const { quantity } = this.state;
    const quantityError = this.validateQuantity(quantity);
    if (quantityError) {
      this.setState({ quantityError });
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (quantity != operation.quantity) {
      dispatch(updateOperationQuantity(interventionId, operation, parseInt(quantity, 10)));
    }
    this.onQuantityCancel();
  };

  onQuantityFocus = () => {
    debug('onQuantityFocus');
    this.quantityIsFocused = true;
  };

  onQuantityBlur = () => {
    debug('onQuantityBlur');
    if (this.quantityIsFocused) {
      this.onQuantitySubmit();
    }
    this.quantityIsFocused = false;
  };

  validateCompletedQuantity = (completedQuantity) => {
    const {
      operation: { quantity },
    } = this.props;
    if (Number.isNaN(completedQuantity) || !Number.isInteger(parseFloat(completedQuantity))) {
      return 'Quantité invalide';
    }
    const completedQuantityInt = parseInt(completedQuantity, 10);
    if (completedQuantityInt < 0) {
      return `Min : ${0}`;
    }
    if (completedQuantityInt > quantity) {
      return `Max : ${quantity}`;
    }
    return '';
  };

  validateQuantity = (quantity) => {
    const {
      operation: { quantity: minQuantity, maxQuantity },
    } = this.props;
    if (Number.isNaN(quantity) || !Number.isInteger(parseFloat(quantity))) {
      return 'Quantité invalide';
    }
    const quantityInt = parseInt(quantity, 10);
    if (quantityInt < minQuantity) {
      return `Min : ${minQuantity}`;
    }
    if (maxQuantity && quantityInt > maxQuantity) {
      return `Max : ${maxQuantity}`;
    }
    return '';
  };

  render() {
    const {
      operation,
      completionStatusCanBeToggled,
      canEdit,
      showOracleStatus,
      oracleStatus,
      onOpenCommandDetails,
      classes,
      updateTabsHeight,
    } = this.props;
    const {
      editingCompletedQuantity,
      completedQuantity,
      completedQuantityError,
      editingQuantity,
      quantity,
      quantityError,
    } = this.state;
    const name = operationName(operation);

    const canEditQuantity = canEdit && operation.origin === 'INTERVENTION';
    const equipmentsWithSerialNumbers = transformOperationToEquipmentsWithSerialNumbers(operation);
    return (
      <>
        <TableRow>
          <TableCell className={classes.narrow}>
            {editingQuantity ? (
              <TextField
                errorText={quantityError}
                autoFocus
                name="quantity"
                type="number"
                min={operation.quantity}
                max={operation.maxQuantity}
                value={quantity}
                className={classes.quantityInput}
                onKeyDown={this.onQuantityKeyDown}
                onChange={this.onQuantityChange}
                onFocus={this.onQuantityFocus}
                onBlur={this.onQuantityBlur}
              />
            ) : canEditQuantity ? (
              <Tooltip title="Modifier la quantité planifiée">
                <Link component="button" onClick={this.onQuantityEdit} className={classes.quantityLink}>
                  {operation.quantity || ''}
                </Link>
              </Tooltip>
            ) : (
              operation.quantity || ''
            )}
          </TableCell>
          <TableCell
            className={classNames({
              [classes.interventionOrigin]: operation.origin === 'INTERVENTION',
            })}
          >
            {name}
          </TableCell>
          {showOracleStatus && (
            <TableCell className={classes.narrow}>
              {oracleStatus && (
                <Tooltip title="Voir le détail des livraisons">
                  <IconButton onClick={() => onOpenCommandDetails(operation.selectedEquipmentId, name)} size="large">
                    {oracleStatus.icon}
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          )}
          <TableCell className={classNames(classes.narrow, editingCompletedQuantity && 'editing')} align="center">
            {editingCompletedQuantity ? (
              <TextField
                error={Boolean(completedQuantityError)}
                helperText={completedQuantityError}
                autoFocus
                name="completedQuantity"
                type="number"
                min="0"
                max={operation.quantity}
                value={completedQuantity}
                className={classes.quantityInput}
                onKeyDown={this.onCompletedQuantityKeyDown}
                onChange={this.onCompletedQuantityChange}
                onFocus={this.onCompletedQuantityFocus}
                onBlur={this.onCompletedQuantityBlur}
              />
            ) : operation.completedQuantity <= 0 || operation.completedQuantity >= operation.quantity ? (
              <Checkbox
                id={operation.selectedEquipmentId}
                disabled={!completionStatusCanBeToggled}
                checked={operation.completedQuantity >= operation.quantity}
                onChange={this.onCompletedChange}
                color="primary"
              />
            ) : completionStatusCanBeToggled ? (
              <Tooltip title="Modifier la quantité faite">
                <Link component="button" onClick={() => this.onCompletedChange(true)} className={classes.quantityLink}>
                  {operation.completedQuantity} / {operation.quantity}
                </Link>
              </Tooltip>
            ) : (
              `${operation.completedQuantity} / ${operation.quantity}`
            )}
          </TableCell>
        </TableRow>
        {Boolean(equipmentsWithSerialNumbers.length) && (
          <>
            <TableRow className={classes.serialFiller} />
            <TableRow>
              <TableCell colSpan={showOracleStatus ? 4 : 3} className={classes.serialCell}>
                <SerialNumbersList
                  canEdit={canEdit}
                  equipments={equipmentsWithSerialNumbers}
                  type={operation.type}
                  constraints={equipmentsWithSerialNumbers.serialNumberConstraints}
                  updateTabsHeight={updateTabsHeight}
                />
              </TableCell>
            </TableRow>
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles)(InterventionOperation);
