import React from 'react';
import { useParams } from 'react-router-dom';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';

import OperationBundle from '../../operations/OperationBundle';

const OperationBundlePage = () => {
  const { bundleId } = useParams();
  return <OperationBundle bundleId={bundleId} />;
};

export default securedPage(roles.equipment.view.code)(OperationBundlePage);
