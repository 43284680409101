import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import BarcodeScanner from './BarcodeScanner';

type SerialNumberBarcodeScannerProps = {
  open: boolean;
  onDetected: (value: string) => void;
  onCancel?: () => void;
};

const SerialNumberBarcodeScanner: FC<SerialNumberBarcodeScannerProps> = ({ open, onDetected, onCancel }) => {
  const [value, setValue] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onCancel}>
      <DialogContent>
        {value ? (
          <DialogContentText>
            Code barre détecté : <span>{value}</span>
          </DialogContentText>
        ) : (
          <DialogContentText>Aucun code barre détecté</DialogContentText>
        )}
        <BarcodeScanner onDetected={setValue} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Annuler</Button>
        <Button variant="contained" color="primary" onClick={() => onDetected(value)} disabled={!value}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SerialNumberBarcodeScanner;
