import {
  STOCK_EQUIPMENT_ERROR,
  STOCK_EQUIPMENT_LOADED,
  STOCK_EQUIPMENT_LOADING,
  STOCK_EQUIPMENT_REFRESH_ERROR,
} from '../constants/AppConstants';
import { StockEquipment } from '../model/model';
import { AnyAction } from 'redux';

type StockEquipmentsState = {
  stockOutletCode?: string;
  stockEquipments: StockEquipment[];
  loading: boolean;
  error: any;
};

const initialState: StockEquipmentsState = {
  stockEquipments: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action: AnyAction): StockEquipmentsState {
  switch (action.type) {
    case STOCK_EQUIPMENT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        stockOutletCode: action.stockOutletCode,
        stockEquipments: [],
      };
    case STOCK_EQUIPMENT_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        stockOutletCode: action.stockOutletCode,
        stockEquipments: action.stockEquipments,
      };
    case STOCK_EQUIPMENT_ERROR:
    case STOCK_EQUIPMENT_REFRESH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
