import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from '@mui/material';

import { INTERVENTION_TRANSITIONS } from '../../constants/interventionTransitions';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import InterventionTransition from './transitions/InterventionTransition';
import { hasMissingSerialNumbers, hasCompletedOperations } from '../../utils/operation-utils';
import { FormActions } from '../utils';

const InterventionsButtons = ({
  attachments,
  canCreateInterventions,
  demandId,
  dispatch,
  hasAnyMissingSerialNumbers,
  hasMissingSignature,
  hasStartedAndFinishedDates,
  hasCompletedOps,
  needsDeliveryDetails,
  numberOfInterventions,
  interventionId,
  transitions,
  operationScheduleDate,
  operationScheduleDayTime,
  isAgency,
}) => {
  if (transitions.length === 0) {
    return null;
  }

  const leftActions = [];
  const rightActions = [];

  transitions.forEach((transition) =>
    (transition.left ? leftActions : rightActions).push(
      <InterventionTransition
        key={transition.id}
        canCreateInterventions={canCreateInterventions}
        demandId={demandId}
        dispatch={dispatch}
        hasMissingSerialNumbers={hasAnyMissingSerialNumbers}
        hasCompletedOperations={hasCompletedOps}
        hasMissingSignature={hasMissingSignature}
        hasStartedAndFinishedDates={hasStartedAndFinishedDates}
        needsDeliveryDetails={needsDeliveryDetails}
        numberOfInterventions={numberOfInterventions}
        interventionId={interventionId}
        numberOfAttachments={attachments.length}
        transition={transition}
        operationScheduleDate={operationScheduleDate}
        operationScheduleDayTime={operationScheduleDayTime}
        isAgency={isAgency}
      />,
    ),
  );

  return (
    <Card>
      <FormActions leftActions={leftActions} rightActions={rightActions} />
    </Card>
  );
};

InterventionsButtons.propTypes = {
  attachments: PropTypes.array,
  demandId: PropTypes.string.isRequired,
  hasAnyMissingSerialNumbers: PropTypes.bool,
  hasCompletedOps: PropTypes.bool,
  needsDeliveryDetails: PropTypes.bool.isRequired,
  numberOfInterventions: PropTypes.number,
  interventionId: PropTypes.string.isRequired,
  transitions: PropTypes.array,
  operationScheduleDate: PropTypes.any,
  operationScheduleDayTime: PropTypes.any,
  operationStartedDate: PropTypes.any,
  operationFinishedDate: PropTypes.any,
  isAgency: PropTypes.bool,
};

function availableTransitions(currentUser, interventionStatus) {
  return INTERVENTION_TRANSITIONS.filter(
    (transition) => transition.from.includes(interventionStatus) && hasRole(currentUser, transition.role.code),
  );
}

/**
 * Returns a boolean indicating whether or not the intervention has missing serial numbers
 * for operations that are completed.
 */
function missingSerialNumbers({ addOperations, removeOperations, moveOperations }) {
  return (
    hasMissingSerialNumbers(addOperations || []) ||
    hasMissingSerialNumbers(removeOperations || []) ||
    hasMissingSerialNumbers(moveOperations || [])
  );
}

function missingSignature({ outletSignature, subcontractorSignature }) {
  return !outletSignature && !subcontractorSignature;
}

function hasStartedAndFinishedDates({ operationStartedDate, operationFinishedDate }) {
  return Boolean(operationStartedDate && operationFinishedDate);
}

/**
 * Returns a boolean indicating whether or not the intervention has at least one completed operation.
 */
function completedOperations({ addOperations, removeOperations, moveOperations }) {
  return (
    hasCompletedOperations(addOperations || []) ||
    hasCompletedOperations(removeOperations || []) ||
    hasCompletedOperations(moveOperations || [])
  );
}

function canUserCreateInterventions(currentUser) {
  return hasRole(currentUser, roles.intervention.create.code);
}

const stateToProps = ({ interventionAttachments, currentUser }, ownProps) => ({
  attachments: Object.values(interventionAttachments),
  canCreateInterventions: canUserCreateInterventions(currentUser),
  hasAnyMissingSerialNumbers: missingSerialNumbers(ownProps),
  hasMissingSignature: missingSignature(ownProps),
  hasStartedAndFinishedDates: hasStartedAndFinishedDates(ownProps),
  hasCompletedOps: completedOperations(ownProps),
  transitions: availableTransitions(currentUser, ownProps.interventionStatus),
});

export default connect(stateToProps)(InterventionsButtons);
