import React from 'react';
import { compose } from 'recompose';

import DemandImport from '../../demands/import/DemandImport';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { urls } from '../../../constants/AppConstants';
import { withPageTitle } from '../../../utils/page-title';

const DemandImportPage = () => (
  <DemandImport
    title="Importer des demandes"
    resultIntro={(nb) => `${nb} demande${nb > 1 ? 's' : ''} importée${nb > 1 ? 's' : ''}.`}
    url={urls.demandImport}
  />
);

export default compose(
  withPageTitle(() => 'Import de demandes'),
  securedPage(roles.demand.import.code),
)(DemandImportPage);
