/**
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Route, Routes } from 'react-router-dom';

import GlobalSnackbar from './layout/GlobalSnackbar';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import Main from './layout/Main';
import theme from './theme';

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/password" element={<ChangePasswordPage />} />
            <Route path="*" element={<Main />} />
          </Routes>
          <GlobalSnackbar />
        </>
      </ThemeProvider>
    </StyledEngineProvider>
  </LocalizationProvider>
);

export default App;
