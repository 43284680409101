import queryString from 'query-string';

import { DAY_ACTIVITY_FILTER_UPDATED, DAY_ACTIVITY_LOADED, urls } from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { paginationToParams, paramsToPagination } from '../utils/paging';
import history from '../history';
import { todayDateRange } from '../utils/date-utils';

const dayActivityCollection = api.all(urls.dayActivity);

function dayActivityFilterUpdated(filter) {
  return {
    type: DAY_ACTIVITY_FILTER_UPDATED,
    filter,
  };
}

export function updateDayActivityFilter(filter) {
  history.push({
    search: queryString.stringify(paginationToParams({ filter })),
  });
}

export function loadDayActivity(query) {
  return (dispatch) => {
    const { filter } = paramsToPagination(query);
    dispatch(dayActivityFilterUpdated(filter));

    if (!filter.scheduledDateRange) {
      filter.scheduledDateRange = todayDateRange();
    }

    dayActivityCollection
      .getAll(paginationToParams({ filter }))
      .then((response) => {
        const data = response.body(false);
        // keep filters
        dispatch({
          type: DAY_ACTIVITY_LOADED,
          data,
        });
      })
      .catch(ignore401);
  };
}
