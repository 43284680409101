import React from 'react';
import { useParams } from 'react-router-dom';

import OperationOption from '../../operations/options/OperationOption';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';

const OperationOptionPage = () => {
  const { optionId } = useParams();
  return <OperationOption optionId={optionId} />;
};

export default securedPage(roles.equipment.view.code)(OperationOptionPage);
