import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Snackbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { dismissGlobalMessage } from '../../actions/SnackbarActions';

const styles = (theme) => ({
  error: {
    backgroundColor: theme.palette.secondary.main,
  },
});

class GlobalSnackbar extends Component {
  static propTypes = {
    autoHideDuration: PropTypes.number,
  };

  static defaultProps = {
    autoHideDuration: 10000,
  };

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(dismissGlobalMessage());
  };

  render() {
    const {
      globalMessage: { open, message, level },
      autoHideDuration,
      classes,
    } = this.props;
    return (
      <Snackbar
        open={open}
        message={message}
        autoHideDuration={autoHideDuration}
        onClose={this.onClose}
        ContentProps={{
          className: classes[level],
        }}
      />
    );
  }
}

const stateToProps = (state) => ({
  globalMessage: state.globalMessage,
});
export default compose(connect(stateToProps), withStyles(styles))(GlobalSnackbar);
