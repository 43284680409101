import React from 'react';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import { useParams } from 'react-router-dom';
import StockEquipmentRemovalPreparation from '../../stock-equipments/StockEquipmentRemovalPreparation';

const StockEquipmentRemovalPreparationPage = () => {
  usePageTitle('Préparation de sortie de stock');
  const { stockOutletCode, batchId } = useParams();

  return <StockEquipmentRemovalPreparation stockOutletCode={stockOutletCode} batchId={batchId} />;
};

export default securedPage(roles.stockEquipments.edit.code)(StockEquipmentRemovalPreparationPage);
