import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { CardHeader, ResponsiveTable } from '../utils';
import { loadRegions, addRegion } from '../../actions/settings/RegionActions';
import { sort, SortDirections } from '../../utils/sorting';
import RegionRow from './RegionRow';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

class RegionsTable extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadRegions());
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addRegion());
  };

  render() {
    // From store
    const { regions, showNew, currentUser } = this.props;
    const editable = hasRole(currentUser, roles.configuration.edit.code);

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter une région"
          addRole={roles.configuration.edit.code}
          onAdd={this.onAddClick}
          addDisabled={showNew}
        >
          Paramétrage des régions
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {regions.map((r) => (
              <RegionRow key={r.id} region={r} editing={Boolean(r.editing)} editable={editable} />
            ))}
          </TableBody>
        </ResponsiveTable>
      </Card>
    );
  }
}

const emptyRegion = {
  id: null,
  name: null,
  editing: true,
};

const sortedRegionsWithNew = (regions) => {
  const sortedRegions = sort(regions, [{ field: 'name', direction: SortDirections.asc }]);
  if (!regions.showNew) {
    return sortedRegions;
  }
  return [emptyRegion, ...sortedRegions];
};

const stateToProps = ({ regions, currentUser }) => ({
  regions: sortedRegionsWithNew(regions),
  showNew: Boolean(regions.showNew),
  currentUser,
});

export default connect(stateToProps)(RegionsTable);
