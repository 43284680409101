import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import DemandTypesTable from '../../settings/DemandTypesTable';
import { withPageTitle } from '../../../utils/page-title';

const DemandTypesPage = () => <DemandTypesTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des types de demandes'),
  securedPage(roles.equipment.view.code),
)(DemandTypesPage);
