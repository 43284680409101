import {
  INTERVENTION_LOADING,
  INTERVENTION_LOADED,
  INTERVENTION_SAVED,
  INTERVENTION_RESET,
  INTERVENTION_ERROR,
  INTERVENTION_COMMAND_ADDED,
  INTERVENTION_COMMAND_UPDATED,
  INTERVENTION_SIGNATURE_OUTLET,
} from '../constants/AppConstants';

const emptyIntervention = {
  subcontractorReference: '',
  comment: '',
  oracleCommands: [],
};

const initialState = {
  intervention: emptyIntervention,
  counter: 0,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INTERVENTION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case INTERVENTION_LOADED:
      return {
        ...state,
        intervention: action.intervention,
        counter: state.counter + 1,
        loading: false,
        error: null,
      };
    case INTERVENTION_SAVED:
      return {
        ...state,
        intervention: {
          ...state.intervention,
          ...action.intervention,
        },
        counter: state.counter + 1,
        loading: false,
        error: null,
      };
    case INTERVENTION_RESET:
      return {
        ...state,
        intervention: emptyIntervention,
        counter: 0,
        loading: false,
        error: null,
      };
    case INTERVENTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.err,
      };
    case INTERVENTION_COMMAND_ADDED:
      return {
        ...state,
        intervention: {
          ...state.intervention,
          oracleCommands: [...(state.intervention.oracleCommands || []), action.command],
        },
      };
    case INTERVENTION_COMMAND_UPDATED:
      return {
        ...state,
        intervention: {
          ...state.intervention,
          oracleCommands: state.intervention.oracleCommands.map((command) =>
            command.index === action.command.index ? { ...command, ...action.command } : command,
          ),
        },
      };
    case INTERVENTION_SIGNATURE_OUTLET:
      return {
        ...state,
        intervention: {
          ...state.intervention,
          rating: action.rating,
        },
      };
    default:
      return state;
  }
}
