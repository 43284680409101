/**
 * Component that encapsulates a page. It checks that a user is connected, and if not redirects to the login page.
 */
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import debugFactory from 'debug';

import ForbiddenPage from './pages/ForbiddenPage';
import { hasAnyRole, hasRole, isLoggedIn } from '../services/SecurityService';

const debug = debugFactory('prestago:SecuredPage');

const securedPage =
  <P,>(requiredRole?: string, alternativeRole?: string) =>
  (ComposedComponent: ComponentType<P>) =>
  (props: P) => {
    const location = useLocation();
    const currentUser = useSelector(({ currentUser }) => currentUser);

    if (!isLoggedIn(currentUser)) {
      debug('SecuredPage - redirecting to login page');
      return (
        <Navigate
          to={{
            pathname: '/login',
            search: queryString.stringify({
              callback: location.pathname + location.search,
            }),
          }}
        />
      );
    }
    if (requiredRole) {
      if (alternativeRole) {
        if (!hasAnyRole(currentUser, [requiredRole, alternativeRole])) {
          debug('SecuredPage - missing role(s) %s %s', requiredRole, alternativeRole);
          return <ForbiddenPage />;
        }
      } else if (!hasRole(currentUser, requiredRole)) {
        debug('SecuredPage - missing role %s', requiredRole);
        return <ForbiddenPage />;
      }
    }

    return <ComposedComponent {...props} />;
  };

export default securedPage;
