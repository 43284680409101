import * as Sentry from '@sentry/browser';
import { api } from '../services/RestService';
import { urls } from '../constants/AppConstants';

const sentryQuery = api.custom(urls.sentry);

export default () => {
  const release = process.env.REACT_APP_APP_VERSION || 'dev';

  sentryQuery.get().then((response) => {
    const { publicDSN, environment } = response.body(false);
    if (publicDSN) {
      Sentry.init({
        dsn: publicDSN,
        environment,
        release,
      });
      console.warn(`Sentry configured to ${publicDSN}`);
    } else {
      console.warn('Sentry not configured : config URL is empty');
    }
  });
};
