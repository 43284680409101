import {
  DEMAND_TYPES_LOADED,
  DEMAND_TYPE_ADDED,
  DEMAND_TYPE_SAVED,
  DEMAND_TYPE_DELETED,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError } from './SnackbarActions';

const demandTypesCollection = api.all(urls.demandTypes);
demandTypesCollection.isFetchNeeded = true;

export function loadDemandTypes() {
  return (dispatch) => {
    demandTypesCollection
      .getAll()
      .then((response) => {
        const list = response.body(false);
        if (list && list.length) {
          demandTypesCollection.isFetchNeeded = false;
        }
        dispatch({
          type: DEMAND_TYPES_LOADED,
          list,
        });
      })
      .catch(ignore401);
  };
}

export function loadDemandTypesIfNeeded() {
  return (dispatch) => {
    if (demandTypesCollection.isFetchNeeded) {
      loadDemandTypes()(dispatch);
    }
  };
}

export function addDemandType() {
  return {
    type: DEMAND_TYPE_ADDED,
  };
}

export function saveDemandType(demandType) {
  return (dispatch) => {
    const creation = !demandType.id;
    const saveRequest = creation
      ? demandTypesCollection.post(demandType)
      : demandTypesCollection.put(demandType.id, demandType);

    saveRequest
      .then((response) => {
        let newDemandType = demandType;
        if (creation) {
          newDemandType = response.body(false);
        }
        dispatch({
          type: DEMAND_TYPE_SAVED,
          item: {
            ...newDemandType,
            editing: false,
          },
          creation,
        });
      })
      .catch(ignore401);
  };
}

export function updateDemandTypePosition(id, position) {
  return (dispatch) => {
    demandTypesCollection
      .patch(id, { position })
      .then(() => {
        dispatch(loadDemandTypes());
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while updating position:', error);
        dispatch(addGlobalError("Une erreur a eu lieu pendant l'enregistrement des données"));
      });
  };
}

export function deleteDemandType(demandType) {
  return (dispatch) => {
    if (demandType.id) {
      demandTypesCollection
        .delete(demandType.id)
        .then(() => {
          dispatch({
            type: DEMAND_TYPE_DELETED,
            id: demandType.id,
          });
        })
        .catch(ignore401)
        .catch((err) => {
          console.error('Error while deleting demand type:', err);
          if (err.response && err.response.data && err.response.statusCode === 400) {
            dispatch(addGlobalError(err.response.data.error));
          } else {
            dispatch(addGlobalError('Erreur lors de la suppression'));
          }
        });
    } else {
      dispatch({
        type: DEMAND_TYPE_DELETED,
        id: null,
      });
    }
  };
}
