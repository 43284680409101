import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import { Card } from '@mui/material';
import StockEquipmentOutletsTable from '../../stock-equipments/StockEquipmentOutletsTable';
import { useAppSelector } from '../../../hooks';
import { isSubcontractor } from '../../../services/SecurityService';
import { CardHeader } from '../../utils';
import Hidden from '@mui/material/Hidden';
import { COMPANIES } from '../../../constants/AppConstants';
import { Navigate } from 'react-router-dom';

const StocksPage = () => {
  usePageTitle('Stocks prestataires');
  const isUserSubcontractor = useAppSelector(({ currentUser }) => isSubcontractor(currentUser));
  const stockOutletCode = useAppSelector(
    ({ currentUser }) => COMPANIES.getById(currentUser.user.company)?.stockOutletCode,
  );

  if (isUserSubcontractor) {
    return <Navigate to={`/stock-equipments/${stockOutletCode}`} replace={true} />;
  }

  return (
    <Card>
      <CardHeader actions={<Hidden mdDown></Hidden>}>Stocks de matériels</CardHeader>
      <StockEquipmentOutletsTable />
    </Card>
  );
};

export default securedPage(roles.stockEquipments.view.code)(StocksPage);
