import {
  AGENCIES_LOADED,
  AGENCIES_LIST_SORT,
  AGENCY_ADDED,
  AGENCY_SAVED,
  AGENCY_DELETED,
  getByIdDecorator,
} from '../../constants/AppConstants';
import { computeSorts, SortDirections } from '../../utils/sorting';

const initialState = {
  list: getByIdDecorator([]),
  sorts: [
    { field: 'name', direction: SortDirections.asc },
    { field: 'region.name', direction: SortDirections.asc },
  ],
  showNew: false,
};

function updateList(list, newAgency) {
  return list.map((agency) => (agency.id === newAgency.id ? newAgency : agency));
}

function deleteInList(list, agencyId) {
  return list.filter((agency) => agency.id !== agencyId);
}

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case AGENCIES_LOADED:
      return {
        ...state,
        list: getByIdDecorator(action.agencies),
      };
    case AGENCY_ADDED:
      return {
        ...state,
        showNew: true,
      };
    case AGENCY_SAVED:
      if (action.creation) {
        return {
          ...state,
          list: getByIdDecorator([...state.list, action.agency]),
          showNew: false,
        };
      }
      return {
        ...state,
        list: getByIdDecorator(updateList(state.list, action.agency)),
      };
    case AGENCY_DELETED:
      if (!action.id) {
        return {
          ...state,
          showNew: false,
        };
      }
      return {
        ...state,
        list: getByIdDecorator(deleteInList(state.list, action.id)),
      };
    case AGENCIES_LIST_SORT:
      return {
        ...state,
        sorts: computeSorts(state.sorts, action.field, action.direction),
      };
    default:
      return state;
  }
}
