import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import debugFactory from 'debug';
import withStyles from '@mui/styles/withStyles';

const debug = debugFactory('prestago:DrawingCanvas');

const styles = {
  root: {
    border: '1px solid black',
    touchAction: 'none',
  },
};

class DrawingCanvas extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    onChange: PropTypes.func,
    ref: PropTypes.shape({ current: PropTypes.object }),
  };

  static defaultProps = {
    width: 400,
    height: 400,
  };

  drawing = false;

  mousePos = { x: 0, y: 0 };

  onStartDrawing = (clientX, clientY) => {
    this.mousePos = this.getMousePos(clientX, clientY);
    debug('onStartDrawing', this.mousePos);
    this.drawing = true;
  };

  onStopDrawing = () => {
    const { onChange } = this.props;
    debug('onStopDrawing');
    this.drawing = false;
    if (onChange) {
      onChange(this.canvas.toDataURL());
    }
  };

  onPauseDrawing = () => {
    debug('onStopDrawing');
    this.mousePos = null;
  };

  onDraw = (clientX, clientY) => {
    if (!this.mousePos) {
      // Un-pause
      this.mousePos = this.getMousePos(clientX, clientY);
    } else if (this.drawing) {
      const newPos = this.getMousePos(clientX, clientY);
      debug('onDraw', newPos);
      this.ctx.beginPath();
      this.ctx.moveTo(this.mousePos.x, this.mousePos.y);
      this.ctx.lineTo(newPos.x, newPos.y);
      this.ctx.stroke();
      this.ctx.closePath();
      this.mousePos = newPos;
    }
  };

  getMousePos = (clientX, clientY) => {
    const rect = this.canvas.getBoundingClientRect();
    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  };

  resetDrawing = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  };

  initCanvas = (canvas) => {
    if (canvas) {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d');
      this.ctx.strokeStyle = 'black';
      this.ctx.lineWidth = 2;
    }
  };

  render() {
    const { width, height, classes } = this.props;
    return (
      <canvas
        ref={this.initCanvas}
        width={width}
        height={height}
        className={classes.root}
        onMouseDown={(e) => this.onStartDrawing(e.clientX, e.clientY)}
        onMouseUp={this.onStopDrawing}
        onMouseOut={this.onPauseDrawing}
        onBlur={this.onPauseDrawing}
        onMouseMove={(e) => this.onDraw(e.clientX, e.clientY)}
        onTouchStart={(e) => {
          e.preventDefault();
          this.onStartDrawing(e.touches[0].clientX, e.touches[0].clientY);
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          this.onStopDrawing();
        }}
        onTouchMove={(e) => {
          e.preventDefault();
          this.onDraw(e.touches[0].clientX, e.touches[0].clientY);
        }}
      />
    );
  }
}

export default withStyles(styles)(DrawingCanvas);
