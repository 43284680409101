import {
  AREAS_LOADED,
  AREA_ADDED,
  AREA_SAVED,
  AREA_DELETED,
  AREAS_LIST_SORT,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalMessage } from '../SnackbarActions';

const areasCollection = api.all(urls.settings.areas);

function areasLoaded(areas) {
  return {
    type: AREAS_LOADED,
    areas,
  };
}

function areaAdded() {
  return {
    type: AREA_ADDED,
  };
}

function areaSaved(area, creation) {
  return {
    type: AREA_SAVED,
    area,
    creation,
  };
}

function areaDeleted(id) {
  return {
    type: AREA_DELETED,
    id,
  };
}

export function sortAreas(field, direction) {
  return {
    type: AREAS_LIST_SORT,
    field,
    direction,
  };
}

export function addArea() {
  return areaAdded();
}

export function loadAreas() {
  return (dispatch) => {
    areasCollection
      .getAll()
      .then((response) => {
        const areas = response.body(false);
        dispatch(areasLoaded(areas));
      })
      .catch(ignore401);
  };
}

export function saveArea(area) {
  return (dispatch) => {
    const creation = !area.id;
    const saveRequest = creation ? areasCollection.post(area) : areasCollection.put(area.id, area);

    saveRequest
      .then((response) => {
        let newArea = area;
        if (creation) {
          newArea = response.body(false);
        }
        dispatch(
          areaSaved(
            {
              ...newArea,
              editing: false,
            },
            creation,
          ),
        );
        dispatch(addGlobalMessage('Modifications sauvegardées'));
      })
      .catch(ignore401);
  };
}

export function deleteArea(area) {
  return (dispatch) => {
    if (area.id) {
      areasCollection
        .delete(area.id, area)
        .then(() => {
          dispatch(areaDeleted(area.id));
        })
        .catch(ignore401);
    } else {
      dispatch(areaDeleted(null));
    }
  };
}
