import debugFactory from 'debug';

import {
  DEMAND_ATTACHMENTS_LIST,
  DEMAND_ATTACHMENTS_ITEM,
  DEMAND_ATTACHMENTS_DELETE,
  apiBase,
  urls,
} from '../constants/AppConstants';
import httpService from '../services/HttpService';
import { addGlobalError } from './SnackbarActions';
import { getErrorMessage } from '../services/ErrorMessageService';
import { escapeFileName } from '../utils/attachment-utils';

const debug = debugFactory('prestago:DemandAttachmentsActions');

function attachmentUrl(demandId, attachmentId, fileName) {
  return `${apiBase}/${urls.demands}/${demandId}/attachments${
    attachmentId && fileName ? `/${attachmentId}/${escapeFileName(fileName)}` : ''
  }`;
}

export function replaceAttachments(attachments) {
  debug('replaceAttachments', attachments);
  return {
    type: DEMAND_ATTACHMENTS_LIST,
    attachments,
  };
}

export function deleteAttachment(attachmentId, attachmentName) {
  debug('deleteAttachment %s %s', attachmentId, attachmentName);
  return (dispatch, getState) => {
    const { demand } = getState();

    // Update the list
    dispatch({
      type: DEMAND_ATTACHMENTS_DELETE,
      id: attachmentId,
      name: attachmentName,
    });

    // Actually call the DELETE WS
    httpService
      .delete({
        url: attachmentUrl(demand.demand.id, attachmentId, attachmentName),
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response;
      })
      .catch((err) => {
        console.error('Error while deleting the attachment:', err);
        dispatch(addGlobalError(getErrorMessage(err)));
      });
  };
}

export function addAttachments(acceptedFiles, rejectedFiles) {
  return (dispatch, getState) => {
    const { demandAttachments, demand } = getState();
    debug('addAttachments %o, %o, %o, %o', acceptedFiles, rejectedFiles, demandAttachments, demand);
    acceptedFiles.forEach((file) => {
      const fileName = file.attachmentName || file.name;

      // Add the placeholder to the list
      dispatch({
        type: DEMAND_ATTACHMENTS_ITEM,
        attachment: {
          name: fileName,
          type: file.type,
          thumbnail: file.type.startsWith('image/') ? file.preview : null,
          uploading: true,
        },
      });

      // Call the POST WS
      const formData = new FormData();
      formData.append('file', file, fileName);
      httpService
        .post({
          url: attachmentUrl(demand.demand.id),
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => Promise.reject(json));
          }
          return response.json();
        })
        .then((json) =>
          dispatch({
            type: DEMAND_ATTACHMENTS_ITEM,
            attachment: json,
          }),
        )
        .catch((err) => {
          console.error('Error while saving the attachment:', err);
          dispatch(addGlobalError(getErrorMessage(err)));
          dispatch({
            type: DEMAND_ATTACHMENTS_DELETE,
            id: null,
            name: fileName,
          });
        });
    });
  };
}
