import {
  OPERATION_OPTION_DELETION_ERRORS,
  OPERATION_OPTION_ERROR,
  OPERATION_OPTION_LOADED,
  OPERATION_OPTION_LOADING,
  OPERATION_OPTION_RESET,
  OPERATION_OPTION_VALIDATION_ERRORS,
  OPERATION_OPTIONS_LOADED,
  OPERATION_OPTIONS_UPDATING_POSITION,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError, addGlobalMessage } from '../SnackbarActions';
import history from '../../history';

/**
 * The REST collection used to manage operation options resources.
 */
const optionsCollection = api.all(urls.settings.options);

export const loadOptions = () => (dispatch) => {
  optionsCollection
    .getAll()
    .then((response) => {
      const options = response.body(false);
      dispatch({
        type: OPERATION_OPTIONS_LOADED,
        options,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading options:', error);
      dispatch(addGlobalError('Une erreur a eu lieu pendant le chargement des données'));
    });
};

export const updatePosition = (id, oldPosition, newPosition) => (dispatch) => {
  dispatch({
    type: OPERATION_OPTIONS_UPDATING_POSITION,
    oldPosition,
    newPosition,
  });
  optionsCollection
    .patch(id, {
      position: newPosition,
    })
    .then(() => {
      dispatch(loadOptions());
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while updating option position:', error);
      dispatch(addGlobalError("Une erreur a eu lieu pendant l'enregistrement des données"));
    });
};

export const loadOption = (id) => (dispatch) => {
  dispatch({
    type: id ? OPERATION_OPTION_LOADING : OPERATION_OPTION_RESET,
  });
  if (!id) {
    return;
  }

  optionsCollection
    .get(id)
    .then((response) => {
      const option = response.body(false);
      dispatch({
        type: OPERATION_OPTION_LOADED,
        option,
      });
    })
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while loading option:', error);
      dispatch(addGlobalError("Une erreur a eu lieu pendant le chargement de l'option"));
      dispatch({
        type: OPERATION_OPTION_ERROR,
        error: "Une erreur a eu lieu pendant le chargement de l'option",
      });
    });
};

export const resetOption = () => ({
  type: OPERATION_OPTION_RESET,
});

export const saveOrUpdateOption = (option) => (dispatch) => {
  const request = option.id ? optionsCollection.put(option.id, option) : optionsCollection.post(option);
  return request
    .then((response) => {
      if (!option.id) {
        const persistedOption = response.body(false);
        dispatch(addGlobalMessage("L'option a été créée."));
        history.push(`/settings/operation-options/${persistedOption.id}`);
      } else {
        dispatch(loadOption(option.id));
        dispatch(addGlobalMessage("L'option a été mise à jour"));
      }
      dispatch(loadOptions());
    })
    .catch(ignore401)
    .catch((err) => {
      console.error('Error while saving an option:', err);
      if (err.response && err.response.data && err.response.statusCode === 400) {
        dispatch({
          type: OPERATION_OPTION_VALIDATION_ERRORS,
          errors: err.response.data.errors,
        });
        dispatch(addGlobalError('Le formulaire contient des erreurs'));
      } else {
        dispatch(addGlobalError("Erreur pendant l'enregistrement"));
      }
    });
};

export const deleteOption = (id) => (dispatch) => {
  optionsCollection
    .delete(id)
    .then(() => {
      dispatch(addGlobalMessage("L'option a été supprimée."));
      history.push('/settings/operation-options');
    })
    .catch(ignore401)
    .catch((err) => {
      console.error('Error while deleting option:', err);
      if (err.response && err.response.data && err.response.data.errors && err.response.statusCode === 400) {
        dispatch({
          type: OPERATION_OPTION_DELETION_ERRORS,
          errors: err.response.data.errors[0].rejectedValue,
        });
      }
      dispatch(addGlobalError("L'option n'a pas pu être supprimée"));
    });
};

export const saveOrUpdateGroup = (options, callback) => (dispatch) => {
  if (!options || options.length === 0) {
    callback();
    return;
  }

  const requestBody = options.map((op) => ({ id: op.id, groupId: op.groupId }));
  api
    .custom('operation-groups/operation-options')
    .post(requestBody)
    .then(callback)
    .catch(ignore401)
    .catch((error) => {
      console.error('Error while updating operations group:', error);
      dispatch(addGlobalError("Une erreur a eu lieu pendant l'enregistrement des données"));
    });
};
