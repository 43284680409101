import { connect } from 'react-redux';

import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import FormButtons from './FormButtons';

const stateToProps = ({ currentUser }, { onReloadDemandCosts, onAddToDemands }) => ({
  canEdit: hasRole(currentUser, roles.equipment.edit.code),
  canDelete: hasRole(currentUser, roles.equipment.delete.code),
  canReloadCosts: onReloadDemandCosts && hasRole(currentUser, roles.demand.updateCost.code),
  canAddToDemands: onAddToDemands && hasRole(currentUser, roles.demand.updateCost.code),
});

export default connect(stateToProps)(FormButtons);
