import React from 'react';
import { connect } from 'react-redux';
import { LabelValue } from '../utils';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const Account = ({ user }) => (
  <Card>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">Mon compte</Typography>
        </Grid>
        <Grid item xs={12}>
          <LabelValue label="Nom">{user && user.lastName}</LabelValue>
        </Grid>
        <Grid item xs={12}>
          <LabelValue label="Prénom">{user && user.firstName}</LabelValue>
        </Grid>
        <Grid item xs={12}>
          <LabelValue label="E-mail">{user && user.username}</LabelValue>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const stateToProps = ({ currentUser: { user } }) => ({
  user,
});

export default connect(stateToProps)(Account);
