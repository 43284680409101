import {
  OPERATION_OPTION_DELETION_ERRORS,
  OPERATION_OPTION_ERROR,
  OPERATION_OPTION_LOADED,
  OPERATION_OPTION_LOADING,
  OPERATION_OPTION_RESET,
  OPERATION_OPTION_VALIDATION_ERRORS,
} from '../../constants/AppConstants';

const emptyOption = {
  demandTypeIds: [],
  emphasis: false,
  equipmentId: '',
  id: null,
  maxQuantity: null,
  name: '',
  operationType: '',
  position: 0,
  prices: {},
};

const initialState = {
  option: emptyOption,
  loading: false,
  error: null,
  deletionErrors: [], // The list of references to the equipment preventing it to be deleted
  validationErrors: [],
  validationErrorsCounter: 0, // To know when form inputs must be updated
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPERATION_OPTION_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        option: action.option,
        deletionErrors: [],
        validationErrors: [],
      };
    case OPERATION_OPTION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        option: emptyOption,
        deletionErrors: [],
        validationErrors: [],
      };
    case OPERATION_OPTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        deletionErrors: [],
        validationErrors: [],
      };
    case OPERATION_OPTION_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        option: emptyOption,
        deletionErrors: [],
        validationErrors: [],
      };
    case OPERATION_OPTION_VALIDATION_ERRORS:
      return {
        ...state,
        deletionErrors: [],
        validationErrors: action.errors || [], // Managing case of BadRequest without validation errors
        validationErrorsCounter: state.validationErrorsCounter + 1,
      };
    case OPERATION_OPTION_DELETION_ERRORS:
      return {
        ...state,
        deletionErrors: action.errors,
      };
    default:
      return state;
  }
}
