import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Pagination from '../commons/Pagination';
import { toggleEquipmentMoveFixed, updateEquipmentMoveFilter } from '../../actions/EquipmentMoveActions';
import DateViewer from '../commons/DateViewer';
import ResponsiveTable from '../utils/ResponsiveTable';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import { EquipmentMove } from '../../model/model';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listRow: {
    '&:nth-of-type(2n)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  listIcon: {
    color: theme.palette.text.primary,
    width: 48,
    paddingRight: 8,
    justifyContent: 'center',
  },
}));

const formatSendSummary = (move: EquipmentMove) =>
  (move.sendError ?? move.sendStatus).name + (move.sendStatus.id === 'FIXED' ? ' (Corrigé)' : '');

const EquipmentMoveStatusIcon = ({ move, canFixMove }: { move: EquipmentMove; canFixMove: boolean }) => {
  const dispatch = useAppDispatch();
  return canFixMove && ['ERROR', 'FIXED'].includes(move.sendStatus.id) ? (
    <Tooltip title={move.sendStatus.id === 'ERROR' ? 'Marquer comme corrigé' : 'Marquer comme non corrigé'}>
      <IconButton onClick={() => dispatch(toggleEquipmentMoveFixed(move))}>{move.sendStatus.icon}</IconButton>
    </Tooltip>
  ) : (
    move.sendStatus.icon
  );
};

const EquipmentMoveTable = () => {
  const classes = useStyles();
  const equipmentMoves = useAppSelector(({ equipmentMoves: { list: equipmentMoves } }) => equipmentMoves);
  const pagination = useAppSelector(({ equipmentMoves: { pagination } }) => pagination);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const canFixMove = hasRole(currentUser, roles.equipmentMove.fix.code);

  const onPageClick = (pageNumber: number) => {
    updateEquipmentMoveFilter({ ...pagination, currentPage: pageNumber });
  };

  const onPageSizeChange = (pageSize: number) => {
    updateEquipmentMoveFilter({ ...pagination, currentPage: 0, pageSize });
  };

  return (
    <div>
      <Hidden mdUp>
        <List className={classes.list}>
          {Boolean(equipmentMoves.length) &&
            equipmentMoves.map((move) => (
              <ListItem key={move.id} className={classes.listRow}>
                <ListItemIcon className={classes.listIcon}>
                  <EquipmentMoveStatusIcon move={move} canFixMove={canFixMove} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      {move.moveDate ? <DateViewer date={move.moveDate} /> : ''} - <strong>{move.serialNumber}</strong>
                      <br />
                      {move.state && move.state.name} -{' '}
                      <Link component={RouterLink} to={`/outlets/${move.outletId}`}>
                        {move.outletCode} - {move.outletName}
                      </Link>
                    </>
                  }
                  secondary={
                    <>
                      {move.sendDate ? (
                        <span>
                          Envoi <DateViewer date={move.sendDate} />
                        </span>
                      ) : (
                        ''
                      )}
                      &nbsp;: {formatSendSummary(move)}
                    </>
                  }
                />
              </ListItem>
            ))}
        </List>
      </Hidden>
      <Hidden mdDown>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Date du mouvement</TableCell>
              <TableCell>Numéro de série</TableCell>
              <TableCell>État</TableCell>
              <TableCell>Lieu</TableCell>
              <TableCell>Intervention</TableCell>
              <TableCell>Date d'envoi</TableCell>
              <TableCell>Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipmentMoves.length ? (
              equipmentMoves.map((move) => (
                <TableRow key={move.id}>
                  <TableCell padding="none" align="center">
                    <EquipmentMoveStatusIcon move={move} canFixMove={canFixMove} />
                  </TableCell>
                  <TableCell>{move.moveDate ? <DateViewer date={move.moveDate} /> : ''}</TableCell>
                  <TableCell>{move.serialNumber}</TableCell>
                  <TableCell>{move.state && move.state.name}</TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/outlets/${move.outletId}`}>
                      {move.outletCode} - {move.outletName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {move.interventionNumber ? (
                      <Link component={RouterLink} to={`/interventions/${move.interventionId}`}>
                        {move.interventionNumber}
                      </Link>
                    ) : (
                      <Tooltip title="Mouvement créé manuellement">
                        <span>N/A</span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{move.sendDate ? <DateViewer date={move.sendDate} /> : ''}</TableCell>
                  <TableCell title={move.errorMessage}>{formatSendSummary(move)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>Aucun mouvement ne correspond aux critères renseignés</TableCell>
              </TableRow>
            )}
          </TableBody>
        </ResponsiveTable>
      </Hidden>
      <Pagination
        currentPage={pagination.currentPage}
        numberOfPages={pagination.numberOfPages}
        pageSize={pagination.pageSize}
        onPageChange={onPageClick}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

export default EquipmentMoveTable;
