import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import OperationSerialNumber from './OperationSerialNumber';

class SerialNumbersList extends React.Component {
  static propTypes = {
    canEdit: PropTypes.bool.isRequired,
    equipments: PropTypes.array.isRequired,
    updateTabsHeight: PropTypes.func,
  };

  render() {
    const { canEdit, equipments, updateTabsHeight } = this.props;
    return (
      equipments &&
      equipments.length > 0 && (
        <Grid container spacing={2}>
          {equipments.map((eq) =>
            eq.serialNumbers.map((serialNumber, idx) => (
              <OperationSerialNumber
                key={`${eq.operation.type}-${eq.eqId}-${idx}`}
                canEdit={canEdit}
                equipmentId={eq.id}
                equipmentCode={eq.code}
                equipmentName={eq.name}
                serialNumberConstraints={eq.serialNumberConstraints}
                serialNumber={serialNumber}
                operation={eq.operation}
                index={idx}
                updateTabsHeight={updateTabsHeight}
              />
            )),
          )}
        </Grid>
      )
    );
  }
}

export default SerialNumbersList;
