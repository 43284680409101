const errorMessages = {
  NO_INPUT: 'Veuillez saisir un e-mail et un mot de passe',
  INVALID_INPUT: 'Il y a une erreur dans la saisie de votre e-mail ou de votre mot de passe.',
  TECHNICAL: 'Une erreur technique est survenue. Veuillez essayer à nouveau dans quelques instants.',
  INACTIVE_ACCOUNT: 'Votre compte est inactif.',
  BLOCKED_ACCOUNT: 'Votre compte a été bloqué.',
  UNAUTHENTICATED: 'Veuillez vous authentifier pour accéder à cette ressource',
  UNINITIALIZED_ACCOUNT: "Vous n'avez pas encore activé votre compte.",
  PASSWORD_EXPIRED: 'Votre mot de passe a expiré.',
};

// eslint-disable-next-line import/prefer-default-export
export const getErrorMessage = (error) => {
  const errorCode =
    typeof error === 'string'
      ? error
      : (error.response && error.response.data && (error.response.data.message || error.response.data.error)) ||
        error.message ||
        error.error ||
        'Erreur inconnue';
  return errorMessages[errorCode] || errorCode;
};
