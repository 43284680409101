import { DEMAND_COST_LOADED, DEMAND_COST_CLOSE, DEMAND_COST_UPDATED, urls } from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError } from './SnackbarActions';

const demandCostQuery = api.custom(urls.demandCost);

export function estimateCost(demand) {
  return (dispatch) => {
    demandCostQuery
      .post(demand)
      .then((response) => {
        const cost = response.body(false);
        dispatch({
          type: DEMAND_COST_LOADED,
          cost,
        });
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while estimating the costs:', err);
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch(addGlobalError(err.response.data.error));
        } else {
          dispatch(addGlobalError('Erreur lors du calcul des coûts'));
        }
      });
  };
}

export function updateCost(demandId) {
  return (dispatch) => {
    api
      .one(urls.demands, demandId)
      .custom('update-cost')
      .post()
      .then((response) => {
        const cost = response.body(false);
        dispatch({
          type: DEMAND_COST_LOADED,
          cost,
        });
        dispatch({
          type: DEMAND_COST_UPDATED,
          cost,
        });
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while reloading the costs:', err);
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch(addGlobalError(err.response.data.error));
        } else {
          dispatch(addGlobalError('Erreur lors de la mise à jour des tarifs'));
        }
      });
  };
}

export function displayCurrentDemandCost() {
  return (dispatch, getState) => {
    const {
      demand: {
        demand: { cost },
      },
    } = getState();
    dispatch({
      type: DEMAND_COST_LOADED,
      cost,
    });
  };
}

export function closeCost() {
  return {
    type: DEMAND_COST_CLOSE,
  };
}
