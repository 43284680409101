import {
  DELIVERY_LOCATIONS_LOADED,
  DELIVERY_LOCATION_ADDED,
  DELIVERY_LOCATION_SAVED,
  DELIVERY_LOCATION_DELETED,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError } from './SnackbarActions';

const deliveryLocationsCollection = api.all(urls.deliveryLocations);
deliveryLocationsCollection.isFetchNeeded = true;

export function loadDeliveryLocations() {
  return (dispatch) => {
    deliveryLocationsCollection
      .getAll()
      .then((response) => {
        const list = response.body(false);
        if (list && list.length) {
          deliveryLocationsCollection.isFetchNeeded = false;
        }
        dispatch({
          type: DELIVERY_LOCATIONS_LOADED,
          list,
        });
      })
      .catch(ignore401);
  };
}

export function loadDeliveryLocationsIfNeeded() {
  return (dispatch) => {
    if (deliveryLocationsCollection.isFetchNeeded) {
      loadDeliveryLocations()(dispatch);
    }
  };
}

export function addDeliveryLocation() {
  return {
    type: DELIVERY_LOCATION_ADDED,
  };
}

export function saveDeliveryLocation(deliveryLocation) {
  return (dispatch) => {
    const creation = !deliveryLocation.id;
    const saveRequest = creation
      ? deliveryLocationsCollection.post(deliveryLocation)
      : deliveryLocationsCollection.put(deliveryLocation.id, deliveryLocation);

    saveRequest
      .then((response) => {
        let newDeliveryLocation = deliveryLocation;
        if (creation) {
          newDeliveryLocation = response.body(false);
        }
        dispatch({
          type: DELIVERY_LOCATION_SAVED,
          item: {
            ...newDeliveryLocation,
            editing: false,
          },
          creation,
        });
      })
      .catch(ignore401);
  };
}

export function deleteDeliveryLocation(deliveryLocation) {
  return (dispatch) => {
    if (deliveryLocation.id) {
      deliveryLocationsCollection
        .delete(deliveryLocation.id)
        .then(() => {
          dispatch({
            type: DELIVERY_LOCATION_DELETED,
            id: deliveryLocation.id,
          });
        })
        .catch(ignore401)
        .catch((err) => {
          console.error('Error while deleting demand type:', err);
          if (err.response && err.response.data && err.response.statusCode === 400) {
            dispatch(addGlobalError(err.response.data.error));
          } else {
            dispatch(addGlobalError('Erreur lors de la suppression'));
          }
        });
    } else {
      dispatch({
        type: DELIVERY_LOCATION_DELETED,
        id: null,
      });
    }
  };
}
