import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell } from '@mui/material';

import { OPERATION_TYPES } from '../../../constants/AppConstants';
import { SortableRow } from '../../utils';
import history from '../../../history';

const onClick = (option) => () => {
  history.push(`/settings/operation-options/${option.id}`);
};

const OperationOptionsTableRow = ({ option, staticContext, ...props }) => (
  <SortableRow onClick={onClick(option)} {...props}>
    <TableCell>{option.name}</TableCell>
    <TableCell>{option.demandTypeNames.join(', ')}</TableCell>
    <TableCell>{option.equipmentName}</TableCell>
    <TableCell>{OPERATION_TYPES.getNameById(option.operationType)}</TableCell>
    <TableCell>{option.maxQuantity}</TableCell>
    <TableCell>{option.emphasis ? 'Oui' : 'Non'}</TableCell>
  </SortableRow>
);

OperationOptionsTableRow.propTypes = {
  option: PropTypes.object.isRequired,
};

export default OperationOptionsTableRow;
