import React from 'react';
import { Chip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '4px 2px',
    height: 24,
  },
  chipLabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
};

/**
 * Static component that looks like a disabled MultiSelect.
 */
const MultiValue = ({ classes, values }) => (
  <div className={classes.root}>
    {values.map((value, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Chip key={`${idx}-${value}`} classes={{ root: classes.chip, label: classes.chipLabel }} label={value} />
    ))}
  </div>
);

export default withStyles(styles)(MultiValue);
