import roles from './roles';

export const ATTACHMENT_OPTIONS = {
  NONE: 'NONE',
  OPTIONAL: 'OPTIONAL',
  MANDATORY: 'MANDATORY',
};

/**
 * Each transition is made of :
 *  - id : The transition id,
 *  - from : The array of demand statuses for which the transition can apply,
 *  - to : The output demand status,
 *  - subcontractor : A boolean indicating whether or not the transition must be performed by a subcontractor or
 *  a PMU user,
 *  - name : The transition name. It will be displayed as the action button label,
 *  - path : The path suffix of the URL to use to send the transition to the back-end server,
 *  - role : The role the user must have to perform the transition,
 *  - primary : A boolean indicating whether or not the action button is a primary button or not,
 *  - attachments : A value indicating whether or not the transition event supports attachments and if it does,
 *  whether or not there must be at least one attachment,
 *  - withDate : Specifying a date is required for this transition.
 *  - withoutInterventions: to perform the transition, there should not be any interventions associated with the demand.
 */
export const DEMAND_TRANSITIONS = [
  {
    id: 'ASK_VALIDATION',
    from: ['_010_EDITING', '_015_REJECTED'],
    to: '_020_AWAITING_PMU_VALIDATION',
    subcontractor: false,
    name: 'Demander la validation',
    path: '/ask-validation',
    role: roles.demand.edit,
    color: 'primary',
    attachments: ATTACHMENT_OPTIONS.NONE,
    confirmationMessage: 'La demande a été envoyée pour validation',
  },
  {
    id: 'DELETE',
    from: ['_010_EDITING', '_015_REJECTED', '_020_AWAITING_PMU_VALIDATION'],
    to: '_100_DELETED',
    subcontractor: false,
    name: 'Supprimer',
    path: '',
    role: roles.demand.delete,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'secondary',
    left: true,
    confirmationMessage: 'La demande a été supprimée',
    withoutComment: true,
  },
  {
    id: 'DELETE_ALWAYS',
    from: [
      '_030_VALID',
      '_040_OPERATIONS_PROCESSING',
      '_045_TO_RESCHEDULE',
      '_050_OPERATIONS_SCHEDULED',
      '_060_OPERATIONS_ON_GOING',
      '_070_AWAITING_PMU_ACCEPTANCE',
      '_080_ACCEPTED_WITH_RESERVE',
      '_090_OPERATIONS_RESERVE_REMOVING',
    ],
    to: '_100_DELETED',
    subcontractor: false,
    name: 'Supprimer',
    path: '',
    role: roles.demand.deleteAlways,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'secondary',
    left: true,
    confirmationMessage: 'La demande a été supprimée',
    withoutComment: true,
  },
  {
    id: 'REFUSE_VALIDATION',
    from: ['_020_AWAITING_PMU_VALIDATION'],
    to: '_010_EDITING',
    subcontractor: false,
    name: 'Refuser',
    path: '/refuse',
    role: roles.demand.validate,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'secondary',
    confirmationMessage: 'La demande a été refusée',
  },
  {
    id: 'VALIDATE',
    from: ['_020_AWAITING_PMU_VALIDATION'],
    to: '_030_VALID',
    subcontractor: false,
    name: 'Valider',
    path: '/validate',
    role: roles.demand.validate,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'primary',
    confirmationMessage: 'La demande a été validée',
  },
  {
    id: 'SUBCONTRACTOR_REJECT',
    from: ['_030_VALID'],
    to: '_015_REJECTED',
    subcontractor: true,
    name: 'Refuser',
    mandatoryComment: true,
    path: '/reject',
    role: roles.demand.process,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'secondary',
    confirmationMessage: 'La demande a été rejetée',
  },
  {
    id: 'ACK',
    from: ['_030_VALID'],
    to: '_040_OPERATIONS_PROCESSING',
    subcontractor: true,
    name: 'Accepter',
    path: '/ack',
    role: roles.demand.process,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'primary',
    confirmationMessage: 'La demande a été prise en compte',
  },
  {
    id: 'START',
    from: ['_050_OPERATIONS_SCHEDULED'],
    to: '_060_OPERATIONS_ON_GOING',
    subcontractor: true,
    name: 'Déclarer une intervention en cours',
    path: '/start',
    role: roles.demand.process,
    color: 'primary',
    attachments: ATTACHMENT_OPTIONS.NONE,
    confirmationMessage: 'La demande a été déclarée en cours de réalisation',
  },
  {
    id: 'CANCEL',
    from: ['_040_OPERATIONS_PROCESSING', '_050_OPERATIONS_SCHEDULED', '_060_OPERATIONS_ON_GOING'],
    to: '_110_CANCELLED',
    subcontractor: true,
    name: 'Annuler la demande',
    mandatoryComment: true,
    path: '/cancel',
    role: roles.demand.process,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'secondary',
    left: true,
    confirmationMessage: 'La demande a été annulée',
  },
  {
    id: 'ASK_ACCEPTANCE',
    from: ['_060_OPERATIONS_ON_GOING', '_090_OPERATIONS_RESERVE_REMOVING'],
    to: '_070_AWAITING_PMU_ACCEPTANCE',
    subcontractor: true,
    name: 'Demander la réception',
    path: '/ask-acceptance',
    role: roles.demand.process,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'primary',
    confirmationMessage: 'La demande a été envoyée pour réception',
  },
  {
    id: 'ACCEPT_WITH_RESERVE',
    from: ['_070_AWAITING_PMU_ACCEPTANCE', '_075_REJECTED_RESERVE'],
    to: '_080_ACCEPTED_WITH_RESERVE',
    subcontractor: false,
    name: 'Réceptionner avec réserve',
    path: '/accept-with-reserves',
    role: roles.demand.receive,
    attachments: ATTACHMENT_OPTIONS.MANDATORY,
    color: 'secondary',
    confirmationMessage: 'La demande a été réceptionnée avec réserves',
  },
  {
    id: 'ACCEPT',
    from: ['_070_AWAITING_PMU_ACCEPTANCE', '_075_REJECTED_RESERVE'],
    to: '_120_ACCEPTED_WITHOUT_RESERVE',
    subcontractor: false,
    name: 'Réceptionner sans réserve',
    path: '/accept-without-reserves',
    role: roles.demand.receive,
    attachments: ATTACHMENT_OPTIONS.OPTIONAL,
    color: 'primary',
    confirmationMessage: 'La demande a été réceptionnée sans réserves',
  },
  {
    id: 'REJECT_RESERVES',
    from: ['_080_ACCEPTED_WITH_RESERVE'],
    to: '_075_REJECTED_RESERVE',
    subcontractor: true,
    name: 'Refuser les réserves',
    mandatoryComment: true,
    path: '/reject-reserves',
    role: roles.demand.process,
    attachments: ATTACHMENT_OPTIONS.OPTIONAL,
    color: 'secondary',
    confirmationMessage: 'La réserves ont été refusées',
  },
  {
    id: 'ACK_RESERVES',
    from: ['_080_ACCEPTED_WITH_RESERVE'],
    to: '_090_OPERATIONS_RESERVE_REMOVING',
    subcontractor: true,
    name: 'Traiter les réserves',
    path: '/ack-reserves',
    role: roles.demand.process,
    attachments: ATTACHMENT_OPTIONS.NONE,
    color: 'primary',
    confirmationMessage: 'La réserves ont été acceptées',
  },
  {
    id: 'REOPEN',
    from: ['_030_VALID', '_040_OPERATIONS_PROCESSING', '_050_OPERATIONS_SCHEDULED'],
    to: '_010_EDITING',
    subcontractor: false,
    name: 'Rouvrir la demande',
    path: '/reopen',
    role: roles.demand.validate,
    attachments: ATTACHMENT_OPTIONS.NONE,
    left: true,
    color: 'neutral',
    confirmationMessage: 'La demande a été rouverte',
    withoutInterventions: true,
  },
  {
    id: 'CANCEL_RECEIVE',
    from: ['_070_AWAITING_PMU_ACCEPTANCE'],
    to: '_060_OPERATIONS_ON_GOING',
    subcontractor: true,
    name: 'Annuler la demande de réception',
    path: '/cancel-receive',
    role: roles.demand.cancelReceive,
    attachments: ATTACHMENT_OPTIONS.NONE,
    left: true,
    color: 'neutral',
    confirmationMessage: 'La demande de réception a été annulée',
  },
];
