import {
  DecoratedArray,
  FDD_SUB_GOAL_DELETED,
  FDD_SUB_GOAL_SAVED,
  FDD_SUB_GOALS_LOADED,
  getByIdDecorator,
} from '../../constants/AppConstants';
import { SubGoal } from '../../model/model';
import { AnyAction } from 'redux';

export type FDDSubGoalState = DecoratedArray<SubGoal>;

const initialState: FDDSubGoalState = getByIdDecorator([]);

export default function (state: FDDSubGoalState = initialState, action: AnyAction): FDDSubGoalState {
  switch (action.type) {
    case FDD_SUB_GOALS_LOADED:
      return getByIdDecorator([...action.subGoals]);
    case FDD_SUB_GOAL_SAVED:
      if (action.creation) {
        return getByIdDecorator([...state, action.subGoal]);
      }
      return getByIdDecorator(state.map((subGoal) => (subGoal.id === action.subGoal.id ? action.subGoal : subGoal)));
    case FDD_SUB_GOAL_DELETED: {
      return getByIdDecorator(state.filter((subGoal): boolean => subGoal.id !== action.id));
    }
    default:
      return state;
  }
}
