import { urls } from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';

import { reloadIntervention } from './InterventionActions';

export function saveSerialNumber(interventionId, operation, equipmentId, index, serialNumber) {
  const endpoint = api
    .one(urls.interventions, interventionId)
    .custom('serial-numbers')
    .custom(operation.type.toUpperCase())
    .custom(operation.selectedEquipmentId)
    .one(equipmentId, index);

  return (dispatch) => {
    endpoint
      .put({ serialNumber })
      .then(() => {
        dispatch(reloadIntervention(interventionId));
        dispatch(addGlobalMessage(`Le numéro de série a été ${serialNumber ? 'enregistré' : 'supprimé'}.`));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while saving serial numbers:', error);
        dispatch(addGlobalError("Un erreur a eu lieu pendant l'enregistrement du numéro de série."));
      });
  };
}
