import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveSerialNumber } from '../../../actions/InterventionSerialNumbersActions';
import SerialNumberField from '../../utils/SerialNumberField';
import { Grid } from '@mui/material';

const OperationSerialNumber = ({
  canEdit,
  equipmentId,
  equipmentCode,
  equipmentName,
  operation,
  interventionId,
  index,
  serialNumber,
  serialNumberConstraints,
  dispatch,
  updateTabsHeight,
}) => (
  <>
    <Grid item xs={6} container alignItems="center">
      <div>{equipmentName}</div>
    </Grid>
    <Grid item xs={6}>
      <SerialNumberField
        disabled={!canEdit}
        name={`${operation.type}-${equipmentId}-${index}-serial`}
        value={serialNumber}
        constraints={serialNumberConstraints}
        onChange={(value) => dispatch(saveSerialNumber(interventionId, operation, equipmentId, index, value))}
        onEquipmentLoaded={() => {
          // Update the tab height after the next render, to allow displaying the equipment validation message
          setTimeout(updateTabsHeight, 100);
        }}
        expectedEquipmentCode={equipmentCode}
      />
    </Grid>
  </>
);

OperationSerialNumber.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  equipmentId: PropTypes.string.isRequired,
  equipmentCode: PropTypes.string.isRequired,
  equipmentName: PropTypes.string.isRequired,
  operation: PropTypes.object.isRequired,
  serialNumber: PropTypes.string,
  serialNumberConstraints: PropTypes.object,
  index: PropTypes.number.isRequired,
  updateTabsHeight: PropTypes.func,
};

const stateToProps = ({ intervention }) => ({
  interventionId: intervention && intervention.intervention && intervention.intervention.id,
});

export default connect(stateToProps)(OperationSerialNumber);
