import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import classNames from 'classnames';

import { loadDemandTypesIfNeeded } from '../../actions/DemandTypeActions';
import { COMPANIES, INTERVENTION_STATUSES } from '../../constants/AppConstants';
import { pieColors } from '../utils/colors';

const styles = (theme) => ({
  pieTitle: {
    marginBottom: theme.spacing(1),
  },
  pie: {
    '& text': {
      fontSize: '0.5rem',
      fontWeight: 500,
      fill: theme.palette.background.paper,
      textShadow: `0 0 1px ${theme.palette.text.primary}`,
      pointerEvents: 'none',
    },
  },
  legend: {
    padding: 0,
  },
  legendItem: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    '&$hovered': {
      fontWeight: 500,
    },
  },
  hovered: {
    fontWeight: 700,
  },
  palette: {
    display: 'inline-block',
    width: 24,
    height: 12,
    marginRight: theme.spacing(1),
  },
});

const sortedActivityEntries = (activities) => Object.entries(activities).sort(([k1, v1], [k2, v2]) => v2 - v1);

const pieDefaults = {
  radius: 50,
  segmentsShift: 4,
};

const DayActivityGraph = ({
  title,
  activityEntries,
  getLabel = (id) => id,
  getColor = (id, idx) => pieColors[idx % pieColors.length],
  classes,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <>
      <Typography variant="h2" className={classes.pieTitle}>
        {title}
      </Typography>
      <div className={classes.pie}>
        <PieChart
          data={activityEntries.map(([id, count], index) => ({
            key: id,
            title: getLabel(id),
            value: count,
            color: getColor(id, index),
          }))}
          radius={pieDefaults.radius - pieDefaults.segmentsShift}
          segmentsShift={(index) => (index === hoveredIndex ? pieDefaults.segmentsShift : 0)}
          lineWidth={67} // 2/3 width donut
          startAngle={270} // Start on top
          label={({ dataEntry: { value } }) => value}
          labelPosition={67}
          animate={true}
          onMouseOver={(_, index) => setHoveredIndex(index)}
          onMouseOut={() => setHoveredIndex(null)}
        />
      </div>
      <ul className={classes.legend}>
        {activityEntries.map(([id, count], index) => (
          <li key={id} className={classes.legendItem}>
            <Typography className={classNames({ [classes.hovered]: index === hoveredIndex })}>
              <span className={classes.palette} style={{ backgroundColor: getColor(id, index) }} />
              {getLabel(id)} ({count})
            </Typography>
          </li>
        ))}
      </ul>
    </>
  );
};

const DayActivityDashboard = ({ dayActivity, demandTypes, agencies, dispatch, classes }) => {
  useEffect(() => dispatch(loadDemandTypesIfNeeded()), [dispatch]);

  if (!dayActivity) {
    return <Typography>Chargement en cours...</Typography>;
  }
  if (!dayActivity.total) {
    return <Typography>Aucune intervention planifiée pour les critères renseignés.</Typography>;
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={6}>
        <DayActivityGraph
          title="Par type de demande"
          activityEntries={sortedActivityEntries(dayActivity.byDemandType)}
          getLabel={demandTypes.getNameById}
          classes={classes}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <DayActivityGraph
          title="Par statut"
          activityEntries={sortedActivityEntries(dayActivity.byStatus)}
          getLabel={INTERVENTION_STATUSES.getNameById}
          getColor={(id) => INTERVENTION_STATUSES.getById(id).pieColor}
          classes={classes}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <DayActivityGraph
          title="Par prestataire"
          activityEntries={sortedActivityEntries(dayActivity.bySubcontractor)}
          getLabel={COMPANIES.getNameById}
          getColor={(id) => COMPANIES.getById(id).pieColor}
          classes={classes}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <DayActivityGraph
          title="Par zone de vente"
          activityEntries={sortedActivityEntries(dayActivity.byAgency)}
          getLabel={(id) => agencies.getNameById(id, () => '(aucune)')}
          classes={classes}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ dayActivity: { data: dayActivity }, demandTypes, agencies: { list: agencies } }) => ({
  dayActivity,
  demandTypes,
  agencies,
});

export default compose(withStyles(styles), connect(mapStateToProps))(DayActivityDashboard);
