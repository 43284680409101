import React from 'react';
import { CardContent, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import DateViewer from '../commons/DateViewer';
import DateTimeViewer from '../commons/DateTimeViewer';
import { INTERVENTION_STATUSES } from '../../constants/AppConstants';
import ResponsiveTable from '../utils/ResponsiveTable';
import history from '../../history';
import { Intervention } from '../../model/model';

type DemandInterventionsProps = {
  interventions: Intervention[];
};

const DemandInterventions = ({ interventions }: DemandInterventionsProps) => {
  const onRowClick = (interventionId) => () => {
    history.push(`/interventions/${interventionId}`);
  };

  return !interventions || interventions.length === 0 ? (
    <CardContent>
      <Typography variant="body1">Aucune intervention n'a encore été créée pour cette demande.</Typography>
    </CardContent>
  ) : (
    <ResponsiveTable>
      <TableHead>
        <TableRow>
          <TableCell>Référence prestataire</TableCell>
          <TableCell>Date de création</TableCell>
          <TableCell>Statut</TableCell>
          <TableCell>Date de planification</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {interventions.map((intervention) => (
          <TableRow key={`intervention-${intervention.id}`} onClick={onRowClick(intervention.id)} hover>
            <TableCell>{intervention.subcontractorReference}</TableCell>
            <TableCell>
              <DateTimeViewer date={intervention.creationDate} />
            </TableCell>
            <TableCell>{INTERVENTION_STATUSES.getNameById(intervention.status)}</TableCell>
            <TableCell>
              <DateViewer date={intervention.operationScheduleDate} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </ResponsiveTable>
  );
};

export default DemandInterventions;
