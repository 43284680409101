import { MAIN_MENU_TOGGLE, MAIN_MENU_CLOSE } from '../constants/AppConstants';

const initialState = { opened: false };

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case MAIN_MENU_TOGGLE:
      return { ...state, opened: !state.opened };
    case MAIN_MENU_CLOSE:
      return { ...state, opened: false };
    default:
      return state;
  }
}
