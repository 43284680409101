import { REGIONS_LOADED, REGION_ADDED, REGION_SAVED, REGION_DELETED, urls } from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';

const regionsCollection = api.all(urls.settings.regions);

function regionsLoaded(regions) {
  return {
    type: REGIONS_LOADED,
    regions,
  };
}

function regionAdded(region) {
  return {
    type: REGION_ADDED,
    region,
  };
}

function regionSaved(region, creation) {
  return {
    type: REGION_SAVED,
    region,
    creation,
  };
}

function regionDeleted(id) {
  return {
    type: REGION_DELETED,
    id,
  };
}

export function addRegion() {
  return regionAdded();
}

export function loadRegions() {
  return (dispatch) => {
    regionsCollection
      .getAll()
      .then((response) => {
        const regions = response.body(false);
        dispatch(regionsLoaded(regions));
      })
      .catch(ignore401);
  };
}

export function saveRegion(region) {
  return (dispatch) => {
    const creation = !region.id;
    const saveRequest = creation ? regionsCollection.post(region) : regionsCollection.put(region.id, region);

    saveRequest
      .then((response) => {
        let newRegion = region;
        if (creation) {
          newRegion = response.body(false);
        }
        dispatch(
          regionSaved(
            {
              ...newRegion,
              editing: false,
            },
            creation,
          ),
        );
      })
      .catch(ignore401);
  };
}

export function deleteRegion(region) {
  return (dispatch) => {
    if (region.id) {
      regionsCollection
        .delete(region.id, region)
        .then(() => {
          dispatch(regionDeleted(region.id));
        })
        .catch(ignore401);
    } else {
      dispatch(regionDeleted(null));
    }
  };
}
