/**
 * **********************
 * ACTIONS
 * **********************
 */
const OPEN_DIALOG = 'VFLI/DIRECT/DIALOG/OPEN_DIALOG';
const CLOSE_DIALOG = 'VFLI/DIRECT/DIALOG/CLOSE_DIALOG';

/**
 * **********************
 * ACTION CREATORS
 * **********************
 */

/**
 * Action used to trigger dialog display.
 *
 * @param title {string} The dialog title. Can be {@code null}.
 * @param message {string} The dialog content. Not {@code null}.
 * @param actions {{label: string, callback: function, color: string}[]} The actions.
 */
export const requestOpenDialog = (title, message, actions) => ({
  type: OPEN_DIALOG,
  payload: {
    title,
    message,
    actions,
  },
});

/**
 * Action used to trigger dialog close.
 */
export const requestCloseDialog = () => ({
  type: CLOSE_DIALOG,
  payload: {},
});

/**
 * **********************
 * SELECTORS
 * **********************
 */

export const selectIsOpened = (state) => state.dialog.opened;

export const selectContent = (state) => {
  return {
    title: state.dialog.title,
    message: state.dialog.message,
    actions: state.dialog.actions,
  };
};

/**
 * **********************
 * REDUCER
 * **********************
 */
const initialState = {
  opened: false,
  title: null,
  message: null,
  actions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        opened: true,
        actions: action.payload.actions,
        message: action.payload.message,
        title: action.payload.title,
      };
    case CLOSE_DIALOG:
      return initialState;
    default:
      return state;
  }
};
