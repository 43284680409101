import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { DatePicker as MUIDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { withFormsy } from 'formsy-react';
import dayjs, { isDayjs } from 'dayjs';

import { getNonFormsyProps } from '../../utils/formsy-utils';
import { InjectedProps } from 'formsy-react/src/withFormsy';
import { DatePickerSlotsComponentsProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import { DateValue } from '../../utils/date-utils';

export type InnerDatePickerFieldProps = Omit<DatePickerProps<DateValue>, 'onChange'> & {
  convertDateToString?: (date: DateValue) => string | null;
  fullWidth?: boolean;
  onChange?: (date: DateValue) => void;
  clearable?: boolean;
};

export const defaultDatePickerAttributes: Partial<DatePickerProps<DateValue>> = {
  format: 'DD/MM/YYYY',
  closeOnSelect: true,
};

const defaultConvertDateToString = (value: DateValue): string | null => {
  if (isDayjs(value)) {
    return value.startOf('day').format();
  }
  return value;
};

const DatePickerField = (props: InnerDatePickerFieldProps & InjectedProps<DateValue>) => {
  const {
    value,
    setValue,
    isPristine,
    isRequired,
    errorMessage,
    fullWidth,
    onChange,
    convertDateToString = defaultConvertDateToString,
    clearable,
    label,
  } = props;

  const handleChange = (value: DateValue) => {
    const stringValue = convertDateToString(value);
    setValue(stringValue);
    onChange?.(stringValue);
  };

  const errorText = isPristine ? '' : errorMessage;
  const childProps = getNonFormsyProps(props);

  const slotProps: DatePickerSlotsComponentsProps<DateValue> = {
    textField: {
      InputLabelProps: {
        shrink: true,
      },
    },
  };
  if (clearable && value) {
    slotProps.actionBar = {
      actions: ['clear'],
    };
  }

  return (
    <FormControl error={Boolean(errorText)} fullWidth={fullWidth}>
      <MUIDatePicker<DateValue>
        {...defaultDatePickerAttributes}
        {...childProps}
        label={isRequired ? `${label} *` : label}
        onChange={handleChange}
        // Keep the timezone when parsing the date
        value={value ? dayjs.parseZone(value) : null}
        slotProps={slotProps}
      />
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default withFormsy(DatePickerField);
