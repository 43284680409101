import { OUTLET_AUTO_COMPLETE } from '../constants/AppConstants';

const initialState = [];
initialState.counter = 0;

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case OUTLET_AUTO_COMPLETE: {
      const newState = [...action.outlets];
      newState.counter = state.counter + 1;
      return newState;
    }
    default:
      return state;
  }
}
