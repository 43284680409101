import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, LinearProgress, Toolbar, IconButton, Typography, Hidden, Link } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Menu, ArrowLeft } from 'mdi-material-ui';

import { toggleMainMenu } from '../../actions/AppActions';
import PMULogo from '../../img/logo_pmu.png';
import BrowserChecker from '../commons/BrowserChecker';

const styles = (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {},
  toggleButton: {
    marginRight: 10,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.complex,
    }),
  },
  toggleButtonToggled: {
    // transform: 'rotate(180deg)',
  },
  logo: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: 50,
      width: 78,
      marginRight: 10,
    },
  },
  right: {
    marginLeft: 'auto',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
  },
});

class Header extends Component {
  menuTriggerClick = () => {
    const { dispatch } = this.props;
    dispatch(toggleMainMenu());
  };

  render() {
    const { classes, mainMenu, loader } = this.props;

    return (
      <AppBar className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              onClick={this.menuTriggerClick}
              className={classNames(classes.toggleButton, {
                [classes.toggleButtonToggled]: mainMenu.opened,
              })}
              color="inherit"
              size="large"
            >
              {mainMenu.opened ? <ArrowLeft /> : <Menu />}
            </IconButton>
          </Hidden>
          <Link component={RouterLink} to="/" className={classes.logo}>
            <Hidden mdDown>
              <img alt="PMU" src={PMULogo} />
            </Hidden>
            <Typography variant="h1" color="inherit" className={classes.title}>
              PRESTAGO
            </Typography>
          </Link>
          <BrowserChecker className={classes.right} />
        </Toolbar>
        {loader.length > 0 && <LinearProgress mode="indeterminate" color="primary" />}
      </AppBar>
    );
  }
}

const stateToProps = (state) => ({
  mainMenu: state.mainMenu,
  loader: state.loader,
});

export default compose(withStyles(styles), connect(stateToProps))(Header);
