import React from 'react';
import { compose } from 'recompose';
import { Card, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import InterventionsFilters from '../../interventions/InterventionsFilters';
import { loadInterventionsListForMap } from '../../../actions/InterventionActions';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import CardContent from '@mui/material/CardContent';
import InterventionsMap from '../../interventions/InterventionsMap';
import { footerHeight } from '../../layout/Footer';
import { withPageTitle } from '../../../utils/page-title';

const filterBoxWhenCollapsed = 88;
const pageBoxesPadding = 48;
const style = (theme) => ({
  mapContent: {
    minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${footerHeight}px - ${filterBoxWhenCollapsed}px - ${pageBoxesPadding}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${
        theme.mixins.toolbar[theme.breakpoints.up('sm')].minHeight
      }px - ${footerHeight}px - ${filterBoxWhenCollapsed}px - ${pageBoxesPadding}px)`,
    },
  },
});

const InterventionMapPage = ({ classes }) => (
  <Grid container spacing={3}>
    <HierarchicalSearch loadActionCreator={loadInterventionsListForMap} />
    <Grid item xs={12}>
      <InterventionsFilters map={true} calendar={false} />
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardContent className={classes.mapContent}>
          <InterventionsMap />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default compose(
  withPageTitle(() => 'Interventions - Carte'),
  withStyles(style),
  securedPage(roles.intervention.view.code),
)(InterventionMapPage);
