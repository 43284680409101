import { alpha, Theme } from '@mui/material';
import { createTheme, lighten } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { frFR as muiLocale } from '@mui/material/locale';
import { frFR as datePickerLocale } from '@mui/x-date-pickers';

import {
  interventionOperationColor,
  labelColor,
  primaryColor,
  secondaryColor,
  subcontractorOperationColor,
} from './utils/colors';

declare module '@mui/material/Button' {
  // noinspection JSUnusedGlobalSymbols
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}
declare module '@mui/material/styles' {
  interface PrestagoColors {
    label: string;
    operation: {
      operation: string;
      subcontractor: string;
    };
  }

  // noinspection JSUnusedGlobalSymbols
  interface Palette {
    prestago: PrestagoColors;
    neutral: Palette['primary'];
  }

  // noinspection JSUnusedGlobalSymbols
  interface PaletteOptions {
    prestago: PrestagoColors;
    neutral: PaletteOptions['primary'];
  }
}
declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}
const defaultTheme = createTheme();

const theme = createTheme(
  {
    typography: {
      h1: {
        fontSize: 18,
        fontWeight: 500,
      },
      h2: {
        fontSize: 18,
        color: primaryColor,
        fontWeight: 500,
      },
      h3: {
        fontSize: 16,
        color: primaryColor,
        fontWeight: 500,
      },
      subtitle1: {
        color: lighten(defaultTheme.palette.text.primary, 0.3),
        fontWeight: 500,
      },
    },
    palette: {
      primary: {
        main: primaryColor,
        contrastText: defaultTheme.palette.common.white,
      },
      secondary: { main: secondaryColor },
      action: {
        active: defaultTheme.palette.text.primary,
      },
      neutral: {
        main: grey[300],
        dark: grey[400],
      },
      prestago: {
        label: labelColor,
        operation: {
          operation: interventionOperationColor,
          subcontractor: subcontractorOperationColor,
        },
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: 4,
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          },
          spacing: {
            '& > *, & > :not(:first-of-type)': {
              margin: 4,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              '& > *': {
                width: '100%',
              },
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&.Mui-disabled:before': {
              borderBottomColor: 'transparent',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: defaultTheme.palette.text.primary,
              cursor: 'text',
            },
          },
          input: {
            height: 'unset',
            '&.Mui-disabled': {
              color: defaultTheme.palette.text.primary,
              WebkitTextFillColor: defaultTheme.palette.text.primary,
              cursor: 'text',
            },
            '&[type=number]': {
              MozAppearance: 'textfield',
              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
            },
            '&::placeholder': {
              opacity: 1,
              color: defaultTheme.palette.text.secondary,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: labelColor,
            '&.Mui-disabled': {
              color: labelColor,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            '&.Mui-disabled': {
              color: defaultTheme.palette.text.primary,
              cursor: 'text',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingLeft: 15,
            paddingRight: 15,
            borderBottom: 'none',
            '&:first-of-type': {
              paddingLeft: 16,
            },
            '&:last-of-type': {
              paddingRight: 16,
            },
          },
          body: {
            fontSize: '1rem',
          },
          head: {
            fontSize: '1rem',
          },
          footer: {
            fontSize: '1rem',
            color: defaultTheme.palette.text.primary,
            fontWeight: 500,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(2n+1)': {
              backgroundColor: defaultTheme.palette.grey[100],
            },
            '&.MuiTableRow-hover:hover': {
              cursor: 'pointer',
            },
          },
          head: {
            '&:nth-of-type(2n+1)': {
              backgroundColor: 'inherit',
            },
          },
          footer: {
            '&:nth-of-type(2n+1)': {
              backgroundColor: 'inherit',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#0a6ebd',
            textDecoration: 'none',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: defaultTheme.palette.text.primary,
            '&.Mui-disabled.Mui-checked': {
              color: defaultTheme.palette.text.secondary,
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            minHeight: '100vh',
          },
          body: {
            minHeight: '100vh',
            backgroundColor: '#fafafa',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 40,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          inset: {
            paddingLeft: 40,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popupIndicator: {
            '&.Mui-disabled': {
              display: 'none',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            display: 'inline-flex',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'neutral',
        },
        variants: [
          {
            props: { color: 'neutral', variant: 'text' },
            style: {
              color: defaultTheme.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(defaultTheme.palette.text.primary, defaultTheme.palette.action.hoverOpacity),
              },
            },
          },
          {
            props: { variant: 'outlined', color: 'neutral' },
            style: {
              color: defaultTheme.palette.text.primary,
              borderColor: defaultTheme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
              '&.Mui-disabled': {
                border: `1px solid ${defaultTheme.palette.action.disabledBackground}`,
              },
              '&:hover': {
                borderColor:
                  defaultTheme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
                backgroundColor: alpha(defaultTheme.palette.text.primary, defaultTheme.palette.action.hoverOpacity),
              },
            },
          },
        ],
      },
    },
  },
  muiLocale,
  datePickerLocale,
);

export default theme;
