import { EQUIPMENTS_LOADED, SORT_EQUIPMENTS } from '../../constants/AppConstants';
import { computeSorts, SortDirections } from '../../utils/sorting';
import { Equipment } from '../../model/model';
import { Sort } from '../../model/pagination';
import { AnyAction } from 'redux';

type EquipmentsState = {
  equipments: Equipment[];
  sorts: Sort[];
};

const initialState: EquipmentsState = {
  equipments: [],
  sorts: [
    { field: 'code', direction: SortDirections.asc },
    { field: 'name', direction: SortDirections.asc },
  ],
};

export default function (state = initialState, action: AnyAction): EquipmentsState {
  switch (action.type) {
    case EQUIPMENTS_LOADED:
      return {
        ...state,
        equipments: action.equipments,
      };
    case SORT_EQUIPMENTS:
      return {
        ...state,
        sorts: computeSorts(state.sorts, action.field, action.direction),
      };
    default:
      return state;
  }
}
