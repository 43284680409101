import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAttachments, deleteAttachment } from '../../actions/DemandAttachmentsActions';
import AttachmentsZone from '../utils/AttachmentsZone';

class DemandAttachments extends Component {
  static propTypes = {
    onAttachmentsChange: PropTypes.func.isRequired,
  };

  /**
   * Workaround for tabs resizing.
   *
   * react-swipeable-views calculates the tab height on tab transition.
   * So, when a new attachment with a long name is added, the tab area does not
   * expand and part of the name is hidden (visible through a scroll bar).
   *
   * The workaround is described here :
   * https://github.com/oliviertassinari/react-swipeable-views/issues/36.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const demandAttachments = Object.values(this.props.demandAttachments);
    const nextDemandAttachments = Object.values(nextProps.demandAttachments);
    if (demandAttachments.length !== nextDemandAttachments.length) {
      this.props.onAttachmentsChange();
    }
  }

  onAdd = (acceptedFiles, rejectedFiles) => {
    const { dispatch } = this.props;
    dispatch(addAttachments(acceptedFiles, rejectedFiles));
  };

  onRemove = (attachmentId, attachmentName) => {
    const { dispatch } = this.props;
    dispatch(deleteAttachment(attachmentId, attachmentName));
  };

  render() {
    const { demandAttachments, dispatch, readOnly } = this.props;
    return (
      <AttachmentsZone
        attachments={Object.values(demandAttachments)}
        dispatch={dispatch}
        onAdd={this.onAdd}
        onRemove={this.onRemove}
        readOnly={readOnly}
      />
    );
  }
}

const stateToProps = ({ demandAttachments }) => ({
  demandAttachments,
});

export default connect(stateToProps)(DemandAttachments);
