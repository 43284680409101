import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Card, Grid } from '@mui/material';

import securedPage from '../../securedPage';
import EquipmentMoveTable from '../../equipment-moves/EquipmentMoveTable';
import EquipmentMoveFilters from '../../equipment-moves/EquipmentMoveFilters';
import roles from '../../../constants/roles';
import { CardHeader } from '../../utils';
import { loadEquipmentMoves } from '../../../actions/EquipmentMoveActions';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import EquipmentMoveExport from '../../equipment-moves/EquipmentMoveExport';
import history from '../../../history';
import Hidden from '@mui/material/Hidden';
import { withPageTitle } from '../../../utils/page-title';

const EquipmentMoveListPage = ({ totalElements }) => (
  <Grid container spacing={3}>
    <HierarchicalSearch loadActionCreator={loadEquipmentMoves} />
    <Grid item xs={12}>
      <EquipmentMoveFilters />
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader
          addLabel="Créer un nouveau mouvement"
          addRole={roles.equipmentMove.create.code}
          onAdd={() => {
            history.push('/equipment-moves/new');
          }}
          actions={
            <Hidden mdDown>
              <EquipmentMoveExport />
            </Hidden>
          }
        >
          Mouvements de matériels
          {totalElements > 0 ? ` (${totalElements})` : ''}
        </CardHeader>
        <EquipmentMoveTable />
      </Card>
    </Grid>
  </Grid>
);

const stateToProps = (state) => ({
  totalElements: state.equipmentMoves.pagination.totalElements,
});

export default compose(
  withPageTitle(() => 'Mouvements de matériels'),
  securedPage(roles.equipmentMove.view.code),
  connect(stateToProps),
)(EquipmentMoveListPage);
