import React from 'react';
import * as PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { formatDate } from '../../utils/date-utils';

const DateViewer = ({ date, format }) => <span>{formatDate(date, format)}</span>;

DateViewer.propTypes = {
  format: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(dayjs)]),
};

DateViewer.defaultProps = {
  format: undefined,
};

export default DateViewer;
