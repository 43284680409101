import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import DeliveryLocationsTable from '../../settings/DeliveryLocationsTable';
import { withPageTitle } from '../../../utils/page-title';

const DeliveryLocationsPage = () => <DeliveryLocationsTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des lieux de livraison'),
  securedPage(roles.deliveryLocation.view.code),
)(DeliveryLocationsPage);
