import { FDD_GOALS_LOADED, FDD_GOAL_ADDED, FDD_GOAL_SAVED, FDD_GOAL_DELETED, urls } from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError } from '../SnackbarActions';

const goalsCollection = api.all(urls.fdd.goals);
goalsCollection.isFetchNeeded = true;

export function loadGoals() {
  return (dispatch) => {
    goalsCollection
      .getAll()
      .then((response) => {
        const list = response.body(false);
        if (list && list.length) {
          goalsCollection.isFetchNeeded = false;
        }
        dispatch({
          type: FDD_GOALS_LOADED,
          list,
        });
      })
      .catch(ignore401);
  };
}

export function loadGoalsIfNeeded() {
  return (dispatch) => {
    if (goalsCollection.isFetchNeeded) {
      loadGoals()(dispatch);
    }
  };
}

export function addGoal() {
  return {
    type: FDD_GOAL_ADDED,
  };
}

export function saveGoal(goal) {
  return (dispatch) => {
    const creation = !goal.id;
    const saveRequest = creation ? goalsCollection.post(goal) : goalsCollection.put(goal.id, goal);

    return saveRequest
      .then((response) => {
        let newGoal = goal;
        if (creation) {
          newGoal = response.body(false);
        }
        dispatch({
          type: FDD_GOAL_SAVED,
          item: {
            ...newGoal,
            editing: false,
          },
          creation,
        });
      })
      .catch(ignore401);
  };
}

export function deleteGoal(goal) {
  return (dispatch) => {
    if (goal.id) {
      goalsCollection
        .delete(goal.id)
        .then(() => {
          dispatch({
            type: FDD_GOAL_DELETED,
            id: goal.id,
          });
        })
        .catch(ignore401)
        .catch((err) => {
          console.error('Error while deleting:', err);
          if (err.response && err.response.data && err.response.statusCode === 400) {
            dispatch(addGlobalError(err.response.data.error));
          } else {
            dispatch(addGlobalError('Erreur lors de la suppression'));
          }
        });
    } else {
      dispatch({
        type: FDD_GOAL_DELETED,
        id: null,
      });
    }
  };
}
