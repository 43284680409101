import React from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { PlusCircle } from 'mdi-material-ui';
import Formsy from 'formsy-react';
import debugFactory from 'debug';

import { AutocompleteField, TextField } from '../utils';
import { addOperation } from '../../actions/InterventionOperationsActions';
import { operationHasNonDummyEquipment } from '../../utils/operation-utils';
import { addMaxValidationRule, addMinValidationRule } from '../../utils/validation-rules';

const debug = debugFactory('prestago:InterventionOperationAdd');

class InterventionOperationAdd extends React.Component {
  static errorMessages = {
    quantity: {
      isInt: 'Veuillez saisir un entier',
      min: 'Minimum 1',
      max: 'Maximum dépassé',
      isDefaultRequiredValue: 'Quantité obligatoire',
      isExisty: 'Quantité obligatoire',
    },
    operation: {
      isDefaultRequiredValue: 'Prestation obligatoire',
      isExisty: 'Prestation obligatoire',
    },
    deliveryLocationId: {
      isDefaultRequiredValue: 'Veuillez sélectionner un lieu',
      isExisty: 'Veuillez sélectionner un lieu',
    },
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      open: false,
      equipmentId: null,
      quantity: 0,
      maxQuantity: 0,
      deliveryLocationId: null,
    };
    addMinValidationRule();
    addMaxValidationRule();
  }

  onClose = () => {
    this.setState({ open: false });
  };

  onOpen = () => {
    this.setState({ open: true });
  };

  onOperationAdd = () => {
    this.form.current.submit();
  };

  onEquipmentIdChange = (equipmentId, operation) => {
    this.setState({
      equipmentId,
      maxQuantity: operation.maxQuantity,
    });
  };

  onQuantityChange = (event) => {
    this.setState({ quantity: event.target.value });
  };

  onDeliveryLocationChange = (value) => {
    this.setState({ deliveryLocationId: value });
  };

  onValidFormSubmit = () => {
    this.onClose();

    const { dispatch, interventionId, operations } = this.props;
    const { equipmentId, quantity, deliveryLocationId } = this.state;
    debug('onValidFormSubmit %s %d %s', equipmentId, quantity, deliveryLocationId);

    const operation = operations.find((op) => op.selectedEquipmentId === equipmentId);
    dispatch(
      addOperation(
        interventionId,
        {
          ...operation,
          quantity,
          displayed: true,
        },
        deliveryLocationId,
      ),
    );

    /*
     * Cleaning the state
     */
    this.setState({
      equipmentId: null,
      quantity: 0,
      maxQuantity: 0,
      deliveryLocationId: null,
    });
  };

  render() {
    const { label, operations, needsDelivery, deliveryLocations, className } = this.props;
    const { open, equipmentId, quantity, maxQuantity, deliveryLocationId } = this.state;

    const operation = equipmentId && operations.find((op) => op.selectedEquipmentId === equipmentId);
    const showDelivery = needsDelivery && operation && operationHasNonDummyEquipment(operation);

    return (
      operations.length > 0 && (
        <>
          <Button color="primary" onClick={this.onOpen} className={className}>
            <PlusCircle />
            {label}
          </Button>
          <Dialog open={open} onClose={this.onClose} maxWidth="sm" fullWidth>
            <DialogContent>
              <Formsy onValidSubmit={this.onValidFormSubmit} noValidate ref={this.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{label}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <AutocompleteField
                      options={operations}
                      getOptionValue={(op) => op.selectedEquipmentId}
                      getOptionLabel={(op) => op.equipments.find((eq) => eq.id === op.selectedEquipmentId).name}
                      label="Prestation"
                      fullWidth
                      name="operation"
                      onChange={this.onEquipmentIdChange}
                      required
                      value={equipmentId}
                      validations={{
                        isExisty: true,
                      }}
                      validationErrors={InterventionOperationAdd.errorMessages.operation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      label="Quantité"
                      fullWidth
                      name="quantity"
                      required
                      validations={{
                        isInt: true,
                        min: 1,
                        max: maxQuantity,
                        isExisty: true,
                      }}
                      validationErrors={{
                        ...InterventionOperationAdd.errorMessages.quantity,
                        max: `Maximum ${maxQuantity}`,
                      }}
                      min="1"
                      max={maxQuantity}
                      onChange={this.onQuantityChange}
                      value={quantity}
                    />
                  </Grid>
                  {showDelivery && (
                    <Grid item xs={12}>
                      <AutocompleteField
                        options={deliveryLocations}
                        label="Lieu de livraison"
                        fullWidth
                        required
                        name="deliveryLocationId"
                        onChange={this.onDeliveryLocationChange}
                        value={deliveryLocationId}
                        validations="isExisty"
                        validationErrors={InterventionOperationAdd.errorMessages.deliveryLocationId}
                      />
                    </Grid>
                  )}
                </Grid>
              </Formsy>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onClose}>Annuler</Button>
              <Button onClick={this.onOperationAdd} color="primary">
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
    );
  }
}

const stateToProps = ({
  intervention: {
    intervention: { id: interventionId },
  },
  deliveryLocations,
}) => ({
  interventionId,
  deliveryLocations,
});

export default connect(stateToProps)(InterventionOperationAdd);
