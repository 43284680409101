import React from 'react';
import { useParams } from 'react-router-dom';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';

import AfterSalesOperation from '../../after-sales/AfterSalesOperation';

const AfterSalesOperationPage = () => {
  const { afterSalesOperationId } = useParams();
  return <AfterSalesOperation afterSalesOperationId={afterSalesOperationId} />;
};

export default securedPage(roles.equipment.view.code)(AfterSalesOperationPage);
