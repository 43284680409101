import React from 'react';
import { compose } from 'recompose';

import Account from '../account/Account';
import ChangePassword from '../account/ChangePassword';
import securedPage from '../securedPage';
import { Grid, Typography } from '@mui/material';
import { withPageTitle } from '../../utils/page-title';

const AccountPage = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h1">Mon compte</Typography>
    </Grid>
    <Grid item xs={12} md={6} container>
      <Account />
    </Grid>
    <Grid item xs={12} md={6} container>
      <ChangePassword />
    </Grid>
  </Grid>
);

export default compose(
  withPageTitle(() => 'Mon compte'),
  securedPage(),
)(AccountPage);
