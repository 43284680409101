import React, { useEffect, useMemo } from 'react';
import { Card, Grid, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';

import { loadEquipments, sortEquipments } from '../../actions/settings/EquipmentActions';
import { invertSortDirection, sort, SortDirections } from '../../utils/sorting';
import { CardHeader, ResponsiveTable, SortableTableHeader } from '../utils';
import roles from '../../constants/roles';

import { formatCurrency } from '../../utils/currency-utils';
import 'intl';
import 'intl/locale-data/jsonp/fr';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { SortDirection } from '../../model/pagination';
import { useNavigate } from 'react-router-dom';
import Truck from 'mdi-material-ui/Truck';
import { Barcode } from 'mdi-material-ui';

const EquipmentsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadEquipments());
  }, [dispatch]);

  const equipments = useAppSelector((state) => state.equipments.equipments);
  const sorts = useAppSelector((state) => state.equipments.sorts);

  const sortedEquipments = useMemo(() => sort(equipments, sorts), [equipments, sorts]);

  const onNewEquipmentClick = () => {
    navigate('/settings/equipments/new');
  };

  const onHeaderClick = (field: string) => {
    const direction: SortDirection =
      sorts[0].field === field ? invertSortDirection(sorts[0].direction) : SortDirections.asc;
    dispatch(sortEquipments(field, direction));
  };

  const onRowClick = (equipmentId) => {
    navigate({ pathname: `/settings/equipments/${equipmentId}` });
  };

  const sortDirection = (field) => {
    if (sorts[0].field === field) {
      return sorts[0].direction;
    }
    return SortDirections.none;
  };

  return (
    <Card>
      <CardHeader
        addLabel="Créer un nouveau matériel"
        addRole={roles.equipment.create.code}
        onAdd={onNewEquipmentClick}
      >
        Paramétrage des matériels/prestations
      </CardHeader>
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <SortableTableHeader
              direction={sortDirection('code')}
              onClickCallback={() => onHeaderClick('code')}
              title="Code"
            />
            <SortableTableHeader
              direction={sortDirection('name')}
              onClickCallback={() => onHeaderClick('name')}
              title="Libellé"
            />
            <TableCell>Particularités</TableCell>
            <TableCell>Prix unitaire</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedEquipments.map((eq) => (
            <TableRow
              key={eq.id}
              onClick={() => onRowClick(eq.id)}
              hover
              sx={
                eq.compatibleConcepts.length === 0 && {
                  '& .MuiTableCell-root': {
                    opacity: 0.4,
                  },
                }
              }
            >
              <TableCell>{eq.code}</TableCell>
              <TableCell>{eq.name}</TableCell>
              <TableCell>
                <Grid container spacing={1}>
                  <Grid item width={32}>
                    {eq.fillSerialNumber && (
                      <Tooltip title="Avec numéro de série">
                        <Barcode />
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item width={32}>
                    {eq.type === 'UNIT' && !eq.dummy && (
                      <Tooltip title="Avec matériel/livraison">
                        <Truck />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">{eq.type === 'UNIT' ? formatCurrency(eq.unitPrice) : 'Kit'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ResponsiveTable>
    </Card>
  );
};

export default EquipmentsTable;
