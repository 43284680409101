/**
 * Returns the displayable status of the given user.
 *
 * @param user
 * @returns {string} The status.
 */
import { USER_STATUSES } from '../constants/AppConstants';

// eslint-disable-next-line import/prefer-default-export
export const getUserStatus = (user) => {
  if (!user.active) {
    return USER_STATUSES.getById('INACTIVE');
  }
  if (user.blocked) {
    return USER_STATUSES.getById('BLOCKED');
  }
  return USER_STATUSES.getById('ACTIVE');
};
