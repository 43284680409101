import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Formsy from 'formsy-react';
import RangeDatePicker from '../commons/RangeDatePicker';
import { updateInterventionsFilter } from '../../actions/InterventionActions';
import { User } from '../../model/user';
import { COMPANIES, INSTALLERS } from '../../constants/AppConstants';
import { AutocompleteField, FilterCard } from '../utils';
import { todayDateRange } from '../../utils/date-utils';
import { updateDayActivityFilter } from '../../actions/DayActivityActions';
import { AgencyAutocompleteField, RegionAutocompleteField } from '../commons/autocompleteFields';

/**
 * Get initial filter state.
 */
const initializeFilters = (user, filter) => {
  const usr = new User(user);
  const company = COMPANIES.getById(usr.company);

  return {
    isRegionDisabled: usr.isRegional() || usr.isAgency() || usr.isArea(),
    isAgencyDisabled: usr.isAgency() || usr.isArea(),
    isSubcontractorDisabled: company && company.isSubcontractor,
    regionIds: usr.isRegional() ? [usr.regionId] : filter.regionIds || [],
    agencyIds: usr.isAgency() ? [usr.agencyId] : filter.agencyIds || [],
    subcontractors: company && company.isSubcontractor ? [user.company] : filter.subcontractors || [],
    scheduledDateRange: filter.scheduledDateRange || todayDateRange(),
  };
};

class DayActivityFilters extends Component {
  constructor(props) {
    super(props);
    this.state = initializeFilters(props.user, props.filter);
  }

  UNSAFE_componentWillReceiveProps({ user, filter }) {
    this.setState(initializeFilters(user, filter));
  }

  /**
   * Change callback that occurs on children filter component selection.
   */
  onChange = (fieldName) => (value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  /**
   * Change callback that occurs when a date selection changes.
   *
   * @param fieldName field name targeted by filter change
   * @param state current state of field
   */
  onDateChange = (fieldName, state) => {
    const newState = {};
    newState[fieldName] = state;
    this.setState(newState);
  };

  /**
   * Triggers the intervention list fetch action with filters defined by user.
   */
  onFilter = () => {
    updateDayActivityFilter(this.getFilter());
  };

  /**
   * Resets state and fetch from server.
   *
   * @param event
   */
  onResetClick = (event) => {
    event.preventDefault();
    const { user } = this.props;

    // Reset filter (setState returns a promise)
    this.setState(initializeFilters(user, {}), () => {
      updateInterventionsFilter({});
    });
  };

  getFilter() {
    // Clean state
    const { ...refinedState } = this.state;
    delete refinedState.isRegionDisabled;
    delete refinedState.isAgencyDisabled;
    delete refinedState.isSubcontractorDisabled;
    if (refinedState.scheduledDateRange.isEmpty()) {
      delete refinedState.scheduledDateRange;
    }
    return refinedState;
  }

  hasFilters = () => {
    const { isRegionDisabled, isAgencyDisabled, isSubcontractorDisabled } = this.state;
    const defaultDateRange = todayDateRange();
    const {
      filter: {
        number = [],
        regionIds = [],
        agencyIds = [],
        subcontractors = [],
        scheduledDateRange = defaultDateRange,
      },
    } = this.props;

    const isScheduledDateRangeFilterInDefaultPosition =
      defaultDateRange.minDate.isSame(scheduledDateRange.minDate, 'day') &&
      defaultDateRange.maxDate.isSame(scheduledDateRange.maxDate, 'day');

    return Boolean(
      number.length ||
        (regionIds.length && !isRegionDisabled) ||
        (agencyIds.length && !isAgencyDisabled) ||
        (subcontractors.length && !isSubcontractorDisabled) ||
        !isScheduledDateRangeFilterInDefaultPosition,
    );
  };

  render() {
    const {
      regionIds,
      agencyIds,
      isRegionDisabled,
      isAgencyDisabled,
      isSubcontractorDisabled,
      subcontractors,
      scheduledDateRange,
    } = this.state;

    return (
      <Formsy noValidate onSubmit={this.onFilter}>
        <FilterCard
          hasFilters={this.hasFilters()}
          fullTextEnabled={false}
          searchButtonText="Voir l'activité"
          onResetClick={this.onResetClick}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegionAutocompleteField
                    multiple
                    name="regionIds"
                    value={regionIds}
                    label="Régions"
                    placeholder="Filtrer par région"
                    disabled={isRegionDisabled}
                    onChange={this.onChange('regionIds')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AgencyAutocompleteField
                    multiple
                    regionIds={regionIds}
                    name="agencyIds"
                    value={agencyIds}
                    label="Zones de vente"
                    placeholder="Filtrer par zone de vente"
                    disabled={isAgencyDisabled}
                    onChange={this.onChange('agencyIds')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RangeDatePicker
                    minRequired={true}
                    maxRequired={true}
                    maxClearable={false}
                    minClearable={false}
                    label="Planifiée entre :"
                    name="scheduledDateRange"
                    range={scheduledDateRange}
                    onChange={this.onDateChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    multiple
                    options={INSTALLERS}
                    label="Prestataire"
                    placeholder="Filtrer par prestataire"
                    fullWidth
                    name="subcontractors"
                    value={subcontractors}
                    disabled={isSubcontractorDisabled}
                    onChange={this.onChange('subcontractors')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FilterCard>
      </Formsy>
    );
  }
}

const mapStateToProps = ({
  agencies: { list: agencies },
  currentUser: { user },
  dayActivity: { filter },
  regions,
}) => ({
  user,
  filter,
  regions,
  agencies,
});

export default connect(mapStateToProps)(DayActivityFilters);
