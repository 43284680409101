import { REGIONS_LOADED, REGION_ADDED, REGION_SAVED, REGION_DELETED } from '../../constants/AppConstants';

const initialState = [];
initialState.showNew = false;

export default function (state = initialState, action) {
  Object.freeze(state);
  // console.info(action.type);
  switch (action.type) {
    case REGIONS_LOADED: {
      const newState = [...action.regions];
      newState.showNew = state.showNew;
      return newState;
    }
    case REGION_ADDED: {
      const newState = [...state];
      newState.showNew = true;
      return newState;
    }
    case REGION_SAVED: {
      if (action.creation) {
        return [...state, action.region]; // showNew = false
      }
      const newState = state.map((region) => (region.id === action.region.id ? action.region : region));
      newState.showNew = state.showNew;
      return newState;
    }
    case REGION_DELETED: {
      if (!action.id) {
        return [...state]; // showNew = false
      }
      const newState = state.filter((region) => region.id !== action.id);
      newState.showNew = state.showNew;
      return newState;
    }
    default:
      return state;
  }
}
