import React from 'react';
import { compose } from 'recompose';

import AreasTable from '../../settings/AreasTable';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { withPageTitle } from '../../../utils/page-title';

const AreasPage = () => <AreasTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des secteurs'),
  securedPage(roles.configuration.view.code),
)(AreasPage);
