import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import InterventionEquipmentsExportFilters from '../../interventions/InterventionEquipmentsExportFilters';
import { withPageTitle } from '../../../utils/page-title';

export default compose(
  withPageTitle(() => 'Export des matériels'),
  securedPage(roles.intervention.equipmentExport.code),
)(InterventionEquipmentsExportFilters);
