import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { defaultDatePickerAttributes } from '../utils/DatePickerField';

import 'intl';
import 'intl/locale-data/jsonp/fr';
import { DateRange } from '../../model/range';
import LabelValue from '../utils/LabelValue';

const normalizeDate = (date) => date && date.startOf('day').format();

class RangeDatePicker extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    range: PropTypes.object.isRequired,
    minClearable: PropTypes.bool,
    maxClearable: PropTypes.bool,
    minRequired: PropTypes.bool,
    maxRequired: PropTypes.bool,
  };

  static getDerivedStateFromProps({ range }) {
    return {
      minDate: range.minDate || null,
      maxDate: range.maxDate || null,
    };
  }

  state = {
    minDate: null,
    maxDate: null,
  };

  onChangeMinDate = (value) => {
    const { name, onChange } = this.props;
    const minDate = normalizeDate(value);
    this.setState(({ maxDate }) => {
      onChange(name, new DateRange({ minDate, maxDate }));
      return { minDate };
    });
  };

  onChangeMaxDate = (value) => {
    const { name, onChange } = this.props;
    const maxDate = normalizeDate(value);
    this.setState(({ minDate }) => {
      onChange(name, new DateRange({ minDate, maxDate }));
      return { maxDate };
    });
  };

  render() {
    const { minDate, maxDate } = this.state;
    const {
      label,
      minRequired,
      maxRequired,
      minClearable,
      maxClearable,
      range, // eslint-disable-line no-unused-vars
      ...others
    } = this.props;
    return (
      <LabelValue label={label} text={false} fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              {...defaultDatePickerAttributes}
              {...others}
              slotProps={{
                textField: { fullWidth: true },
              }}
              placeholder="Date min"
              onChange={this.onChangeMinDate}
              value={minDate && dayjs(minDate) > dayjs(0) ? dayjs(minDate) : null}
              maxDate={maxDate ? dayjs(maxDate) : undefined}
              required={minRequired ?? false}
              clearable={minClearable ?? false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              {...defaultDatePickerAttributes}
              {...others}
              slotProps={{
                textField: { fullWidth: true },
              }}
              placeholder="Date max"
              onChange={this.onChangeMaxDate}
              value={maxDate ? dayjs(maxDate) : null}
              required={maxRequired ?? false}
              minDate={minDate && dayjs(minDate) > dayjs(0) ? dayjs(minDate) : undefined}
              clearable={maxClearable ?? false}
            />
          </Grid>
        </Grid>
      </LabelValue>
    );
  }
}

export default RangeDatePicker;
