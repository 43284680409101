import {
  DELIVERY_LOCATIONS_LOADED,
  DELIVERY_LOCATION_ADDED,
  DELIVERY_LOCATION_SAVED,
  DELIVERY_LOCATION_DELETED,
  getByIdDecorator,
} from '../constants/AppConstants';
import { sort, SortDirections } from '../utils/sorting';

/**
 * The delivery locations compatible with an intervention : those owned by the same subcontractor or by the PMU.
 */
export const filterDeliveryLocationsByCompany = (deliveryLocations, intervention) =>
  deliveryLocations &&
  deliveryLocations.filter(
    (deliveryLocation) =>
      deliveryLocation.company === 'PMU' || deliveryLocation.company === intervention?.subcontractor,
  );

const sortByNameAndDecorate = (list) => {
  const sortedList = getByIdDecorator(sort(list, [{ field: 'name', direction: SortDirections.asc }]));
  sortedList.showNew = list.showNew;
  return sortedList;
};

const initialState = getByIdDecorator([]);
initialState.showNew = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_LOCATIONS_LOADED: {
      const newState = [...action.list];
      newState.showNew = state.showNew;
      return sortByNameAndDecorate(newState);
    }
    case DELIVERY_LOCATION_ADDED: {
      const newState = [...state];
      newState.showNew = true;
      return sortByNameAndDecorate(newState);
    }
    case DELIVERY_LOCATION_SAVED: {
      if (action.creation) {
        return sortByNameAndDecorate([...state, action.item]);
      }
      const newState = state.map((location) => (location.id === action.item.id ? action.item : location));
      newState.showNew = state.showNew;
      return sortByNameAndDecorate(newState);
    }
    case DELIVERY_LOCATION_DELETED: {
      if (!action.id) {
        return sortByNameAndDecorate([...state]);
      }
      const newState = state.filter((location) => location.id !== action.id);
      newState.showNew = state.showNew;
      return sortByNameAndDecorate(newState);
    }
    default:
      return state;
  }
}
