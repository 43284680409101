import React from 'react';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import StockEquipmentReception from '../../stock-equipments/StockEquipmentReception';
import { useParams } from 'react-router-dom';

const StockEquipmentReceptionPage = () => {
  usePageTitle('Réception de matériel');
  const { stockOutletCode } = useParams();

  return <StockEquipmentReception stockOutletCode={stockOutletCode} />;
};

export default securedPage(roles.stockEquipments.edit.code)(StockEquipmentReceptionPage);
