import React from 'react';
import { Table } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    overflowX: 'auto',
    minHeight: '0.01%',
  },
};

const ResponsiveTable = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Table {...props} />
  </div>
);

export default withStyles(styles)(ResponsiveTable);
