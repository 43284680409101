import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, ListItem, ListItemText, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Pagination from '../commons/Pagination';
import { ResponsiveTable, SortableTableHeader } from '../utils';
import { SortDirections } from '../../utils/sorting';
import { updateOutletsFilter } from '../../actions/OutletActions';
import history from '../../history';
import Hidden from '@mui/material/Hidden';
import { compose } from 'recompose';

const styles = (theme) => ({
  list: {
    padding: 0,
  },
  listRow: {
    '&:nth-of-type(2n)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  listIcon: {
    color: theme.palette.text.primary,
  },
  muted: {
    fontStyle: 'italic',
    color: theme.palette.grey[500],
  },
});

class OutletsTable extends Component {
  static propTypes = {
    outlets: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  onPageClick = (pageNumber) => {
    const {
      pagination: { ...pagination },
    } = this.props;
    pagination.currentPage = pageNumber;
    updateOutletsFilter(pagination);
  };

  onPageSizeChange = (pageSize) => {
    const {
      pagination: { ...pagination },
    } = this.props;

    // Force a pagination restart @ 0
    pagination.currentPage = 0;
    pagination.pageSize = pageSize;
    updateOutletsFilter(pagination);
  };

  onHeaderClick = (field) => () => {
    const {
      pagination: { ...pagination },
    } = this.props;
    pagination.sort = { field, direction: this.nextDirection(field) };
    updateOutletsFilter(pagination);
  };

  onRowClick = (outletId) => () => {
    history.push({ pathname: `/outlets/${outletId}` });
  };

  sortDirection = (field) => {
    const { sort } = this.props.pagination;
    return sort.field === field ? sort.direction : SortDirections.none;
  };

  nextDirection(field) {
    const { sort } = this.props.pagination;
    return sort.field === field && sort.direction === SortDirections.asc ? SortDirections.desc : SortDirections.asc;
  }

  render() {
    const { outlets, pagination, classes } = this.props;
    return (
      <div>
        <Hidden mdUp>
          <List className={classes.list}>
            {Boolean(outlets.length) &&
              outlets.map((outlet) => (
                <ListItem key={outlet.id} className={classes.listRow} onClick={this.onRowClick(outlet.id)}>
                  <ListItemText
                    primary={
                      <>
                        <strong>{outlet.code + ' - ' + outlet.name}</strong>
                        <span style={{ color: outlet.active ? 'inherit' : 'grey' }}>
                          {outlet.active ? '' : ' (en erreur)'}
                        </span>
                        <br />
                        {outlet.agency && outlet.agency.name}{' '}
                        <span className={classes.muted}>{outlet.area && '(' + outlet.area.name + ')'}</span>
                      </>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Hidden>
        <Hidden mdDown>
          <ResponsiveTable>
            <TableHead>
              <TableRow>
                <SortableTableHeader
                  direction={this.sortDirection('region_name')}
                  onClickCallback={this.onHeaderClick('region_name')}
                  title="Région"
                />
                <SortableTableHeader
                  direction={this.sortDirection('agency_name')}
                  onClickCallback={this.onHeaderClick('agency_name')}
                  title="Zone de vente"
                />
                <SortableTableHeader
                  direction={this.sortDirection('area_name')}
                  onClickCallback={this.onHeaderClick('area_name')}
                  title="Secteur"
                />
                <SortableTableHeader
                  direction={this.sortDirection('code')}
                  onClickCallback={this.onHeaderClick('code')}
                  title="Code PDV"
                />
                <SortableTableHeader
                  direction={this.sortDirection('name')}
                  onClickCallback={this.onHeaderClick('name')}
                  title="Enseigne"
                />
                <SortableTableHeader
                  direction={this.sortDirection('address.city')}
                  onClickCallback={this.onHeaderClick('address.city')}
                  title="Ville"
                />
                <TableCell>Demande(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outlets.length ? (
                outlets.map((outlet) => (
                  <TableRow key={outlet.code} onClick={this.onRowClick(outlet.id)} hover>
                    <TableCell>{outlet.region && outlet.region.name}</TableCell>
                    <TableCell>{outlet.agency && outlet.agency.name}</TableCell>
                    <TableCell>{outlet.area && outlet.area.name}</TableCell>
                    <TableCell style={{ color: outlet.active ? 'inherit' : 'grey' }}>
                      {outlet.code} {outlet.active ? '' : '(en erreur)'}
                    </TableCell>
                    <TableCell>{outlet.name}</TableCell>
                    <TableCell>{outlet.city}</TableCell>
                    <TableCell>
                      {outlet.demandTypes && outlet.demandTypes.map((demandType) => demandType.name).join(', ')}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="7">Aucun point de vente ne correspond aux critères renseignés</TableCell>
                </TableRow>
              )}
            </TableBody>
          </ResponsiveTable>
        </Hidden>
        <Pagination
          currentPage={pagination.currentPage}
          numberOfPages={pagination.numberOfPages}
          pageSize={pagination.pageSize}
          onPageChange={this.onPageClick}
          onPageSizeChange={this.onPageSizeChange}
        />
      </div>
    );
  }
}

const stateToProps = ({ outlets: { list: outlets, pagination } }) => ({
  // Sorted by server
  outlets,
  pagination,
});

export default compose(withStyles(styles), connect(stateToProps))(OutletsTable);
