import React, { Component } from 'react';
import {
  Dialog,
  Button,
  CardContent,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Attachment } from 'mdi-material-ui';

import DateTimeViewer from '../commons/DateTimeViewer';
import { DEMAND_STATUSES } from '../../constants/AppConstants';
import { LabelValue } from '../utils';
import ResponsiveTable from '../utils/ResponsiveTable';
import AttachmentsZone from '../utils/AttachmentsZone';

const styles = {
  dialogContent: {
    minWidth: 300,
  },
  comment: {
    whiteSpace: 'pre-wrap',
  },
};

class DemandHistory extends Component {
  state = {
    detailsOpened: false,
    eventDetails: null,
  };

  onClose = () => {
    this.setState({ detailsOpened: false });
  };

  onRowClick = (event) => () => {
    this.setState({
      detailsOpened: true,
      eventDetails: event,
    });
  };

  render() {
    const { events, classes } = this.props;
    const { detailsOpened, eventDetails } = this.state;
    return !events || events.length === 0 ? (
      <CardContent>
        <Typography variant="body1">Cette demande n'a pas encore été créée.</Typography>
      </CardContent>
    ) : (
      <>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Événement</TableCell>
              <TableCell>Auteur</TableCell>
              <TableCell>Message</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {events &&
              events.map((event) => (
                <TableRow key={`event-${event.createdDate}`} onClick={this.onRowClick(event)} hover>
                  <TableCell>
                    <DateTimeViewer date={event.createdDate} />
                  </TableCell>
                  <TableCell>{DEMAND_STATUSES.getNameById(event.demandStatus)}</TableCell>
                  <TableCell>
                    {event.user ? `${event.user.firstName} ${event.user.lastName}` : 'Import Demande'}
                  </TableCell>
                  <TableCell className={classes.comment}>{event.comment}</TableCell>
                  <TableCell align="right">
                    {event.attachments && Object.keys(event.attachments).length > 0 && <Attachment />}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </ResponsiveTable>
        <Dialog
          open={detailsOpened}
          onClose={this.onClose}
          maxWidth={
            eventDetails && eventDetails.attachments && Object.keys(eventDetails.attachments).length > 0 ? 'md' : 'sm'
          }
          fullWidth
        >
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LabelValue label="Commentaires" classes={{ content: classes.comment }}>
                  {eventDetails && eventDetails.comment}
                </LabelValue>
              </Grid>
              {eventDetails && eventDetails.attachments && Object.keys(eventDetails.attachments).length > 0 && (
                <Grid item xs={12}>
                  <LabelValue label="Pièces jointes" text={false}>
                    <AttachmentsZone attachments={Object.values(eventDetails.attachments)} readOnly cols={4} />
                  </LabelValue>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(DemandHistory);
