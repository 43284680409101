import { hasRole } from './SecurityService';
import roles from '../constants/roles';
import { COMPANIES } from '../constants/AppConstants';

/**
 * The demand statuses compatible with an intervention creation
 *
 * @type {string[]}
 */
const demandInterventionStatuses = [
  '_040_OPERATIONS_PROCESSING',
  '_045_TO_RESCHEDULE',
  '_050_OPERATIONS_SCHEDULED',
  '_060_OPERATIONS_ON_GOING',
  '_090_OPERATIONS_RESERVE_REMOVING',
];

const demandTerminalStatuses = ['_100_DELETED', '_110_CANCELLED', '_120_ACCEPTED_WITHOUT_RESERVE'];

export function checkCanCreateIntervention(currentUser, demandStatus) {
  return hasRole(currentUser, roles.intervention.create.code) && demandInterventionStatuses.indexOf(demandStatus) >= 0;
}

/**
 * Returns a boolean indicating whether the demand can be modified by the current user.
 *
 * The demand can be modified by a PMU user until its validation. It can also
 * be modified by the subcontractor user between the demand validation and the
 * moment the PMU is asked to receive the demand.
 *
 * @param currentUser The current user stored in Redux.
 * @param demandStatus The demand status.
 * @returns {boolean} true if the demand can be modified.
 */
export function checkDemandCanBeModified(currentUser, demandStatus) {
  const userCompany = currentUser.user && COMPANIES.getById(currentUser.user.company);

  const pmuStates = ['_010_EDITING', '_015_REJECTED'];
  const pmuValidateStates = ['_020_AWAITING_PMU_VALIDATION'];
  const subcontractorStates = ['_040_OPERATIONS_PROCESSING', '_050_OPERATIONS_SCHEDULED'];

  return (
    hasRole(currentUser, roles.demand.edit.code) &&
    (!userCompany ||
      (!userCompany.isSubcontractor &&
        (pmuStates.includes(demandStatus) ||
          (pmuValidateStates.includes(demandStatus) && hasRole(currentUser, roles.demand.validate.code)))) ||
      (userCompany.isSubcontractor && subcontractorStates.includes(demandStatus)))
  );
}

export function checkDemandCanUpdateAttachments(currentUser, demandStatus) {
  return hasRole(currentUser, roles.demand.edit.code) && !demandTerminalStatuses.includes(demandStatus);
}

/**
 * Check whether the demand status allows to update the demand "core" fields.
 */
export function checkDemandCoreLocked(demandStatus) {
  return !['_010_EDITING', '_015_REJECTED', '_020_AWAITING_PMU_VALIDATION', '_030_VALID'].includes(demandStatus);
}
