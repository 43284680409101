import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import RegionsTable from '../../settings/RegionsTable';
import { withPageTitle } from '../../../utils/page-title';

const RegionsPage = () => <RegionsTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des régions'),
  securedPage(roles.configuration.view.code),
)(RegionsPage);
