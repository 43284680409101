import {
  FDD_AGREEMENT_TYPES_LOADED,
  FDD_AGREEMENT_TYPE_ADDED,
  FDD_AGREEMENT_TYPE_SAVED,
  FDD_AGREEMENT_TYPE_DELETED,
  urls,
} from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';
import { addGlobalError } from '../SnackbarActions';

const agreementTypesCollection = api.all(urls.fdd.agreementTypes);
agreementTypesCollection.isFetchNeeded = true;

export function loadAgreementTypes() {
  return (dispatch) => {
    agreementTypesCollection
      .getAll()
      .then((response) => {
        const list = response.body(false);
        if (list && list.length) {
          agreementTypesCollection.isFetchNeeded = false;
        }
        dispatch({
          type: FDD_AGREEMENT_TYPES_LOADED,
          list,
        });
      })
      .catch(ignore401);
  };
}

export function loadAgreementTypesIfNeeded() {
  return (dispatch) => {
    if (agreementTypesCollection.isFetchNeeded) {
      loadAgreementTypes()(dispatch);
    }
  };
}

export function addAgreementType() {
  return {
    type: FDD_AGREEMENT_TYPE_ADDED,
  };
}

export function saveAgreementType(agreementType) {
  return (dispatch) => {
    const creation = !agreementType.id;
    const saveRequest = creation
      ? agreementTypesCollection.post(agreementType)
      : agreementTypesCollection.put(agreementType.id, agreementType);

    return saveRequest
      .then((response) => {
        let newAgreementType = agreementType;
        if (creation) {
          newAgreementType = response.body(false);
        }
        dispatch({
          type: FDD_AGREEMENT_TYPE_SAVED,
          item: {
            ...newAgreementType,
            editing: false,
          },
          creation,
        });
      })
      .catch(ignore401);
  };
}

export function deleteAgreementType(agreementType) {
  return (dispatch) => {
    if (agreementType.id) {
      agreementTypesCollection
        .delete(agreementType.id)
        .then(() => {
          dispatch({
            type: FDD_AGREEMENT_TYPE_DELETED,
            id: agreementType.id,
          });
        })
        .catch(ignore401)
        .catch((err) => {
          console.error('Error while deleting:', err);
          if (err.response && err.response.data && err.response.statusCode === 400) {
            dispatch(addGlobalError(err.response.data.error));
          } else {
            dispatch(addGlobalError('Erreur lors de la suppression'));
          }
        });
    } else {
      dispatch({
        type: FDD_AGREEMENT_TYPE_DELETED,
        id: null,
      });
    }
  };
}
