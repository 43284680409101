import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { withFormsy } from 'formsy-react';

import { getNonFormsyProps } from '../../utils/formsy-utils';
import { DateValue, defaultDateTimeFormat } from '../../utils/date-utils';
import dayjs, { isDayjs } from 'dayjs';
import { InjectedProps } from 'formsy-react/src/withFormsy';
import { InnerDatePickerFieldProps } from './DatePickerField';

type InnerDateTimePickerFieldProps = Omit<InnerDatePickerFieldProps, 'convertDateToString'> & {
  convertDateTimeToString: InnerDatePickerFieldProps['convertDateToString'];
};

export const defaultDateTimePickerAttributes = {
  format: defaultDateTimeFormat,
  closeOnSelect: true,
  ampm: false,
};

const defaultConvertDateTimeToString = (value: DateValue): string | null => {
  if (isDayjs(value)) {
    return value.format();
  }
  return value;
};

const DateTimePickerField = (props: InnerDateTimePickerFieldProps & InjectedProps<DateValue>) => {
  const {
    value,
    setValue,
    isPristine,
    errorMessage,
    fullWidth,
    onChange,
    convertDateTimeToString = defaultConvertDateTimeToString,
  } = props;

  const handleChange = (value: DateValue) => {
    const stringValue = convertDateTimeToString(value);
    setValue(stringValue);
    onChange?.(stringValue);
  };

  const errorText = isPristine ? '' : errorMessage;
  const childProps = getNonFormsyProps(props);

  return (
    <FormControl error={Boolean(errorText)} fullWidth={fullWidth}>
      <MUIDateTimePicker
        {...defaultDateTimePickerAttributes}
        {...childProps}
        onChange={handleChange}
        // Keep the timezone when parsing the date
        value={value ? dayjs.parseZone(value) : null}
        slotProps={{
          textField: {
            InputLabelProps: {
              shrink: true,
            },
          },
        }}
      />
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default withFormsy(DateTimePickerField);
