import {
  INTERVENTION_OPERATIONS_THAT_CAN_BE_ADDED_LIST,
  INTERVENTION_OPERATIONS,
  INTERVENTION_OPERATION_ADDED,
  INTERVENTION_OPERATION_UPDATE_COMPLETED_QUANTITY,
  INTERVENTION_OPERATION_UPDATE_QUANTITY,
} from '../constants/AppConstants';

const initialState = {
  addableOperations: {
    addOperations: [],
    moveOperations: [],
    removeOperations: [],
  },
  operations: {
    addOperations: [],
    moveOperations: [],
    removeOperations: [],
  },
};

/**
 * Removes the given operation from the given list if the operation has the
 * given type.
 *
 * @param operations The array of operations.
 * @param op The operation to remove.
 * @param operationsType The expected type of operations.
 * @returns {*} The array of operations.
 */
function remove(operations, op, operationsType) {
  if (op.type !== operationsType) {
    return operations;
  }
  return operations.filter((operation) => operation.equipments.every((eq) => op.selectedEquipmentId !== eq.id));
}

/**
 * Adds the given operation to the given list if the operation has the
 * given type.
 *
 * @param operations The array of operations.
 * @param op The operation to add.
 * @param operationsType The expected type of operations.
 * @returns {*} The array of operations.
 */
function add(operations, op, operationsType) {
  if (op.type !== operationsType) {
    return operations;
  }
  return operations.concat(op);
}

function changeCompleted(operations, equipmentId, completedQuantity) {
  return operations.map((op) =>
    op.selectedEquipmentId === equipmentId
      ? {
          ...op,
          completedQuantity,
        }
      : op,
  );
}

function changeQuantity(operations, equipmentId, quantity) {
  return operations.map((op) =>
    op.selectedEquipmentId === equipmentId
      ? {
          ...op,
          quantity,
        }
      : op,
  );
}

export default function (state = initialState, action) {
  switch (action.type) {
    case INTERVENTION_OPERATIONS_THAT_CAN_BE_ADDED_LIST:
      return {
        ...state,
        addableOperations: action.operations,
      };
    case INTERVENTION_OPERATIONS:
      return {
        ...state,
        operations: action.operations,
      };
    case INTERVENTION_OPERATION_ADDED: {
      const operation = action.operation;
      return {
        addableOperations: {
          addOperations: remove(state.addableOperations.addOperations, operation, 'ADD'),
          moveOperations: remove(state.addableOperations.moveOperations, operation, 'MOVE'),
          removeOperations: remove(state.addableOperations.removeOperations, operation, 'REMOVE'),
        },
        operations: {
          addOperations: add(state.operations.addOperations, operation, 'ADD'),
          moveOperations: add(state.operations.moveOperations, operation, 'MOVE'),
          removeOperations: add(state.operations.removeOperations, operation, 'REMOVE'),
        },
      };
    }
    case INTERVENTION_OPERATION_UPDATE_COMPLETED_QUANTITY: {
      const { operationType, completedQuantity, equipmentId } = action;
      const { addOperations, moveOperations, removeOperations } = state.operations;
      return {
        ...state,
        operations: {
          addOperations:
            operationType === 'ADD' ? changeCompleted(addOperations, equipmentId, completedQuantity) : addOperations,
          moveOperations:
            operationType === 'MOVE' ? changeCompleted(moveOperations, equipmentId, completedQuantity) : moveOperations,
          removeOperations:
            operationType === 'REMOVE'
              ? changeCompleted(removeOperations, equipmentId, completedQuantity)
              : removeOperations,
        },
      };
    }
    case INTERVENTION_OPERATION_UPDATE_QUANTITY: {
      const { operationType, quantity, equipmentId } = action;
      const { addOperations, moveOperations, removeOperations } = state.operations;
      return {
        ...state,
        operations: {
          addOperations: operationType === 'ADD' ? changeQuantity(addOperations, equipmentId, quantity) : addOperations,
          moveOperations:
            operationType === 'MOVE' ? changeQuantity(moveOperations, equipmentId, quantity) : moveOperations,
          removeOperations:
            operationType === 'REMOVE' ? changeQuantity(removeOperations, equipmentId, quantity) : removeOperations,
        },
      };
    }
    default:
      return state;
  }
}
