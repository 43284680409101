/* global google */
import React, { memo, useState } from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';
import { INTERVENTION_STATUS_COLORS } from '../../constants/AppConstants';
import { formatDate } from '../../utils/date-utils';
import { lightGrey } from '../utils/colors';
import { Box, Button, Popover, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Intervention } from '../../model/model';

type InterventionMarkerProps = {
  outlet: any;
  interventions: Intervention[];
};

const calendarTodayPath =
  'M7,10H12V15H7M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z';
const progressClockPath =
  'M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z';
const checkCirclePath =
  'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z';

const interventionLabel = (intervention) => {
  const label = `${intervention.number} - ${formatDate(intervention.operationScheduleDate)} - ${
    intervention.status.name
  }`;
  if (intervention.status.id === '_020_SCHEDULED') {
    let detailedStatus;
    if (intervention.operationFinishedDate) {
      detailedStatus = 'Effectuée';
    } else if (intervention.operationStartedDate) {
      detailedStatus = 'Démarrée';
    } else {
      detailedStatus = 'Non démarrée';
    }
    return `${label} - ${detailedStatus}`;
  }
  return label;
};

/**
 * Component in charge of displaying the intervention marker on the map.
 */
const InterventionMarker = ({ interventions, outlet }: InterventionMarkerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [zIndex, setZIndex] = useState(2);
  const [opacity, setOpacity] = useState(0.8);

  const activate = () => {
    setZIndex(3);
    setOpacity(1);
  };
  const deactivate = () => {
    setZIndex(2);
    setOpacity(0.8);
  };
  const navigateToIntervention = (interventionId) => navigate(`/interventions/${interventionId}`);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMarkerClick = (event: google.maps.MapMouseEvent) =>
    setAnchorEl((event.domEvent as MouseEvent).currentTarget);

  const handleLabelClick = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const statusColors = Object.values(INTERVENTION_STATUS_COLORS);
  const {
    status: markerStatus,
    operationStartedDate: markerStartedDate,
    operationFinishedDate: markerFinishedDate,
  } = [...interventions]
    .sort(
      (intervention1, intervention2) =>
        statusColors.indexOf(intervention2.status.color) - statusColors.indexOf(intervention1.status.color),
    )
    .map(({ status, operationStartedDate, operationFinishedDate }) => ({
      status,
      operationStartedDate,
      operationFinishedDate,
    }))[0];

  let markerIcon: google.maps.Symbol = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 3,
    anchor: new google.maps.Point(1, 0),
    strokeWeight: 2,
    fillColor: theme.palette.grey[800],
    strokeColor: theme.palette.grey[800],
  };
  if (markerStatus.id === '_020_SCHEDULED') {
    const externalIcon = {
      anchor: new google.maps.Point(16, 4),
      strokeWeight: 1.5,
    };
    if (markerFinishedDate) {
      markerIcon = {
        ...externalIcon,
        path: checkCirclePath,
      };
    } else if (markerStartedDate) {
      markerIcon = {
        ...externalIcon,
        path: progressClockPath,
      };
    } else {
      markerIcon = {
        ...externalIcon,
        path: calendarTodayPath,
      };
    }
  }

  const position: google.maps.LatLngLiteral = {
    lat: outlet.latitude,
    lng: outlet.longitude,
  };

  return (
    <>
      <Marker
        icon={markerIcon}
        position={position}
        onClick={handleMarkerClick}
        onMouseOut={deactivate}
        onMouseOver={activate}
        zIndex={zIndex}
      />
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={() => ({
          x: -4 * outlet.name.length,
          y: -22,
        })}
      >
        <Box
          sx={{
            cursor: 'pointer',
            backgroundColor: markerStatus.color,
            borderRadius: '3px',
            color: 'white',
            transform: `scale(${opacity})`,
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '3px',
            opacity,
          }}
          onClick={handleLabelClick}
          onMouseOut={deactivate}
          onMouseOver={activate}
        >
          {outlet.name}
        </Box>
      </OverlayView>
      <Popover
        id={outlet.name}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            fontWeight: 500,
            px: 1,
            py: 2,
            background: lightGrey,
          }}
        >
          {outlet.code} - {outlet.name}
        </Box>
        {interventions.map((intervention) => (
          <div key={intervention.id}>
            <Button onClick={() => navigateToIntervention(intervention.id)} fullWidth>
              <Typography style={{ color: intervention.status.color }} variant="body2">
                {interventionLabel(intervention)}
              </Typography>
            </Button>
          </div>
        ))}
      </Popover>
    </>
  );
};

export default memo(InterventionMarker);
