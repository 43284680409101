import { OPERATION_BUNDLES_LOADED, OPERATION_BUNDLES_SORT } from '../../constants/AppConstants';
import { computeSorts, SortDirections } from '../../utils/sorting';

const initialState = {
  list: [],
  sorts: [{ field: 'name', direction: SortDirections.asc }],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPERATION_BUNDLES_LOADED:
      return {
        ...state,
        list: action.bundles,
      };
    case OPERATION_BUNDLES_SORT:
      return {
        ...state,
        sorts: computeSorts(state.sorts, action.field, action.direction),
      };
    default:
      return state;
  }
}
