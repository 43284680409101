import React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, Button, DialogContent, DialogContentText, DialogActions, Grid } from '@mui/material';
import Formsy from 'formsy-react';
import { saveIntervention } from '../../actions/InterventionActions';
import InterventionGlobalInformation from './InterventionGlobalInformation';

export default class InterventionCreationDialog extends React.Component {
  static initialState = {
    comment: '',
    subcontractorReference: '',
    teamCode: '',
    submitting: false,
  };

  static propTypes = {
    demandId: PropTypes.string.isRequired,
    demandNumber: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      ...InterventionCreationDialog.initialState,
    };
  }

  UNSAFE_componentWillReceiveProps({ opened: nextOpened }) {
    const { opened } = this.props;
    if (nextOpened && !opened) {
      this.setState({ submitting: false });
    }
  }

  onChange = (fieldName) => (value) => {
    const newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  };

  onCreateButtonClick = () => {
    this.form.current.submit();
  };

  onSave = () => {
    const { demandId, dispatch } = this.props;
    const { comment, subcontractorReference, teamCode } = this.state;
    dispatch(
      saveIntervention({
        comment,
        demandId,
        subcontractorReference,
        teamCode,
      }),
    );
    this.setState({
      ...InterventionCreationDialog.initialState,
      submitting: true,
    });
  };

  getTitle = () => {
    const { demandNumber } = this.props;
    const title = 'Nouvelle intervention';
    return title + (demandNumber ? ` pour la demande ${demandNumber}` : '');
  };

  render() {
    const { onClose, opened } = this.props;
    const { comment, subcontractorReference, teamCode, submitting } = this.state;

    return (
      <Dialog open={opened} onClose={onClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>{this.getTitle()}</DialogContentText>
            </Grid>

            <Grid item xs={12}>
              <Formsy noValidate onValidSubmit={this.onSave} ref={this.form}>
                <InterventionGlobalInformation
                  subcontractorReference={subcontractorReference}
                  teamCode={teamCode}
                  comment={comment}
                  readOnly={false}
                  onChange={this.onChange}
                />
              </Formsy>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button color="primary" onClick={this.onCreateButtonClick} disabled={submitting}>
            Créer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
