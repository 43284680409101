import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { CardHeader, SortableTable } from '../../utils';

import { loadOptions, updatePosition } from '../../../actions/settings/OperationOptionActions';
import OperationOptionsTableRow from './OperationOptionsTableRow';

import roles from '../../../constants/roles';
import { hasRole } from '../../../services/SecurityService';
import history from '../../../history';

class OperationOptionsTable extends React.Component {
  static propTypes = {
    canEdit: PropTypes.bool,
    options: PropTypes.array,
  };

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadOptions());
  }

  onNewClick = () => {
    history.push('/settings/operation-options/new');
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const { dispatch, options } = this.props;
      const option = options[oldIndex];
      dispatch(updatePosition(option.id, oldIndex, newIndex));
    }
  };

  render() {
    const { canEdit, options } = this.props;
    return (
      <Card>
        <CardHeader addLabel="Créer une nouvelle option" addRole={roles.equipment.create.code} onAdd={this.onNewClick}>
          Paramétrage des options
        </CardHeader>
        <SortableTable onSortEnd={this.onSortEnd}>
          <TableHead>
            <TableRow>
              <TableCell>Libellé</TableCell>
              <TableCell>Types de demande</TableCell>
              <TableCell>Matériel</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Qté max</TableCell>
              <TableCell>Affichée par déf.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((option) => (
              <OperationOptionsTableRow key={option.id} index={option.position} disabled={!canEdit} option={option} />
            ))}
          </TableBody>
        </SortableTable>
      </Card>
    );
  }
}

const stateToProps = ({ currentUser, operationOptions: { options } }) => ({
  canEdit: hasRole(currentUser, roles.equipment.edit.code),
  options,
});

export default connect(stateToProps)(OperationOptionsTable);
