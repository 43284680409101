import { InjectedProps } from 'formsy-react/src/withFormsy';
import React, { ReactNode } from 'react';

export type ErrorTextProps = {
  displayMultipleErrors?: boolean;
};

/**
 * Returns the element containing the error messages for a form field.
 *
 * If {@code displayMultipleErrors} is {@code false}, then
 * only the first error is displayed. Otherwise, all errors are displayed.
 */
export const computeErrorText = <T,>({
  displayMultipleErrors = true,
  errorMessages,
  errorMessage,
  isPristine,
}: Pick<InjectedProps<T>, 'errorMessages' | 'errorMessage' | 'isPristine'> & ErrorTextProps): ReactNode => {
  if (isPristine) {
    return null;
  }
  if (displayMultipleErrors) {
    if (errorMessages.length === 0) {
      return null;
    }
    return (
      <span>
        {errorMessages.map((msg) => (
          <span key={`${msg}`}>
            {msg}
            <br />
          </span>
        ))}
      </span>
    );
  }
  return errorMessage;
};
