import React from 'react';
import * as PropTypes from 'prop-types';
import { DAYTIME_TYPES } from '../../constants/AppConstants';

const DayTimeViewer = ({ dayTime }) => {
  const constant = DAYTIME_TYPES.getById(dayTime);

  if (constant) {
    return <span>{constant.name}</span>;
  }
  return null;
};

DayTimeViewer.propTypes = {
  dayTime: PropTypes.string.isRequired,
};

export default DayTimeViewer;
