import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import Formsy from 'formsy-react';
import { Check, Close, Delete } from 'mdi-material-ui';

import { AutocompleteField, EditableTableRow, MultiValue } from '../utils';
import { deleteDemandType, saveDemandType } from '../../actions/DemandTypeActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import { CONCEPTS, COST_STRUCTURES, QUESTIONS } from '../../constants/AppConstants';
import SortableRow from '../utils/SortableRow';

class DemandTypeRow extends Component {
  static propTypes = {
    demandType: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    editing: PropTypes.bool.isRequired,
  };

  state = {
    demandType: this.props.demandType,
    editing: this.props.editing,
    deleting: false,
  };

  onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { demandType } = this.props;

    this.setState(({ editing }) => (editing ? null : { editing: true, demandType }));
  };

  onChange = (fieldName) => (value) => {
    this.setState(({ demandType }) => ({
      demandType: {
        ...demandType,
        [fieldName]: value,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Case enter is pressed
      this.onSave(e);
    } else if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, demandType } = this.props;

    if (!demandType.id) {
      dispatch(deleteDemandType(demandType));
      return;
    }

    this.setState({
      demandType: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;

    this.setState({ editing: false });

    const { demandType } = this.state;
    dispatch(saveDemandType(demandType));
    dispatch(addGlobalMessage('Modifications sauvegardées'));
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, demandType } = this.props;
    dispatch(deleteDemandType(demandType));
    dispatch(addGlobalMessage(`Type de demande supprimé : ${demandType.name}`));
  }

  render() {
    const { demandType, editing, deleting } = this.state;
    const { editable, demandType: demandTypeProp, dispatch, ...props } = this.props;

    return (
      <SortableRow
        {...props}
        component={EditableTableRow}
        editable={editable}
        editing={editing}
        onEdit={(e) => this.onEditClick(e)}
        actions={
          <>
            <Tooltip title="Enregistrer le type de demande">
              <IconButton onClick={this.onSave} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(demandType.id) && (
              <Tooltip title="Supprimer le type de demande">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <TableCell>
          {editing ? (
            <TextField
              name="name"
              autoFocus
              value={demandType.name}
              onChange={(e) => this.onChange('name')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <span>{demandTypeProp.name}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <TextField
              name="oracleCode"
              value={demandType.oracleCode}
              onChange={(e) => this.onChange('oracleCode')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <span>{demandTypeProp.oracleCode}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <TextField
              name="oracleHeaderLabel"
              value={demandType.oracleHeaderLabel}
              onChange={(e) => this.onChange('oracleHeaderLabel')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <span>{demandTypeProp.oracleHeaderLabel}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <TextField
              name="oracleLineLabel"
              value={demandType.oracleLineLabel}
              onChange={(e) => this.onChange('oracleLineLabel')(e.target.value)}
              fullWidth
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <span>{demandTypeProp.oracleLineLabel}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <AutocompleteField
                multiple
                clearable={false}
                options={CONCEPTS}
                disabled={!editing}
                fullWidth
                name="compatibleConcepts"
                value={demandType.compatibleConcepts}
                onChange={this.onChange('compatibleConcepts')}
              />
            </Formsy>
          ) : (
            <MultiValue
              values={CONCEPTS.filter((concept) => demandTypeProp.compatibleConcepts.includes(concept.id)).map(
                (concept) => concept.name,
              )}
            />
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <AutocompleteField
                options={COST_STRUCTURES}
                name="costStructure"
                value={demandType.costStructure}
                fullWidth
                onChange={this.onChange('costStructure')}
              />
            </Formsy>
          ) : (
            <span>{COST_STRUCTURES.getNameById(demandTypeProp.costStructure)}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <AutocompleteField
                multiple
                clearable={false}
                options={QUESTIONS}
                disabled={!editing}
                fullWidth
                name="questions"
                value={demandType.questions}
                onChange={this.onChange('questions')}
              />
            </Formsy>
          ) : (
            <MultiValue
              values={QUESTIONS.filter((question) => demandTypeProp.questions.includes(question.id)).map(
                (question) => question.name,
              )}
            />
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Checkbox
              name="multiOutlet"
              onChange={(event, checked) => this.onChange('multiOutlet')(checked)}
              checked={demandType.multiOutlet}
              color="primary"
            />
          ) : (
            <span>{demandTypeProp.multiOutlet ? 'Oui' : 'Non'}</span>
          )}
        </TableCell>
        <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer le type de demande {demandTypeProp.name} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </SortableRow>
    );
  }
}

export default connect()(DemandTypeRow);
