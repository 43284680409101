import {
  DEMAND_OPERATIONS_LOADED,
  DEMAND_OPERATIONS_RESET,
  DEMAND_OPERATION_COMPLETION_UPDATE,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';

const demandOperationsEndpoint = api.custom(urls.demandOperations);

function demandOperationsLoaded(operations) {
  return {
    type: DEMAND_OPERATIONS_LOADED,
    operations,
  };
}

function demandOperationsReset() {
  return {
    type: DEMAND_OPERATIONS_RESET,
  };
}

export function resetDemandOperations() {
  return (dispatch) => {
    dispatch(demandOperationsReset());
  };
}

export function loadDemandOperations(type, concept, answers, subcontractor) {
  return (dispatch) => {
    demandOperationsEndpoint
      .post({
        demandTypeId: type,
        concept,
        answers,
        subcontractor,
      })
      .then((response) => {
        const operations = response.body(false);
        dispatch(demandOperationsLoaded(operations));
      })
      .catch(ignore401);
  };
}

export function updateOperationCompletedQuantity(demandId, operation, completedQuantity) {
  const endpoint = api.one(urls.demands, demandId);
  return (dispatch) => {
    endpoint
      .all('operations')
      .post({
        type: operation.type,
        equipmentId: operation.selectedEquipmentId,
        completedQuantity,
      })
      .then(() => {
        dispatch({
          type: DEMAND_OPERATION_COMPLETION_UPDATE,
          operationType: operation.type,
          completedQuantity,
          equipmentId: operation.selectedEquipmentId,
        });
        dispatch(addGlobalMessage('La modification est enregistrée'));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while toggling completion status:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement de la modification"));
      });
  };
}
