import {
  FDD_GOALS_LOADED,
  FDD_GOAL_ADDED,
  FDD_GOAL_SAVED,
  FDD_GOAL_DELETED,
  getByIdDecorator,
} from '../../constants/AppConstants';

const initialState = getByIdDecorator([]);
initialState.showNew = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case FDD_GOALS_LOADED: {
      const newState = [...action.list];
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    case FDD_GOAL_ADDED: {
      const newState = [...state];
      newState.showNew = true;
      return getByIdDecorator(newState);
    }
    case FDD_GOAL_SAVED: {
      if (action.creation) {
        return getByIdDecorator([...state, action.item]);
      }
      const newState = state.map((goal) => (goal.id === action.item.id ? action.item : goal));
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    case FDD_GOAL_DELETED: {
      if (!action.id) {
        return getByIdDecorator([...state]);
      }
      const newState = state.filter((goal) => goal.id !== action.id);
      newState.showNew = state.showNew;
      return getByIdDecorator(newState);
    }
    default:
      return state;
  }
}
