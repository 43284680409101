import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { usePageTitle } from '../../../utils/page-title';
import { useParams } from 'react-router-dom';
import StockEquipmentRemoval from '../../stock-equipments/StockEquipmentRemoval';

const StockEquipmentRemovalPage = () => {
  usePageTitle('Sortie de matériel');
  const { stockOutletCode } = useParams();

  return <StockEquipmentRemoval stockOutletCode={stockOutletCode} />;
};

export default securedPage(roles.stockEquipments.edit.code)(StockEquipmentRemovalPage);
