import { EquipmentSerialNumberTypeId } from '../model/model';

export const isNullish = (value: any): boolean => value === null || value === undefined;

export const isAlphabetic = (value: string) => /^[A-Za-z]*$/.test(value);

export const isAlphaNumeric = (value: string) => /^[0-9A-Za-z]*$/.test(value);

export const isNumeric = (value: string) => /^[0-9]*$/.test(value);

export const isHexadecimal = (value: string) => /^[0-9A-Fa-f]*$/.test(value);

export const containsCharacters = (value: string, unauthorized: string) => {
  const specialCharactersEscaped: string = unauthorized.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex: RegExp = new RegExp(`[${specialCharactersEscaped}]`);
  return regex.test(value);
};

export const matchType = (value: string, type: EquipmentSerialNumberTypeId) => {
  switch (type) {
    case 'ALPHABETIC':
      return isAlphabetic(value);
    case 'HEXADECIMAL':
      return isHexadecimal(value);
    case 'ALPHANUMERIC':
      return isAlphaNumeric(value);
    case 'NUMERIC':
      return isNumeric(value);
  }
};
