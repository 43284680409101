import React, { useEffect, useState } from 'react';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CardHeader, ResponsiveTable } from '../utils';
import roles from '../../constants/roles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import FDDSubGoalRow from './FDDSubGoalRow';
import { loadSubGoals } from '../../actions/fdd/FDDSubGoalActions';
import { SubGoal } from '../../model/model';

const FDDSubGoalsTable = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadSubGoals());
  }, [dispatch]);

  const fddSubGoals: SubGoal[] = useAppSelector(({ fddSubGoals }) => fddSubGoals);
  const [items, setItems] = useState<SubGoal[]>(fddSubGoals);

  useEffect(() => {
    setItems(fddSubGoals);
  }, [fddSubGoals]);

  const onAddClick = () => {
    setItems([...items, getEmptyItem()]);
  };

  const getEmptyItem = (): SubGoal => ({
    id: null,
    name: '',
  });

  return (
    <Card>
      <CardHeader addLabel="Ajouter un sous-objectif" addRole={roles.fdd.admin.code} onAdd={onAddClick}>
        Sous-objectifs FDD
      </CardHeader>
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map((subGoal: SubGoal) => (
              <FDDSubGoalRow key={`${subGoal.id}`} subGoal={subGoal} disabled={!subGoal.id} />
            ))}
        </TableBody>
      </ResponsiveTable>
    </Card>
  );
};

export default FDDSubGoalsTable;
