import {
  INTERVENTION_SIGNATURES,
  INTERVENTION_SIGNATURE_OUTLET,
  INTERVENTION_SIGNATURE_SUBCONTRACTOR,
  apiBase,
  urls,
} from '../constants/AppConstants';
import httpService from '../services/HttpService';
import { addGlobalMessage, addGlobalError } from './SnackbarActions';
import { getErrorMessage } from '../services/ErrorMessageService';

function attachmentUrl(interventionId, fileName) {
  return `${apiBase}/${urls.interventions}/${interventionId}/${fileName}`;
}

function dataURLtoBlob(dataURL) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = atob(dataURL.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

/**
 * Initializes the stor with the current signatures.
 */
export function interventionSignatures(outletSignature, subcontractorSignature) {
  return {
    type: INTERVENTION_SIGNATURES,
    outletSignature,
    subcontractorSignature,
  };
}

export function saveOutletSignature(interventionId, dataURL, rating) {
  return (dispatch) => {
    const file = dataURLtoBlob(dataURL);
    const path = 'outlet-signature';
    const fileName = `${path}.png`;

    /*
     * Calling the back-server to add the files
     */
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append(
      'rating',
      new Blob(
        [
          JSON.stringify({
            rating,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    httpService
      .post({
        url: attachmentUrl(interventionId, path),
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response.json();
      })
      /*
       * Replacing the temporary attachment in Redux by the
       * server version (without the "uploading" flag).
       */
      .then((json) => {
        dispatch(addGlobalMessage("L'intervention a été signée par le point de vente"));
        dispatch({
          type: INTERVENTION_SIGNATURE_OUTLET,
          signature: json,
          rating,
        });
      })
      /*
       * If there was an error server-side, a message is displayed.
       */
      .catch((err) => {
        console.error('Error while saving the signature:', err);
        dispatch(addGlobalError(getErrorMessage(err)));
      });
  };
}

export function saveSubcontractorSignature(interventionId, dataURI) {
  return (dispatch) => {
    const file = dataURLtoBlob(dataURI);
    const path = 'subcontractor-signature';
    const fileName = `${path}.png`;

    /*
     * Calling the back-server to add the files
     */
    const formData = new FormData();
    formData.append('file', file, fileName);
    httpService
      .post({
        url: attachmentUrl(interventionId, path),
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response.json();
      })
      /*
       * Replacing the temporary attachment in Redux by the
       * server version (without the "uploading" flag).
       */
      .then((json) => {
        dispatch(addGlobalMessage("L'intervention a été signée par le prestataire"));
        dispatch({
          type: INTERVENTION_SIGNATURE_SUBCONTRACTOR,
          signature: json,
        });
      })
      /*
       * If there was an error server-side, a message is displayed.
       */
      .catch((err) => {
        console.error('Error while saving the signature:', err);
        dispatch(addGlobalError(getErrorMessage(err)));
      });
  };
}
