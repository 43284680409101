import { FDDS_FILTER_UPDATED, FDDS_LOADED } from '../../constants/AppConstants';

const initialState = {
  list: [],
  pagination: {
    currentPage: 0,
    numberOfPages: 0,
    pageSize: 10,
    totalElements: 0,
    filter: {},
  },
};

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case FDDS_FILTER_UPDATED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize: action.pageSize || 10,
          filter: action.filter,
        },
      };
    case FDDS_LOADED:
      return {
        ...state,
        list: action.page.content,
        pagination: {
          ...state.pagination,
          currentPage: action.page.number,
          numberOfPages: action.page.totalPages,
          totalElements: action.page.totalElements,
        },
      };
    default:
      return state;
  }
}
