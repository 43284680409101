import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';
import Formsy from 'formsy-react';

import { AutocompleteField, TextField } from '../utils';
import { deleteDeliveryLocation, saveDeliveryLocation } from '../../actions/DeliveryLocationsActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import EditableTableRow from '../utils/EditableTableRow';
import { ACTIVE_INSTALLERS, COMPANIES } from '../../constants/AppConstants';

class DeliveryLocationRow extends Component {
  static errorMessages = {
    name: {
      isDefaultRequiredValue: 'Veuillez saisir une enseigne',
      isExisty: 'Veuillez saisir une enseigne',
      maxLength: 'Longueur max: 50',
    },
    recipient: {
      maxLength: 'Longueur max: 50',
    },
    addressLine1: {
      maxLength: 'Longueur max: 50',
    },
    addressLine2: {
      maxLength: 'Longueur max: 50',
    },
    addressLine3: {
      maxLength: 'Longueur max: 50',
    },
    postalCode: {
      maxLength: 'Longueur max: 10',
    },
    city: {
      maxLength: 'Longueur max: 50',
    },
    phone: {
      maxLength: 'Longueur max: 10',
    },
    company: {
      isDefaultRequiredValue: 'Veuillez saisir une société',
      isExisty: 'Veuillez saisir une société',
    },
  };

  static propTypes = {
    deliveryLocation: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    editing: PropTypes.bool.isRequired,
  };

  state = {
    deliveryLocation: {},
    editing: this.props.editing || false,
    deleting: false,
  };

  form = React.createRef();

  onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { deliveryLocation } = this.props;
    this.setState(({ editing }) => (editing ? null : { editing: true, deliveryLocation }));
  };

  onChange = (fieldName) => (e) => {
    e.persist();
    this.setState(({ deliveryLocation }) => ({
      deliveryLocation: {
        ...deliveryLocation,
        [fieldName]: e.target.value,
      },
    }));
  };

  onChangeCompany = (company) => {
    this.setState(({ deliveryLocation }) => ({
      deliveryLocation: {
        ...deliveryLocation,
        company,
      },
    }));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, deliveryLocation } = this.props;

    if (!deliveryLocation.id) {
      dispatch(deleteDeliveryLocation(deliveryLocation));
      return;
    }

    this.setState({
      deliveryLocation: {},
      editing: false,
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSubmitClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.form.current.submit();
  };

  onSave = () => {
    const { dispatch } = this.props;
    const { deliveryLocation } = this.state;

    this.setState({ editing: false });

    dispatch(saveDeliveryLocation(deliveryLocation));
    dispatch(addGlobalMessage('Modifications sauvegardées'));
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, deliveryLocation } = this.props;
    dispatch(deleteDeliveryLocation(deliveryLocation));
    dispatch(addGlobalMessage(`Lieu de livraison supprimé : ${deliveryLocation.name}`));
  }

  renderForm = (props) => <Formsy ref={this.form} noValidate onValidSubmit={this.onSave} {...props} />;

  render() {
    const { deliveryLocation, editing, deleting } = this.state;
    const { deliveryLocation: deliveryLocationProp, editable } = this.props;

    return (
      <EditableTableRow
        component={this.renderForm}
        editable={editable}
        editing={editing}
        onEdit={(e) => this.onEditClick(e)}
        actions={
          <>
            <Tooltip title="Enregistrer le lieu de livraison">
              <IconButton type="submit" onClick={this.onSubmitClick} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(deliveryLocationProp.id) && (
              <Tooltip title="Supprimer le lieu de livraison">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        TableCellProps={{ component: 'div' }}
      >
        <TableCell component="div">
          {editing ? (
            <AutocompleteField
              options={ACTIVE_INSTALLERS}
              name="company"
              value={deliveryLocation.company}
              onChange={this.onChangeCompany}
              fullWidth
              required
              validations="isExisty"
              validationErrors={DeliveryLocationRow.errorMessages.company}
            />
          ) : (
            <span>{COMPANIES.getNameById(deliveryLocationProp.company)}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="name"
              value={deliveryLocation.name}
              onChange={this.onChange('name')}
              fullWidth
              onKeyDown={this.onKeyDown}
              required
              validations="isExisty,maxLength:50"
              validationErrors={DeliveryLocationRow.errorMessages.name}
            />
          ) : (
            <span>{deliveryLocationProp.name}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="recipient"
              value={deliveryLocation.streetNumber}
              onChange={this.onChange('recipient')}
              fullWidth
              onKeyDown={this.onKeyDown}
              validations="maxLength:50"
              validationErrors={DeliveryLocationRow.errorMessages.recipient}
            />
          ) : (
            <span>{deliveryLocationProp.recipient}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="addressLine1"
              value={deliveryLocation.addressLine1}
              onChange={this.onChange('addressLine1')}
              fullWidth
              onKeyDown={this.onKeyDown}
              validations="maxLength:50"
              validationErrors={DeliveryLocationRow.errorMessages.addressLine1}
            />
          ) : (
            <span>{deliveryLocationProp.addressLine1}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="addressLine2"
              value={deliveryLocation.addressLine2}
              onChange={this.onChange('addressLine2')}
              fullWidth
              onKeyDown={this.onKeyDown}
              validations="maxLength:50"
              validationErrors={DeliveryLocationRow.errorMessages.addressLine2}
            />
          ) : (
            <span>{deliveryLocationProp.addressLine2}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="addressLine3"
              value={deliveryLocation.addressLine3}
              onChange={this.onChange('addressLine3')}
              fullWidth
              onKeyDown={this.onKeyDown}
              validations="maxLength:50"
              validationErrors={DeliveryLocationRow.errorMessages.addressLine3}
            />
          ) : (
            <span>{deliveryLocationProp.addressLine3}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="postalCode"
              value={deliveryLocation.postalCode}
              onChange={this.onChange('postalCode')}
              fullWidth
              onKeyDown={this.onKeyDown}
              validations="maxLength:10"
              validationErrors={DeliveryLocationRow.errorMessages.postalCode}
            />
          ) : (
            <span>{deliveryLocationProp.postalCode}</span>
          )}
        </TableCell>
        <TableCell component="div">
          {editing ? (
            <TextField
              name="city"
              value={deliveryLocation.city}
              onChange={this.onChange('city')}
              fullWidth
              onKeyDown={this.onKeyDown}
              validations="maxLength:50"
              validationErrors={DeliveryLocationRow.errorMessages.city}
            />
          ) : (
            <span>{deliveryLocationProp.city}</span>
          )}
        </TableCell>
        {editable && editing && (
          <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer le lieu de livraison {deliveryLocationProp.name} ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
              <Button color="secondary" onClick={() => this.onDelete()}>
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </EditableTableRow>
    );
  }
}

export default connect()(DeliveryLocationRow);
