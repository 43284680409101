import { hasAnyRole } from '../../services/SecurityService';
import roles from '../../constants/roles';

export const getSaveButtonLabel = (fddId, status, payments = []) => {
  if (!fddId) {
    return 'Créer';
  }
  if (status === '_30_CLOSED' || payments.length === 0 || payments.some((payment) => !payment.paymentValidated)) {
    return 'Enregistrer';
  }
  return 'Enregistrer et clôturer';
};

export const canUserEditTracking = (currentUser, fddStatus) =>
  fddStatus === '_20_IN_PROGRESS' && hasAnyRole(currentUser, [roles.fdd.edit_bd.code, roles.fdd.assessment_edit.code]);
