import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import 'intl';
import 'intl/locale-data/jsonp/fr';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { loadAfterSalesOperations, updatePosition } from '../../actions/settings/AfterSalesOperationActions';
import { CardHeader, SortableTable } from '../utils';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import AfterSalesOperationsTableRow from './AfterSalesOperationsTableRow';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const AfterSalesOperationsTable = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const canEdit = hasRole(currentUser, roles.equipment.edit.code);
  const afterSalesOperations = useAppSelector(({ afterSalesOperations: { list } }) => list);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadAfterSalesOperations());
  }, [dispatch]);

  const onNewClick = () => {
    navigate('/settings/after-sales-operations/new');
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const operation = afterSalesOperations[oldIndex];
      dispatch(updatePosition(operation.id, oldIndex, newIndex));
    }
  };

  return (
    <Card>
      <CardHeader
        addLabel="Créer une nouvelle prestation d'après vente"
        addRole={roles.equipment.create.code}
        onAdd={onNewClick}
      >
        Paramétrage des prestations d'après-vente
      </CardHeader>
      <SortableTable onSortEnd={onSortEnd}>
        <TableHead>
          <TableRow>
            <TableCell>Libellé</TableCell>
            <TableCell>Matériel</TableCell>
            <TableCell>Affichée par déf.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {afterSalesOperations.map((operation) => (
            <AfterSalesOperationsTableRow
              key={operation.id}
              index={operation.position}
              disabled={!canEdit}
              operation={operation}
            />
          ))}
        </TableBody>
      </SortableTable>
    </Card>
  );
};

const stateToProps = (state) => ({
  canEdit: hasRole(state.currentUser, roles.equipment.edit.code),
  afterSalesOperations: state.afterSalesOperations.list,
});

export default connect(stateToProps)(AfterSalesOperationsTable);
