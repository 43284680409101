import {
  DEMAND_LOADED,
  NEW_TRANSITION_ATTACHMENT_UPLOADING,
  NEW_TRANSITION_ATTACHMENT_UPLOADED,
  TRANSITION_ATTACHMENT_DELETED,
} from '../constants/AppConstants';

/* **********************
 * SELECTORS
 * **********************/

export const selectDemandAttachmentInProgress = (state) =>
  Object.values(state.demandTransitionAttachments).some((attachment) => attachment.uploading);

/* **********************
 * REDUCER
 * **********************/

/**
 * The state is an object containing attachments.
 */
export default function (state = {}, action) {
  switch (action.type) {
    /*
     * The demand was loaded: We extract the transition attachments.
     */
    case DEMAND_LOADED:
      // noinspection JSUnresolvedVariable
      return action.demand.transitionAttachments || {};

    /*
     * The user has just added a new attachment: it is added to the list
     * of transition attachments.
     * ---
     * The new attachment is saved in the database: its status in Redux
     * is updated to reflect that.
     */
    case NEW_TRANSITION_ATTACHMENT_UPLOADING:
    case NEW_TRANSITION_ATTACHMENT_UPLOADED: {
      return {
        ...state,
        [action.attachment.name]: action.attachment,
      };
    }

    /*
     * The user has just deleted an attachment: this one is removed from the list.
     */
    case TRANSITION_ATTACHMENT_DELETED: {
      const newState = { ...state };
      delete newState[action.name];
      return newState;
    }

    default:
      return state;
  }
}
