import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import ReloadDemandCosts from '../reload-demands/ReloadDemandCosts';
import AddToDemands from '../reload-demands/AddToDemands';
import { FormActions } from '../utils';

class FormButtons extends React.Component {
  static propTypes = {
    deletionErrors: PropTypes.array.isRequired,
    globalErrors: PropTypes.array,
    editing: PropTypes.bool.isRequired,
    elementId: PropTypes.string,
    elementName: PropTypes.string.isRequired,
    onActivateEditingMode: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    onReloadDemandCosts: PropTypes.func,
    onAddToDemands: PropTypes.func,
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool,
    canReloadCosts: PropTypes.bool,
    canAddToDemands: PropTypes.bool,
  };

  static defaultProps = {
    globalErrors: [],
    onReloadDemandCosts: () => {},
    onAddToDemands: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpened: false,
    };
  }

  onOpenDeleteDialog = () => {
    this.setState({ deleteDialogOpened: true });
  };

  onCloseDeleteDialog = () => {
    this.setState({ deleteDialogOpened: false });
  };

  render() {
    const {
      canDelete,
      canEdit,
      canReloadCosts,
      canAddToDemands,
      deletionErrors,
      globalErrors,
      editing,
      elementId,
      elementName,
      onActivateEditingMode,
      onCancel,
      onDelete,
      onSave,
      onReloadDemandCosts,
      onAddToDemands,
    } = this.props;
    const leftActions = [];
    const rightActions = [];
    if (editing) {
      if (elementId) {
        rightActions.push(
          <Button variant="contained" key="cancel" onClick={() => onCancel()}>
            Annuler
          </Button>,
        );
      }
      rightActions.push(
        <Button variant="contained" key="save" color="primary" onClick={onSave} type="submit">
          {elementId ? 'Enregistrer' : 'Créer'}
        </Button>,
      );
    } else {
      if (canEdit) {
        rightActions.push(
          <Button variant="contained" key="edit" color="primary" onClick={() => onActivateEditingMode()}>
            Modifier
          </Button>,
        );
      }
      if (canDelete) {
        leftActions.push(
          <Button variant="contained" key="delete" color="secondary" onClick={this.onOpenDeleteDialog}>
            Supprimer
          </Button>,
        );
      }
      if (canReloadCosts) {
        leftActions.push(
          <ReloadDemandCosts key="reload-costs" elementName={elementName} onReloadDemandCosts={onReloadDemandCosts} />,
        );
      }
      if (canAddToDemands) {
        leftActions.push(
          <AddToDemands key="add-to-demands" elementName={elementName} onAddToDemands={onAddToDemands} />,
        );
      }
    }

    if (!leftActions.length && !rightActions.length) {
      return null;
    }

    const { deleteDialogOpened } = this.state;
    return (
      <Card>
        {!!globalErrors && !!globalErrors.length && (
          <CardContent component="ul">
            {globalErrors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </CardContent>
        )}
        <FormActions leftActions={leftActions} rightActions={rightActions} />
        <Dialog open={deleteDialogOpened} onClose={this.onCloseDeleteDialog}>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer {elementName} ?</DialogContentText>
            <DialogContentText>
              Vous pouvez aussi supprimer tous les concepts compatibles pour le rendre inactif.
            </DialogContentText>
            {deletionErrors.length > 0 && (
              <DialogContentText>
                {elementName} est utilisé dans les prestations suivantes. Vous ne pouvez pas le supprimer tant qu'il est
                utilisé.
                <ul>
                  {deletionErrors.map((op) => (
                    <li key={op}>{op}</li>
                  ))}
                </ul>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button key="delete-cancel" onClick={this.onCloseDeleteDialog}>
              Annuler
            </Button>
            <Button key="delete-confirm" onClick={onDelete} color="primary">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

export default FormButtons;
