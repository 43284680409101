import dayjs, { Dayjs, UnitType } from 'dayjs';
import { DateRange } from '../model/range';

export type DateValue = Dayjs | string | null;

const defaultDateFormat = 'DD/MM/YYYY';
export const defaultDateTimeFormat = 'DD/MM/YYYY HH:mm';

export const threeMonthRange = (date?: DateValue): DateRange => {
  const minDate = dayjs(date).subtract(1, 'month').startOf('month');

  const maxDate = dayjs(date).add(2, 'month').endOf('month');

  return new DateRange({ minDate, maxDate });
};

export const startingFromLastMonth = (): DateRange => {
  const minDate = dayjs(new Date()).subtract(1, 'month');

  return new DateRange({ minDate, maxDate: null });
};

export const todayDateRange = (): DateRange => {
  const today = dayjs(new Date());
  return new DateRange({ minDate: today, maxDate: today });
};

export const formatDate = (date: DateValue, format?: string): string =>
  date ? dayjs(date).format(format ?? defaultDateFormat) : '';

export const formatDateTime = (date: DateValue, format?: string): string =>
  date ? dayjs(date).format(format ?? defaultDateTimeFormat) : '';

export const roundDate = (date: Dayjs, precision: number, unit: UnitType): Dayjs =>
  date
    .clone()
    .set(unit, Math.round(date.get(unit) / precision) * precision)
    .startOf(unit);
