import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import Formsy from 'formsy-react';
import debugFactory from 'debug';

import { AutocompleteField, DatePickerField, TextField } from '../../utils';
import { deleteIntervention, loadIntervention } from '../../../actions/InterventionActions';
import { performInterventionTransition } from '../../../actions/InterventionTransitionsActions';
import { POST_ACTIONS, UNFINISHED_CAUSES } from '../../../constants/interventionTransitions';
import InterventionCreationDialog from '../InterventionCreationDialog';
import { DAYTIME_TYPES } from '../../../constants/AppConstants';
import { loadDeliveryLocationsIfNeeded } from '../../../actions/DeliveryLocationsActions';
import { filterDeliveryLocationsByCompany } from '../../../reducers/deliveryLocationsReducer';
import { addMaxValidationRule } from '../../../utils/validation-rules';
import CheckboxField from '../../utils/CheckboxField';
import InterventionOperationsToCommand from '../InterventionOperationsToCommand';
import { extractCommandQuantities } from '../../../utils/operation-utils';

const debug = debugFactory('prestago:InterventionTransition');

class InterventionTransition extends React.Component {
  static config = {
    SCHEDULE: {
      message: "À quelle date souhaitez-vous planifier l'intervention ?",
      buttons: {
        confirm: 'Planifier',
        cancel: 'Annuler',
      },
    },
    DECLARE_CANCELLED: {
      message:
        'Vous êtes sur le point de déclarer une intervention comme annulée. Merci de nous indiquer le motif. ' +
        'Il est nécessaire de reprogrammer une nouvelle intervention dans les meilleurs délais. ',
      buttons: {
        confirm: 'À reprogrammer',
        cancel: 'Annuler',
      },
    },
    REPROGRAM_CANCELLED: {
      message:
        'Vous êtes sur le point de reprogrammer une intervention précédemment annulée. ' +
        "Vous allez être redirigé vers la création d'une nouvelle intervention pour la même demande.",
      buttons: {
        confirm: 'Reprogrammer',
        cancel: 'Annuler',
      },
    },
    TERMINATE: {
      message:
        "Vous êtes sur le point de terminer l'intervention. " +
        'Il vous appartient de passer la demande correspondante en statut "En attente de réception PMU". ' +
        "Cela n'est pas fait automatiquement.",
      buttons: {
        confirm: 'Terminer',
        cancel: 'ANNULER',
      },
    },
    DECLARE_UNFINISHED: {
      message:
        'Vous êtes sur le point de déclarer une intervention comme inachevée. Merci de sélectionner le ' +
        "motif d'annulation. Il vous est demandé de reprogrammer une nouvelle intervention dans les meilleurs délais.",
      buttons: {
        confirm: 'Inachevée, à reprogrammer',
        cancel: 'Annuler',
      },
    },
    REPROGRAM_UNFINISHED: {
      message:
        'Vous êtes sur le point de reprogrammer une intervention pour finaliser cette intervention inachevée. ' +
        "Vous allez être redirigé vers la création d'une nouvelle intervention sur la même demande.",
      buttons: {
        confirm: 'Reprogrammer',
        cancel: 'Annuler',
      },
    },
    REOPEN: {
      message: "Vous êtes sur le point de rouvrir de l'intervention, confirmez-vous ?",
      buttons: {
        confirm: 'Rouvrir',
        cancel: 'Annuler',
      },
    },
    DELETE: {
      message: "Vous êtes sur le point de forcer la suppression de l'intervention, confirmez-vous ?",
      buttons: {
        confirm: 'Supprimer',
        cancel: 'Annuler',
      },
    },
  };

  static errorMessages = {
    date: {
      isDefaultRequiredValue: 'Veuillez sélectionner une date',
      isExisty: 'Veuillez sélectionner une date',
    },
    dayTime: {
      isDefaultRequiredValue: 'Veuillez sélectionner un moment dans la journée',
      isExisty: 'Veuillez sélectionner un moment dans la journée',
    },
    unfinishedReason: {
      isDefaultRequiredValue: 'Veuillez sélectionner un motif',
      isExisty: 'Veuillez sélectionner un motif',
    },
    deliveryLocationId: {
      isDefaultRequiredValue: 'Veuillez sélectionner un lieu',
      isExisty: 'Veuillez sélectionner un lieu',
    },
  };

  static propTypes = {
    canCreateInterventions: PropTypes.bool.isRequired,
    demandId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    hasMissingSerialNumbers: PropTypes.bool,
    hasMissingSignature: PropTypes.bool,
    hasStartedAndFinishedDates: PropTypes.bool,
    needsDeliveryDetails: PropTypes.bool,
    numberOfInterventions: PropTypes.number,
    interventionId: PropTypes.string.isRequired,
    numberOfAttachments: PropTypes.number.isRequired,
    transition: PropTypes.object.isRequired,
    operationScheduleDate: PropTypes.any,
    operationScheduleDayTime: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.operationsTable = React.createRef();
    this.state = {
      opened: false,
      creationOpened: false,
      comment: '',
      date: null,
      dayTime: null,
      unfinishedReason: null,
      skipDelivery:
        this.props.needsDeliveryDetails && !this.props.isAgency && (this.props.numberOfInterventions ?? 0) > 1,
      deliveryLocationId: '',
      operations: [],
    };
    addMaxValidationRule();
  }

  componentDidMount() {
    // Loading all needed data from server
    this.props.dispatch(loadDeliveryLocationsIfNeeded());
  }

  onOpen = () => {
    this.setState({ opened: true });
  };

  onClose = () => {
    this.setState({ opened: false });
  };

  onDateChange = (fieldName) => (date) => {
    debug('onDateChange %s %o', fieldName, date);
    this.setState({
      [fieldName]: date,
    });
  };

  onTextChange = (fieldName) => (event) => {
    this.setState({
      [fieldName]: event.target.value,
    });
  };

  onSelectChange = (fieldName) => (value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  onSkipDeliveryChange = (checked) => {
    this.setState({
      skipDelivery: checked,
    });
  };

  allQuantitiesMissing = () => {
    const { isAgency, needsDeliveryDetails, transition } = this.props;
    const { skipDelivery, operations } = this.state;
    return (
      needsDeliveryDetails &&
      transition.deliveryDetails &&
      !isAgency &&
      !skipDelivery &&
      operations.every((op) => !op.commandQuantity)
    );
  };

  onValidSubmit = () => {
    const { dispatch, interventionId, demandId, transition, isAgency } = this.props;
    if (transition.id === 'DELETE') {
      dispatch(deleteIntervention(interventionId, demandId));
    } else {
      const { comment, date, dayTime, unfinishedReason, skipDelivery, deliveryLocationId, operations } = this.state;
      dispatch(
        performInterventionTransition(
          interventionId,
          transition,
          {
            comment,
            date,
            dayTime,
            unfinishedReason,
            skipDelivery,
            deliveryToOutlet: isAgency,
            deliveryLocationId,
            equipments: extractCommandQuantities(operations),
          },
          this.doPostAction(),
        ),
      );
    }
  };

  /**
   * When the user cancels the new intervention creation, the intervention
   * needs to be reloaded as a transition happened before the creation dialog
   * was opened.
   */
  onCreationDialogCancel = () => {
    const { dispatch, interventionId } = this.props;
    this.setState({ creationOpened: false });
    dispatch(loadIntervention(interventionId));
  };

  /**
   * For most of the transitions, the post action is to reload the intervention so
   * that the new status is visible.
   *
   * However, for some other transitions, the "intervention creation" dialog should
   * be opened.
   */
  doPostAction = () => () => {
    const { canCreateInterventions, dispatch, interventionId, transition } = this.props;
    if (transition.postAction === POST_ACTIONS.RELOAD || !canCreateInterventions) {
      dispatch(loadIntervention(interventionId));
    } else {
      this.setState({ creationOpened: true });
    }
  };

  handleOperationsChange = (operations) => {
    this.setState({
      operations,
    });
  };

  render() {
    const {
      canCreateInterventions,
      demandId,
      dispatch,
      hasMissingSerialNumbers,
      hasMissingSignature,
      hasStartedAndFinishedDates,
      hasCompletedOperations,
      needsDeliveryDetails: interventionNeedDeliveryDetails,
      numberOfAttachments,
      transition,
      deliveryLocations,
      isAgency,
      addOperations,
    } = this.props;
    const { opened, creationOpened, comment, date, dayTime, unfinishedReason, skipDelivery, deliveryLocationId } =
      this.state;

    const { message, buttons } = InterventionTransition.config[transition.id];

    const needsDeliveryDetails = interventionNeedDeliveryDetails && transition.deliveryDetails;

    /*
     * Global error
     */
    const globalErrors = [];
    if (transition.mandatorySerialNumbers && hasMissingSerialNumbers) {
      globalErrors.push('Veuillez saisir les numéros de série des prestations terminées.');
    }
    if (transition.mandatoryAttachments && numberOfAttachments < 1) {
      globalErrors.push("Veuillez ajouter au moins une photo de l'intervention.");
    }
    if (transition.forbiddenCompletedOps && hasCompletedOperations) {
      globalErrors.push("Impossible d'annuler ou supprimer une intervention avec des prestations effectuées.");
    }
    if (transition.mandatorySignature && hasMissingSignature) {
      globalErrors.push("Veuillez signer l'intervention.");
    }
    if (transition.mandatoryStartedFinishedDates && !hasStartedAndFinishedDates) {
      globalErrors.push("Veuillez saisir et enregistrer les dates de début et fin d'intervention.");
    }
    if (this.allQuantitiesMissing()) {
      globalErrors.push('Veuillez saisir au moins une quantité, ou sélectionner "Pas besoin de livraison".');
    }

    return (
      <>
        <Button variant="contained" color={transition.color} onClick={this.onOpen}>
          {transition.name}
        </Button>

        <Dialog open={opened} onClose={this.onClose}>
          <DialogContent sx={{ padding: 0 }}>
            <Formsy ref={this.form} noValidate onValidSubmit={this.onValidSubmit}>
              <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DialogContentText>{message}</DialogContentText>
                  </Grid>

                  {!transition.withoutComment && (
                    <Grid item xs={12}>
                      <TextField
                        label="Commentaires"
                        fullWidth
                        multiline
                        name="comment"
                        defaultValue={comment}
                        onChange={this.onTextChange('comment')}
                      />
                    </Grid>
                  )}

                  {transition.mandatoryDate && (
                    <>
                      <Grid item xs={12} md={6}>
                        <DatePickerField
                          label="Date d'intervention"
                          fullWidth
                          required
                          validationErrors={InterventionTransition.errorMessages.date}
                          validations="isExisty"
                          onChange={this.onDateChange('date')}
                          name="date"
                          value={date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <AutocompleteField
                          options={DAYTIME_TYPES}
                          label="Moment de la journée"
                          fullWidth
                          required
                          name="dayTime"
                          onChange={this.onSelectChange('dayTime')}
                          value={dayTime}
                          validations="isExisty"
                          validationErrors={InterventionTransition.errorMessages.dayTime}
                        />
                      </Grid>
                    </>
                  )}

                  {transition.mandatoryCause && (
                    <Grid item xs={12}>
                      <AutocompleteField
                        options={UNFINISHED_CAUSES}
                        label="Motif"
                        fullWidth
                        name="cause"
                        onChange={this.onSelectChange('unfinishedReason')}
                        value={unfinishedReason}
                        required
                        validations="isExisty"
                        validationErrors={InterventionTransition.errorMessages.unfinishedReason}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>

              {needsDeliveryDetails && (
                <>
                  <Box sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                      {isAgency ? (
                        <Grid item xs={12}>
                          <DialogContentText>Livraison en zone de vente</DialogContentText>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <CheckboxField
                              name="skipDelivery"
                              value={skipDelivery}
                              onChange={this.onSkipDeliveryChange}
                              label="Pas besoin de livraison"
                            />
                          </Grid>
                          {!skipDelivery && (
                            <>
                              <Grid item xs={12}>
                                <AutocompleteField
                                  options={deliveryLocations}
                                  label="Lieu de livraison"
                                  fullWidth
                                  required
                                  name="deliveryLocationId"
                                  onChange={this.onSelectChange('deliveryLocationId')}
                                  value={deliveryLocationId}
                                  validations="isExisty"
                                  validationErrors={InterventionTransition.errorMessages.deliveryLocationId}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <DialogContentText>
                                  Saisissez les quantités à livrer pour chaque prestation, en excluant les matériels
                                  déjà en stock :
                                </DialogContentText>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </Box>
                  {!isAgency && !skipDelivery && (
                    <InterventionOperationsToCommand
                      operations={addOperations}
                      onChange={this.handleOperationsChange}
                      filledByDefault={true}
                    />
                  )}
                </>
              )}

              {globalErrors.length > 0 && (
                <Box sx={{ padding: 2 }}>
                  <Grid container spacing={2}>
                    {globalErrors.map((error) => (
                      <Grid item xs={12} key={error}>
                        <DialogContentText color="error">{error}</DialogContentText>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Formsy>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.onClose}>{buttons.cancel}</Button>
            <Button
              onClick={() => this.form.current.submit()}
              disabled={globalErrors.length > 0}
              color={transition.color}
            >
              {buttons.confirm}
            </Button>
          </DialogActions>
        </Dialog>

        {transition.postAction === POST_ACTIONS.CREATE && canCreateInterventions && (
          <InterventionCreationDialog
            demandId={demandId}
            dispatch={dispatch}
            opened={creationOpened}
            onClose={this.onCreationDialogCancel}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  intervention: { intervention },
  deliveryLocations,
  interventionOperations: {
    operations: { addOperations },
  },
}) => ({
  deliveryLocations: filterDeliveryLocationsByCompany(deliveryLocations, intervention),
  addOperations,
});

export default connect(mapStateToProps)(InterventionTransition);
