import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { CardHeader, SortableTableHeader, ResponsiveTable } from '../utils';
import { loadAgencies, sortAgencies, addAgency } from '../../actions/settings/AgencyActions';
import { loadRegions } from '../../actions/settings/RegionActions';
import { sort, SortDirections } from '../../utils/sorting';
import AgencyRow from './AgencyRow';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

class AgenciesTable extends Component {
  componentDidMount() {
    const { dispatch, regions } = this.props;
    dispatch(loadAgencies());
    if (!regions.length) {
      dispatch(loadRegions());
    }
  }

  onAddClick = () => {
    const { dispatch } = this.props;
    dispatch(addAgency());
  };

  onHeaderClick(field) {
    const { dispatch, sorts } = this.props;
    const direction = sorts[0].field === field ? -sorts[0].direction : SortDirections.asc;
    dispatch(sortAgencies(field, direction));
  }

  sortDirection = (field) => {
    const { sorts } = this.props;
    if (sorts[0].field === field) {
      return sorts[0].direction;
    }
    return SortDirections.none;
  };

  render() {
    // From store
    const { agencies, showNew, currentUser } = this.props;
    const editable = hasRole(currentUser, roles.configuration.edit.code);

    return (
      <Card>
        <CardHeader
          addLabel="Ajouter une zone de vente"
          addRole={roles.configuration.edit.code}
          onAdd={this.onAddClick}
          addDisabled={showNew}
        >
          Paramétrage des zones de vente
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <SortableTableHeader
                direction={this.sortDirection('code')}
                onClickCallback={() => this.onHeaderClick('code')}
                title="Code"
              />
              <SortableTableHeader
                direction={this.sortDirection('name')}
                onClickCallback={() => this.onHeaderClick('name')}
                title="Nom"
              />
              <SortableTableHeader
                direction={this.sortDirection('region.name')}
                onClickCallback={() => this.onHeaderClick('region.name')}
                title="Région"
              />
              <SortableTableHeader
                direction={this.sortDirection('oracleCode')}
                onClickCallback={() => this.onHeaderClick('oracleCode')}
                title="UO Oracle"
              />
              <SortableTableHeader
                direction={this.sortDirection('oracleLabel')}
                onClickCallback={() => this.onHeaderClick('oracleLabel')}
                title="Libellé Oracle"
              />
              <SortableTableHeader
                direction={this.sortDirection('clientNumber')}
                onClickCallback={() => this.onHeaderClick('clientNumber')}
                title="Numéro client"
              />
              <SortableTableHeader
                direction={this.sortDirection('deliveryLocation')}
                onClickCallback={() => this.onHeaderClick('deliveryLocation')}
                title="Lieu de livraison"
              />
              <SortableTableHeader
                direction={this.sortDirection('billingLocation')}
                onClickCallback={() => this.onHeaderClick('billingLocation')}
                title="Lieu de facturation"
              />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {agencies.map((a) => (
              <AgencyRow key={a.id} agency={a} editing={a.editing} editable={editable} />
            ))}
          </TableBody>
        </ResponsiveTable>
      </Card>
    );
  }
}

const emptyAgency = (region) => ({
  id: null,
  name: null,
  code: null,
  region,
  oracleCode: null,
  oracleLabel: null,
  clientNumber: null,
  deliveryLocation: null,
  billingLocation: null,
  editing: true,
});

const sortedAgenciesWithNew = (list, sorts, showNew, regions) => {
  const sortedAgencies = sort(list, sorts);
  if (!showNew) {
    return sortedAgencies;
  }
  return [emptyAgency(regions[0]), ...sortedAgencies];
};

const stateToProps = ({ agencies: { list, sorts, showNew }, regions, currentUser }) => {
  const sortedRegions = sort(regions, [
    {
      field: 'name',
      direction: SortDirections.asc,
    },
  ]);
  return {
    agencies: sortedAgenciesWithNew(list, sorts, showNew, sortedRegions),
    regions: sortedRegions,
    showNew,
    sorts,
    currentUser,
  };
};

export default connect(stateToProps)(AgenciesTable);
