import React from 'react';
import debugFactory from 'debug';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  deleteAfterSalesOperation,
  loadAfterSalesOperation,
  loadAfterSalesOperations,
  resetAfterSalesOperation,
  saveOrUpdateAfterSalesOperation,
  updateGroup,
} from '../../actions/settings/AfterSalesOperationActions';
import { loadEquipments } from '../../actions/settings/EquipmentActions';
import {
  AutocompleteField,
  CardHeader,
  LoadingMessage,
  SortableRow,
  SortableTable,
  TextField,
  ToggleSwitch,
} from '../utils';
import GroupSelect from '../commons/GroupSelect';
import { sort, SortDirections } from '../../utils/sorting';
import EquipmentButtons from '../equipments/EquipmentButtons';
import { ACTIVE_INSTALLERS, CONCEPTS, COST_STRUCTURE_AFTER_SALES } from '../../constants/AppConstants';
import { loadDemandTypesIfNeeded } from '../../actions/DemandTypeActions';
import { addOperationToDemands } from '../../actions/settings/ReloadDemandCostsActions';
import roles from '../../constants/roles';
import { Delete } from 'mdi-material-ui';
import { arrayMove } from 'react-sortable-hoc';
import { requestCloseDialog, requestOpenDialog } from '../../reducers/dialogBoxReducer';
import { addMaxValidationRule, addMinValidationRule } from '../../utils/validation-rules';
import { withPageTitle } from '../../utils/page-title';

const debug = debugFactory('prestago:AfterSalesOperation');

const getAvailableConcepts = (equipments, equipmentId) => {
  debug('getAvailableConcepts %o %s', equipments, equipmentId);
  if (!equipments || !equipmentId) {
    return CONCEPTS;
  }
  const equipment = equipments.find((c) => c.id === equipmentId);
  if (!equipment) {
    return CONCEPTS;
  }

  return CONCEPTS.filter((c) => equipment.compatibleConcepts.indexOf(c.id) >= 0);
};

const getAvailableDemandTypes = (demandTypes, selectedConcepts, availableConcepts) => {
  debug('getAvailableDemandTypes %o %o %o', demandTypes, selectedConcepts, availableConcepts);
  const concepts = selectedConcepts && selectedConcepts.length ? selectedConcepts : availableConcepts.map((c) => c.id);
  if (!demandTypes || !concepts || !concepts.length) {
    return demandTypes && demandTypes.filter((type) => type.costStructure === COST_STRUCTURE_AFTER_SALES);
  }

  return demandTypes
    .filter((type) => type.costStructure === COST_STRUCTURE_AFTER_SALES)
    .filter((type) => type.compatibleConcepts.some((c) => concepts.indexOf(c) >= 0));
};

const getAvailableLists = (selectedEquipmentId, selectedConcepts, selectedDemandTypeIds, equipments, demandTypes) => {
  const availableConcepts = getAvailableConcepts(equipments, selectedEquipmentId);
  const compatibleConcepts = selectedConcepts.filter((cid) => availableConcepts.some((ac) => ac.id === cid));
  const availableDemandTypes = getAvailableDemandTypes(demandTypes, compatibleConcepts, availableConcepts);
  const demandTypeIds = selectedDemandTypeIds.filter((tid) => availableDemandTypes.some((at) => at.id === tid));
  return {
    availableConcepts,
    availableDemandTypes,
    compatibleConcepts,
    demandTypeIds,
  };
};

const isEmptyNumber = (n) => n === '' || n === null || n === undefined;

class AfterSalesOperation extends React.Component {
  static errorMessages = {
    name: {
      isDefaultRequiredValue: 'Veuillez saisir un libellé',
      isExisty: 'Veuillez saisir un libellé',
    },
    equipmentId: {
      isDefaultRequiredValue: 'Veuillez sélectionner un matériel',
      isExisty: 'Veuillez sélectionner un matériel',
    },
    price: {
      isDefaultRequiredValue: 'Veuillez saisir un prix unitaire',
      isExisty: 'Veuillez saisir un prix unitaire',
      min: 'Le prix minimal est 0€',
      isEmptyString: 'Veuillez saisir le tarif de pose, dépose ou déplacement',
    },
    quantity: {
      isDefaultRequiredValue: 'Veuillez saisir une quantité',
      isExisty: 'Veuillez saisir une quantité',
      isInt: 'Veuillez saisir un entier',
      min: 'Minimum 1',
      max: 'Maximum 100',
    },
  };

  static propTypes = {
    afterSalesOperation: PropTypes.object.isRequired,
    afterSalesOperationId: PropTypes.string,
    demandTypes: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    deletionErrors: PropTypes.array.isRequired,
    validationErrors: PropTypes.array.isRequired,
    validationErrorsCounter: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const { afterSalesOperationId, equipments, demandTypes } = props;
    const availableLists = getAvailableLists(null, [], [], equipments, demandTypes);

    this.form = React.createRef();

    addMinValidationRule();
    addMaxValidationRule();

    this.state = {
      ...availableLists,
      name: '',
      equipmentId: null,
      emphasis: true,
      groupId: null,
      prices: [
        {
          position: 0,
          companies: [],
          demandTypeIds: [],
          addPrice: null,
          interventionPrice: null,
          movePrice: null,
          removePrice: null,
        },
      ],
      addMaxQuantity: null,
      removeMaxQuantity: null,
      moveMaxQuantity: null,

      editing: !afterSalesOperationId,
      /*
       * When creating a group, some existing operations may join
       * the group.
       *
       * Consequently, these operations should also be updated to fill
       * their groups ids.
       */
      touchedOperations: [],
    };
  }

  componentDidMount() {
    // Loading all needed data from server
    const { dispatch, afterSalesOperationId, afterSalesOperations } = this.props;
    dispatch(loadAfterSalesOperation(afterSalesOperationId));

    dispatch(loadDemandTypesIfNeeded());
    dispatch(loadEquipments());
    if (!afterSalesOperations || !afterSalesOperations.length) {
      dispatch(loadAfterSalesOperations());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, afterSalesOperationId, afterSalesOperation, validationErrorsCounter, demandTypes, equipments } =
      this.props;
    const {
      afterSalesOperationId: nextAfterSalesOperationId,
      afterSalesOperation: nextAfterSalesOperation,
      validationErrorsCounter: nextValidationErrorsCounter,
      validationErrors: nextValidationErrors,
      demandTypes: nextDemandTypes,
      equipments: nextEquipments,
    } = nextProps;

    // Load new operation if route changed
    const routeChanged = nextAfterSalesOperationId !== afterSalesOperationId;
    if (routeChanged) {
      dispatch(loadAfterSalesOperation(nextAfterSalesOperationId));
      return;
    }

    // If the afterSalesOperation's id changed, the form state is reset.
    const operationChanged = nextAfterSalesOperation !== afterSalesOperation;
    const equipmentsChanged = JSON.stringify(equipments) !== JSON.stringify(nextEquipments);
    const demandTypesChanged = JSON.stringify(demandTypes) !== JSON.stringify(nextDemandTypes);
    if (operationChanged) {
      this.setState({ editing: false });
    }
    if (operationChanged || equipmentsChanged || demandTypesChanged) {
      this.resetFormState(nextAfterSalesOperation, nextEquipments, nextDemandTypes);
    }

    /*
     * If the server invalidates some fields, we need to update the form.
     */
    if (validationErrorsCounter !== nextValidationErrorsCounter) {
      const errors = {};
      nextValidationErrors.forEach((error) => {
        errors[error.field] = error.defaultMessage;
      });
      this.form.current.updateInputsWithError(errors);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetAfterSalesOperation());
  }

  onActivateEditingMode = () => {
    this.setState({ editing: true });
  };

  onCancel = () => {
    const { afterSalesOperation, demandTypes, equipments } = this.props;
    this.resetFormState(afterSalesOperation, equipments, demandTypes);
    this.setState({ editing: false });
    this.form.current.reset();
  };

  onChangeBasic = (fieldName) => (event) => {
    const { value } = event.target;
    const newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  };

  onEquipmentChange = (equipmentId) => {
    const { equipments, demandTypes } = this.props;

    this.setState(({ compatibleConcepts, demandTypeIds }) => ({
      ...getAvailableLists(equipmentId, compatibleConcepts, demandTypeIds, equipments, demandTypes),
      equipmentId,
    }));
  };

  onGroupSelect = (groupId) => {
    this.setState({ groupId });
  };

  onEmphasisChange = (emphasis) => {
    this.setState({ emphasis });
  };

  onOperationTouched = (opId, groupId) => {
    const { touchedOperations } = this.state;

    const newTouchedOperations = [
      {
        id: opId,
        groupId,
      },
    ].concat(touchedOperations.filter((op) => op.id !== opId));
    this.setState({
      touchedOperations: newTouchedOperations,
    });
  };

  onConceptsChange = (selectedConcepts) => {
    const { equipments, demandTypes } = this.props;
    this.setState(({ equipmentId, demandTypeIds }) =>
      getAvailableLists(equipmentId, selectedConcepts, demandTypeIds, equipments, demandTypes),
    );
  };

  onDemandTypesChange = (newDemandTypeIds) => {
    const { prices, demandTypeIds } = this.state;
    const { dispatch } = this.props;
    const toDelete = demandTypeIds.filter((value) => !newDemandTypeIds.includes(value));
    const demandTypeIdsUsed = prices.flatMap((p) => p.demandTypeIds);
    if (demandTypeIdsUsed.includes(toDelete[0])) {
      dispatch(
        requestOpenDialog(
          null,
          'Veuillez retirer les coûts de prestation qui inclus ce type de demande avant de le retirer de la liste.',
          [
            {
              label: 'Ok',
              callback: () => dispatch(requestCloseDialog()),
              color: 'neutral',
              variant: 'text',
            },
          ],
        ),
      );
    } else {
      this.setState({ demandTypeIds: newDemandTypeIds });
    }
  };

  onPriceMultiSelectChange = (index, fieldName) => (values) => {
    const { prices } = this.state;
    prices[index][fieldName] = values;
    this.setState({ prices });
  };

  onAddRow = () => {
    const { prices } = this.state;
    prices.forEach((price) => price.position++);
    prices.unshift({
      position: 0,
      companies: [],
      demandTypeIds: [],
      addPrice: null,
      interventionPrice: null,
      movePrice: null,
      removePrice: null,
    });
    this.setState({ prices });
  };

  byPosition = (aso1, aso2) => aso1.position - aso2.position;

  onRemovePrice = (index) => () => {
    const { prices } = this.state;
    prices.splice(index, 1);
    for (let i = index; i < prices.length; i++) {
      prices[i].position--;
    }
    this.setState({ prices });
  };

  onPriceChange = (index, label) => (event) => {
    const { prices } = this.state;
    prices[index][label] = event.target.value;
    this.setState({ prices });
  };

  onDelete = () => {
    const { dispatch, afterSalesOperation } = this.props;
    dispatch(deleteAfterSalesOperation(afterSalesOperation.id));
  };

  onAddToDemands = () => {
    const { dispatch, afterSalesOperation } = this.props;
    dispatch(addOperationToDemands({ afterSalesId: afterSalesOperation.id }));
  };

  onSave = () => {
    this.form.current.submit();
  };

  onPricePositionChange = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const { prices } = this.state;
      arrayMove(prices, oldIndex, newIndex).forEach((item, index) => (item.position = index));
      this.setState({ prices });
    }
  };

  onValidSubmit = () => {
    const { dispatch, afterSalesOperation } = this.props;
    const { groupId } = this.state;
    /*
     * The operations that were touched when (un-)selecting groups.
     *
     * Really touched operations are operations that do not have a group id (disassociation)
     * or that have the group id than the current operations.
     */
    const { touchedOperations } = this.state;
    const reallyTouchedOperations = touchedOperations.filter((opt) => !opt.groupId || opt.groupId === groupId);

    dispatch(
      updateGroup(reallyTouchedOperations, () => {
        const {
          name,
          equipmentId,
          emphasis,
          prices,
          addMaxQuantity,
          removeMaxQuantity,
          moveMaxQuantity,
          compatibleConcepts,
          demandTypeIds,
        } = this.state;

        // Save the after sale operation and after reload groups
        dispatch(
          saveOrUpdateAfterSalesOperation({
            id: afterSalesOperation.id,
            name,
            equipmentId,
            emphasis,
            groupId,
            prices,
            addMaxQuantity,
            removeMaxQuantity,
            moveMaxQuantity,
            compatibleConcepts,
            demandTypeIds,
            position: afterSalesOperation.position,
          }),
        ).then(() => dispatch(loadAfterSalesOperations()));
      }),
    );
  };

  getElementUrl = (id) => `/settings/after-sales-operations/${id}`;

  resetFormState = (afterSalesOperation, equipments, demandTypes) => {
    const availableLists = getAvailableLists(
      afterSalesOperation.equipmentId,
      afterSalesOperation.compatibleConcepts || [],
      afterSalesOperation.demandTypeIds || [],
      equipments,
      demandTypes,
    );

    this.setState({
      ...availableLists,
      name: afterSalesOperation.name,
      equipmentId: afterSalesOperation.equipmentId,
      emphasis: afterSalesOperation.emphasis,
      groupId: afterSalesOperation.groupId,
      prices: afterSalesOperation.prices.map((p) => ({ ...p })),
      addMaxQuantity: afterSalesOperation.addMaxQuantity,
      removeMaxQuantity: afterSalesOperation.removeMaxQuantity,
      moveMaxQuantity: afterSalesOperation.moveMaxQuantity,
      touchedOperations: [],
    });
  };

  render() {
    const { deletionErrors, afterSalesOperation, equipments, allOperations, loading, serverError } = this.props;
    if (loading || serverError) {
      return (
        <LoadingMessage loading={loading} serverError={serverError}>
          Prestation d'après vente
        </LoadingMessage>
      );
    }

    const {
      editing,
      name,
      equipmentId,
      emphasis,
      groupId,
      addMaxQuantity,
      removeMaxQuantity,
      moveMaxQuantity,
      touchedOperations,
      compatibleConcepts,
      demandTypeIds,
      prices,
      availableConcepts,
      availableDemandTypes,
    } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Prestation d'après vente : {name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Formsy noValidate ref={this.form} onValidSubmit={this.onValidSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Informations générales</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Libellé"
                          fullWidth
                          name="name"
                          onChange={this.onChangeBasic('name')}
                          required
                          validations="isExisty"
                          validationErrors={AfterSalesOperation.errorMessages.name}
                          value={name}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          disabled={!editing}
                          label="Matériel"
                          fullWidth
                          name="equipmentId"
                          onChange={this.onEquipmentChange}
                          required
                          validations="isExisty"
                          validationErrors={AfterSalesOperation.errorMessages.equipmentId}
                          value={equipmentId}
                          options={equipments}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <GroupSelect
                          readOnly={!editing}
                          getElementUrl={this.getElementUrl}
                          name={name}
                          groupId={groupId}
                          elementId={afterSalesOperation.id}
                          operations={allOperations}
                          onChange={this.onGroupSelect}
                          onOperationTouched={this.onOperationTouched}
                          touchedOperations={touchedOperations}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Conditions d'affichage</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <ToggleSwitch
                          name="emphasis"
                          label="Affichée par défaut"
                          value={emphasis}
                          disabled={!editing}
                          onChange={this.onEmphasisChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={availableConcepts}
                          disabled={!editing}
                          label="Concepts compatibles"
                          placeholder="Tous"
                          fullWidth
                          name="compatibleConcepts"
                          value={compatibleConcepts}
                          onChange={this.onConceptsChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={availableDemandTypes}
                          disabled={!editing}
                          label="Types de demande compatibles"
                          placeholder="Tous"
                          fullWidth
                          name="demandTypes"
                          onChange={this.onDemandTypesChange}
                          value={demandTypeIds}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Quantités maximum</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Pose"
                          fullWidth
                          name="addMaxQuantity"
                          onChange={this.onChangeBasic('addMaxQuantity')}
                          required
                          type="number"
                          min="1"
                          max="100"
                          validations="isExisty,isInt,min:1,max:100"
                          validationErrors={AfterSalesOperation.errorMessages.quantity}
                          value={addMaxQuantity || ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Dépose"
                          fullWidth
                          name="removeMaxQuantity"
                          onChange={this.onChangeBasic('removeMaxQuantity')}
                          required
                          type="number"
                          min="1"
                          max="100"
                          validations="isExisty,isInt,min:1,max:100"
                          validationErrors={AfterSalesOperation.errorMessages.quantity}
                          value={removeMaxQuantity || ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Déplacement"
                          fullWidth
                          name="moveMaxQuantity"
                          onChange={this.onChangeBasic('moveMaxQuantity')}
                          required
                          type="number"
                          min="1"
                          max="100"
                          validations="isExisty,isInt,min:1,max:100"
                          validationErrors={AfterSalesOperation.errorMessages.quantity}
                          value={moveMaxQuantity || ''}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    addLabel="Ajouter un nouvelle règle"
                    addRole={roles.demand.create.code}
                    onAdd={editing ? this.onAddRow : null}
                  >
                    Coûts de la prestation
                  </CardHeader>
                  <SortableTable onSortEnd={this.onPricePositionChange}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Prestataire</TableCell>
                        <TableCell>Type de demande</TableCell>
                        <TableCell>Intervention (€ / intervention)</TableCell>
                        <TableCell>Pose (€ / unité)</TableCell>
                        <TableCell>Dépose (€ / unité)</TableCell>
                        <TableCell>Déplacement (€ / unité)</TableCell>
                        {editing && <TableCell />}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prices.sort(this.byPosition).map((price, index) => {
                        return (
                          <SortableRow index={index} disabled={!editing} key={`${price.position}-${editing}`}>
                            <TableCell>
                              <AutocompleteField
                                multiple
                                options={ACTIVE_INSTALLERS}
                                disabled={!editing}
                                label="Prestataire"
                                placeholder="Tous"
                                fullWidth
                                name="subcontractor"
                                onChange={this.onPriceMultiSelectChange(index, 'companies')}
                                value={price.companies}
                              />
                            </TableCell>
                            <TableCell>
                              <AutocompleteField
                                multiple
                                options={
                                  demandTypeIds.length === 0
                                    ? availableDemandTypes
                                    : availableDemandTypes.filter((dt) => demandTypeIds.some((id) => id === dt.id))
                                }
                                disabled={!editing}
                                label="Type de demande"
                                placeholder="Toutes"
                                fullWidth
                                name="demandTypes"
                                onChange={this.onPriceMultiSelectChange(index, 'demandTypeIds')}
                                value={price.demandTypeIds}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                disabled={!editing}
                                fullWidth
                                name="interventionPrice"
                                onChange={this.onPriceChange(index, 'interventionPrice')}
                                type="number"
                                min="0"
                                validations={{
                                  min: 0,
                                  ...(isEmptyNumber(price.addPrice) &&
                                  isEmptyNumber(price.removePrice) &&
                                  isEmptyNumber(price.movePrice)
                                    ? { isEmptyString: true }
                                    : {}),
                                }}
                                validationErrors={AfterSalesOperation.errorMessages.price}
                                value={price.interventionPrice}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                disabled={!editing}
                                fullWidth
                                name="addPrice"
                                onChange={this.onPriceChange(index, 'addPrice')}
                                type="number"
                                min="0"
                                validations="min:0"
                                validationErrors={AfterSalesOperation.errorMessages.price}
                                value={price.addPrice}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                disabled={!editing}
                                fullWidth
                                name="removePrice"
                                onChange={this.onPriceChange(index, 'removePrice')}
                                type="number"
                                min="0"
                                validations="min:0"
                                validationErrors={AfterSalesOperation.errorMessages.price}
                                value={price.removePrice}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                disabled={!editing}
                                fullWidth
                                name="movePrice"
                                onChange={this.onPriceChange(index, 'movePrice')}
                                type="number"
                                min="0"
                                validations="min:0"
                                validationErrors={AfterSalesOperation.errorMessages.price}
                                value={price.movePrice}
                              />
                            </TableCell>
                            {editing && (
                              <TableCell>
                                <IconButton onClick={this.onRemovePrice(index)}>
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            )}
                          </SortableRow>
                        );
                      })}
                    </TableBody>
                  </SortableTable>
                </Card>
              </Grid>
            </Grid>
          </Formsy>
        </Grid>
        <Grid item xs={12}>
          <EquipmentButtons
            deletionErrors={deletionErrors}
            editing={editing}
            elementId={afterSalesOperation.id}
            elementName={`la prestation d'après vente "${afterSalesOperation.name}"`}
            onCancel={this.onCancel}
            onActivateEditingMode={this.onActivateEditingMode}
            onDelete={this.onDelete}
            onSave={this.onSave}
            onAddToDemands={this.onAddToDemands}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ afterSalesOperation, afterSalesOperations, demandTypes, equipments }) => ({
  afterSalesOperation: afterSalesOperation.afterSalesOperation,
  allOperations: afterSalesOperations.list,
  demandTypes: demandTypes,
  deletionErrors: afterSalesOperation.deletionErrors,
  equipments: sort(equipments.equipments, [
    {
      field: 'name',
      direction: SortDirections.asc,
    },
  ]),
  loading: afterSalesOperation.loading,
  serverError: afterSalesOperation.error,
  validationErrors: afterSalesOperation.validationErrors,
  validationErrorsCounter: afterSalesOperation.validationErrorsCounter,
});

export default compose(
  connect(mapStateToProps),
  withPageTitle(({ afterSalesOperationId, afterSalesOperation }) =>
    afterSalesOperationId
      ? `Prestation d'après-vente ${afterSalesOperation?.name ?? ''}`
      : "Nouvelle prestation d'après-vente",
  ),
)(AfterSalesOperation);
