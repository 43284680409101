import {
  FDD_SECONDARY_THRESHOLDS_LOADED,
  FDD_SECONDARY_THRESHOLD_SAVED,
  getByIdDecorator,
} from '../../constants/AppConstants';

const initialState = getByIdDecorator([]);

export default function (state = initialState, action) {
  switch (action.type) {
    case FDD_SECONDARY_THRESHOLDS_LOADED: {
      return getByIdDecorator(action.list);
    }
    case FDD_SECONDARY_THRESHOLD_SAVED: {
      return getByIdDecorator([action.item]);
    }
    default:
      return state;
  }
}
