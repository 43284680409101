import queryString from 'query-string';

import { LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_LOADING, urls } from '../constants/AppConstants';
import { loadUser, logout, registerUserCheck } from './CurrentUserActions';
import { addGlobalMessage } from './SnackbarActions';
import { api } from '../services/RestService';
import history from '../history';

const userCustom = api.custom(urls.user.login);

export function loginLoad() {
  return {
    type: LOGIN_LOADING,
  };
}

export function loginSuccess() {
  return (dispatch) => {
    registerUserCheck(dispatch);
    dispatch({
      type: LOGIN_SUCCESS,
    });
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

export function asyncLogin(username, password, callback) {
  /*
   * Basic authentication is used.
   */
  const authHeaderValue = `Basic ${btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`;
  const headers = { authorization: authHeaderValue };

  return (dispatch) => {
    /*
     * Setting the login state as 'loading'.
     */
    dispatch(loginLoad());

    userCustom
      .get({}, headers)
      .then((response) => {
        const user = response.body(false);
        dispatch(loginSuccess());
        dispatch(loadUser(user));
        dispatch(addGlobalMessage(`Bienvenue ${user.firstName} ${user.lastName} !`));
        history.push(callback || '/');
      })
      .catch((error) => {
        dispatch(loginError(error));
        dispatch(logout()); // just to be safe

        const data = error.response.data;
        if (data.message === 'PASSWORD_EXPIRED') {
          const token = data.token;
          history.push({
            pathname: '/password',
            search: queryString.stringify({ token, expired: true }),
          });
        }
      });
  };
}
