import { ACTIVE_INSTALLERS, COMPANIES } from '../constants/AppConstants';

export const isLoggedIn = (currentUser) => currentUser && currentUser.loggedIn;

export const hasRole = (currentUser, role) =>
  isLoggedIn(currentUser) && currentUser.user.authorities.some((auth) => auth.authority === role);

export const hasAnyRole = (currentUser, roles) =>
  isLoggedIn(currentUser) && currentUser.user.authorities.some((auth) => roles.some((role) => auth.authority === role));

export const hasAllRoles = (currentUser, roles) =>
  isLoggedIn(currentUser) &&
  currentUser.user.authorities.some((auth) => roles.every((role) => auth.authority === role));

export const isSubcontractor = (currentUser) => {
  if (!isLoggedIn(currentUser)) {
    return false;
  }
  const userCompany = COMPANIES.getById(currentUser.user.company);
  return userCompany.isSubcontractor;
};

export const visibleActiveInstallers = (currentUser) => {
  if (!isLoggedIn(currentUser)) {
    return [];
  }
  return isSubcontractor(currentUser)
    ? ACTIVE_INSTALLERS.filter((company) => currentUser.user.company === company.id)
    : ACTIVE_INSTALLERS;
};

export const visibleCompanies = (currentUser) => {
  if (!isLoggedIn(currentUser)) {
    return [];
  }
  const userCompany = COMPANIES.getById(currentUser.user.company);
  if (!userCompany.isSubcontractor) {
    return COMPANIES;
  }
  return COMPANIES.filter((company) => currentUser.user.company === company.id);
};
