import React from 'react';
import { compose } from 'recompose';

import OperationOptionsTable from '../../operations/options/OperationOptionsTable';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { withPageTitle } from '../../../utils/page-title';

const OperationOptionsPage = () => <OperationOptionsTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des options'),
  securedPage(roles.equipment.view.code),
)(OperationOptionsPage);
