import { api, ignore401 } from '../services/RestService';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';

import { urls } from '../constants/AppConstants';
import { getErrorMessage } from '../services/ErrorMessageService';

// eslint-disable-next-line import/prefer-default-export
export function performInterventionTransition(interventionId, transition, formData, postAction) {
  const endpoint = api.one(urls.interventions, interventionId);
  return (dispatch) => {
    endpoint
      .all('transitions')
      .post({
        transition: transition.id,
        ...formData,
      })
      .then(() => {
        dispatch(addGlobalMessage(transition.confirmationMessage));
        postAction();
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while performing transition:', error.response);
        if (error.response && error.response.statusCode === 400) {
          dispatch(addGlobalError(getErrorMessage(error)));
        } else {
          dispatch(addGlobalError("Une erreur a eu lieu pendant l'enregistrement de la transition."));
        }
      });
  };
}
