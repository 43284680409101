import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Formsy from 'formsy-react';
import queryString from 'query-string';
import { Fab, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { ArrowLeft, Email, Send } from 'mdi-material-ui';

import { TextField } from '../utils';
import { forgotPassword } from '../../actions/UserActions';
import { usePageTitle } from '../../utils/page-title';
import { useStyles } from './LoginPage';
import { useAppDispatch } from '../../hooks';

const errorMessages = {
  isDefaultRequiredValue: 'Veuillez saisir un email',
  isEmail: 'Veuillez saisir un e-mail valide',
};

const ForgotPasswordPage = () => {
  usePageTitle('Mot de passe oublié');

  const classes = useStyles();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);

  const { expired } = queryString.parse(search);

  const handleSubmit = () => {
    dispatch(forgotPassword(email));
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Paper elevation={2} className={classes.paper}>
          <Formsy
            onValid={() => setValid(true)}
            onInvalid={() => setValid(false)}
            onValidSubmit={handleSubmit}
            noValidate
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {expired ? (
                  <Typography variant="body2" color="error">
                    Ce lien n'est plus valide.
                    <br />
                    Entrez l'adresse e-mail associée à votre compte.
                    <br />
                    Nous vous enverrons un nouveau lien pour créer un mot de passe.
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Entrez l'adresse e-mail associée à votre compte.
                    <br />
                    Nous vous enverrons un lien pour créer un nouveau mot de passe.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Email />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      name="email"
                      type="email"
                      label="Adresse e-mail"
                      value={email}
                      fullWidth
                      required
                      validations="isEmail"
                      validationErrors={errorMessages}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Tooltip title="Envoyer le lien">
              <div className={classes.buttonContainer}>
                <Fab
                  type="submit"
                  size="medium"
                  disabled={!valid}
                  classes={{
                    root: classes.button,
                    disabled: classes.buttonDisabled,
                  }}
                >
                  <Send />
                </Fab>
              </div>
            </Tooltip>
          </Formsy>
        </Paper>

        <Typography variant="body1" className={classes.footer}>
          <Link to="/login">
            <ArrowLeft className={classes.footerIcon} fontSize="inherit" />
            Retour à la connexion
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
