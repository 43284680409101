import debugFactory from 'debug';
import queryString from 'query-string';

import {
  COMPANIES,
  CONCEPTS,
  INTERVENTION_COMMAND_ADDED,
  INTERVENTION_COMMAND_UPDATED,
  INTERVENTION_ERROR,
  INTERVENTION_LOADED,
  INTERVENTION_LOADING,
  INTERVENTION_RESET,
  INTERVENTION_SAVED,
  INTERVENTION_STATUSES,
  INTERVENTIONS_FILTER_UPDATED,
  INTERVENTIONS_LOADED,
  INTERVENTIONS_NEED_FILTER,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';
import { paginationToParams, paramsToPagination } from '../utils/paging';
import { interventionAttachments } from './InterventionAttachmentsActions';
import { interventionOperations } from './InterventionOperationsActions';
import { interventionSignatures } from './InterventionSignaturesActions';
import { SortDirections } from '../utils/sorting';
import { User } from '../model/user';
import { startingFromLastMonth, threeMonthRange } from '../utils/date-utils';
import history from '../history';

const debug = debugFactory('prestago:InterventionActions');

/**
 * The REST collection used to manage intervention resources.
 */
const interventionsCollection = api.all(urls.interventions);

export const interventionExportUrl = `${interventionsCollection.url()}/export.xlsx`;

export const interventionEquipmentsExportUrl = `${interventionsCollection.url()}/equipments/export.csv`;

const transform = (paged) => ({
  ...paged,
  content: paged.content.map((intervention) => ({
    ...intervention,
    concept: CONCEPTS.getById(intervention.concept),
    status: INTERVENTION_STATUSES.getById(intervention.status),
    subcontractor: COMPANIES.getById(intervention.subcontractor),
  })),
});

function interventionsFilterUpdated(pagination) {
  const { pageSize, filter } = pagination;
  return {
    type: INTERVENTIONS_FILTER_UPDATED,
    pageSize,
    filter,
  };
}

/**
 * Builds the error object that can be stored in Redux and that is
 * understood by the React components.
 *
 * @param error The error object returned by restful.js.
 * @param defaultMessage The default error message.
 * @return {*} The error object to store in Redux.
 */
const buildError = (error, defaultMessage) => {
  if (!error || !error.response || !error.response.statusCode) {
    return {
      code: 0,
      message: "Une erreur s'est produite",
    };
  }

  const status = error.response.statusCode;
  let message;
  switch (status) {
    case 404:
      message = "L'intervention n'existe pas";
      break;
    case 403:
      message = "Vous n'êtes pas autorisé à effectuer cette opération";
      break;
    default:
      message = defaultMessage;
  }

  return {
    code: status,
    message,
  };
};

export function updateInterventionsFilter(pagination) {
  history.push({
    search: queryString.stringify(paginationToParams(pagination)),
  });
}

function loadInterventions(query, calendar, map) {
  return (dispatch, getState) => {
    const defaultSort = calendar
      ? { field: 'operation_schedule_date', direction: SortDirections.asc }
      : { field: 'number', direction: SortDirections.desc };
    const pagination = paramsToPagination(query, defaultSort);
    if (calendar) {
      delete pagination.filter.scheduledDateRange;
    }
    if (calendar || map) {
      pagination.pageSize = 1000;
    }
    if (map && !pagination.filter.scheduledDateRange) {
      pagination.filter.scheduledDateRange = startingFromLastMonth();
    }
    dispatch(interventionsFilterUpdated(pagination));

    const {
      currentUser: { user },
    } = getState();
    const { text, agencyIds, areaIds, denormOutletIds } = pagination.filter;
    if (
      calendar &&
      (!text || text.length === 0) &&
      (!agencyIds || agencyIds.length === 0) &&
      (!areaIds || areaIds.length === 0) &&
      (!denormOutletIds || denormOutletIds.length === 0) &&
      !new User(user).isSubcontractorTech()
    ) {
      dispatch({
        type: INTERVENTIONS_NEED_FILTER,
      });
    } else {
      const requestPagination = {
        ...pagination,
        filter: { ...pagination.filter },
      };
      if (calendar) {
        requestPagination.filter.scheduledDateRange = threeMonthRange(requestPagination.filter.date);
      }

      interventionsCollection
        .getAll(paginationToParams(requestPagination, ['outletNames']))
        .then((response) => {
          const page = transform(response.body(false));
          // keep filters
          dispatch({
            type: INTERVENTIONS_LOADED,
            page,
          });
        })
        .catch(ignore401);
    }
  };
}

export function loadInterventionsList(query) {
  return loadInterventions(query, false, false);
}

export function loadInterventionsListForMap(query) {
  return loadInterventions(query, false, true);
}

export function loadInterventionsListForCalendar(query) {
  return loadInterventions(query, true, false);
}

export function saveIntervention(intervention) {
  return (dispatch) => {
    interventionsCollection
      .post(intervention)
      .then((response) => {
        const { id, number } = response.body(false);
        debug('Intervention created: %s %s', id, number);
        dispatch(addGlobalMessage(`L'intervention ${number} a bien été créée.`));
        dispatch({
          type: INTERVENTION_SAVED,
          intervention: { ...intervention, id },
        });
        history.push(`/interventions/${id}`);
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error thrown during intervention creation:', err);
        dispatch(addGlobalError("Une erreur est intervenue pendant la création de l'intervention"));
        dispatch({
          type: INTERVENTION_ERROR,
          err: buildError(err, "Erreur lors de l'enregistrement de l'intervention"),
        });
      });
  };
}

export function updateIntervention(intervention) {
  return (dispatch) => {
    interventionsCollection
      .patch(intervention.id, intervention)
      .then(() => {
        dispatch({
          type: INTERVENTION_SAVED,
          intervention,
        });

        dispatch(addGlobalMessage("L'intervention a bien été sauvegardée"));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while saving the intervention:', err);
        dispatch({
          type: INTERVENTION_ERROR,
          err: buildError(err, "Erreur lors de l'enregistrement de l'intervention"),
        });
      });
  };
}

export function resetIntervention() {
  return {
    type: INTERVENTION_RESET,
  };
}

export function reloadIntervention(id) {
  return (dispatch) => {
    if (!id) {
      return;
    }
    interventionsCollection
      .get(id)
      .then((response) => {
        const intervention = response.body(false);
        dispatch({
          type: INTERVENTION_LOADED,
          intervention,
        });
        dispatch(interventionAttachments(intervention.attachments));
        dispatch(
          interventionOperations(
            intervention.addOperations,
            intervention.moveOperations,
            intervention.removeOperations,
          ),
        );
        dispatch(interventionSignatures(intervention.outletSignature, intervention.subcontractorSignature));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while loading the intervention:', err);
        dispatch({
          type: INTERVENTION_ERROR,
          err: buildError(err, "Erreur lors du chargement de l'intervention"),
        });
      });
  };
}

export function loadIntervention(id) {
  return (dispatch) => {
    dispatch(resetIntervention());
    if (!id) {
      return;
    }
    dispatch({
      type: INTERVENTION_LOADING,
    });
    reloadIntervention(id)(dispatch);
  };
}

export function deleteIntervention(interventionId, demandId) {
  return (dispatch) => {
    interventionsCollection
      .delete(interventionId)
      .then(() => {
        dispatch(addGlobalMessage("L'intervention a bien été supprimée"));
        history.push({ pathname: `/demands/${demandId}` });
      })
      .catch(ignore401)
      .catch(() => {
        dispatch(addGlobalError("Une erreur a eu lieu lors de la suppression de l'intervention"));
      });
  };
}

export function addCommand(interventionId, equipments, deliveryLocationId) {
  const endpoint = api.one(urls.interventions, interventionId);
  const command = {
    deliveryToOutlet: !deliveryLocationId,
    deliveryLocationId,
    equipments,
  };
  return (dispatch) => {
    endpoint
      .all('commands')
      .post(command)
      .then((response) => {
        const savedCommand = response.body(false);
        if (savedCommand) {
          dispatch({
            type: INTERVENTION_COMMAND_ADDED,
            command: savedCommand,
          });
        }
        dispatch(addGlobalMessage('La livraison a bien été commandée.'));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while adding command:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement"));
      });
  };
}

export function updateCommand(interventionId, commandIndex, deliveryLocationId) {
  const endpoint = api.one(urls.interventions, interventionId).one('commands', commandIndex);
  const command = {
    deliveryToOutlet: !deliveryLocationId,
    deliveryLocationId,
  };

  return (dispatch) => {
    dispatch({
      type: INTERVENTION_COMMAND_UPDATED,
      command: { ...command, index: commandIndex },
    });
    endpoint
      .post(command)
      .then(() => {
        dispatch(addGlobalMessage(`Le lieu de livraison de la commande ${commandIndex} a bien été mis à jour.`));
      })
      .catch(ignore401)
      .catch((error) => {
        console.error('Error while adding command:', error);
        dispatch(addGlobalError("Une erreur s'est produite pendant l'enregistrement"));
      });
  };
}
