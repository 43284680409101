import debugFactory from 'debug';

import { CURRENT_USER_LOADED, LOGOUT, CURRENT_USER, urls } from '../constants/AppConstants';
import { api } from '../services/RestService';
import { addGlobalMessage } from './SnackbarActions';
import { closeMainMenu } from './AppActions';

const debug = debugFactory('prestago:CurrentUserActions');

const logoutCustom = api.custom(urls.user.logout);
const userCustom = api.custom(urls.user.login);

const userCheckListenerContainer = {};

export function checkCurrentUser() {
  return (dispatch) => {
    debug('checkLogin');
    userCustom
      .get()
      .then(() => {
        debug('checkLogin success');
      })
      .catch((error) => {
        debug('checkLogin error');
        if (error.response && error.response.statusCode === 401) {
          dispatch(addGlobalMessage('Votre session a expiré, veuillez vous reconnecter.'));
        } else {
          debug('checkLogin error details %o', error);
        }
      });
  };
}

export function registerUserCheck(dispatch) {
  userCheckListenerContainer.listener = () => {
    dispatch(checkCurrentUser());
  };
  window.addEventListener('focus', userCheckListenerContainer.listener);
}

export function loadUser(user) {
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));
  return {
    type: CURRENT_USER_LOADED,
    user,
  };
}

export function logout() {
  window.removeEventListener('focus', userCheckListenerContainer.listener);
  localStorage.removeItem(CURRENT_USER);
  return {
    type: LOGOUT,
  };
}

export function asyncLogout() {
  return (dispatch) => {
    logoutCustom.post().then(() => {
      dispatch(logout());
      dispatch(closeMainMenu());
      dispatch(addGlobalMessage('À bientôt !'));
    });
  };
}
