import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { User } from '../../model/user';
import { paginationToParams, paramsToPagination } from '../../utils/paging';
import { useLocation, useNavigate } from 'react-router-dom';

const arrayContainsOnly = (array, value) => array?.length === 1 && array[0] === value;

const HierarchicalSearch = ({ loadActionCreator, user: userProp, dispatch }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(search);
  const pagination = paramsToPagination(params);
  useEffect(() => {
    const user = new User(userProp);
    if (
      user.isNational() ||
      (arrayContainsOnly(pagination.filter.regionIds, user.regionId) &&
        (!user.agencyId || arrayContainsOnly(pagination.filter.agencyIds, user.agencyId)))
    ) {
      // The filter is valid - load the list
      dispatch(loadActionCreator(params));
    } else {
      // The filter is invalid or missing - reset to the default filter according to the user's hierarchy
      navigate(
        {
          search: queryString.stringify(
            paginationToParams({
              ...pagination,
              currentPage: 0,
              filter: {
                ...pagination.filter,
                regionIds: user.isRegional() ? [user.regionId] : [],
                agencyIds: user.isAgency() ? [user.agencyId] : [],
                areaIds: user.areasIds || [],
              },
            }),
          ),
        },
        { replace: true },
      );
    }
  }, [dispatch, navigate, loadActionCreator, pagination, params, search, userProp]);
  return null;
};

HierarchicalSearch.propTypes = {
  loadActionCreator: PropTypes.func.isRequired,
};

const stateToProps = ({ currentUser: { user } }) => ({
  user,
});

export default compose(connect(stateToProps))(HierarchicalSearch);
