import React from 'react';
import { compose } from 'recompose';
import { Card, CardContent, Grid } from '@mui/material';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { withPageTitle } from '../../../utils/page-title';
import { CardHeader } from '../../utils';
import DayActivityFilters from '../../day-activity/DayActivityFilters';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import { loadDayActivity } from '../../../actions/DayActivityActions';
import DayActivityDashboard from '../../day-activity/DayActivityDashboard';

const DayActivityPage = () => (
  <Grid container spacing={3}>
    <HierarchicalSearch loadActionCreator={loadDayActivity} />
    <Grid item xs={12}>
      <DayActivityFilters />
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader>Activité de la journée</CardHeader>
        <CardContent>
          <DayActivityDashboard />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default compose(
  withPageTitle(() => 'Activité de la journée'),
  securedPage(roles.intervention.view.code),
)(DayActivityPage);
