import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
  actions: {
    width: 0,
    whiteSpace: 'nowrap',
  },
};

const EditableTableRow = ({ classes, children, actions, editable, editing, onEdit, TableCellProps, ...props }) => (
  <TableRow onClick={editable && onEdit} hover={editable && !editing} {...props}>
    {children}
    {actions && (
      <TableCell className={classes.actions} {...TableCellProps}>
        {editable && editing && actions}
      </TableCell>
    )}
  </TableRow>
);

EditableTableRow.propTypes = {
  editable: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  actions: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  TableCellProps: PropTypes.any,
};

export default withStyles(styles)(EditableTableRow);
