import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
} from '@mui/material';
import TruckDelivery from 'mdi-material-ui/TruckDelivery';
import Formsy from 'formsy-react';
import { AutocompleteField } from '../utils';
import { addCommand } from '../../actions/InterventionActions';
import { addMaxValidationRule, addMinValidationRule } from '../../utils/validation-rules';
import { filterDeliveryLocationsByCompany } from '../../reducers/deliveryLocationsReducer';
import InterventionOperationsToCommand from './InterventionOperationsToCommand';
import { extractCommandQuantities } from '../../utils/operation-utils';

const errorMessages = {
  operation: {
    isInt: 'Veuillez saisir un entier',
    min: 'Min 0',
  },
  deliveryLocationId: {
    isDefaultRequiredValue: 'Veuillez sélectionner un lieu',
    isExisty: 'Veuillez sélectionner un lieu',
  },
};

class InterventionCommandAdd extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.operationsTable = React.createRef();
    this.state = {
      open: false,
      operations: [],
      deliveryLocationId: null,
      triedSubmitting: false,
    };
    addMinValidationRule();
    addMaxValidationRule();
  }

  onClose = () => {
    this.setState({
      open: false,
      operations: [],
      deliveryLocationId: null,
      triedSubmitting: false,
    });
  };

  onOpen = () => {
    this.setState({
      open: true,
      operations: [],
      deliveryLocationId: null,
      triedSubmitting: false,
    });
  };

  handleOperationsChange = (operations) => {
    this.setState({
      operations,
    });
  };

  onChangeAllQuantities = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.operationsTable.current?.fillAllQuantities();
  };

  onDeliveryLocationChange = (deliveryLocationId) => {
    this.setState({
      deliveryLocationId,
    });
  };

  allQuantitiesMissing = () => this.state.operations.every((op) => !op.commandQuantity);

  onInvalidSubmit = () => {
    this.setState({ triedSubmitting: true });
  };

  onValidSubmit = () => {
    const { dispatch, interventionId } = this.props;
    const { operations, deliveryLocationId } = this.state;

    this.setState({ triedSubmitting: true });
    if (this.allQuantitiesMissing()) {
      return;
    }

    this.onClose();

    dispatch(addCommand(interventionId, extractCommandQuantities(operations), deliveryLocationId));
  };

  render() {
    const { operations, deliveryLocations, isAgency, className } = this.props;
    const { open, deliveryLocationId, triedSubmitting } = this.state;
    return (
      <>
        <Button color="primary" onClick={this.onOpen} className={className}>
          <TruckDelivery />
          Nouvelle livraison
        </Button>
        <Dialog open={open} onClose={this.onClose}>
          <DialogTitle>Demander une nouvelle livraison</DialogTitle>
          <Formsy onInvalidSubmit={this.onInvalidSubmit} onValidSubmit={this.onValidSubmit} noValidate ref={this.form}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DialogContentText>
                    Saisissez les quantités à relivrer pour chaque prestation (ou{' '}
                    <Link component="button" variant="body1" onClick={this.onChangeAllQuantities}>
                      tout relivrer
                    </Link>
                    ) :
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  {isAgency ? (
                    <DialogContentText>Livraison en zone de vente</DialogContentText>
                  ) : (
                    <AutocompleteField
                      options={deliveryLocations}
                      label="Lieu de livraison"
                      fullWidth
                      required
                      name="deliveryLocationId"
                      onChange={this.onDeliveryLocationChange}
                      value={deliveryLocationId}
                      validations="isExisty"
                      validationErrors={errorMessages.deliveryLocationId}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <InterventionOperationsToCommand
              operations={operations}
              onChange={this.handleOperationsChange}
              ref={this.operationsTable}
            />
            {triedSubmitting && this.allQuantitiesMissing() && (
              <DialogContent>
                <DialogContentText color="error">Veuillez saisir au moins une quantité.</DialogContentText>
              </DialogContent>
            )}
          </Formsy>
          <DialogActions>
            <Button onClick={this.onClose}>Annuler</Button>
            <Button onClick={() => this.form.current.submit()} color="primary">
              Relivrer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({
  intervention: {
    intervention: { id: interventionId, area, subcontractor },
  },
  deliveryLocations,
}) => ({
  interventionId,
  deliveryLocations: filterDeliveryLocationsByCompany(deliveryLocations, { subcontractor }),
  isAgency: !area || !area.id,
});

export default connect(mapStateToProps)(InterventionCommandAdd);
