import { USERS_LOAD, USERS_FILTER_AND_SORT, COMPANIES } from '../constants/AppConstants';
import { computeSorts, SortDirections } from '../utils/sorting';
import { getUserStatus } from '../utils/user-utils';

const initialState = {
  /*
   * The list of users.
   */
  users: [],
  /*
   * The list of sorts. The main one is the first one.
   */
  sorts: [
    { field: 'lastName', direction: SortDirections.asc },
    { field: 'firstName', direction: SortDirections.asc },
    { field: 'email', direction: SortDirections.asc },
  ],
  /*
   * The data available as filters criteria.
   */
  filterAvailableData: {
    availableStatuses: [],
    availableProfiles: [],
    availableRegions: [],
    availableAgencies: [],
    availableAreas: [],
  },
  /*
   * The filters values.
   */
  filters: {
    text: '',
    name: '',
    statuses: [],
    profiles: [],
    companies: [],
    regions: [],
    agencies: [],
    areas: [],
  },
};

/**
 * Builds the data available as filter criteria from the list of users.
 */
const filterAvailableData = (users) => {
  const availableStatuses = [];
  const availableProfiles = [];
  const availableRegions = [];
  const availableAgencies = [];
  const availableAreas = [];

  const statusIds = [];
  const profileIds = [];
  const regionIds = [];
  const agencyIds = [];
  const areaIds = [];

  users.forEach(({ status, profiles, company, region, agency, areas }) => {
    if (!statusIds.includes(status.id)) {
      availableStatuses.push(status);
      statusIds.push(status.id);
    }

    profiles.forEach((profile) => {
      if (!profileIds.includes(profile.id)) {
        availableProfiles.push({ id: profile.id, name: profile.name });
        profileIds.push(profile.id);
      }
    });

    if (region && !regionIds.includes(region.id)) {
      availableRegions.push({ id: region.id, name: region.name });
      regionIds.push(region.id);
    }

    if (agency && !agencyIds.includes(agency.id)) {
      availableAgencies.push({ id: agency.id, name: agency.name });
      agencyIds.push(agency.id);
    }

    if (areas) {
      areas.forEach((area) => {
        if (!areaIds.includes(area.id)) {
          availableAreas.push({ id: area.id, name: area.name });
          areaIds.push(area.id);
        }
      });
    }
  });

  const compareNames = (p1, p2) => p1.name.localeCompare(p2.name);
  availableProfiles.sort(compareNames);
  availableRegions.sort(compareNames);
  availableAgencies.sort(compareNames);
  availableAreas.sort(compareNames);

  return {
    availableStatuses,
    availableProfiles,
    availableRegions,
    availableAgencies,
    availableAreas,
  };
};

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case USERS_LOAD: {
      const users = action.users.map((user) => ({
        ...user,
        company: COMPANIES.getById(user.company),
        status: getUserStatus(user),
      }));
      return {
        ...state,
        users,
        error: action.error,
        filterAvailableData: filterAvailableData(users),
      };
    }
    case USERS_FILTER_AND_SORT: {
      const {
        sortField,
        sortDirection,
        text = '',
        name = '',
        profiles = [],
        statuses = [],
        companies = [],
        regions = [],
        agencies = [],
        areas = [],
      } = action.query;

      const direction = parseInt(sortDirection, 10);
      const sorts =
        sortField && sortDirection && !Number.isNaN(direction)
          ? computeSorts(state.sorts, sortField, direction)
          : state.sorts;

      /*
       * profiles, statuses, companies
       * can be an object or an array (depending on the number of
       * parameters in the query string).
       *
       * For example, if there are two "profiles" parameters, it's
       * an array. If there is one parameter, it's an object.
       */
      const filters = {
        text,
        name,
        profiles: [].concat(profiles),
        statuses: [].concat(statuses),
        companies: [].concat(companies),
        regions: [].concat(regions),
        agencies: [].concat(agencies),
        areas: [].concat(areas),
      };

      return {
        ...state,
        sorts,
        filters,
      };
    }
    default:
      return state;
  }
}
