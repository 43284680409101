import debugFactory from 'debug';

import {
  USERS_LOAD,
  USERS_FILTER_AND_SORT,
  USER_LOAD,
  USER_CARD_LEAVE,
  USER_TOGGLE_EDIT,
  USER_ERROR,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { addGlobalMessage, addGlobalError } from './SnackbarActions';
import { loadUser as loadUserAfterLogin } from './CurrentUserActions';
import history from '../history';
import queryString from 'query-string';

const debug = debugFactory('prestago:UserActions');

const usersCollection = api.all('users');
const usersTokensCollections = api.all('user-tokens');
usersCollection.isFetchNeeded = true;

const usersLoaded = (users, error) => ({
  type: USERS_LOAD,
  error,
  users,
});

const userLoaded = (user, error) => ({
  type: USER_LOAD,
  error,
  user,
});

export function loadUsers() {
  return (dispatch) => {
    usersCollection
      .getAll()
      .then((response) => response.body(false))
      .then((list) => {
        if (list && list.length) {
          usersCollection.isFetchNeeded = false;
        }
        dispatch(usersLoaded(list, null));
      })
      .catch(ignore401)
      .catch((err) => {
        dispatch(usersLoaded([], err.message));
      });
  };
}

export function loadUsersIfNeeded() {
  return (dispatch) => {
    if (usersCollection.isFetchNeeded) {
      loadUsers()(dispatch);
    }
  };
}

export function filterAndSortUsers(query) {
  return {
    type: USERS_FILTER_AND_SORT,
    query,
  };
}

export function loadUser(id) {
  return (dispatch) => {
    // noinspection JSUnresolvedFunction
    usersCollection
      .get(id)
      .then((response) => {
        const user = response.body(false);
        dispatch(userLoaded(user, null));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while loading the user:', err);
        dispatch(userLoaded(null, err));
      });
  };
}

export function leaveUserCard() {
  return {
    type: USER_CARD_LEAVE,
  };
}

export function toggleEditMode() {
  return {
    type: USER_TOGGLE_EDIT,
  };
}

export function saveUser(user) {
  return (dispatch) => {
    const { regionId, agencyId, areasIds, profilesIds, ...rest } = user;
    const userToSave = {
      ...rest,
      region: { id: regionId },
      agency: { id: agencyId },
      areas: areasIds.map((areaId) => ({ id: areaId })),
      profiles: profilesIds.map((profilesId) => ({ id: profilesId })),
    };
    const savedRequest = user.id ? usersCollection.put(user.id, userToSave) : usersCollection.post(userToSave);

    savedRequest
      .then((response) => {
        if (!user.id) {
          history.push({ pathname: `/users/users/${response.body(false).id}` });
        }
        const userId = user.id ? user.id : response.body(false).id;
        // Update the user in the store from the server version
        dispatch(loadUser(userId));
        const msg = user.id
          ? "L'utilisateur a bien été sauvegardé"
          : "L'utilisateur a été créé. Un email lui a été envoyé.";
        dispatch(addGlobalMessage(msg));
      })
      .catch(ignore401)
      .catch((error) => {
        dispatch({
          type: USER_ERROR,
          error,
        });
      });
  };
}

export function changeActivationStatus(userId, active) {
  return (dispatch) => {
    usersCollection
      .patch(userId, { active })
      .then(() => {
        dispatch(loadUser(userId));
        dispatch(addGlobalMessage(`L'utilisateur a bien été ${active ? 'activé' : 'désactivé'}`));
      })
      .catch(ignore401)
      .catch((error) => {
        dispatch({
          type: USER_ERROR,
          error,
        });
      });
  };
}

export function deleteUser(userId) {
  return (dispatch) => {
    usersCollection
      .delete(userId)
      .then(() => {
        dispatch(leaveUserCard());
        history.push({ pathname: '/users/users' });
        dispatch(addGlobalMessage("L'utilisateur a bien été supprimé"));
      })
      .catch(ignore401)
      .catch((error) => {
        dispatch({
          type: USER_ERROR,
          error,
        });
      });
  };
}

export function changeUserPassword(oldPassword, newPassword) {
  return (dispatch) => {
    api
      .custom('users/change-password')
      .post({ oldPassword, newPassword })
      .then(() => {
        dispatch(addGlobalMessage('Le mot de passe a été mis à jour.'));
      })
      .catch(ignore401)
      .catch((err) => {
        if (err.response && err.response.data && err.response.statusCode === 400) {
          dispatch(addGlobalError(err.response.data.error || 'Le mot de passe est invalide'));
        } else {
          dispatch(addGlobalError("Une erreur technique s'est produite."));
        }
      });
  };
}

export function createUserPassword(token, password) {
  return (dispatch) => {
    api
      .custom('users/password')
      .post({ token, password })
      .then((response) => {
        const user = response.body(false);
        dispatch(loadUserAfterLogin(user));
        history.push({ pathname: '/' });
      })
      .catch(ignore401)
      .catch((err) => {
        const statusCode = err.response && err.response.statusCode;
        if (statusCode === 404) {
          dispatch(addGlobalError("Ce lien n'est pas valide"));
        } else if (statusCode === 400) {
          dispatch(addGlobalError(err.response.data.error || 'Le mot de passe est invalide'));
        } else {
          dispatch(addGlobalError("Une erreur technique s'est produite."));
        }
      });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    api
      .custom('users/forgot-password')
      .post(null, { email })
      .then(() => {
        dispatch(addGlobalMessage('Un email vient de vous être envoyé.'));
        history.push({ pathname: '/login' });
      })
      .catch(ignore401)
      .catch((err) => {
        if (err.response && err.response.data && err.response.statusCode === 404) {
          dispatch(addGlobalError("L'email saisi n'existe pas. Veuillez saisir votre email."));
        } else {
          dispatch(addGlobalError("Une erreur technique s'est produite."));
        }
      });
  };
}

export function checkUserToken(token) {
  return () => {
    usersTokensCollections.get(token).catch(() => {
      debug('Invalid token. Redirecting.');
      history.push({ pathname: '/forgot-password', search: queryString.stringify({ expired: true }) });
    });
  };
}
