import { DEMAND_ATTACHMENTS_LIST, DEMAND_ATTACHMENTS_ITEM, DEMAND_ATTACHMENTS_DELETE } from '../constants/AppConstants';
import debugFactory from 'debug';

const debug = debugFactory('prestago:AttachmentReducer');

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEMAND_ATTACHMENTS_LIST:
      debug('DEMAND_ATTACHMENTS_LIST', action.attachments);
      return action.attachments || {};
    case DEMAND_ATTACHMENTS_ITEM: {
      debug('DEMAND_ATTACHMENTS_ITEM', action.attachment);
      const newState = { ...state };
      newState[action.attachment.name] = action.attachment;
      return newState;
    }
    case DEMAND_ATTACHMENTS_DELETE: {
      debug('DEMAND_ATTACHMENTS_DELETE', action.id, action.name);
      const newState = { ...state };
      delete newState[action.name];
      return newState;
    }
    default:
      return state;
  }
}
