import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import Formsy, { addValidationRule } from 'formsy-react';
import { connect } from 'react-redux';

import { TextField } from '../utils';
import { performDemandTransition } from '../../actions/DemandTransitionActions';
import { deleteDemand } from '../../actions/DemandActions';
import DemandTransitionAttachments from './DemandTransitionAttachments';
import { ATTACHMENT_OPTIONS } from '../../constants/demandTransitions';
import { selectDemandAttachmentInProgress } from '../../reducers/demandTransitionAttachmentsReducer';

class DemandTransition extends React.Component {
  static defaultButtons = {
    confirm: 'OUI',
    cancel: 'NON',
  };

  static config = {
    ASK_VALIDATION: {
      message: () => "Souhaitez-vous demander la validation de la demande à l'encadrement de la zone de vente ?",
    },
    VALIDATE: {
      message: (demandNumber) => `Souhaitez-vous valider la demande n° ${demandNumber} ?`,
      buttons: {
        confirm: 'VALIDER',
        cancel: 'ANNULER',
      },
    },
    REFUSE_VALIDATION: {
      message: (demandNumber) => `Souhaitez-vous refuser la demande n° ${demandNumber} ?`,
      buttons: {
        confirm: 'REFUSER',
        cancel: 'ANNULER',
      },
    },
    DELETE: {
      message: (demandNumber) => `Vous êtes sur le point de supprimer la demande n° ${demandNumber}, confirmez-vous ?`,
    },
    DELETE_ALWAYS: {
      message: (demandNumber) =>
        `Vous êtes sur le point de forcer la suppression de la demande n° ${demandNumber}, confirmez-vous ?`,
    },
    SUBCONTRACTOR_REJECT: {
      message: (demandNumber) =>
        `Souhaitez-vous refuser la demande n° ${demandNumber} ?\r\nElle sera renvoyée au PMU pour correction.`,
      buttons: {
        confirm: 'REFUSER',
        cancel: 'ANNULER',
      },
    },
    ACK: {
      message: (demandNumber) => `Souhaitez-vous prendre en compte la demande n° ${demandNumber} ?`,
    },
    START: {
      message: () =>
        "Vous êtes sur le point de déclarer qu'une première intervention a démarré dans " +
        'le point de vente, confirmez-vous ?',
    },
    CANCEL: {
      message: () => "Vous êtes sur le point d'annuler la demande, confirmez-vous ?",
    },
    ASK_ACCEPTANCE: {
      message: () => 'Vous êtes sur le point de demander au PMU de réceptionner, confirmez-vous ?',
    },
    ACCEPT: {
      message: () => 'Réceptionnez-vous sans réserves ?',
      buttons: {
        confirm: 'RÉCEPTIONNER',
        cancel: 'ANNULER',
      },
    },
    ACCEPT_WITH_RESERVE: {
      message: () => 'Réceptionnez-vous avec réserves ?',
      buttons: {
        confirm: 'RÉCEPTIONNER',
        cancel: 'ANNULER',
      },
    },
    REJECT_RESERVES: {
      message: (demandNumber) =>
        `Souhaitez-vous déclarer les réserves de la demande n° ${demandNumber} comme non recevables ?`,
      buttons: {
        confirm: 'REFUSER',
        cancel: 'ANNULER',
      },
    },
    ACK_RESERVES: {
      message: (demandNumber) => `Souhaitez-vous traiter les réserves de la demande n° ${demandNumber} ?`,
      buttons: {
        confirm: 'OUI',
        cancel: 'ANNULER',
      },
    },
    REOPEN: {
      message: (demandNumber) => `Souhaitez-vous rouvrir la demande n° ${demandNumber} ?`,
      buttons: {
        confirm: 'ROUVRIR',
        cancel: 'ANNULER',
      },
    },
    CANCEL_RECEIVE: {
      message: (demandNumber) => `Souhaitez-vous annuler la demande de réception pour la demande n° ${demandNumber} ?`,
    },
  };

  static errorMessages = {
    comment: {
      isDefaultRequiredValue: 'Veuillez saisir un commentaire',
      isExisty: 'Veuillez saisir un commentaire',
    },
    attachments: {
      hasAtLeastOneAttachment: 'Veuillez ajouter au moins une pièce jointe',
    },
  };

  static propTypes = {
    transition: PropTypes.object.isRequired,
    demandNumber: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      opened: false,
      comment: '',
    };

    addValidationRule('hasAtLeastOneAttachment', (values) => {
      // noinspection JSUnresolvedVariable
      const attachments = values.transitionAttachments || {};
      return Object.values(attachments).length >= 1;
    });
  }

  onOpen = () => {
    this.setState({ opened: true });
  };

  onClose = () => {
    this.setState({ opened: false });
  };

  onConfirm = () => {
    this.form.current.submit();
  };

  onCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  onValidSubmit = () => {
    this.onClose();
    const { demandId, dispatch, transition } = this.props;
    const { comment } = this.state;

    if (transition.id === 'DELETE' || transition.id === 'DELETE_ALWAYS') {
      dispatch(deleteDemand(demandId));
    } else {
      dispatch(
        performDemandTransition(demandId, transition, {
          comment,
        }),
      );
    }
  };

  getAttachmentsValidation = () => {
    const { transition } = this.props;
    if (transition.attachments === ATTACHMENT_OPTIONS.MANDATORY) {
      return {
        validations: 'hasAtLeastOneAttachment',
        validationErrors: DemandTransition.errorMessages.attachments,
      };
    }
    return {};
  };

  render() {
    const { transition, demandNumber, demandId, attachmentInProgress } = this.props;
    const { opened, comment } = this.state;
    const config = DemandTransition.config[transition.id];
    const message = config.message(demandNumber);
    const buttons = config.buttons ? config.buttons : DemandTransition.defaultButtons;

    // Comment constraints
    const commentConstraints = transition.mandatoryComment ? 'isExisty' : null;
    const isCommentRequired = !!transition.mandatoryComment;

    return (
      <>
        <Button variant="contained" onClick={this.onOpen} color={transition.color}>
          {transition.name}
        </Button>
        <Dialog
          open={opened}
          onClose={this.onClose}
          maxWidth={transition.attachments === ATTACHMENT_OPTIONS.NONE ? 'sm' : 'md'}
          fullWidth
        >
          <DialogContent>
            <Formsy ref={this.form} noValidate onValidSubmit={this.onValidSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{message}</DialogContentText>
                </Grid>
                {!transition.withoutComment && (
                  <Grid item xs={12}>
                    <TextField
                      label="Commentaires"
                      fullWidth
                      multiline
                      name="comment"
                      required={isCommentRequired}
                      validationErrors={DemandTransition.errorMessages.comment}
                      validations={commentConstraints}
                      value={comment}
                      onChange={this.onCommentChange}
                    />
                  </Grid>
                )}

                <DemandTransitionAttachments
                  demandId={demandId}
                  name="transitionAttachments"
                  transition={transition}
                  {...this.getAttachmentsValidation()}
                />
              </Grid>
            </Formsy>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>{buttons.cancel}</Button>
            <Button onClick={this.onConfirm} color={transition.color} disabled={attachmentInProgress}>
              {buttons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const stateToProps = (state) => ({
  attachmentInProgress: selectDemandAttachmentInProgress(state),
});
export default connect(stateToProps)(DemandTransition);
