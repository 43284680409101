import React from 'react';
import { CardContent, Checkbox, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import * as PropTypes from 'prop-types';
import { CollapsibleCard, ResponsiveTable } from '../utils';

const styles = {
  addCell: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingRight: 20,
    textAlign: 'right',
    color: 'green',
  },
  selectCol: {
    width: 60,
  },
  quantityCol: {
    width: 100,
  },
  emphasisCol: {
    width: 200,
  },
};

const OperationBundleOptions = ({ title, options, selected = [], editing, onAdd, onRemove, classes }) => {
  const displayedOptions = editing ? options : options.filter((option) => selected.includes(option.id));
  return (
    Boolean(options.length) && (
      <CollapsibleCard title={title} defaultOpened>
        {displayedOptions.length ? (
          <ResponsiveTable>
            <TableHead>
              <TableRow>
                {editing && <TableCell className={classes.selectCol}>&nbsp;</TableCell>}
                <TableCell>Libellé</TableCell>
                <TableCell className={classes.quantityCol}>Qté max</TableCell>
                <TableCell className={classes.emphasisCol}>Affichée par déf.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedOptions.map((option) => (
                <TableRow key={option.id}>
                  {editing && (
                    <TableCell>
                      <Checkbox
                        checked={selected.includes(option.id)}
                        onChange={(event, checked) => (checked ? onAdd(option.id) : onRemove(option.id))}
                        color="primary"
                      />
                    </TableCell>
                  )}
                  <TableCell>{option.name}</TableCell>
                  <TableCell>{option.maxQuantity}</TableCell>
                  <TableCell>{option.emphasis ? 'Oui' : 'Non'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ResponsiveTable>
        ) : (
          <CardContent>
            <Typography variant="body1">Aucune option associée au forfait</Typography>
          </CardContent>
        )}
      </CollapsibleCard>
    )
  );
};

OperationBundleOptions.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  editing: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(OperationBundleOptions);
