import {
  PROFILES_LOAD_SUCCESS,
  PROFILES_LOAD_ERROR,
  PROFILES_LIST_UPDATE,
  PROFILES_LIST_DELETE,
  PROFILES_LIST_SORT,
} from '../constants/AppConstants';
import { computeSorts, SortDirections } from '../utils/sorting';

const initialState = {
  list: [],
  sorts: [
    { field: 'name', direction: SortDirections.asc },
    { field: 'description', direction: SortDirections.asc },
  ],
  error: null,
};

function updateList(list, newProfile) {
  let updated = false;
  const newList = list.map((profile) => {
    if (profile.id === newProfile.id) {
      updated = true;
      return newProfile;
    }
    return profile;
  });
  if (!updated) {
    newList.push(newProfile);
  }
  return newList;
}

function deleteInList(list, deletedProfile) {
  return list.filter((profile) => profile.id !== deletedProfile.id);
}

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case PROFILES_LOAD_SUCCESS:
      return {
        ...state,
        list: action.data,
        error: null,
      };
    case PROFILES_LOAD_ERROR:
      return {
        ...state,
        list: [],
        error: action.error,
      };
    case PROFILES_LIST_UPDATE:
      return {
        ...state,
        list: updateList(state.list, action.data),
      };
    case PROFILES_LIST_DELETE:
      return {
        ...state,
        list: deleteInList(state.list, action.data),
      };
    case PROFILES_LIST_SORT:
      return {
        ...state,
        sorts: computeSorts(state.sorts, action.field, action.direction),
      };

    default:
      return state;
  }
}
