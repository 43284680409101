import React from 'react';
import * as PropTypes from 'prop-types';
import { CardContent, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ResponsiveTable from '../../utils/ResponsiveTable';

const DemandImportErrors = ({ errors }) => {
  if (errors.length === 0) {
    return <div style={{ padding: '20px' }}>Aucune erreur</div>;
  }

  return (
    <>
      <CardContent>
        <Typography variant="h2">Récapitulatif des erreurs&nbsp;:</Typography>
      </CardContent>
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <TableCell>Numéro</TableCell>
            <TableCell>Colonne</TableCell>
            <TableCell>Erreur</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors.map((error) => (
            <TableRow key={error.line}>
              <TableCell>{error.line}</TableCell>
              <TableCell>{error.column || '-'}</TableCell>
              <TableCell>{error.error}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ResponsiveTable>
    </>
  );
};

DemandImportErrors.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default DemandImportErrors;
