import { ADD_GLOBAL_MESSAGE, DISMISS_GLOBAL_MESSAGE } from '../constants/AppConstants';

const initialState = {
  message: '',
  level: 'info',
  open: false,
};

export default function globalMessageReducer(state = initialState, action = null) {
  Object.freeze(state);
  switch (action.type) {
    case ADD_GLOBAL_MESSAGE:
      return {
        ...state,
        open: true,
        message: action.message,
        level: action.level,
      };
    case DISMISS_GLOBAL_MESSAGE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
