import fetch from 'isomorphic-fetch';
import restful, { fetchBackend } from 'restful.js';
import { apiBase } from '../constants/AppConstants';
import store from '../store';
import { startProgress, stopProgress } from '../actions/AppActions';
import { logout } from '../actions/CurrentUserActions';
import { addGlobalError } from '../actions/SnackbarActions';
import { getErrorMessage } from './ErrorMessageService';

const api = restful(apiBase, fetchBackend(fetch));

api.identifier('id');

const csrfToken = () => {
  const cookie = document.cookie;
  if (cookie.indexOf('XSRF-TOKEN') < 0) {
    return null;
  }

  /*
   * The regular expression was found here:
   * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
   */
  return cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
};

api.addRequestInterceptor((config) => {
  const { headers, method, url } = config;
  store.dispatch(startProgress(`${method}-${url}`));

  const token = csrfToken();
  if (token !== null) {
    headers['X-XSRF-TOKEN'] = token;
  }

  // just return modified arguments
  return {
    headers,
    credentials: 'same-origin',
  };
});

api.addResponseInterceptor((response, { method, url }) => {
  store.dispatch(stopProgress(`${method}-${url}`));
});

api.on('error', (error, { method, url }) => {
  if (error?.response?.statusCode === 401) {
    store.dispatch(logout());
  }

  store.dispatch(stopProgress(`${method}-${url}`));
  if (error?.response?.statusCode !== 404) {
    store.dispatch(addGlobalError(getErrorMessage(error)));
  }
});

const convert404ToNull = (error) => {
  if (error && error.response && error.response.statusCode === 404) {
    return Promise.resolve(null);
  }
  return Promise.reject(error);
};

const ignore401 = (error) => {
  if (error && error.response && error.response.statusCode === 401) {
    return Promise.resolve(null);
  }
  return Promise.reject(error);
};

export { api, ignore401, convert404ToNull };
