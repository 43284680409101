import React, { Component } from 'react';
import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';

import { AutocompleteField, CardHeader, ToggleSwitch } from '../utils';
import RangeDatePicker from '../commons/RangeDatePicker';
import { DateRange } from '../../model/range';
import { interventionEquipmentsExportUrl } from '../../actions/InterventionActions';
import { paginationToParams } from '../../utils/paging';
import { downloadUrl } from '../../actions/DownloadActions';
import { User } from '../../model/user';
import { COMPANIES, INSTALLERS, OPERATION_TYPES } from '../../constants/AppConstants';
import { DemandTypeAutocompleteField } from '../commons/autocompleteFields';

class InterventionEquipmentsExportFilters extends Component {
  /**
   * Get initial filter state.
   */
  static getNoFilterState(user) {
    const usr = new User(user);
    const company = COMPANIES.getById(usr.company);
    const scheduledDateRange = new DateRange();
    return {
      operationTypes: [],
      scheduledDateRange,
      onlySerialNumbers: false,
      demandTypeIds: [],
      isSubcontractorDisabled: company && company.isSubcontractor,
      subcontractors: company && company.isSubcontractor ? [user.company] : [],
    };
  }

  constructor(props) {
    super(props);
    this.state = InterventionEquipmentsExportFilters.getNoFilterState(props.user);
  }

  onChange = (fieldName) => (value) => {
    this.setState({ [fieldName]: value });
  };

  /**
   * Change callback that occurs when a date selection changes.
   *
   * @param fieldName field name targeted by filter change
   * @param state current state of field
   */
  onDateChange = (fieldName, state) => {
    this.setState({ [fieldName]: state });
  };

  /**
   * Resets state and fetch from server.
   *
   * @param event
   */
  onResetClick = (event) => {
    const { user } = this.props;
    event.preventDefault();
    this.setState(InterventionEquipmentsExportFilters.getNoFilterState(user));
  };

  getExportUrl() {
    const { operationTypes, scheduledDateRange, onlySerialNumbers, demandTypeIds, subcontractors } = this.state;

    const params = paginationToParams({
      filter: {
        operationTypes,
        scheduledDateRange: !scheduledDateRange.isEmpty() && scheduledDateRange,
        onlySerialNumbers,
        demandTypeIds,
        subcontractors,
      },
    });

    const urlParam = params.filter
      ? '?' + params.filter.map((filter) => `filter%5B%5D=${encodeURIComponent(filter)}`).join('&')
      : '';
    return interventionEquipmentsExportUrl + urlParam;
  }

  render() {
    const { dispatch } = this.props;
    const {
      operationTypes,
      scheduledDateRange,
      onlySerialNumbers,
      demandTypeIds,
      subcontractors,
      isSubcontractorDisabled,
    } = this.state;

    const url = this.getExportUrl();

    return (
      <Card>
        <CardHeader>Export des matériels</CardHeader>
        <CardContent>
          <Formsy noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteField
                      multiple
                      options={OPERATION_TYPES}
                      name="operationTypes"
                      value={operationTypes}
                      label="Types d'opération"
                      placeholder="Filtrer par type d'opération"
                      onChange={this.onChange('operationTypes')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DemandTypeAutocompleteField
                      multiple
                      name="demandTypeIds"
                      value={demandTypeIds}
                      label="Types de demande"
                      placeholder="Filtrer par type de demande"
                      onChange={this.onChange('demandTypeIds')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ToggleSwitch
                      name="onlySerialNumbers"
                      label="Avec numéros de série"
                      value={onlySerialNumbers}
                      onChange={this.onChange('onlySerialNumbers')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteField
                      multiple
                      options={INSTALLERS}
                      name="subcontractors"
                      value={subcontractors}
                      label="Prestataire"
                      placeholder="Filtrer par prestataire"
                      onChange={this.onChange('subcontractors')}
                      disabled={isSubcontractorDisabled}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RangeDatePicker
                      minClearable={true}
                      maxClearable={false}
                      label="Planifiée entre :"
                      name="scheduledDateRange"
                      range={scheduledDateRange}
                      onChange={this.onDateChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Formsy>
        </CardContent>
        <CardActions style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            label="Télécharger"
            color="primary"
            onClick={() => dispatch(downloadUrl(url, 'Matériels.csv'))}
          >
            Télécharger
          </Button>
          <Button variant="contained" onClick={this.onResetClick}>
            Réinitialiser
          </Button>
        </CardActions>
      </Card>
    );
  }
}

const stateToProps = ({ currentUser: { user } }) => ({ user });

export default connect(stateToProps)(InterventionEquipmentsExportFilters);
