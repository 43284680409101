/*
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your appliction state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 * 3) (optional) Add an async function like this:
 *    export function asyncYourAction(var) {
 *        return (dispatch) => {
 *             // Do async stuff here
 *             return dispatch(yourAction(var));
 *        };
 *    }
 *
 *    If you add an async function, remove the export from the function
 *    created in the second step
 */

// Disable the no-use-before-define eslint rule for this file
// It makes more sense to have the asnyc actions before the non-async ones
/* eslint-disable no-use-before-define */

import { DISPLAY_LOADER, HIDE_LOADER, MAIN_MENU_CLOSE, MAIN_MENU_TOGGLE } from '../constants/AppConstants';

// export function asyncChangeProjectName(name) {
//   return (dispatch) => {
//     // You can do async stuff here!
//     // API fetching, Animations,...
//     // For more information as to how and why you would do this, check https://github.com/gaearon/redux-thunk
//     return dispatch(changeProjectName(name));
//   };
// }

export function startProgress(name = 'global') {
  return {
    type: DISPLAY_LOADER,
    name,
  };
}

export function stopProgress(name = 'global') {
  return {
    type: HIDE_LOADER,
    name,
  };
}

export function toggleMainMenu() {
  return {
    type: MAIN_MENU_TOGGLE,
  };
}

export function closeMainMenu() {
  return {
    type: MAIN_MENU_CLOSE,
  };
}
