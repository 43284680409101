import React from 'react';
import { useParams } from 'react-router-dom';

import roles from '../../../constants/roles';
import securedPage from '../../securedPage';
import { usePageTitle } from '../../../utils/page-title';
import EquipmentForm from '../../equipments/EquipmentForm';

const EquipmentPage = () => {
  const { equipmentId } = useParams();
  usePageTitle(equipmentId ? 'Détail matériel' : 'Nouveau matériel');
  return <EquipmentForm equipmentId={equipmentId} />;
};

export default securedPage(roles.equipment.view.code)(EquipmentPage);
