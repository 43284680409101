import React from 'react';
import { useParams } from 'react-router-dom';

import roles from '../../../constants/roles';
import securedPage from '../../securedPage';
import FDD from '../../fdd/FDD';

const FDDPage = () => {
  const { fddId } = useParams();
  return <FDD id={fddId} />;
};

export default securedPage(roles.fdd.view.code)(FDDPage);
