import React, { useEffect, useMemo, useState } from 'react';
import { FormHelperText, IconButton, InputAdornment, Tooltip, useTheme } from '@mui/material';
import BarcodeScan from 'mdi-material-ui/BarcodeScan';

import TextField, { TextFieldProps } from './TextField';
import SerialNumberBarcodeScanner from './SerialNumberBarcodeScanner';
import { EquipmentSerialNumberTypeId, StockEquipment } from '../../model/model';
import { api, convert404ToNull, ignore401 } from '../../services/RestService';
import { EQUIPMENT_SERIAL_NUMBER_TYPES, urls } from '../../constants/AppConstants';
import buildDebounce from '../../utils/debounce';
import { Alert, Check } from 'mdi-material-ui';
import { containsCharacters, matchType } from '../../utils/ts-utils';

export type SerialNumberConstraints = {
  minCharacters?: number;
  maxCharacters?: number;
  serialNumberType?: EquipmentSerialNumberTypeId;
  unauthorizedCharacters?: string;
};

type SerialNumberFieldProps = Omit<TextFieldProps, 'onChange' | 'fullWidth' | 'onBlur' | 'InputProps'> & {
  /**
   * Callback for when the text value changes.
   */
  onChange: (value: string) => void;
  /**
   * Callback for when the stock equipment matching the input has been loaded
   * @param equipment The matching equipment, or `null` if not found, or `undefined` if loading or empty.
   */
  onEquipmentLoaded?: (equipment: StockEquipment | null | undefined) => void;
  InputProps?: Omit<TextFieldProps['InputProps'], 'endAdornment'>;
  expectedEquipmentCode?: string;
  constraints?: SerialNumberConstraints;
};

type SearchStatus = 'empty' | 'loading' | 'success' | 'not_found' | 'wrong_model';

export const loadStockEquipment = async (serialNumber: string): Promise<StockEquipment | null> => {
  const endpoint = api.custom(urls.stockEquipmentBySerialNumber);

  try {
    const response = await endpoint.get({ serialNumber });
    return response.body(false);
  } catch (error) {
    return convert404ToNull(error).catch((error) => ignore401(error));
  }
};

const SerialNumberField = ({
  disabled,
  placeholder = 'N° de série',
  value: valueProp,
  onChange,
  onEquipmentLoaded,
  InputProps,
  color,
  expectedEquipmentCode,
  constraints,
  ...childProps
}: SerialNumberFieldProps) => {
  const debounce = useMemo(() => buildDebounce(500), []);
  const theme = useTheme();
  const [scanning, setScanning] = useState(false);
  const [value, setValue] = useState(valueProp);
  const [equipment, setEquipment] = useState<StockEquipment>();
  const [equipmentStatus, setEquipmentStatus] = useState<SearchStatus>(valueProp ? 'loading' : 'empty');

  useEffect(() => {
    if (value !== valueProp) {
      setValue(valueProp ?? null);
      setEquipment(undefined);
      setEquipmentStatus(valueProp ? 'loading' : 'empty');
      onEquipmentLoaded?.(undefined);
    }
    setScanning(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp]);

  const getWarnings = (): string[] => {
    if (!constraints || !valueProp) return [];
    const isTooLong: boolean = constraints.maxCharacters && valueProp.length > constraints.maxCharacters;
    const isTooShort: boolean = constraints.minCharacters && valueProp.length < constraints.minCharacters;
    const hasUnauthorizedCharacters: boolean =
      constraints.unauthorizedCharacters && containsCharacters(valueProp, constraints.unauthorizedCharacters);
    const hasWrongType: boolean = constraints.serialNumberType && !matchType(valueProp, constraints.serialNumberType);

    const warningsList: string[] = [];
    if (constraints.minCharacters && constraints.maxCharacters) {
      if (isTooLong || isTooShort) {
        warningsList.push(
          `Doit contenir entre ${constraints.minCharacters} et ${constraints.maxCharacters} caractères`,
        );
      }
    } else {
      if (isTooShort) {
        warningsList.push(`Doit contenir au moins ${constraints.minCharacters} caractères`);
      }
      if (isTooLong) {
        warningsList.push(`Doit contenir maximum ${constraints.maxCharacters} caractères`);
      }
    }

    if (hasUnauthorizedCharacters) {
      warningsList.push(`Contient des caractères interdits : ${constraints.unauthorizedCharacters}`);
    }

    if (hasWrongType) {
      warningsList.push(`Format attendu : ${EQUIPMENT_SERIAL_NUMBER_TYPES.getById(constraints.serialNumberType).name}`);
    }
    return warningsList;
  };

  const handleChange = (newValue: string | null) => {
    setValue(newValue);

    if (newValue) {
      debounce(async () => {
        if (newValue !== valueProp) {
          onChange(newValue);
        }
        const eq = await loadStockEquipment(newValue);
        onEquipmentLoaded?.(eq);
        setEquipment(eq);
        if (!eq) {
          setEquipmentStatus('not_found');
        } else if (expectedEquipmentCode && expectedEquipmentCode !== eq.equipmentCode) {
          setEquipmentStatus('wrong_model');
        } else {
          setEquipmentStatus('success');
        }
      });
    } else {
      if (valueProp) {
        onChange(newValue);
      }
      onEquipmentLoaded?.(undefined);
      setEquipment(undefined);
      setEquipmentStatus('empty');
    }
  };

  const handleDetected = (detectedValue: string) => {
    setScanning(false);
    handleChange(detectedValue ?? null);
  };

  const inputProps: TextFieldProps['InputProps'] = {
    ...InputProps,
  };
  if (!disabled) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        {equipmentStatus === 'success' && <Check color="primary" sx={{ opacity: 0.5 }} />}
        {['not_found', 'wrong_model'].includes(equipmentStatus) && <Alert color="warning" sx={{ opacity: 0.5 }} />}
        <Tooltip title="Scanner un code-barre">
          <IconButton color="primary" onClick={() => setScanning(true)} size="large">
            <BarcodeScan />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  }

  let helperText: string;
  if (equipmentStatus === 'not_found') {
    helperText = 'Matériel non trouvé';
  } else if (equipmentStatus === 'wrong_model') {
    helperText = equipment.equipmentName;
  }

  return (
    <>
      <TextField
        {...childProps}
        fullWidth
        disabled={disabled}
        onChange={(event) => handleChange(event.target.value ?? null)}
        value={value}
        placeholder={placeholder}
        InputProps={inputProps}
        helperText={helperText}
        sx={{
          '& .MuiFormHelperText-root:not(.Mui-error)': {
            color: theme.palette.warning.main,
          },
        }}
      />
      {getWarnings()?.map((warning: string, index: number) => (
        <FormHelperText sx={{ color: theme.palette.warning.main }} key={'warn' + index}>
          {warning}
        </FormHelperText>
      ))}

      <SerialNumberBarcodeScanner onDetected={handleDetected} open={scanning} onCancel={() => setScanning(false)} />
    </>
  );
};

export default SerialNumberField;
