import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Close, FileDocument } from 'mdi-material-ui';
import DropZone from 'react-dropzone';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { addGlobalError } from '../../actions/SnackbarActions';
import { escapeFileName } from '../../utils/attachment-utils';
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth';

const styles = (theme) => ({
  image: {
    background: 'no-repeat center center',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
  },
  imageUploading: {
    filter: 'grayscale(1)',
  },
  imageUploadingStatus: {
    background: 'rgba(0,0,0,0.3)',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  imageTitle: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    pointerEvents: 'none',
  },
  imageActions: {
    background: 'transparent',
    pointerEvents: 'none',
  },
  imageActionsButton: {
    pointerEvents: 'auto',
    color: theme.palette.primary.contrastText,
    background: 'radial-gradient(farthest-side at center, rgba(0,0,0,0.3), rgba(0,0,0,0))',
  },
  dropZone: {
    height: '100%',
    padding: theme.spacing(1),
    borderColor: '#666',
    borderStyle: 'dashed',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    backgroundColor: theme.palette.background.paper,
  },
  activeDropZone: {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  rejectDropZone: {
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
  },
  validationErrorDropZone: {
    borderColor: theme.palette.error.main,
  },
});

const attachmentUrl = (attachment) => {
  if (!attachment || !attachment.id) {
    return null;
  }
  return `/api/attachments/${attachment.id}/${escapeFileName(attachment.name)}`;
};

const thumbnailUrl = (attachment) => {
  if (!attachment || !attachment.thumbnail) {
    return null;
  }
  if (!attachment.id) {
    return attachment.thumbnail;
  }
  return `/api/attachments/${attachment.id}/thumbnail/${escapeFileName(attachment.name)}`;
};

class AttachmentsZone extends React.Component {
  static propTypes = {
    attachments: PropTypes.array.isRequired,
    dispatch: PropTypes.func,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    readOnly: PropTypes.bool,
    color: PropTypes.string,
    cols: PropTypes.number,
    validationError: PropTypes.bool,
  };

  static defaultProps = {
    color: undefined,
    cols: 6,
  };

  constructor(props) {
    super(props);
    this.state = {
      attachmentToDelete: null,
    };
  }

  onAdd = (acceptedFiles, rejectedFiles) => {
    const { dispatch, onAdd } = this.props;
    if (acceptedFiles.some((file) => file.size > 5242880)) {
      dispatch(addGlobalError('Vous ne pouvez pas mettre de pièce jointe supérieure à 5 Mo.'));
      return;
    }

    /* Workaround for iOS naming all the images with the same name */
    const iosImages = acceptedFiles.filter((file) => file.name.match(/^image\.[^.]+$/));
    if (iosImages.length > 0) {
      const date = dayjs().format('YYYY-MM-DD_HH-mm-ss_');
      for (let imageNo = 0; imageNo < iosImages.length; imageNo++) {
        const image = iosImages[imageNo];
        image.attachmentName = `image_${date}${imageNo + 1}.${image.name.split('.').pop()}`;
      }
    }

    /*
     * Calling the handler provided by the parent component.
     */
    onAdd(acceptedFiles, rejectedFiles);
  };

  /**
   * When clicking on the "delete cross" on an attachment, this attachment is
   * added to the state as the attachment that will be removed if the user confirms
   * the deletion.
   */
  onRemove = (event, attachment) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ attachmentToDelete: { id: attachment.id, name: attachment.name } });
  };

  onRemoveCancel = () => {
    this.setState({ attachmentToDelete: null });
  };

  onRemoveConfirm = () => {
    const { attachmentToDelete } = this.state;
    const { onRemove } = this.props;

    /*
     * Calling the handler provided by the parent component.
     */
    onRemove(attachmentToDelete.id, attachmentToDelete.name);

    /*
     * Cleaning the state.
     */
    this.setState({ attachmentToDelete: null });
  };

  getResponsiveCols = () => {
    const { width } = this.props;
    if (isWidthUp('xl', width)) {
      return 6;
    }
    if (isWidthUp('lg', width)) {
      return 5;
    }
    if (isWidthUp('md', width)) {
      return 4;
    }
    if (isWidthUp('sm', width)) {
      return 3;
    }
    return 2;
  };

  render() {
    const { attachments, readOnly, cols, validationError, classes } = this.props;
    const { attachmentToDelete } = this.state;

    return readOnly && attachments.length === 0 ? (
      <Typography variant="body1">Aucune pièce jointe</Typography>
    ) : (
      <>
        <ImageList cols={Math.min(cols, this.getResponsiveCols())} rowHeight={184} sx={{ m: 0 }}>
          {attachments.map((attachment) => (
            <ImageListItem key={attachment.name}>
              <ButtonBase
                className={classNames(classes.image, {
                  [classes.imageUploading]: attachment.uploading,
                })}
                style={attachment.thumbnail ? { backgroundImage: `url("${thumbnailUrl(attachment)}")` } : {}}
                href={attachmentUrl(attachment)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {!attachment.thumbnail && <FileDocument />}
                {attachment.uploading && (
                  <Typography variant="body2" className={classes.imageUploadingStatus}>
                    Envoi en cours...
                  </Typography>
                )}
              </ButtonBase>
              <ImageListItemBar className={classes.imageTitle} title={attachment.name} />
              {!readOnly && (
                <ImageListItemBar
                  classes={{
                    root: classes.imageActions,
                    actionIcon: classes.imageActionsButton,
                  }}
                  position="top"
                  actionPosition="right"
                  actionIcon={
                    <Tooltip title="Supprimer la pièce jointe" onClick={(event) => this.onRemove(event, attachment)}>
                      <IconButton color="inherit" size="large">
                        <Close />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}
            </ImageListItem>
          ))}
          {!readOnly && (
            <ImageListItem>
              <DropZone onDrop={this.onAdd}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                  <div
                    {...getRootProps()}
                    className={classNames(classes.dropZone, {
                      [classes.validationErrorDropZone]: validationError,
                      [classes.activeDropZone]: isDragActive,
                      [classes.rejectDropZone]: isDragReject,
                    })}
                  >
                    <input {...getInputProps()} />
                    Déposez un fichier ici, ou cliquez pour sélectionner un fichier à ajouter.
                  </div>
                )}
              </DropZone>
            </ImageListItem>
          )}
        </ImageList>
        <Dialog open={Boolean(attachmentToDelete)} onClose={this.onRemoveCancel}>
          <DialogContent>
            <DialogContentText>Êtes vous certain de vouloir supprimer la pièce jointe ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onRemoveCancel}>Annuler</Button>
            <Button color="primary" onClick={this.onRemoveConfirm}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default compose(withStyles(styles), withWidth())(AttachmentsZone);
