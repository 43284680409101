import React from 'react';
import * as PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { AutocompleteField, LoadingMessage, TextField } from '../utils';
import { leaveUserCard, loadUser, saveUser, toggleEditMode } from '../../actions/UserActions';
import { loadRegions } from '../../actions/settings/RegionActions';
import { loadAgencies } from '../../actions/settings/AgencyActions';
import { loadAreas } from '../../actions/settings/AreaActions';
import { loadProfiles } from '../../actions/ProfileActions';
import UserActivationDialog from './UserActivationDialog';
import UserDeletionDialog from './UserDeletionDialog';
import { getUserStatus } from '../../utils/user-utils';
import history from '../../history';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  status: {
    verticalAlign: 'bottom',
  },
  actions: {
    justifyContent: 'center',
  },
};

/**
 * Returns an object containing the agencies and the areas that can be chosen
 * in Select elements.
 *
 * @param props The props containing the entire (not filtered) list of agencies
 * and areas.
 * @param user The object representing the form data.
 * @returns {{displayableAgencies: *, displayableAreas: *}}
 */
const getDisplayableAgenciesAndAreas = (props, user) => {
  const { regionId, agencyId } = user;
  const displayableAgencies = props.availableAgencies.filter((agency) => agency.region.id === regionId || !regionId);

  const agenciesIds = displayableAgencies.map((agency) => agency.id);
  const displayableAreas = props.availableAreas.filter((area) => {
    if (agencyId) {
      return area.agency.id === agencyId;
    }
    return agenciesIds.indexOf(area.agency.id) >= 0;
  });
  return { displayableAgencies, displayableAreas };
};

class User extends React.Component {
  static errorMessages = {
    company: {
      isDefaultRequiredValue: 'La compagnie est obligatoire',
      isExisty: 'La compagnie est obligatoire',
    },
    email: {
      isDefaultRequiredValue: "L'email est obligatoire",
      isEmail: 'Veuillez saisir un e-mail valide',
      isExisty: 'Veuillez saisir un e-mail',
      maxLength: 'Un nom doit contenir au plus 64 caractères',
      minLength: 'Un nom doit contenir au moins cinq caractères',
    },
    firstName: {
      isDefaultRequiredValue: 'Veuillez saisir un prénom',
      isExisty: 'Veuillez saisir un prénom',
      maxLength: 'Un prénom doit contenir au plus 64 caractères',
      minLength: 'Un prénom doit contenir au moins deux caractères',
    },
    lastName: {
      isDefaultRequiredValue: 'Veuillez saisir un nom',
      isExisty: 'Veuillez saisir un nom',
      maxLength: 'Un nom doit contenir au plus 64 caractères',
      minLength: 'Un nom doit contenir au moins deux caractères',
    },
    profiles: {
      isDefaultRequiredValue: 'Veuillez choisir au moins un profil',
      minLength: 'Veuillez choisir au moins un profil',
    },
  };

  static propTypes = {
    availableAgencies: PropTypes.array.isRequired,
    availableAreas: PropTypes.array.isRequired,
    availableCompanies: PropTypes.array.isRequired,
    availableProfiles: PropTypes.array.isRequired,
    availableRegions: PropTypes.array.isRequired,
    hasUserEditRole: PropTypes.bool.isRequired,
    hasUserDeleteRole: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      user: this.props.user,
      ...getDisplayableAgenciesAndAreas(props, props.user),
    };
  }

  /**
   * When the component is mounted, an AJAX request is triggered
   * to load the user data if there is an existing update.
   *
   * Otherwise, this is the page of a new user. So, the editing
   * mode is activated.
   *
   * In all cases, the reference data is loaded.
   */
  componentDidMount() {
    const { userId, dispatch } = this.props;
    if (userId) {
      dispatch(loadUser(userId));
    } else {
      dispatch(toggleEditMode());
    }
    dispatch(loadRegions());
    dispatch(loadAgencies());
    dispatch(loadAreas());
    dispatch(loadProfiles());
  }

  /**
   * Method called when the component will receive new properties.
   * There are three important different cases:
   *    1 - The user was not already loaded: the state is updated
   *    with the user data,
   *    2 - The available agencies change,
   *    3 - The available areas change.
   *
   * In the first case, the user is updated in the state.
   * In all cases, the displayable agencies and areas are recomputed.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    let changed = false;
    let user = this.state.user;

    /*
     * If the user quits the edition mode, then the displayed user is
     * the one from the store.
     */
    if ((this.props.editing && !nextProps.editing) || !this.isUserLoaded()) {
      changed = true;
      user = nextProps.user;
    }

    const areasChanged = this.props.availableAreas.length !== nextProps.availableAreas.length;
    const agenciesChanged = this.props.availableAgencies.length !== nextProps.availableAgencies.length;
    if (areasChanged || agenciesChanged) {
      changed = true;
    }

    if (changed) {
      this.setState({
        user,
        ...getDisplayableAgenciesAndAreas(nextProps, user),
      });
    }
  }

  /**
   * When the user leaves this page, the component will be unmounted.
   * An action is then dispatched to clean the Redux store.
   *
   * If this is not done, the next time the user goes on an user card,
   * this will be initialized with the previous user data until the user
   * will be loaded from the server.
   */
  componentWillUnmount() {
    this.props.dispatch(leaveUserCard());
  }

  onTextFieldChange = (fieldName) => (event) => {
    const newValue = event.target.value;
    this.setState(({ user }) => ({
      user: {
        ...user,
        [fieldName]: newValue,
      },
    }));
  };

  onCompanyChange = (company) => {
    this.setState(({ user }) => ({
      user: {
        ...user,
        company,
      },
    }));
  };

  /**
   * Handler called when the user selects a region.
   *
   * If a different region is selected then the previous selection
   * of agency and areas is cleared.
   */
  onRegionChange = (regionId) => {
    this.setState(({ user }) => {
      if (regionId === user.regionId) {
        return null;
      }
      const newUser = {
        ...user,
        regionId,
        agencyId: null,
        areasIds: [],
      };
      return {
        user: newUser,
        ...getDisplayableAgenciesAndAreas(this.props, newUser),
      };
    });
  };

  /**
   * Handler called when the user selects an agency.
   *
   * It the user selects an agency without selecting a region, the parent
   * region is automatically selected.
   *
   * If a different agency is selected, then the previous selection of
   * areas is cleared.
   */
  onAgencyChange = (agencyId) => {
    this.setState(({ user }) => {
      if (agencyId === user.agencyId) {
        return null;
      }

      /*
       * The user selected an agency without selecting a region.
       */
      let regionId = user.regionId;
      if (!user.regionId && agencyId !== null) {
        const agency = this.props.availableAgencies.find((a) => a.id === agencyId);
        const region = this.props.availableRegions.find((r) => r.id === agency.region.id);
        regionId = region.id;
      }
      const newUser = {
        ...user,
        agencyId,
        regionId,
        areasIds: [],
      };
      return {
        user: newUser,
        ...getDisplayableAgenciesAndAreas(this.props, newUser),
      };
    });
  };

  /**
   * Handler called when the user selects an area.
   *
   * If no region or agency were chosen, the area's parents are
   * automatically selected.
   */
  onAreasChange = (areasIds) => {
    this.setState(({ user }) => {
      const { availableAreas, availableAgencies } = this.props;
      let agencyId = user.agencyId;
      let regionId = user.regionId;

      // Searching parents.
      if (!agencyId) {
        const oneArea = availableAreas.find((a) => areasIds.includes(a.id));
        const agency = availableAgencies.find((a) => oneArea.agency.id === a.id);
        agencyId = agency.id;
        regionId = agency.region.id;
      }

      const newUser = {
        ...user,
        areasIds,
        agencyId,
        regionId,
      };
      return {
        user: newUser,
        ...getDisplayableAgenciesAndAreas(this.props, newUser),
      };
    });
  };

  onProfilesChange = (profilesIds) => {
    this.setState(({ user }) => ({
      user: {
        ...user,
        profilesIds,
      },
    }));
  };

  onEditButtonClick = () => {
    this.props.dispatch(toggleEditMode());
  };

  /**
   * Handler called when the user clicks on the "save" button.
   */
  onSaveButtonClick = () => {
    this.form.current.submit();
  };

  onValidSubmit = () => {
    this.props.dispatch(saveUser(this.state.user));
  };

  onCancelButtonClick = () => {
    const { userId, dispatch } = this.props;
    if (userId) {
      dispatch(toggleEditMode());
    } else {
      history.push({ pathname: '/users/users' });
    }
  };

  /**
   * Returns a boolean indicating if the user is loaded or not.
   *
   * If this is an update form, then we check the ID of the user in the
   * state. Otherwise, this is a "new user" form and there is no user to
   * load.
   *
   * @returns { boolean } { @code true } If the user is loaded or not.
   */
  isUserLoaded() {
    const { userId } = this.props;
    return !userId || typeof this.state.user.id === 'string';
  }

  render() {
    if (!this.isUserLoaded()) {
      return <LoadingMessage loading>Utilisateur</LoadingMessage>;
    }

    const {
      availableCompanies,
      availableProfiles,
      availableRegions,
      user,
      editing,
      hasUserEditRole,
      hasUserDeleteRole,
      classes,
      dispatch,
    } = this.props;
    const {
      user: { firstName, lastName, email, phoneNumber, company, regionId, agencyId, areasIds, profilesIds },
      displayableAgencies,
      displayableAreas,
    } = this.state;

    const userStatus = getUserStatus(user);
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h1">
            Utilisateur {user.firstName} {user.lastName}
          </Typography>
          {user.id && (
            <Typography variant="body1" className={classes.status}>
              {userStatus.name} {userStatus.icon}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Formsy onValidSubmit={this.onValidSubmit} noValidate ref={this.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          key={`firstName${editing}`}
                          disabled={!editing}
                          label="Prénom"
                          fullWidth
                          name="firstname"
                          required
                          validationErrors={User.errorMessages.firstName}
                          validations="isExisty,minLength:2,maxLength:64"
                          value={firstName}
                          onChange={this.onTextFieldChange('firstName')}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          key={`lastName${editing}`}
                          disabled={!editing}
                          label="Nom"
                          fullWidth
                          name="lastName"
                          required
                          validationErrors={User.errorMessages.lastName}
                          validations="isExisty,minLength:2,maxLength:64"
                          value={lastName}
                          onChange={this.onTextFieldChange('lastName')}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          key={`email${editing}`}
                          disabled={!editing}
                          label="Adresse e-mail"
                          fullWidth
                          name="email"
                          required
                          type="email"
                          validationErrors={User.errorMessages.email}
                          validations="isEmail,isExisty,minLength:5,maxLength:64"
                          value={email}
                          onChange={this.onTextFieldChange('email')}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          key={`phone${editing}`}
                          disabled={!editing}
                          label="Téléphone"
                          fullWidth
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={this.onTextFieldChange('phoneNumber')}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          options={availableCompanies}
                          disabled={!editing}
                          label="Entreprise"
                          fullWidth
                          name="company"
                          required
                          validationErrors={User.errorMessages.company}
                          validations="isExisty"
                          onChange={this.onCompanyChange}
                          value={company}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <AutocompleteField
                          options={availableRegions}
                          disabled={!editing}
                          label="Région"
                          fullWidth
                          name="region"
                          onChange={this.onRegionChange}
                          value={regionId}
                          clearable
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          options={displayableAgencies}
                          disabled={!editing}
                          label="Zone de vente"
                          fullWidth
                          name="agency"
                          onChange={this.onAgencyChange}
                          value={agencyId}
                          clearable
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={displayableAreas}
                          disabled={!editing}
                          label="Secteurs"
                          fullWidth
                          name="areas"
                          value={areasIds}
                          onChange={this.onAreasChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={availableProfiles}
                          disabled={!editing}
                          label="Profils"
                          fullWidth
                          name="profiles"
                          required
                          validationErrors={User.errorMessages.profiles}
                          validations="minLength:1"
                          value={profilesIds}
                          onChange={this.onProfilesChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Formsy>
            </CardContent>
            <CardActions className={classes.actions}>
              {editing ? (
                <>
                  <Button variant="contained" color="primary" onClick={this.onSaveButtonClick}>
                    Enregistrer
                  </Button>
                  <Button variant="contained" onClick={this.onCancelButtonClick}>
                    Annuler
                  </Button>
                </>
              ) : (
                hasUserEditRole && (
                  <Button variant="contained" color="primary" onClick={this.onEditButtonClick}>
                    Modifier
                  </Button>
                )
              )}
              {user.id && hasUserEditRole && (
                <UserActivationDialog id={user.id} active={user.active} dispatch={dispatch} email={user.email} />
              )}
              {user.id && hasUserDeleteRole && (
                <UserDeletionDialog id={user.id} dispatch={this.props.dispatch} email={user.email} />
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(User);
