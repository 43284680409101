import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Card, Grid } from '@mui/material';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { CardHeader } from '../../utils';
import { loadFDDs } from '../../../actions/fdd/FDDActions';
import HierarchicalSearch from '../../commons/HierarchicalSearch';
import FDDTable from '../../fdd/FDDTable';
import FDDFilters from '../../fdd/FDDFilters';
import FDDExport from '../../fdd/FDDExport';
import history from '../../../history';
import Hidden from '@mui/material/Hidden';
import { withPageTitle } from '../../../utils/page-title';

const FDDListPage = ({ totalElements }) => (
  <Grid container spacing={3}>
    <HierarchicalSearch loadActionCreator={loadFDDs} />
    <Grid item xs={12}>
      <FDDFilters />
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader
          addLabel="Créer un nouveau dossier FDD"
          addRole={roles.fdd.edit.code}
          onAdd={() => history.push('/fdd/new')}
          actions={
            <Hidden mdDown>
              <FDDExport />
            </Hidden>
          }
        >
          Dossiers de fonds de développement
          {totalElements > 0 ? ` (${totalElements})` : ''}
        </CardHeader>
        <FDDTable />
      </Card>
    </Grid>
  </Grid>
);

const stateToProps = (state) => ({
  totalElements: state.fdds.pagination.totalElements,
});

export default compose(
  withPageTitle(() => 'Fonds de développement'),
  securedPage(roles.fdd.view.code),
  connect(stateToProps),
)(FDDListPage);
