import React, { PropsWithChildren } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

type LoadingMessageProps = PropsWithChildren<{
  loading?: boolean;
  serverError?: string | { message: string };
}>;

const LoadingMessage = ({ children, loading, serverError }: LoadingMessageProps) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h1">{children}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography>
            {loading && 'Chargement en cours'}
            {serverError && (typeof serverError === 'string' ? serverError : serverError.message)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default LoadingMessage;
