import {
  INTERVENTION_SIGNATURES,
  INTERVENTION_SIGNATURE_OUTLET,
  INTERVENTION_SIGNATURE_SUBCONTRACTOR,
} from '../constants/AppConstants';

const initialState = {
  outletSignature: null,
  subcontractorSignature: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INTERVENTION_SIGNATURES:
      return {
        ...state,
        outletSignature: action.outletSignature,
        subcontractorSignature: action.subcontractorSignature,
      };
    case INTERVENTION_SIGNATURE_OUTLET:
      return {
        ...state,
        outletSignature: action.signature,
      };
    case INTERVENTION_SIGNATURE_SUBCONTRACTOR:
      return {
        ...state,
        subcontractorSignature: action.signature,
      };
    default:
      return state;
  }
}
