import React, { useEffect, useState } from 'react';
import Formsy from 'formsy-react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import {
  deleteEquipment,
  loadEquipment,
  loadEquipments,
  saveOrUpdateEquipment,
} from '../../actions/settings/EquipmentActions';
import { reloadDemandCosts } from '../../actions/settings/ReloadDemandCostsActions';
import { AutocompleteField, LoadingMessage, TextField, ToggleSwitch } from '../utils';
import { CONCEPTS, EQUIPMENT_SERIAL_NUMBER_TYPES, EQUIPMENT_TYPES } from '../../constants/AppConstants';
import { sort, SortDirections } from '../../utils/sorting';
import EquipmentButtons from './EquipmentButtons';
import { addMinValidationRule } from '../../utils/validation-rules';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Concept, ConceptId, Equipment, EquipmentSerialNumberTypeId, EquipmentTypeId } from '../../model/model';
import { AppDispatch } from '../../store';

const errorMessages = {
  name: {
    isDefaultRequiredValue: 'Veuillez saisir un libellé',
    isExisty: 'Veuillez saisir un libellé',
  },
  code: {
    isDefaultRequiredValue: 'Veuillez saisir un code',
    isExisty: 'Veuillez saisir un code',
    maxLength: 'Taille maximum : 20',
  },
  type: {
    isDefaultRequiredValue: 'Veuillez choisir un type de matériel',
    isExisty: 'Veuillez choisir un type de matériel',
  },
  equipmentIds: {
    isDefaultRequiredValue: 'Veuillez sélectionner au moins un matériel',
    isExisty: 'Veuillez sélectionner au moins un matériel',
    minLength: 'Veuillez sélectionner au moins un matériel',
  },
  unitPrice: {
    isDefaultRequiredValue: 'Veuillez saisir un prix unitaire',
    isExisty: 'Veuillez saisir un prix unitaire',
    min: 'Le prix minimal est 0€',
  },
};
const EquipmentForm = ({ equipmentId }) => {
  const form = React.createRef<Formsy>();
  const dispatch: AppDispatch = useAppDispatch();
  const loading: boolean = useAppSelector(({ equipment: { loading } }) => loading);
  const serverError = useAppSelector(({ equipment: { error } }) => error);
  const validationErrors = useAppSelector(({ equipment: { validationErrors } }) => validationErrors);
  const deletionErrors = useAppSelector(({ equipment: { deletionErrors } }) => deletionErrors);
  const equipment = useAppSelector(({ equipment: { equipment } }) => equipment);

  const equipments: Equipment[] = useAppSelector(({ equipments: { equipments } }) =>
    sort(equipments, [
      {
        field: 'name',
        direction: SortDirections.asc,
      },
    ]),
  );

  const getCompatibleConcepts = (): Concept[] => {
    if (type !== 'KIT' || !equipmentIds?.length || !equipments?.length) {
      return CONCEPTS;
    }
    let compatibleConcepts: Concept[] = [...CONCEPTS];
    for (const equipmentId of equipmentIds) {
      const equipmentConceptIds: Set<ConceptId> = new Set(
        equipments.find((eq: Equipment): boolean => eq.id === equipmentId).compatibleConcepts,
      );
      compatibleConcepts = compatibleConcepts.filter((concept) => equipmentConceptIds.has(concept.id));
    }
    return compatibleConcepts;
  };

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [concepts, setConcepts] = useState<ConceptId[]>([]);

  const [type, setType] = useState<EquipmentTypeId>();
  const [equipmentIds, setEquipmentIds] = useState<string[]>([]);
  const [unitPrice, setUnitPrice] = useState(0);
  const [fillSerialNumber, setFillSerialNumber] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [compatibleEquipments, setCompatibleEquipments] = useState<Equipment[]>([]);

  const [serialNumberType, setSerialNumberType] = useState<EquipmentSerialNumberTypeId>();
  const [minCharacters, setMinCharacters] = useState<number>(null);
  const [maxCharacters, setMaxCharacters] = useState<number>(null);
  const [unauthorizedCharacters, setUnauthorizedCharacters] = useState('');

  const [editing, setEditing] = useState(!Boolean(equipmentId));
  addMinValidationRule();

  useEffect((): void => {
    const errors = {};
    validationErrors.forEach((error) => {
      errors[error.field] = error.defaultMessage;
    });
    form.current?.updateInputsWithError(errors);
  }, [form, validationErrors]);

  useEffect((): void => {
    /*
     * If no id (for new equipment), then loadEquipment will reset the data in Redux.
     */
    dispatch(loadEquipment(equipmentId));

    /*
     * Loading equipments for kits.
     */
    dispatch(loadEquipments());
  }, [dispatch, equipmentId]);

  useEffect((): void => {
    resetForm();
    if (Boolean(equipment.id)) {
      setEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  const onActivateEditingMode = (): void => {
    setEditing(true);
  };

  const onCancel = (): void => {
    resetForm();
    setEditing(false);
  };

  const onChangeUnitPrice = (event): void => setUnitPrice(event.target.value);

  const onChangeName = (event): void => setName(event.target.value);

  const onChangeCode = (event): void => setCode(event.target.value);

  const onChangeMinCharacters = (event): void => setMinCharacters(event.target.value);

  const onChangeUnauthorizedCharacters = (event): void => setUnauthorizedCharacters(event.target.value);

  const onChangeMaxCharacters = (event): void => setMaxCharacters(event.target.value);

  const onChangeSerialNumberType = (type): void => setSerialNumberType(type);

  const onChangeFillSerialNumber = (checked): void => setFillSerialNumber(checked);

  const onEquipmentsChange = (equipmentIds): void => setEquipmentIds(equipmentIds);

  const onChangeDummy = (checked): void => {
    if (checked) {
      setDummy(true);
      setFillSerialNumber(false);
    } else {
      setDummy(false);
    }
  };

  const onConceptsChange = (concepts): void => {
    setConcepts(concepts);
    setCompatibleEquipments(getCompatibleEquipments(equipments, concepts, equipment.id));
  };

  const onTypeChange = (type): void => {
    if (type === 'UNIT') {
      setType(type);
      setEquipmentIds([]);
    } else {
      setType(type);
      setFillSerialNumber(false);
      setDummy(false);
      setUnitPrice(0);
    }
  };

  const onDelete = (): void => {
    dispatch(deleteEquipment(equipment.id));
  };

  const onReloadDemandCosts = ({ date }): void => {
    dispatch(reloadDemandCosts(date, equipment));
  };

  const onValidSubmit = (): void => {
    dispatch(
      saveOrUpdateEquipment({
        id: equipmentId,
        name,
        code,
        type,
        compatibleConcepts: concepts,
        equipmentIds,
        unitPrice,
        fillSerialNumber,
        dummy,
        serialNumberType,
        minCharacters,
        maxCharacters,
        unauthorizedCharacters,
      }),
    );
  };

  const getCompatibleEquipments = (equipments, selectedConcepts, currentEqId): Equipment[] => {
    if (selectedConcepts.length === 0) {
      return [];
    }

    return equipments.filter(
      (eq) =>
        eq.type === 'UNIT' &&
        eq.id !== currentEqId &&
        selectedConcepts.every((concept) => eq.compatibleConcepts.indexOf(concept) >= 0),
    );
  };

  const resetCompatibleEquipments = (equipments, concepts, equipmentId): void => {
    setCompatibleEquipments(getCompatibleEquipments(equipments, concepts, equipmentId));
  };

  const resetForm = (): void => {
    setName(equipment.name);
    setCode(equipment.code);
    setConcepts(equipment.compatibleConcepts);
    setType(equipment.type);
    setEquipmentIds(equipment.equipmentIds);
    setUnitPrice(equipment.unitPrice);
    setFillSerialNumber(equipment.fillSerialNumber);
    setDummy(equipment.dummy);
    setSerialNumberType(equipment.serialNumberType);
    setMinCharacters(equipment.minCharacters);
    setMaxCharacters(equipment.maxCharacters);
    setUnauthorizedCharacters(equipment.unauthorizedCharacters);
    resetCompatibleEquipments(equipments, equipment.compatibleConcepts, equipment.id);
  };

  return loading || serverError ? (
    <LoadingMessage loading={loading} serverError={serverError}>
      Matériel
    </LoadingMessage>
  ) : (
    <Formsy noValidate onValidSubmit={onValidSubmit} ref={form}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            Matériel {code}
            {equipment.id && concepts.length === 0 && ' (inactif)'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} container>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2">Informations générales</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        disabled={!editing}
                        label="Libellé"
                        fullWidth
                        name="name"
                        onChange={onChangeName}
                        required
                        validations="isExisty"
                        validationErrors={errorMessages.name}
                        value={name}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        disabled={!editing}
                        label="Code"
                        fullWidth
                        name="code"
                        onChange={onChangeCode}
                        required
                        validations="isExisty,maxLength:20"
                        validationErrors={errorMessages.code}
                        value={code}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteField
                        multiple
                        options={getCompatibleConcepts()}
                        disabled={!editing}
                        label="Concepts compatibles"
                        placeholder="Aucun"
                        fullWidth
                        name="compatibleConcepts"
                        value={concepts}
                        onChange={onConceptsChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} container>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2">Détails</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <AutocompleteField
                        options={EQUIPMENT_TYPES}
                        disabled={!editing}
                        label="Type"
                        fullWidth
                        name="type"
                        required
                        validations="isExisty"
                        validationErrors={errorMessages.type}
                        value={type}
                        onChange={onTypeChange}
                      />
                    </Grid>

                    {type === 'KIT' && (
                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={compatibleEquipments}
                          disabled={!editing}
                          label="Matériels"
                          fullWidth
                          name="equipments"
                          required
                          validations="isExisty,minLength:1"
                          validationErrors={errorMessages.equipmentIds}
                          value={equipmentIds}
                          onChange={onEquipmentsChange}
                        />
                      </Grid>
                    )}

                    {type === 'UNIT' && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            disabled={!editing}
                            label="Prix unitaire (€)"
                            fullWidth
                            name="unitPrice"
                            onChange={onChangeUnitPrice}
                            required
                            type="number"
                            validations="isExisty,min:0"
                            validationErrors={errorMessages.unitPrice}
                            value={unitPrice}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ToggleSwitch
                            label="Avec numéro de série"
                            value={fillSerialNumber}
                            disabled={!editing}
                            onChange={onChangeFillSerialNumber}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ToggleSwitch
                            label="Prestation sans matériel / sans livraison"
                            value={dummy}
                            disabled={!editing}
                            onChange={onChangeDummy}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} container>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2">Contraintes de numéro de série</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <AutocompleteField
                        options={EQUIPMENT_SERIAL_NUMBER_TYPES}
                        disabled={!editing}
                        label="Type "
                        fullWidth
                        placeholder=""
                        name="serialNumberType"
                        value={serialNumberType}
                        onChange={onChangeSerialNumberType}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="number"
                        disabled={!editing}
                        label="Nombre minimal de caractères"
                        fullWidth
                        name="minCharacters"
                        value={minCharacters}
                        onChange={onChangeMinCharacters}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="number"
                        disabled={!editing}
                        label="Nombre maximal de caractères"
                        fullWidth
                        name="maxCharacters"
                        value={maxCharacters}
                        onChange={onChangeMaxCharacters}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={!editing}
                        label="Caractères interdits"
                        fullWidth
                        name="unauthorizedCharacters"
                        value={unauthorizedCharacters}
                        onChange={onChangeUnauthorizedCharacters}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EquipmentButtons
            deletionErrors={deletionErrors}
            editing={editing}
            elementId={equipment.id}
            elementName={`le matériel ${equipment.code} ${equipment.name}`}
            onCancel={onCancel}
            onActivateEditingMode={onActivateEditingMode}
            onDelete={onDelete}
            onReloadDemandCosts={onReloadDemandCosts}
            onAddToDemands={null}
          />
        </Grid>
      </Grid>
    </Formsy>
  );
};

export default EquipmentForm;
