import { FDD_SECONDARY_THRESHOLDS_LOADED, FDD_SECONDARY_THRESHOLD_SAVED, urls } from '../../constants/AppConstants';
import { api, ignore401 } from '../../services/RestService';

const secondaryThresholdsCollection = api.all(urls.fdd.secondaryThresholds);
secondaryThresholdsCollection.isFetchNeeded = true;

export function loadSecondaryThresholds() {
  return (dispatch) => {
    secondaryThresholdsCollection
      .getAll()
      .then((response) => {
        const list = response.body(false);
        if (list && list.length) {
          secondaryThresholdsCollection.isFetchNeeded = false;
        }
        dispatch({
          type: FDD_SECONDARY_THRESHOLDS_LOADED,
          list,
        });
      })
      .catch(ignore401);
  };
}

export function loadSecondaryThresholdsIfNeeded() {
  return (dispatch) => {
    if (secondaryThresholdsCollection.isFetchNeeded) {
      loadSecondaryThresholds()(dispatch);
    }
  };
}

export function saveSecondaryThreshold(threshold) {
  return (dispatch) => {
    secondaryThresholdsCollection
      .put(threshold.id, threshold)
      .then((response) => {
        dispatch({
          type: FDD_SECONDARY_THRESHOLD_SAVED,
          item: {
            ...threshold,
            editing: false,
          },
        });
      })
      .catch(ignore401);
  };
}
