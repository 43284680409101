import { DEMAND_COST_LOADED, DEMAND_COST_CLOSE } from '../constants/AppConstants';

const defaultCost = {
  bundleCost: 0,
  optionsCost: 0,
  equipmentCost: 0,
  totalCost: 0,
};
const initialState = {
  opened: false,
  cost: defaultCost,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEMAND_COST_LOADED:
      return {
        ...state,
        opened: true,
        cost: action.cost || defaultCost,
      };
    case DEMAND_COST_CLOSE:
      return {
        opened: false,
        cost: defaultCost,
      };
    default:
      return state;
  }
}
