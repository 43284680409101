import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';
import Formsy from 'formsy-react';

import { deleteArea, saveArea } from '../../actions/settings/AreaActions';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import { EditableTableRow } from '../utils';
import { AgencyAutocompleteField } from '../commons/autocompleteFields';

class AreaRow extends Component {
  static propTypes = {
    area: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
  };

  state = {
    area: this.props.area,
    editing: this.props.editing || false,
    deleting: false,
  };

  onEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { area } = this.props;
    this.setState(({ editing }) => (editing ? null : { editing: true, area }));
  };

  onChangeName = (e) => {
    e.persist();
    this.setState(({ area }) => ({
      area: {
        ...area,
        name: e.target.value,
      },
    }));
  };

  onChangeAgency = (id, agency) => {
    this.setState(({ area }) => ({
      area: {
        ...area,
        agency,
        region: agency && agency.region,
      },
    }));
  };

  onKeyDown = (e) => {
    e.persist();
    if (e.keyCode === 13) {
      // Case enter is pressed
      this.onSave(e);
    } else if (e.keyCode === 27) {
      // Case escape is pressed
      this.onCancelEdit(e);
    }
  };

  onCancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, area } = this.props;

    if (!area.id) {
      dispatch(deleteArea(area));
      return;
    }

    this.setState({
      editing: false,
      area: {},
    });

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      editing: false,
    });

    const { dispatch } = this.props;
    const { area } = this.state;
    dispatch(saveArea(area));
    dispatch(addGlobalMessage('Modifications sauvegardées'));
  };

  onAskDeleteConfirmation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      deleting: true,
    });
  };

  onCancelDelete() {
    this.setState({
      deleting: false,
    });
  }

  onDelete() {
    const { dispatch, area } = this.props;
    dispatch(deleteArea(area));
    dispatch(addGlobalMessage(`Secteur supprimé : ${area.name}`));
  }

  render() {
    // From AreasTable
    const { area, editing, deleting } = this.state;
    const { area: areaProp, editable } = this.props;

    return (
      <EditableTableRow
        editable={editable}
        editing={editing}
        onEdit={(e) => this.onEditClick(e, area)}
        actions={
          <>
            <Tooltip title="Enregistrer le secteur">
              <IconButton onClick={this.onSave} size="large">
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annuler les changements">
              <IconButton onClick={this.onCancelEdit} size="large">
                <Close />
              </IconButton>
            </Tooltip>
            {Boolean(area.id) && (
              <Tooltip title="Supprimer le secteur">
                <IconButton onClick={this.onAskDeleteConfirmation} size="large">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <TableCell>
          {editing ? (
            <Formsy>
              <TextField
                autoFocus
                name="label"
                value={area.name}
                onChange={this.onChangeName}
                fullWidth
                onKeyDown={this.onKeyDown}
              />
            </Formsy>
          ) : (
            <span>{areaProp.name}</span>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <Formsy>
              <AgencyAutocompleteField
                name="agency"
                value={area.agency && area.agency.id}
                onChange={this.onChangeAgency}
              />
            </Formsy>
          ) : (
            <span>{areaProp.agency && areaProp.agency.name}</span>
          )}
        </TableCell>
        <TableCell>{editing ? area.region && area.region.name : areaProp.region && areaProp.region.name}</TableCell>
        <Dialog open={deleting} onClose={() => this.onCancelDelete()}>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer le secteur {areaProp.name} ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelDelete()}>Annuler</Button>
            <Button color="secondary" onClick={() => this.onDelete()}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </EditableTableRow>
    );
  }
}

export default connect()(AreaRow);
