import { FC, ReactNode } from 'react';
import { CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

type FormActionsProps = {
  leftActions?: ReactNode;
  rightActions?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
  },
  actionBloc: {
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    '& > *, & > :not(:first-of-type)': {
      margin: 4,
    },
    '&:not(:first-of-type)': {
      margin: 0,
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        width: '100%',
      },
    },
  },
}));

const FormActions: FC<FormActionsProps> = ({ leftActions, rightActions }) => {
  const classes = useStyles();
  return (
    <CardActions className={classes.root}>
      <div className={classes.actionBloc}>{leftActions}</div>
      <div className={classes.actionBloc}>{rightActions}</div>
    </CardActions>
  );
};

export default FormActions;
