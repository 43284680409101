import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, Link, TableCell, TableRow, TextField as MuiTextField, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { AutocompleteField, TextField } from '../utils';
import { updateOperationCompletedQuantity } from '../../actions/DemandOperationsActions';
import classNames from 'classnames';

const errorMessages = {
  operation: {
    isInt: 'Veuillez saisir un entier',
    min: 'Min 0',
    max: 'Maximum dépassé',
    isExisty: 'Prestation obligatoire',
    isDefaultRequiredValue: 'Prestation obligatoire',
  },
};

const styles = (theme) => ({
  interventionOperation: {
    color: theme.palette.prestago.operation.intervention,
  },
  subcontractorOperation: {
    color: theme.palette.prestago.operation.subcontractor,
  },
  equipmentSelect: { minWidth: 210, whiteSpace: 'nowrap' },
  narrow: {
    width: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  quantityLink: {
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
  quantityInput: {
    minWidth: 35,
    width: 40,
  },
});

class DemandOperation extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    canUserToggleCompletionStatuses: PropTypes.bool.isRequired,
    operation: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    onChangeOperation: PropTypes.func.isRequired,
    onChangeOperationGroup: PropTypes.func.isRequired,
    demandId: PropTypes.string,
  };

  state = {
    editingCompletedQuantity: false,
    completedQuantity: this.props.operation.completedQuantity,
    completedQuantityError: '',
  };

  onCompletedChange = (event, checked) => {
    const { dispatch, demandId, operation } = this.props;

    if (parseInt(operation.quantity, 10) === 1) {
      // Check or uncheck
      dispatch(updateOperationCompletedQuantity(demandId, operation, checked ? 1 : 0));
    } else {
      // Display the completed quantity field
      this.setState({
        editingCompletedQuantity: true,
        completedQuantity: operation.completedQuantity || '',
        completedQuantityError: '',
      });
    }
  };

  onCompletedQuantityCancel = () => {
    this.setState({ editingCompletedQuantity: false });
  };

  onCompletedQuantityChange = (e) => {
    const completedQuantity = e.target.value;
    const completedQuantityError = this.validateCompletedQuantity(completedQuantity);
    this.setState({
      completedQuantity,
      completedQuantityError,
    });
  };

  onCompletedQuantityKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Enter
      this.onCompletedQuantitySubmit();
    } else if (e.keyCode === 27) {
      // Escape
      this.onCompletedQuantityCancel();
    }
  };

  onCompletedQuantitySubmit = () => {
    const { dispatch, demandId, operation } = this.props;
    const { completedQuantity } = this.state;
    const completedQuantityError = this.validateCompletedQuantity(completedQuantity);
    if (completedQuantityError) {
      this.setState({ completedQuantityError });
      return;
    }

    // eslint-disable-next-line eqeqeq
    if (completedQuantity != operation.completedQuantity) {
      dispatch(updateOperationCompletedQuantity(demandId, operation, parseInt(completedQuantity, 10)));
    }
    this.onCompletedQuantityCancel();
  };

  onCompletedQuantityFocus = () => {
    this.completedQuantityIsFocused = true;
  };

  onCompletedQuantityBlur = () => {
    if (this.completedQuantityIsFocused) {
      this.onCompletedQuantitySubmit();
    }
    this.completedQuantityIsFocused = false;
  };

  getSelectedOperationName = () => {
    const { operation } = this.props;
    if (operation.equipments.length === 1) {
      return operation.equipments[0].name;
    }

    const equipment = operation.equipments.find((eq) => eq.id === operation.selectedEquipmentId);
    return equipment ? equipment.name : '';
  };

  getOperationClassName = () => {
    const { operation, classes } = this.props;
    if (operation.origin === 'INTERVENTION') {
      return classes.interventionOperation;
    }
    if (operation.origin === 'SUBCONTRACTOR') {
      return classes.subcontractorOperation;
    }
    return null;
  };

  getOperationOrigin = () => {
    const { operation } = this.props;
    if (operation.origin === 'INTERVENTION') {
      return "Prestation ajoutée par le technicien lors de l'intervention";
    }
    if (operation.origin === 'SUBCONTRACTOR') {
      return 'Prestation ajoutée par le prestataire';
    }
    return '';
  };

  validateCompletedQuantity = (completedQuantity) => {
    const {
      operation: { quantity },
    } = this.props;
    if (Number.isNaN(completedQuantity) || !Number.isInteger(parseFloat(completedQuantity))) {
      return 'Quantité invalide';
    }
    const completedQuantityInt = parseInt(completedQuantity, 10);
    if (completedQuantityInt < 0) {
      return `Min : ${0}`;
    }
    if (completedQuantityInt > quantity) {
      return `Max : ${quantity}`;
    }
    return '';
  };

  render() {
    const { canUserToggleCompletionStatuses, operation, readOnly, onChangeOperation, onChangeOperationGroup, classes } =
      this.props;
    const { editingCompletedQuantity, completedQuantity, completedQuantityError } = this.state;
    const onQuantityClick = () => canUserToggleCompletionStatuses && this.onCompletedChange(true);
    return (
      <TableRow className={this.getOperationClassName()} title={this.getOperationOrigin()}>
        {operation.equipments.length === 1 || readOnly ? (
          <TableCell>{this.getSelectedOperationName()}</TableCell>
        ) : (
          <TableCell>
            <AutocompleteField
              options={operation.equipments}
              fullWidth
              className={classes.equipmentSelect}
              name={`${operation.type}-${operation.equipments[0].id}-equipment`}
              value={operation.selectedEquipmentId}
              onChange={onChangeOperationGroup}
              disabled={readOnly}
            />
          </TableCell>
        )}
        {readOnly ? (
          <TableCell>{operation.quantity || ''}</TableCell>
        ) : (
          <TableCell>
            <TextField
              type="number"
              fullWidth
              name={`${operation.type}-${operation.equipments[0].id}-quantity`}
              value={operation.quantity || ''}
              validations={{
                isInt: true,
                min: operation.minQuantity || 0,
                max: operation.maxQuantity,
                isExisty: Boolean(operation.minQuantity),
              }}
              required={Boolean(operation.minQuantity)}
              validationErrors={{
                ...errorMessages.operation,
                min: `Min ${operation.minQuantity || 0}`,
                max: `Max ${operation.maxQuantity}`,
              }}
              min={operation.minQuantity || 0}
              max={operation.maxQuantity}
              disabled={readOnly}
              className={classes.quantityInput}
              onChange={(event) => onChangeOperation(operation.equipments[0].id, event.target.value)}
            />
          </TableCell>
        )}
        {readOnly && (
          <TableCell className={classNames(classes.narrow, editingCompletedQuantity && 'editing')} align="center">
            {editingCompletedQuantity ? (
              <MuiTextField
                error={Boolean(completedQuantityError)}
                helperText={completedQuantityError}
                autoFocus
                name="completedQuantity"
                type="number"
                min="0"
                max={operation.quantity}
                value={completedQuantity || ''}
                className={classes.quantityInput}
                onKeyDown={this.onCompletedQuantityKeyDown}
                onChange={this.onCompletedQuantityChange}
                onFocus={this.onCompletedQuantityFocus}
                onBlur={this.onCompletedQuantityBlur}
              />
            ) : operation.completedQuantity <= 0 || operation.completedQuantity >= operation.quantity ? (
              <Checkbox
                id={operation.selectedEquipmentId}
                disabled={!canUserToggleCompletionStatuses}
                checked={operation.completedQuantity >= operation.quantity}
                onChange={this.onCompletedChange}
                color="primary"
              />
            ) : canUserToggleCompletionStatuses ? (
              <Tooltip title="Modifier la quantité faite">
                <Link component="button" onClick={onQuantityClick} className={classes.quantityLink}>
                  {operation.completedQuantity} / {operation.quantity}
                </Link>
              </Tooltip>
            ) : (
              `${operation.completedQuantity} / ${operation.quantity}`
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export default withStyles(styles)(DemandOperation);
