import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, CardActions, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { PlusCircle } from 'mdi-material-ui';

import { CollapsibleCard, SortableTable } from '../utils';
import { getByIdDecorator } from '../../constants/AppConstants';
import OperationBundleOperation from './OperationBundleOperation';

const styles = (theme) => ({
  quantityCol: {
    width: 100,
  },
  actionCol: {
    width: 83,
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
});

class OperationBundleOperations extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    operations: PropTypes.array.isRequired,
    selectableEquipments: PropTypes.array.isRequired,
    editing: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onAddToDemands: PropTypes.func.isRequired,
    canAddToDemands: PropTypes.bool.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { title: oldTitle, operations: oldOps, editing: oldEditing, selectableEquipments: oldEqs } = this.props;
    const { title, operations, editing, selectableEquipments } = nextProps;
    return (
      editing !== oldEditing ||
      title !== oldTitle ||
      JSON.stringify(operations) !== JSON.stringify(oldOps) ||
      JSON.stringify(selectableEquipments) !== JSON.stringify(oldEqs)
    );
  }

  render() {
    const {
      title,
      listName,
      operations,
      selectableEquipments,
      editing,
      onChange,
      onSortEnd,
      onRemove,
      onAdd,
      onAddToDemands,
      canAddToDemands,
      classes,
    } = this.props;
    const equipments = getByIdDecorator(selectableEquipments);
    const usedEquipmentIds = operations.flatMap((op) => op.equipmentIds);

    return (
      <CollapsibleCard title={`${title} (${operations.length})`} withPadding={false} defaultOpened>
        <SortableTable onSortEnd={onSortEnd}>
          {!!operations.length && (
            <TableHead>
              <TableRow>
                <TableCell>Matériels</TableCell>
                <TableCell className={classes.quantityCol}>Qté max</TableCell>
                <TableCell className={classes.quantityCol}>Qté min</TableCell>
                <TableCell className={classes.actionCol} />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {operations.map((operation, idx) => (
              <OperationBundleOperation
                key={`add-op-${operation.equipmentIds.concat('-')}`}
                operation={operation}
                equipments={equipments}
                usedEquipmentIds={usedEquipmentIds}
                editing={editing}
                index={idx}
                onChange={(index, fieldName, value) => onChange(listName, index, fieldName, value)}
                onRemove={(index) => onRemove(listName, index)}
                onAddToDemands={() => onAddToDemands(idx)}
                canAddToDemands={canAddToDemands}
              />
            ))}
          </TableBody>
        </SortableTable>
        {editing && (
          <CardActions>
            <Button variant="text" color="primary" onClick={() => onAdd(listName)}>
              <PlusCircle className={classes.buttonIcon} />
              Ajouter une prestation
            </Button>
          </CardActions>
        )}
      </CollapsibleCard>
    );
  }
}

export default withStyles(styles)(OperationBundleOperations);
