import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Tooltip } from '@mui/material';
import Formsy from 'formsy-react';
import { TextField } from '../utils';
import { ATTACHMENT_OPTIONS } from '../../constants/demandTransitions';
import { performFDDTransition } from '../../actions/fdd/FDDActions';

export default class FDDTransition extends React.Component {
  static defaultButtons = {
    confirm: 'OUI',
    cancel: 'NON',
  };

  static errorMessages = {
    comment: {
      isDefaultRequiredValue: 'Veuillez saisir un commentaire',
      isExisty: 'Veuillez saisir un commentaire',
    },
  };

  static propTypes = {
    transition: PropTypes.object.isRequired,
    number: PropTypes.number,
    correctPayments: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      opened: false,
      comment: '',
    };
  }

  onOpen = () => {
    this.setState({ opened: true });
  };

  onClose = () => {
    this.setState({ opened: false });
  };

  onConfirm = () => {
    this.form.current.submit();
  };

  onCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  onValidSubmit = () => {
    this.onClose();
    const { fddId, dispatch, transition } = this.props;
    const { comment } = this.state;

    dispatch(performFDDTransition(fddId, transition, comment));
  };

  render() {
    const { transition, number, correctPayments } = this.props;
    const { opened, comment } = this.state;
    const message = transition.message(number);
    const buttons = transition.buttons || FDDTransition.defaultButtons;

    // Comment constraints
    const commentConstraints = transition.mandatoryComment ? 'isExisty' : null;
    const isCommentRequired = !!transition.mandatoryComment;

    return (
      <>
        {transition.requiresCorrectPayments && !correctPayments ? (
          <Tooltip title="Veuillez corriger les versements">
            <div>
              <Button variant="contained" disabled={true}>
                {transition.buttonLabel}
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button variant="contained" onClick={this.onOpen} color={transition.color}>
            {transition.buttonLabel}
          </Button>
        )}
        <Dialog
          open={opened}
          onClose={this.onClose}
          maxWidth={transition.attachments === ATTACHMENT_OPTIONS.NONE ? 'sm' : 'md'}
          fullWidth
        >
          <DialogContent>
            <Formsy ref={this.form} noValidate onValidSubmit={this.onValidSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{message}</DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Commentaires"
                    fullWidth
                    multiline
                    name="comment"
                    required={isCommentRequired}
                    validationErrors={FDDTransition.errorMessages.comment}
                    validations={commentConstraints}
                    value={comment}
                    onChange={this.onCommentChange}
                  />
                </Grid>
              </Grid>
            </Formsy>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>{buttons.cancel}</Button>
            <Button onClick={this.onConfirm} color={transition.color}>
              {buttons.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
