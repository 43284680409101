import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import securedPage from '../../securedPage';
import Demand from '../../demands/Demand';
import roles from '../../../constants/roles';

const DemandPage = () => {
  const { demandId } = useParams();
  const { search } = useLocation();
  return <Demand demandId={demandId} outletCode={queryString.parse(search).outlet} />;
};

export default securedPage(roles.demand.view.code)(DemandPage);
