import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import withWidth from '@mui/material/Hidden/withWidth';
import DateViewer from '../commons/DateViewer';
import { COMPANIES } from '../../constants/AppConstants';
import Grid from '@mui/material/Grid';
import { compose } from 'recompose';
import classNames from 'classnames';

const styles = (theme) => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexEnd: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  listRow: {
    '&:nth-of-type(2n)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  muted: {
    fontStyle: 'italic',
    color: theme.palette.grey[500],
  },
});

const DemandsListItem = ({ classes, demand, onClick, width }) => {
  const renderAgency = (demand) => (
    <span>
      {demand.agency.name} <span className={classes.muted}>{demand.area.name && '(' + demand.area.name + ')'}</span>
    </span>
  );

  const renderValidatedDate = (demand) =>
    demand.validationDate ? (
      <span>
        Validée : <DateViewer date={demand.validationDate} />
      </span>
    ) : null;

  const renderOperationScheduleDate = (demand) =>
    demand.operationScheduleDate ? (
      <span>
        Planifiée : <DateViewer date={demand.operationScheduleDate} />
      </span>
    ) : null;

  const renderDemandType = (demand) => (
    <span>
      {demand.demandType.name}
      {' - '}
      <span style={{ color: demand.concept.color }}>{demand.concept.name}</span>{' '}
      {COMPANIES.getById(demand.subcontractor.id).icon}
    </span>
  );

  const getSecondary = () =>
    width === 'xs' ? (
      <>
        {renderAgency(demand)}
        <br />
        {renderDemandType(demand)}
        {renderValidatedDate(demand) && (
          <>
            <br />
            {renderValidatedDate(demand)}
          </>
        )}
        {renderOperationScheduleDate(demand) && (
          <>
            <br />
            {renderOperationScheduleDate(demand)}
          </>
        )}
      </>
    ) : (
      <Grid component="span" container>
        <Grid component="span" item xs={12} sm={6} className={classes.flexColumn}>
          {renderAgency(demand)}
          {renderValidatedDate(demand)}
        </Grid>
        <Grid component="span" item xs={12} sm={6} className={classNames(classes.flexColumn, classes.flexEnd)}>
          {renderDemandType(demand)}
          {renderOperationScheduleDate(demand)}
        </Grid>
      </Grid>
    );

  return (
    <ListItem key={demand.id} className={classes.listRow} onClick={onClick}>
      <ListItemText
        primary={
          <>
            <strong>{demand.outlet.code + ' - ' + demand.outlet.name + ' '}</strong>
            <span className={classes.muted}>n°{demand.number} </span>
            <br />
            <span>{demand.status.name}</span>
            <br />
          </>
        }
        secondary={getSecondary()}
      />
    </ListItem>
  );
};

export default compose(withStyles(styles), withWidth())(DemandsListItem);
