import {
  DEMAND_LOADED,
  DEMAND_SAVED,
  DEMAND_RESET,
  DEMAND_ERROR,
  DEMAND_VALIDATION_ERROR,
  DEMAND_OPERATION_COMPLETION_UPDATE,
  DEMAND_UPDATE_INVOICE,
  DEMAND_COST_UPDATED,
} from '../constants/AppConstants';

const emptyDemand = {
  id: null,
  number: null,
  outletCode: '',
  outlet: null,
  type: null,
  concept: null,
  comment: '',
  answers: {},
  addOperations: null,
  removeOperations: null,
  moveOperations: null,
  events: [],
};

const initialState = {
  /**
   * The demand object. Initialized to a blank demand that can be used to initialize a new demand form.
   */
  demand: { ...emptyDemand },
  /**
   * Used to be aware that the demand has been updated, independently of whether the demand content itself has changed.
   */
  counter: 0,
  /**
   * Demand loading error details.
   */
  error: null,
  /**
   * Demand validation errors.
   */
  validationErrors: [],
  /**
   * To know when form inputs must be updated
   */
  validationErrorsCounter: 0,
};

function changeCompleted(operations, equipmentId, completedQuantity) {
  return operations.map((op) =>
    op.selectedEquipmentId === equipmentId
      ? {
          ...op,
          completedQuantity,
        }
      : op,
  );
}

export default function (state = initialState, action) {
  switch (action.type) {
    case DEMAND_LOADED:
      return {
        ...state,
        demand: action.demand,
        counter: state.counter + 1,
        error: null,
        validationErrors: [],
      };
    case DEMAND_SAVED: {
      const demand = {
        ...state.demand,
        ...action.demand,
      };
      return {
        ...state,
        demand,
        counter: state.counter + 1,
        error: null,
      };
    }
    case DEMAND_COST_UPDATED: {
      return {
        ...state,
        demand: {
          ...state.demand,
          cost: action.cost,
        },
        counter: state.counter + 1,
        error: null,
        validationErrors: [],
      };
    }
    case DEMAND_RESET:
      return {
        ...state,
        demand: { ...emptyDemand },
        counter: 0,
        error: null,
        validationErrors: [],
      };
    case DEMAND_ERROR:
      return {
        ...state,
        demand: { ...emptyDemand },
        error: action.err,
        validationErrors: [],
      };
    case DEMAND_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: action.errors,
        validationErrorsCounter: state.validationErrorsCounter + 1,
      };
    case DEMAND_OPERATION_COMPLETION_UPDATE: {
      /*
       * The "completion" flag has been changed for an operation.
       * We do not want to reload the demand entirely form the server. So, we update the "completion"
       * field of the operation in the store.
       */
      const { addOperations, moveOperations, removeOperations } = state.demand;

      const demand = {
        ...state.demand,
        addOperations:
          action.operationType === 'ADD'
            ? changeCompleted(addOperations, action.equipmentId, action.completedQuantity)
            : addOperations,
        moveOperations:
          action.operationType === 'MOVE'
            ? changeCompleted(moveOperations, action.equipmentId, action.completedQuantity)
            : moveOperations,
        removeOperations:
          action.operationType === 'REMOVE'
            ? changeCompleted(removeOperations, action.equipmentId, action.completedQuantity)
            : removeOperations,
      };
      return {
        ...state,
        demand,
        counter: state.counter + 1,
      };
    }
    case DEMAND_UPDATE_INVOICE:
      return {
        ...state,
        demand: {
          ...state.demand,
          invoice: action.invoice,
        },
        counter: state.counter + 1,
      };
    default:
      return state;
  }
}
