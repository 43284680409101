import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import debugFactory from 'debug';
import { Card, CardActions, CardContent, Grid, LinearProgress, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  resetReloadDemandCostsStatus,
  loadReloadDemandCostsStatus,
  reloadDemandCosts,
} from '../../actions/settings/ReloadDemandCostsActions';
import { formatDate, formatDateTime } from '../../utils/date-utils';
import ReloadDemandCosts from './ReloadDemandCosts';

const debug = debugFactory('prestago:ReloadDemandCostsStatus');

const styles = (theme) => ({
  statusHeader: {
    backgroundColor: theme.palette.grey[100],
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    justifyContent: 'flex-start',
  },
});

class ReloadDemandCostsStatus extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(resetReloadDemandCostsStatus());
    this.initializeLoadStatus();
  }

  componentWillUnmount() {
    debug('Clearing interval: %s', this.reloadIntervalId);
    window.clearInterval(this.reloadIntervalId);
  }

  onReloadDemandCosts = ({ date }) => {
    const { dispatch } = this.props;
    dispatch(reloadDemandCosts(date));
    window.setTimeout(this.initializeLoadStatus, 1000);
  };

  initializeLoadStatus = () => {
    this.nbRefreshTries = 0;
    this.loadStatus();
    if (!this.reloadIntervalId) {
      this.reloadIntervalId = window.setInterval(this.loadStatus, 5000);
    }
  };

  loadStatus = () => {
    debug('loadStatus');
    const { dispatch, statuses } = this.props;

    if (
      this.nbRefreshTries >= 2 &&
      this.reloadIntervalId &&
      (!statuses ||
        !statuses.length ||
        statuses.every(
          (status) =>
            (status.total !== null && status.progress === status.total) || (status.errors && status.errors.length),
        ))
    ) {
      debug('stopping refresh after %d tries.', this.nbRefreshTries);
      window.clearInterval(this.reloadIntervalId);
      this.reloadIntervalId = null;
    } else {
      dispatch(loadReloadDemandCostsStatus());
      this.nbRefreshTries = (this.nbRefreshTries || 0) + 1;
    }
  };

  render() {
    const { statuses, classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardActions className={classes.actions}>
              <ReloadDemandCosts onReloadDemandCosts={this.onReloadDemandCosts} />
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1">
            {statuses && statuses.length ? 'Mises à jour de la dernière heure :' : 'Aucune mise à jour récente'}
          </Typography>
        </Grid>
        {statuses &&
          statuses.map(
            ({ type, operationName, equipmentName, createdSince, progress, total, errors, creationDate }) => (
              <Grid item xs={12} key={`${equipmentName}-${creationDate}`}>
                <Card>
                  <CardContent className={classes.statusHeader}>
                    <Typography variant="h2">
                      Mise à jour des {type === 'reload-demand-costs' && 'tarifs de'} demandes
                      {createdSince && ` créées depuis le ${formatDate(createdSince)}`}
                      {equipmentName && ` pour le matériel "${equipmentName}"`}
                      {operationName && ` pour ajouter ${operationName}`}
                    </Typography>
                    <Typography variant="body2">Démarrée à {formatDateTime(creationDate, 'HH[h]mm')}</Typography>
                  </CardContent>
                  <CardContent>
                    {total !== null || (errors && errors.length) ? (
                      <>
                        <Typography variant="body1">
                          {progress || 0} demande{progress > 1 ? 's' : ''} mise
                          {progress > 1 ? 's' : ''} à jour sur {total || 0}
                        </Typography>
                        <LinearProgress variant="determinate" value={total ? (100 * progress) / total : 100} />
                      </>
                    ) : (
                      <>
                        <Typography variant="body1">Mises à jour des demandes en cours</Typography>
                        <LinearProgress variant="indeterminate" />
                      </>
                    )}
                    {errors && errors.length > 0 && (
                      <Typography variant="body1">
                        Erreurs :
                        <ul>
                          {errors.map((error) => (
                            <li>{error}</li>
                          ))}
                        </ul>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ),
          )}
      </Grid>
    );
  }
}

const stateToProps = ({ reloadDemandCostsStatus: statuses }) => ({ statuses });

export default compose(withStyles(styles), connect(stateToProps))(ReloadDemandCostsStatus);
