import React from 'react';
import { AppBar, Tabs } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    boxShadow: `inset 0 -2px 0 0 ${theme.palette.grey[200]}`,
  },
  tabs: {},
});

const ResponsiveTabs = ({ classes, onChangeIndex, ...props }) => (
  <AppBar position="static" color="default" className={classes.root}>
    <Tabs
      onChange={(event, index) => onChangeIndex(index)}
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
      className={classes.tabs}
      {...props}
    />
  </AppBar>
);

export default withStyles(styles)(ResponsiveTabs);
