import React from 'react';
import { connect } from 'react-redux';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { loadBundles, sortBundles } from '../../actions/settings/OperationBundleActions';
import { sort, SortDirections } from '../../utils/sorting';
import { CardHeader, ResponsiveTable, SortableTableHeader } from '../utils';
import roles from '../../constants/roles';
import { COMPANIES } from '../../constants/AppConstants';
import history from '../../history';

import 'intl';
import 'intl/locale-data/jsonp/fr';

import { formatCurrency } from '../../utils/currency-utils';

class OperationBundlesTable extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadBundles());
  }

  onNewClick = () => {
    history.push('/settings/operation-bundles/new');
  };

  onHeaderClick(field) {
    const { dispatch, sorts } = this.props;
    const direction = sorts[0].field === field ? -sorts[0].direction : SortDirections.asc;
    dispatch(sortBundles(field, direction));
  }

  onRowClick = (equipmentId) => {
    history.push({ pathname: `/settings/operation-bundles/${equipmentId}` });
  };

  sortDirection = (field) => {
    const { sorts } = this.props;
    if (sorts[0].field === field) {
      return sorts[0].direction;
    }
    return SortDirections.none;
  };

  render() {
    const { bundles } = this.props;
    return (
      <Card>
        <CardHeader addLabel="Créer un nouveau forfait" addRole={roles.equipment.create.code} onAdd={this.onNewClick}>
          Paramétrage des forfaits
        </CardHeader>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <SortableTableHeader
                direction={this.sortDirection('name')}
                onClickCallback={() => this.onHeaderClick('name')}
                title="Libellé"
              />
              <SortableTableHeader
                direction={this.sortDirection('demandTypeName')}
                onClickCallback={() => this.onHeaderClick('demandTypeName')}
                title="Type de demande"
              />
              <SortableTableHeader
                direction={this.sortDirection('subcontractor')}
                onClickCallback={() => this.onHeaderClick('subcontractor')}
                title="Prestataire"
              />
              <SortableTableHeader
                direction={this.sortDirection('price')}
                onClickCallback={() => this.onHeaderClick('price')}
                title="Prix"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {bundles.map((bundle) => (
              <TableRow key={bundle.id} onClick={() => this.onRowClick(bundle.id)} hover>
                <TableCell>{bundle.name}</TableCell>
                <TableCell>{bundle.demandTypeName}</TableCell>
                <TableCell>{COMPANIES.getNameById(bundle.subcontractor)}</TableCell>
                <TableCell align="right">{formatCurrency(bundle.price)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
      </Card>
    );
  }
}

const stateToProps = ({ operationBundles: { list, sorts } }) => ({
  bundles: sort(list, sorts),
  sorts,
});

export default connect(stateToProps)(OperationBundlesTable);
