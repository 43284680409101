import { arrayMove } from 'react-sortable-hoc';
import { AFTER_SALES_OPERATIONS_LOADED, AFTER_SALES_OPERATION_UPDATING_POSITION } from '../../constants/AppConstants';

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AFTER_SALES_OPERATIONS_LOADED:
      return {
        ...state,
        list: action.afterSalesOperations,
      };
    case AFTER_SALES_OPERATION_UPDATING_POSITION:
      // Proactively update the displayed list while the position is saved to avoid flickering
      return {
        list: arrayMove(state.list, action.oldPosition, action.newPosition),
      };
    default:
      return state;
  }
}
