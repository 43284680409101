import React from 'react';
import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { formatDateTime } from '../../utils/date-utils';

export const footerHeight = 80;
const styles = (theme) => ({
  footer: {
    textAlign: 'center',
    color: theme.palette.grey[500],
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      bottom: 0,
    },
    left: 0,
    right: 0,
    height: footerHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const Footer = ({ classes }) => {
  const appVersion = process.env.REACT_APP_APP_VERSION || 'dev';
  const appCommitId = process.env.REACT_APP_APP_COMMIT_ID || '';
  const appTimestamp = process.env.REACT_APP_APP_TIMESTAMP || '';
  const appBuildDate = appTimestamp && ` - ${formatDateTime(parseInt(appTimestamp, 10))}`;

  return (
    <footer className={classes.footer}>
      <Typography variant="body1" color="inherit">
        Copyright &copy; PMU - <span title={appCommitId + appBuildDate}>v.{appVersion}</span>
      </Typography>
    </footer>
  );
};

export default withStyles(styles)(Footer);
