import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material';
import { Check, Close, Delete } from 'mdi-material-ui';

import { EditableTableRow } from '../utils';
import { addGlobalMessage } from '../../actions/SnackbarActions';
import { deleteSubGoal, saveSubGoal } from '../../actions/fdd/FDDSubGoalActions';
import { SubGoal } from '../../model/model';
import { useAppDispatch } from '../../hooks';
import { AppDispatch } from '../../store';

type FDDSubGoalsRowProps = {
  subGoal: SubGoal;
  disabled: boolean;
};

const FDDSubGoalRow = ({ subGoal: subGoalProp }: FDDSubGoalsRowProps) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [deleting, setDeleting] = useState<boolean>(false);
  const [subGoal, setSubGoal] = useState<SubGoal>(subGoalProp);
  const [editing, setEditing] = useState<boolean>(!subGoal?.name);

  const onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setEditing(true);
  };

  const onChangeName = (event): void => {
    setSubGoal({
      ...subGoal,
      name: event.target.value,
    });
  };

  const onKeyDown = (event): void => {
    if (event.keyCode === 13) {
      // Case enter is pressed
      onSave(event);
    } else if (event.keyCode === 27) {
      // Case escape is pressed
      onCancelEdit(event);
    }
  };

  const onCancelEdit = (event): void => {
    event.preventDefault();
    event.stopPropagation();

    if (subGoal?.id) {
      setSubGoal(subGoalProp);
    } else {
      setSubGoal(null);
    }
    setEditing(false);

    dispatch(addGlobalMessage('Modifications annulées'));
  };

  const onSave = (event): void => {
    event.preventDefault();
    event.stopPropagation();

    if (subGoal) {
      dispatch(saveSubGoal(subGoal))
        .then(() => {
          setEditing(false);
          dispatch(addGlobalMessage('Modifications sauvegardées'));
        })
        .catch((error): void => {
          console.error('Erreur lors de la sauvegarde du sous-objectif : ', error);
        });
    }
  };

  const onAskDeleteConfirmation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDeleting(true);
  };

  const onCancelDelete = () => {
    setDeleting(false);
  };

  const onDelete = () => {
    dispatch(deleteSubGoal(subGoal));
    dispatch(addGlobalMessage(`Sous-objectif supprimé : ${subGoal?.name}`));
  };

  return (
    <EditableTableRow
      editable
      editing={editing}
      onEdit={(event) => onEditClick(event)}
      actions={
        <>
          <Tooltip title="Enregistrer le sous-objectif">
            <IconButton onClick={onSave} size="large">
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title="Annuler les changements">
            <IconButton onClick={onCancelEdit} size="large">
              <Close />
            </IconButton>
          </Tooltip>
          {Boolean(subGoal?.id) && (
            <Tooltip title="Supprimer le sous-objectif">
              <IconButton onClick={onAskDeleteConfirmation} size="large">
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
    >
      <TableCell>
        {editing ? (
          <TextField
            name="name"
            autoFocus
            value={subGoal?.name}
            onChange={onChangeName}
            fullWidth
            onKeyDown={onKeyDown}
          />
        ) : (
          <span>{subGoal?.name}</span>
        )}
      </TableCell>
      <Dialog open={deleting} onClose={onCancelDelete}>
        <DialogContent>
          <DialogContentText>Êtes-vous sûr de vouloir supprimer le sous-objectif {subGoal?.name} ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelDelete}>Annuler</Button>
          <Button color="secondary" onClick={onDelete}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </EditableTableRow>
  );
};

export default FDDSubGoalRow;
