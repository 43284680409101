import { addValidationRule } from 'formsy-react';
import { Values } from 'formsy-react/dist/interfaces';

export const addOutletExistsValidationRule = () =>
  addValidationRule('outletExists', (values, value, outlet) => !!outlet);

export const addMinValidationRule = () =>
  addValidationRule(
    'min',
    (values: Values, value, min) =>
      min === undefined || min === null || value === undefined || value === null || value === '' || value >= min,
  );

export const addMaxValidationRule = () =>
  addValidationRule(
    'max',
    (values: Values, value, max) =>
      max === undefined || max === null || value === undefined || value === null || value === '' || value <= max,
  );
