import { DAY_ACTIVITY_FILTER_UPDATED, DAY_ACTIVITY_LOADED } from '../constants/AppConstants';

const initialState = {
  data: {},
  filter: {},
};

export default function (state = initialState, action) {
  Object.freeze(state);
  switch (action.type) {
    case DAY_ACTIVITY_FILTER_UPDATED:
      return {
        ...state,
        filter: action.filter,
      };
    case DAY_ACTIVITY_LOADED:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
