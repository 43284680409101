import debugFactory from 'debug';

import { api, ignore401 } from '../services/RestService';
import { loadDemand } from './DemandActions';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';
import httpService from '../services/HttpService';
import { getErrorMessage } from '../services/ErrorMessageService';
import {
  NEW_TRANSITION_ATTACHMENT_UPLOADING,
  NEW_TRANSITION_ATTACHMENT_UPLOADED,
  TRANSITION_ATTACHMENT_DELETED,
  urls,
} from '../constants/AppConstants';
import { escapeFileName } from '../utils/attachment-utils';

const debug = debugFactory('prestago:DemandTransitionActions');

const attachmentApiUrl = (demandId, attachmentId, fileName) =>
  `/api/demands/transitions/${demandId}/attachments${
    attachmentId && fileName ? `/${attachmentId}/${escapeFileName(fileName)}` : ''
  }`;

export function performDemandTransition(demandId, transition, formData) {
  return (dispatch) => {
    const url = urls.demandTransitions + transition.path;
    api
      .custom(url)
      .post({
        id: demandId,
        transition: transition.id,
        message: formData.comment,
      })
      .then(() => {
        dispatch(addGlobalMessage(transition.confirmationMessage));
        dispatch(loadDemand(demandId));
      })
      .catch(ignore401)
      .catch((err) => {
        console.error('Error while performing transition:', err);
        dispatch(addGlobalError(`Erreur : ${getErrorMessage(err)}`));
      });
  };
}

export function addTransitionAttachment(files, demandId) {
  return (dispatch) => {
    debug('Saving next transition attachments: %o %s', files, demandId);

    files.forEach((file) => {
      const fileName = file.attachmentName || file.name;

      /*
       * Adding the attachment to the list in Redux.
       */
      dispatch({
        type: NEW_TRANSITION_ATTACHMENT_UPLOADING,
        attachment: {
          name: fileName,
          type: file.type,
          thumbnail: file.type.startsWith('image/') ? file.preview : null,
          uploading: true,
        },
      });

      // Call the POST WS
      const formData = new FormData();
      formData.append('file', file, fileName);
      httpService
        .post({
          url: attachmentApiUrl(demandId),
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => Promise.reject(json));
          }
          return response.json();
        })
        .then((json) =>
          dispatch({
            type: NEW_TRANSITION_ATTACHMENT_UPLOADED,
            attachment: json,
          }),
        )
        .catch((err) => {
          console.error('Error while saving the attachment:', err);
          dispatch(addGlobalError(getErrorMessage(err)));
        });
    });
  };
}

export function deleteTransitionAttachment(demandId, attachmentId, attachmentName) {
  return (dispatch) => {
    /*
     * Removing the attachment in Redux.
     */
    dispatch({
      type: TRANSITION_ATTACHMENT_DELETED,
      id: attachmentId,
      name: attachmentName,
    });

    /*
     * Removing the attachment in the back-end server.
     */
    httpService
      .delete({ url: attachmentApiUrl(demandId, attachmentId, attachmentName) })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response;
      })
      .catch((err) => {
        console.error('Error while deleting the attachment:', err);
        dispatch(addGlobalError(getErrorMessage(err)));
      });
  };
}
