import { OUTLETS_IMPORTS_LOADED } from '../constants/AppConstants';

const initialState = {
  list: [],
  pagination: {
    currentPage: 0,
    numberOfPages: 0,
    pageSize: 5,
    totalElements: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OUTLETS_IMPORTS_LOADED:
      return {
        ...state,
        list: action.page.content,
        pagination: {
          currentPage: action.page.number,
          numberOfPages: action.page.totalPages,
          pageSize: action.size || initialState.pagination.pageSize,
          totalElements: action.page.totalElements,
        },
      };
    default:
      return state;
  }
}
