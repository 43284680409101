import { Sort, SortDirection } from '../model/pagination';

/**
 * Get object attribute from 'dot notation'.
 * For instance when path is 'agency.name' then recompose result is obj[agency][name]
 *
 * @param obj target object
 * @param path path of attribute written in 'dot notation'
 * @returns {*}
 */
function recompose<T>(obj: T, path: string) {
  return path.split('.').reduce((o, i) => o?.[i], obj);
}

/**
 * Sorts the given array of items according to the given
 * array of sorts.
 *
 * Each sort is an object containing two fields: the name
 * of the field that must be used to sort and the direction. The
 * direction is an integer. the value is greater than 0
 * for an ascending sort and lower than 0 for a descending
 * sort.
 *
 * @param items The array to sort.
 * @param sorts The array of sorts.
 */
export const sort = <T>(items: T[], sorts: Sort[]): T[] => {
  const copy = items.slice(0);
  if (Array.isArray(items)) {
    copy.sort((a, b) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const sortItem of sorts) {
        const fieldA = recompose(a, sortItem.field);
        const fieldB = recompose(b, sortItem.field);
        const direction = sortItem.direction;
        if (fieldA !== fieldB) {
          if (typeof fieldA === 'string' && typeof fieldB === 'string') {
            return fieldA.toUpperCase() < fieldB.toUpperCase() ? -direction : direction;
          }
          return fieldA < fieldB ? -direction : direction;
        }
      }
      return 0;
    });
  }
  return copy;
};

/**
 * Adds a new sort item to the array of sorts. If there
 * was already a sort of the same field, the old one is
 * removed.
 *
 * @param currentSorts The current array of sorts.
 * @param field The field of the new sort.
 * @param direction The direction of the new sort.
 * @returns The new array of sort items.
 */
export const computeSorts = (currentSorts: Sort[], field: string, direction: SortDirection): Sort[] => {
  const sorts: Sort[] = [{ field, direction }];
  return sorts.concat(currentSorts.filter((s) => s.field !== field));
};

export const SortDirections: Record<string, SortDirection> = {
  asc: 1,
  desc: -1,
  none: 0,
};

export const compareByName = <T extends { name: string }>(a: T, b: T): number => a.name.localeCompare(b.name);

export const invertSortDirection = (direction: SortDirection): SortDirection => -direction as SortDirection;
