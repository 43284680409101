import { RELOAD_DEMAND_COSTS_STATUS_LOADED } from '../../constants/AppConstants';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case RELOAD_DEMAND_COSTS_STATUS_LOADED:
      return action.statuses;
    default:
      return state;
  }
}
