import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CardContent } from '@mui/material';

import AttachmentsZone from '../utils/AttachmentsZone';

import { addAttachments, deleteAttachment } from '../../actions/InterventionAttachmentsActions';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';

class InterventionAttachments extends React.Component {
  static propTypes = {
    attachments: PropTypes.array.isRequired,
    interventionId: PropTypes.string.isRequired,
    onAttachmentsChange: PropTypes.func.isRequired,
  };

  /**
   * Workaround for tabs resizing.
   *
   * react-swipeable-views calculates the tab height on tab transition.
   * So, when a new attachment with a long name is added, the tab area does not
   * expand and part of the name is hidden (visible through a scroll bar).
   *
   * The workaround is described here :
   * https://github.com/oliviertassinari/react-swipeable-views/issues/36.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onAttachmentsChange } = this.props;
    const attachments = Object.values(this.props.attachments);
    const nextAttachments = Object.values(nextProps.attachments);
    if (attachments.length !== nextAttachments.length) {
      onAttachmentsChange();
    }
  }

  onAdd = (acceptedFiles, rejectedFiles) => {
    const { dispatch, interventionId } = this.props;
    dispatch(addAttachments(interventionId, acceptedFiles, rejectedFiles));
  };

  onRemove = (attachmentId, attachmentName) => {
    const { dispatch, interventionId } = this.props;
    dispatch(deleteAttachment(interventionId, attachmentId, attachmentName));
  };

  render() {
    const { dispatch, attachments, readOnly } = this.props;

    return (
      <CardContent>
        <AttachmentsZone
          attachments={attachments}
          dispatch={dispatch}
          onAdd={this.onAdd}
          onRemove={this.onRemove}
          readOnly={readOnly}
        />
      </CardContent>
    );
  }
}
const canUserAddAttachments = (state) => {
  const intervention = state.intervention;
  if (!intervention || !intervention.intervention || !intervention.intervention.status) {
    return false;
  }
  return hasRole(state.currentUser, roles.intervention.process.code);
};

export default connect((state) => ({
  attachments: Object.values(state.interventionAttachments),
  readOnly: !canUserAddAttachments(state),
}))(InterventionAttachments);
