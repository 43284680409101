import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import EquipmentsTable from '../../equipments/EquipmentsTable';
import { withPageTitle } from '../../../utils/page-title';

const EquipmentsPage = () => <EquipmentsTable />;

export default compose(
  withPageTitle(() => 'Paramétrage des matériels'),
  securedPage(roles.equipment.view.code),
)(EquipmentsPage);
