import {
  CommandEquipment,
  CommandOperation,
  DemandEquipment,
  DemandOperation,
  EquipmentWithSerialNumbers,
} from '../model/model';

export const operationName = (operation: DemandOperation): string => {
  // noinspection JSUnresolvedVariable
  const equipments = operation.equipments;
  if (equipments.length === 1) {
    return equipments[0].name;
  }

  const equipment = equipments.find((eq) => eq.id === operation.selectedEquipmentId);
  return equipment ? equipment.name : '';
};

const getEquipmentsWithSerialNumber = (equipment: DemandEquipment): DemandEquipment[] => {
  const subEquipments: DemandEquipment[] = equipment.equipments || [];
  const result: DemandEquipment[] = subEquipments.reduce(
    (equipments: DemandEquipment[], eq: DemandEquipment) => equipments.concat(getEquipmentsWithSerialNumber(eq)),
    [],
  );

  return equipment.fillSerialNumber ? result.concat(equipment) : result;
};

export const equipmentsWithSerialNumberToFill = (operation: DemandOperation): DemandEquipment[] => {
  // noinspection JSUnresolvedVariable
  const equipment = operation.equipments.find((eq) => eq.id === operation.selectedEquipmentId);
  return getEquipmentsWithSerialNumber(equipment);
};

// noinspection JSUnresolvedVariable
const equipmentHasSerialNumberToFill = (eq: DemandEquipment): boolean =>
  eq.fillSerialNumber || (eq.equipments && eq.equipments.some(equipmentHasSerialNumberToFill));

export const operationHasSerialNumbers = (op: DemandOperation): boolean => {
  if (!op.displayed || op.quantity < 1) {
    return false;
  }

  const equipmentId = op.selectedEquipmentId;
  // noinspection JSUnresolvedVariable
  const equipment = op.equipments.find((eq) => eq.id === equipmentId);
  return equipmentHasSerialNumberToFill(equipment);
};

export const operationsWithSerialNumbers = (operations: DemandOperation[]): DemandOperation[] =>
  operations ? operations.filter(operationHasSerialNumbers) : [];

export const transformOperationToEquipmentsWithSerialNumbers = (op: DemandOperation): EquipmentWithSerialNumbers[] =>
  equipmentsWithSerialNumberToFill(op)
    .map((eq) => {
      const result: EquipmentWithSerialNumbers = {
        id: eq.id,
        code: eq.code,
        name: eq.name,
        operation: op,
        serialNumbers: eq.serialNumbers,
        serialNumberConstraints: {
          minCharacters: eq.minCharacters,
          maxCharacters: eq.maxCharacters,
          serialNumberType: eq.serialNumberType,
          unauthorizedCharacters: eq.unauthorizedCharacters,
        },
      };

      /*
       * If the array containing serial numbers does not exist or does not
       * the correct size, the correct array is created.
       */
      if (!result.serialNumbers || result.serialNumbers.length < op.quantity) {
        const serialNumbers = [].concat(result.serialNumbers || []);
        for (let i = serialNumbers.length; i < op.quantity; i++) {
          serialNumbers.push('');
        }
        result.serialNumbers = serialNumbers;
      }

      return result;
    })
    .sort(compareEquipmentsByName);

const compareEquipmentsByName = (eq1: EquipmentWithSerialNumbers, eq2: EquipmentWithSerialNumbers): number =>
  eq1.name.localeCompare(eq2.name);

/**
 * Transforms each of the input operations into a object of the following format:
 * <ul>
 *    <li>id: The equipment's id,</li>
 *    <li>name: The equipment's name,</li>
 *    <li>operation: The operation the equipment is related to,</li>
 *    <li>serialNumbers: The array containing the serial numbers for the equipments.</li>
 * </ul>
 *
 * Even if no serial number was provided for the equipments, the array is of the expected
 * size. Empty serial numbers are empty strings.
 *
 * @param operations The operation to transform.
 * @returns The equipments array.
 */
export const transformOperationsToEquipmentsWithSerialNumbers = (
  operations: DemandOperation[],
): EquipmentWithSerialNumbers[] =>
  operations.flatMap(transformOperationToEquipmentsWithSerialNumbers).sort(compareEquipmentsByName);

const serialNumberIsFilled = (sn: string) => sn && !/^\s+$/.test(sn);

/**
 * Returns a boolean indicating whether the given operation has at least
 * one missing serial numbers.
 *
 * @param operation The operation to check.
 * @returns {@code true} if there is at least one missing serial number.
 */
export const hasOperationMissingSerialNumbers = (operation: DemandOperation): boolean => {
  const equipments = transformOperationsToEquipmentsWithSerialNumbers([operation]);
  return equipments.some((eq) => eq.serialNumbers.filter(serialNumberIsFilled).length < operation.completedQuantity);
};

/**
 * Returns a boolean indicating whether the intervention has missing serial numbers
 * for operations that are completed.
 */
export const hasMissingSerialNumbers = (operations: DemandOperation[] = []): boolean => {
  const ops = operationsWithSerialNumbers(operations).filter((op) => op.completedQuantity > 0);
  const equipments = transformOperationsToEquipmentsWithSerialNumbers(ops);
  return equipments.some((eq) => eq.serialNumbers.filter(serialNumberIsFilled).length < eq.operation.completedQuantity);
};

/**
 * Returns a boolean indicating whether the given list of operations
 * contains at least one completed operation.
 *
 * @param operations The input list of operations.
 */
export const hasCompletedOperations = (operations: DemandOperation[] = []): boolean =>
  operations.some((op) => op.displayed && op.quantity > 0 && op.completedQuantity > 0);

export const operationHasNonDummyEquipment = (operation: DemandOperation): boolean => {
  const selectedEquipment = operation.equipments.find((eq) => eq.id === operation.selectedEquipmentId);
  if (selectedEquipment.type === 'KIT') {
    return selectedEquipment.equipments.some((eq) => !eq.dummy);
  }
  return !selectedEquipment.dummy;
};

/**
 * Returns a boolean indicating whether the given list of operations contains at least one non-dummy equipment.
 */
export const hasNonDummyEquipments = (operations: DemandOperation[] = []): boolean =>
  operations.some((op) => op.displayed && op.quantity > 0 && operationHasNonDummyEquipment(op));

export const operationsWithNonDummyEquipments = (operations: DemandOperation[] = []): DemandOperation[] =>
  operations
    .filter((op) => op.displayed && op.quantity > 0 && operationHasNonDummyEquipment(op))
    .map((op) => ({ ...op }));

export const extractCommandQuantities = (operations?: CommandOperation[]): CommandEquipment[] => {
  if (!operations?.length) {
    return [];
  }
  return operations
    .filter((op) => op.commandQuantity)
    .flatMap((op) => {
      const equipment = op.equipments.find((eq) => eq.id === op.selectedEquipmentId);
      if (!equipment) {
        return [];
      }
      if (equipment.type === 'KIT') {
        return equipment.equipments
          .filter((eq) => !eq.dummy)
          .map((eq) => ({
            equipmentId: equipment.id,
            equipmentCode: eq.code,
            quantity: op.commandQuantity,
          }));
      }
      return [
        {
          equipmentId: equipment.id,
          equipmentCode: equipment.code,
          quantity: op.commandQuantity,
        },
      ];
    });
};
