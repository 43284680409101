import { AsyncAutocompleteField, AsyncAutocompleteFieldProps } from '../utils/AutocompleteField';
import { ElementType } from 'react';
import { ChipTypeMap } from '@mui/material/Chip';
import { api, ignore401 } from '../../services/RestService';
import { urls } from '../../constants/AppConstants';
import { isArray } from 'lodash';
import { Outlet } from '../../model/model';

type OutletAutocompleteFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<AsyncAutocompleteFieldProps<Outlet, Multiple, DisableClearable, ChipComponent>, 'loadOptions'> &
  (Multiple extends true
    ? {
        outletNames?: string[];
      }
    : {});

export const loadOutlet = async (code: string): Promise<Outlet> => {
  const outletLoadByCodeEndpoint = api.one(urls.outletByCode, code);

  try {
    const response = await outletLoadByCodeEndpoint.get();
    return response.body(false);
  } catch (error) {
    return ignore401(error);
  }
};

const loadOptions = async (prefix = ''): Promise<Outlet[]> => {
  const outletsAutoCompletionEndpoint = api.all(urls.outletAutoCompletion);

  try {
    const response = await outletsAutoCompletionEndpoint.getAll({ prefix });
    return response.body(false);
  } catch (error) {
    return ignore401(error);
  }
};

const getOptionLabel = ({ id, code, name }: Outlet) => (code ? `${code} - ${name}` : name ?? id);

const OutletAutocompleteField = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: OutletAutocompleteFieldProps<Multiple, DisableClearable, ChipComponent>,
) => {
  let knownOptions: Outlet[] = [];
  if (
    props.multiple &&
    'outletNames' in props &&
    isArray(props.value) &&
    props.outletNames.length === props.value.length
  ) {
    knownOptions = props.value.map((id, index) => ({ id, name: props.outletNames[index] }));
  }
  return (
    <AsyncAutocompleteField
      getOptionLabelInInput={props.multiple ? getOptionLabel : ({ code, name }: Outlet) => code ?? name}
      getOptionLabel={getOptionLabel}
      {...props}
      loadOptions={loadOptions}
      knownOptions={knownOptions}
    />
  );
};

export default OutletAutocompleteField;
