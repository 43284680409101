import React from 'react';
import { compose } from 'recompose';

import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import ReloadDemandCostsStatus from '../../reload-demands/ReloadDemandCostsStatus';
import { withPageTitle } from '../../../utils/page-title';

const EquipmentsPage = () => <ReloadDemandCostsStatus />;

export default compose(
  withPageTitle(() => 'Mise à jour des tarifs de demandes'),
  securedPage(roles.demand.updateCost.code),
)(EquipmentsPage);
